import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setWindowWidth } from 'reducers/window-size.slice'
import { throttle } from 'utils/CommonUtils'

/**
 * Custom React Hook to handle window resize events.
 *
 * This hook will dispatch a redux action every time the window is resized,
 * throttled at a maximum frequency of once every 200ms (to prevent excessive
 * performance overhead). The dispatched action will add the new window width
 * to the redux store.
 *
 * During development, also logs the new window width to the console.
 *
 * On unmount, this hook will automatically clean up the event listener it adds.
 *
 * Dependencies: `useDispatch` (a redux hook).
 */
export const useWindowResize = () => {
  // Get the redux dispatcher function.
  const dispatch = useDispatch();

  // Use a React effect hook to handle window resize events.
  useEffect(() => {
    // Define an event handler function that gets called each time the
    // window is resized. The execution of this function will be throttled.
    const handleResize = throttle(() => {
      // Get the new window width.
      const width = window.innerWidth;

      // If we're in development mode, log the new width to the console.
      if (process.env.NODE_ENV === 'development') {
        console.log('Window resized to:', width);
      }

      // Dispatch the new width to the redux store.
      // This will cause a rerender of any components that depend on it.
      dispatch(setWindowWidth(width));
    }, 200);

    // Attach the event handler function to the 'resize' event of the window object.
    window.addEventListener('resize', handleResize);

    // Define cleanup logic to remove the event listener when component dismounts.
    // This will prevent memory leaks.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]); // This effect hook depends on the dispatch function.
};
