import React, { useRef, useState } from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

interface FormikCharacterCountTextAreaProps {
	name: string; // The name of the form field to associate this input with
	placeholder: string; // The placeholder text key for the textarea, used for translation
	maxLength: number; // The maximum allowed number of characters in the textarea
	labelTranslationKey: string; // Translation key for the label text
	charCounterTranslationKey: string; // Translation key for the character counter message
}

/**
 * A reusable Formik text area component that includes a character count.
 * This component integrates with Formik and i18next for form state management and localization.
 *
 * The component displays a textarea with a dynamic character count and a label,
 * both localized using the provided translation keys. It also provides visual feedback
 * on errors using conditional styling.
 *
 * @param {FormikCharacterCountTextAreaProps} props - Component props including name, placeholder, maxLength, and translation keys.
 * @returns {React.ReactElement} - A rendered text area component with character count and label.
 *
 * @example
 * Usage:
 * ```tsx
 * <FormikCharacterCountTextArea
 *   name="description"
 *   placeholder="form:inputDescriptionPlaceholder"
 *   maxLength={300}
 *   labelTranslationKey="form:descriptionLabel"
 *   charCounterTranslationKey="form:charactersRemaining"
 * />
 * ```
 */
export const FormikCharacterCountTextArea: React.FC<FormikCharacterCountTextAreaProps> = ({ name, placeholder, maxLength, labelTranslationKey, charCounterTranslationKey }: FormikCharacterCountTextAreaProps): React.ReactElement => {
	const { t } = useTranslation();
	const [field, meta, helpers] = useField(name);
	const { setValue } = helpers;
	const [charCount, setCharCount] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

	const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value); // Update the Formik field's value
		setCharCount(e.target.value.length); // Update character count state
	};

    const focusInput = (): void => {
        inputRef.current?.focus();
    };


    const inputClass = `fmc-input fds-color__bg--white ${meta.touched && meta.error ? 'fmc-input--error' : ''}`;

	return (
		<>
			<div className={`fds-flex--column fds-m--b-1 ${meta.touched && meta.error ? "fmc-floating-label--error" : ""}`}>
                <label onClick={focusInput}>
                    <small>{t(labelTranslationKey)}</small>
                </label>
				<textarea
					{...field}
					value={field.value}
					onChange={handleTextareaChange}
					placeholder={t(placeholder)} // Localized placeholder
					maxLength={maxLength}
					className={inputClass}
					style={{ minHeight: "200px", minWidth: "100%", padding: "10px" }}
				/>
			</div>
            <small style={{ fontSize: "12px", color: "rgb(77, 77, 77)" }}>
					{maxLength - charCount} {t(charCounterTranslationKey)}
			</small>
			{meta.touched && meta.error && (
				<div className="fds-flex__row">
					<small style={{ color: "var(--fds-color--error1)", marginTop: "4px" }}>{meta.error.toString()}</small>
				</div>
			)}
		</>
	);
};