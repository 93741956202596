import React from 'react';
import { Card, Col, Row, Tag } from "components/components";
import { useGetAnalyticsQuery } from "services/admin-dashboard";
import { AdminDashboardAnalyticsResponse, AnalyticsItem } from "services/admin-dashboard.types";
import { CardGroup } from '@onemagnify/cxc-components-web';

interface AnalyticsItemCardProps {
    item: AnalyticsItem;
}

const formatLabel = (text: string): string => {
    return text
        .replace(/SVE/gi, "Small Volume Exception")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b(USA|CAN)(?=[A-Z])/g, "$1 ")
        .replace(/\bLast Json Refresh Time Of (USA|CAN):?/g, "Last Json Refresh Time Of $1 ")
        .replace(/^./, str => str.toUpperCase());
};

const formatDate = (dateStr: any) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC' // Adjust timezone if needed
    }).format(date);
};

const AnalyticsItemCard: React.FC<AnalyticsItemCardProps> = ({ item }) => {
    return (
        <Card title={formatLabel(item.title)} content={true} className="fds-p--x-2">
            <table>
                <tbody>
                    {Object.entries(item?.group?.groups).map(([key, value]) => (
                        <tr key={key} className='fmc-divider--horizontal'>
                            <td>{formatLabel(key)}</td>
                            <td>{key?.toLowerCase().includes('time') ? formatDate(value) : value}</td>
                        </tr>
                 
                    ))}
                </tbody>
            </table>
        </Card>
    );
};

// Main component to display analytics
const Analytics: React.FunctionComponent = () => {
    const { data, isLoading, error } = useGetAnalyticsQuery();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading analytics data.</div>;

    const analyticsData = data as unknown as AdminDashboardAnalyticsResponse;

    return (
        <Col xs={12} className="fds-m--y-1 fds-p--x-0">
            <Row className="fds-flex--center">
                <Col xs={12} >
                    <Row className="fds-flex--center">
                        <Tag tag="h1" className="fmc-type--heading3 fds-color--primary fds-p--t-2 fds-align--center fds-p--b-3">
                            Analytics
                        </Tag>
                    </Row>
                </Col>
            </Row>
            <Row className="fds-flex--center">
                <Col xs={12}>
                    <CardGroup>
                        {analyticsData?.data?.map((item, index) => (
                            <AnalyticsItemCard key={index} item={item} />
                        ))}
                    </CardGroup>
                </Col>
            </Row>
        </Col>
    );
};

export default Analytics;
