/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/prefer-immediate-return */
import React, { useEffect, useState } from 'react';
import FormInputs from './FormInputs';
import { Button, Row, TopBanner } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useGetDocmentStatusForDealerQuery } from 'services/loa-docs-details';
import { useGetLOADataQueryMutation } from 'services/loa-waiver';
import { useAuth } from 'providers/AuthProvider';
import { LOADocDeatilsApiResponse } from 'services/loa-docs-details.types';
import SnackBarNotification from 'components/components/Notifications/SnackBarNotification';
interface LOAWaiverFormInterface {
	page: number;
	setPage: any;
}

const LOAWaiverForm = (props: LOAWaiverFormInterface) => {
	const { page, setPage } = props;
	const { pacode, marketArea, region, salesCode } = useSelector(
		(state: RootState) => state.pacode
	);
	const [showLoader, setShowLoader] = useState(false);

	const tokenInfo: any = useAuth();
	const { data, isLoading } = useGetDocmentStatusForDealerQuery({
		paCode: pacode,
		salesCode: salesCode,
	}) as {
		data: LOADocDeatilsApiResponse | undefined;
		isLoading: boolean;
		isError: boolean;
	};

	const [updateFunc, { isError, isSuccess }]: any =
		useGetLOADataQueryMutation();
	const [fileError, setFileError] = useState('');
	const [associationError, setAssociationError] = useState('');
	const [disableOptions, setDisableOptions]: any = useState([]);
	const [apiError, setApiError]: any = useState('');
	const title = {
		0: 'Page 1',
		1: 'Page 2',
		3: 'Page 3',
		4: 'Finish',
	};
	const handleDismiss = () => {
		setApiError('');
	};

	const [formData, setFormData] = useState<any>({
		DocType: 'LOA',
		uploadedFiles: '',
		fileName: '',
		complianceGroupId: [],
	});
	const [dataVerbiage, setDataVerbiage] = useState<string[]>([]);
	const [waiverUserData, setWaiverUserData]: any = useState({});
	useEffect(() => {
		setWaiverUserData(tokenInfo?.tokenProps?.authDetails);

		if (data) {
			const temp: any = [];
			if (data?.data?.loa) temp.push('LOA');
			if (data?.data?.waiver) temp.push('Waiver');
			setDisableOptions(temp);
		}
	}, [data]);

	const assessCheckboxes = (e: any) => {
		const verbiageArray = dataVerbiage;
		const itemIndex = verbiageArray.indexOf(e.target.value);

		if (itemIndex > -1) {
			verbiageArray.splice(itemIndex, 1);
			setDataVerbiage(verbiageArray);
		} else {
			verbiageArray.push(e.target.value);
			setDataVerbiage(verbiageArray);
		}
	};
	const [fileName, setFileName] = useState('');
	const getFileName = (e: any) => {
		const uploadedFiles = e.target.files;
		const uploadedSingleFile = uploadedFiles[0].name;
		setFileName(uploadedSingleFile);
	};
	const handleChange = (e: any, groupId: any) => {
		const type = e.target.type;
		const checkBox = type === 'checkbox' ? e.target : '';
		const file = type === 'file' ? e?.target?.files[0] : '';
		const name = e.target.name;

		const value = type === checkBox ? e.target.checked : e.target.value;

		if (checkBox) {
			assessCheckboxes(e);
			setFormData((prevData: any) => ({
				...prevData,
				complianceGroupId: e.target.checked
					? [...prevData.complianceGroupId, groupId]
					: prevData.complianceGroupId.filter(
							(item: any) => item !== groupId
					  ),
			}));
			setAssociationError('');
			return;
		} else if (type === 'file') {
			getFileName(e);
			setFormData((pre: any) => ({
				...pre,
				[name]: file,
				fileName: file.name,
			}));
			setFileError('');
			return;
		}

		setFormData((prevData: any) => ({
			...prevData,
			[name]: value,
		}));
	};

	const { ...inputInformation } = formData;

	const canSubmit =
		[...Object.values(inputInformation)].every(Boolean) &&
		page === Object.keys(title).length - 1;

	const handlePrev = () => setPage((prev: number) => prev - 1);

	const handleNext = async (action: string) => {
		if (action === 'step2') {
			if (formData.uploadedFiles === '')
				setFileError('Please select at least one file');
			else {
				setFileError('');
				setPage((prev: number) => prev + 1);
			}
		} else if (action === 'step3') {
			if (formData.complianceGroupId.length) {
				setAssociationError('');
				setPage((prev: number) => prev + 1);
			} else {
				setAssociationError(
					'Please select at least one qualifers section association'
				);
			}
		} else if (action === 'step4') {
			setShowLoader(true);
			const payload = new FormData();
			payload.append('DocType', formData.DocType.toUpperCase());
			payload.append('file', formData.uploadedFiles);
			payload.append('complianceGroupId', formData.complianceGroupId);
			payload.append('salesCode', salesCode);
			payload.append('paCode', pacode);
			payload.append('documentDescription', '');
			payload.append('countryCode', waiverUserData.countryCode);
			payload.append(
				'preferredLanguage',
				waiverUserData.preferredLanguage
			);
			payload.append('wslId', waiverUserData.wslId);
			await updateFunc(payload);
			setShowLoader(false);
		} else {
			setPage((prev: number) => prev + 1);
		}
	};

	const handleSubmit = (e: { preventDefault: () => void }) => {
		e.preventDefault();
	};

	useEffect(() => {
		if (isSuccess) setPage((prev: number) => prev + 1);
	}, [isSuccess]);
	// useEffect(() => {
	// 	if (isError) setApiError('message');
	// }, [isError]);

	const formContent = (
		<form className={'fds-color--primary'} onSubmit={handleSubmit}>
			<FormInputs
				page={page}
				formData={formData}
				handleChange={handleChange}
				dataVerbiage={dataVerbiage}
				fileError={fileError}
				associationError={associationError}
				disableOptions={disableOptions}
				identifyPage="upload"
				fileName={fileName}
				showLoader={showLoader}
			/>
			<div className="formButton-container">
				{page === 0 ? (
					<>
						<button
							className="fmc-button fds-m--t-2"
							aria-label="Continue to upload"
							onClick={() => handleNext('step1')}
							disabled={disableOptions.includes(formData.DocType)}
						>
							<span>Continue to Upload Documents</span>
							<span className="fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right chevron-filled-right"></span>
						</button>
					</>
				) : (
					<></>
				)}
				{page === 1 ? (
					<Button
						ariaLabel={'Continue to associations'}
						onClick={() => {
							handleNext('step2');
						}}
						rightIcon
					>
						Continue to Make Associations
					</Button>
				) : (
					<></>
				)}
				{page === 2 ? (
					<Button
						ariaLabel={'Save Selections'}
						onClick={() => handleNext('step3')}
						rightIcon
					>
						Save Associations
					</Button>
				) : (
					<></>
				)}

				{page === 3 ? (
					showLoader ? (
						<p>Loading...</p>
					) : (
						<Row>
							<Button
								ariaLabel={'Edit Selections'}
								onClick={handlePrev}
								buttonClassName={'fds-m--r-2'}
								leftIcon
							>
								Edit Associations
							</Button>

							<Button
								ariaLabel={'Save Selections'}
								onClick={() => handleNext('step4')}
								buttonClassName={'fds-m--l-2'}
								rightIcon
							>
								Save Associations
							</Button>
						</Row>
					)
				) : (
					// )
					<></>
				)}
				{page === 4 ? (
					<Row className={'fds-m--y-3'}>
						<Button
							ariaLabel={'Save Selections'}
							onClick={() => {
								window.location.href = '/';
							}}
							buttonClassName={'fds-m--r-2'}
						>
							Upload Additional
						</Button>

						<Button
							ariaLabel={'dashboard'}
							onClick={() => {
								window.location.href = `/dashboard/${pacode}`;
							}}
							buttonClassName={'fds-m--l-2 LOA-button-outline'}
							dark
						>
							View Dashboard
						</Button>
					</Row>
				) : (
					<></>
				)}
			</div>
		</form>
	);

	return formContent;
};

export default LOAWaiverForm;
