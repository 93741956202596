import React from "react";
import { Button, Col, Dialog, Row, Tag } from "components/components";

interface LOADeleteModalProps {
	deleteModal: { index: number; name: string } | null;
	onDismiss: any;
	onConfirmDelete: any;
}
const AttestationModal = (props: LOADeleteModalProps) => {
	const { deleteModal, onDismiss, onConfirmDelete } = props;

	return (
		<div>
			<Dialog title={"Delete Document"} opened={!!deleteModal} onClick={() => onDismiss(false)}>
				<Col xs={12}>
					<Tag tag={"p"} className={"fds-align--center fds-color__text--gray2 font-size--cta fds-p--y-1"}>
						Would you like to delete {deleteModal?.name} ?
					</Tag>

					<Row className={"fds-flex__justify--center"}>
						<Col xs={6}>
							<Row className="fds-align--center fds-m--y-2">
								<Col xs={6}>
									{/* @ts-ignore */}
									<Button isOutlined type="button" onClick={() => onDismiss(false)} className={"font-size--cta"}>
										Cancel
									</Button>
								</Col>
								<Col xs={6}>
									{/* @ts-ignore */}
									<Button type="button" ariaLabel={"confirm delete"} onClick={() => onConfirmDelete(deleteModal?.index)} buttonClassName={"fds-m--l-2 "}>
										Yes, Delete
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Dialog>
		</div>
	);
};

export default AttestationModal;
