// @ts-nocheck
import React, {
	FC,
	Fragment,
	isValidElement,
	useRef,
	useTransition,
	useState,
	useEffect,
	Children,
	MutableRefObject,
} from 'react';

import classNames from 'classnames';
import { Tag } from '../';
import {
	AccordionCLASSES,
	AccordionSTATES,
	AccordionATTRIBUTES,
	AccordionSTRINGS,
	//   KEYCODES
} from '../../../constants/AccordionConstants';

import { isDefined } from '../../utils/Helpers';

import {
	AccordionProps,
	ChildrenType,
	PanelHTMLElement,
} from '../../../types/accordionTypes';

/**
 * FMCAccordion React Component.
 *
 * @param {string} 'as'
 * @component 'as' which determines Component element type or if is another Component.
 * @param {(string|string[])} light is a flag that determines if UI of Component is light or dark.
 * @function _expandAll() private function which expands all Accordion Panels.
 * @function expandAll(function) is a function that invokes the private _expandAll function. 
 * @function _collapseAll() private function which collapses all Accordion Panels.
 * @function collapseAll(function) is a function that invokes the private _collapseAll function.
 * @param {string} ariaLabel applies the aria-label attribute to the Component. REQUIRED.
 * @param {string} className applies className attribute to Component.
 * @param {React.ReactNode} props.children - The children components which exist within Component. REQUIRED.
 * @example
 * <Accordion ariaLabel={'Aria Label - Required Parameter'}>
      <AccordionPanel
        id={'Accordion Panel ID - Required Parameter'}
        button={'Accordion Panel Button Props - Required Parameter'}
      >
        AccordionPanel Child Content as a String or React Component
      </AccordionPanel>
      ...
    </Accordion>
 */

export const Accordion: FC<AccordionProps> = (props) => {
	const {
		as = 'div',
		light = false,
		expandPanel,
		expandAll,
		collapsePanel,
		collapseAll,
		onLoadPanelEvent,
		'aria-label': ariaLabel,
		className,
		children,
		...attributes
	} = props;

	const classes = [
		'fmc-accordion',
		'js-fmc-accordion',
		{ 'fmc-accordion--light': light },
	];

	const ClassName = classNames(classes, className);

	const errorMsg = 'Only AccordionPanel Component can be used with Accordion';

	const _accordionRef = useRef() as MutableRefObject<HTMLElement[]>;
	const [transitionState, startTransitionState] = useTransition();
	const [accordionPanels, setAccordionPanels] = useState(
		_accordionRef.current
	);
	const [currentPanel, setCurrentPanel] = useState(0);

	const queryElementForPanels = (element: HTMLElement) => {
		const _panel: PanelHTMLElement = {
			...{
				panelElement: element,
				buttonElement: element?.querySelector(
					`.${AccordionCLASSES.BUTTON}`
				),
				bodyElement: element?.querySelector(
					`.${AccordionCLASSES.BODY}`
				),
				contentElement: element?.querySelector(
					`.${AccordionCLASSES.CONTENT}`
				),
				state: element?.classList?.contains(AccordionCLASSES.EXPANDED)
					? AccordionSTATES.OPEN
					: AccordionSTATES.CLOSED,
			},
		};

		return {
			panel: _panel,
		};
	};

	const setAccordionElements = (element: any) => {
		setAccordionPanels(
			element?.querySelectorAll(`:scope > .${AccordionCLASSES.PANEL}`)
		);
		setCurrentPanel(0);
	};

	const initializeAccordion = () => {
		accordionPanels?.forEach((element: HTMLElement, index) => {
			const { panel } = queryElementForPanels(element);
			setPanelState(
				panel,
				panel.panelElement?.classList?.contains(
					AccordionCLASSES.EXPANDED
				)
					? AccordionSTATES.OPEN
					: AccordionSTATES.CLOSED
			);

			panel?.buttonElement?.addEventListener('click', () =>
				_onClick(panel, index)
			);
			panel?.buttonElement?.addEventListener('keyup', (event) =>
				_onKeyup(event)
			);
			panel?.buttonElement?.addEventListener('keydown', (event) =>
				_onKeydown(event)
			);
			panel?.buttonElement?.addEventListener('blur', () =>
				_onBlur(panel)
			);
			panel?.bodyElement?.addEventListener('transitionend', (event) =>
				_onTransitionEnd(panel, event)
			);

			if (
				panel.panelElement?.classList?.contains(AccordionCLASSES.ACTIVE)
			) {
				setCurrentPanel(index);
				panel?.buttonElement?.focus();
			}
		});
	};

	/**
	 * Function within FMC Accordion which manages state transitions between opening and closing Accordion Panels
	 * @param {HTMLElement} element of Accordion panel which is currently undergoing UI transition
	 * @enum {string} of Accordion panel state which determines current step in UI transition
	 */

	const setPanelState = (panel: PanelHTMLElement, state: string) => {
		panel.state = state;

		switch (state) {
			case AccordionSTATES.CLOSED:
				panel?.bodyElement?.style?.setProperty('height', '0');
				// Hidden visibility makes focusable elements not focusable, so it is used instead of aria-hidden.
				panel?.contentElement?.style?.setProperty(
					'visibility',
					'hidden'
				);
				panel?.buttonElement?.setAttribute(
					AccordionATTRIBUTES.ARIA_EXPANDED,
					'false'
				);
				break;
			case AccordionSTATES.CLOSING:
				// Set the height to the content height before closing. This is necessary because the CSS
				// transition will not run when the height changes from "auto" to "0".
				panel?.bodyElement?.style?.setProperty(
					'height',
					`${panel?.contentElement?.offsetHeight}px`
				);
				void panel?.panelElement.offsetHeight;
				panel?.bodyElement?.style?.setProperty('height', '0');
				panel?.contentElement?.style?.setProperty(
					'visibility',
					'visible'
				);
				panel?.buttonElement?.setAttribute(
					AccordionATTRIBUTES.ARIA_EXPANDED,
					'false'
				);
				break;
			case AccordionSTATES.OPENING:
				panel?.bodyElement?.style?.setProperty(
					'height',
					`${panel?.contentElement?.offsetHeight}px`
				);
				panel?.contentElement?.style?.setProperty(
					'visibility',
					'visible'
				);
				panel?.buttonElement?.setAttribute(
					AccordionATTRIBUTES.ARIA_EXPANDED,
					'true'
				);
				break;
			case AccordionSTATES.OPEN:
				panel?.bodyElement?.style?.setProperty('height', `auto`); // Let the body's height change freely with its content.
				panel?.contentElement?.style?.setProperty(
					'visibility',
					'visible'
				);
				panel?.buttonElement?.setAttribute(
					AccordionATTRIBUTES.ARIA_EXPANDED,
					'true'
				);
				break;
			default:
				break;
		}
	};

	const _expandPanel = (panel: PanelHTMLElement) => {
		startTransitionState(() => {
			panel?.panelElement?.classList?.add(AccordionCLASSES.EXPANDED);
			if (panel.state !== AccordionSTATES.OPEN) {
				setPanelState(panel, AccordionSTATES.OPENING);
			}
		});
	};

	const _collapsePanel = (panel: PanelHTMLElement) => {
		startTransitionState(() => {
			panel?.panelElement?.classList.remove(AccordionCLASSES.EXPANDED);
			if (panel.state !== AccordionSTATES.CLOSED) {
				setPanelState(panel, AccordionSTATES.CLOSING);
			}
		});
	};

	const _navigatePanels = (direction: string) => {
		accordionPanels[currentPanel].blur();
		accordionPanels[currentPanel].classList.remove(AccordionCLASSES.ACTIVE);

		switch (direction) {
			case AccordionSTRINGS.HOME:
				setCurrentPanel(0);
				break;
			case AccordionSTRINGS.END:
				setCurrentPanel(accordionPanels.length - 1);
				break;
			case AccordionSTRINGS.NEXT:
				setCurrentPanel(currentPanel + 1);
				break;
			case AccordionSTRINGS.PREV:
				setCurrentPanel(currentPanel - 1);
				break;
			default:
				setCurrentPanel(0);
				break;
		}

		if (currentPanel > accordionPanels.length - 1) setCurrentPanel(0);
		if (currentPanel < 0) setCurrentPanel(accordionPanels.length - 1);

		_focusPanel(
			accordionPanels[currentPanel].querySelector(
				`.${AccordionCLASSES.BUTTON}`
			)
		);
	};

	const _focusPanel = (buttonElement: any) => {
		const _panelElement: HTMLElement | null = buttonElement?.closest(
			`.${AccordionCLASSES.PANEL}`
		);
		_panelElement?.focus();
		_panelElement?.classList?.add(AccordionCLASSES.ACTIVE);
		buttonElement?.focus();
	};

	const _onLoadPanelEvent = (
		panel: PanelHTMLElement,
		state?: 'closed' | 'opened'
	) => {
		const loadState = state
			? state
			: !panel?.panelElement?.classList?.contains(
					AccordionCLASSES.EXPANDED
			  );
		if (loadState) {
			_expandPanel(panel);
		} else {
			_collapsePanel(panel);
		}
	};

	const _onClick = (panel: PanelHTMLElement, index: number) => {
		setCurrentPanel(index);
		panel?.panelElement?.classList?.add(AccordionCLASSES.ACTIVE);

		if (
			panel?.panelElement?.classList?.contains(AccordionCLASSES.EXPANDED)
		) {
			_collapsePanel(panel);
		} else {
			_expandPanel(panel);
		}
	};

	const _onBlur = (panel: PanelHTMLElement) => {
		panel?.panelElement?.classList?.remove(AccordionCLASSES.ACTIVE);
		panel?.panelElement?.blur();
	};

	const _onKeyup = (event) => {
		switch (event.keyCode) {
			case KEYCODES.TAB:
				_focusPanel(event.target);
				break;
			case KEYCODES.SPACE:
				event.preventDefault();
				break;
		}
	};

	const _onKeydown = (event) => {
		switch (event.keyCode) {
			case KEYCODES.ENTER:
			case KEYCODES.SPACE:
				event.preventDefault();
				event.target.click();
				break;
			case KEYCODES.LEFT:
			case KEYCODES.UP:
				event.preventDefault();
				_navigatePanels(AccordionSTRINGS.PREV);
				break;
			case KEYCODES.RIGHT:
			case KEYCODES.DOWN:
				``;
				event.preventDefault();
				_navigatePanels(AccordionSTRINGS.NEXT);
				break;
			case KEYCODES.END:
				event.preventDefault();
				_navigatePanels(AccordionSTRINGS.END);
				break;
			case KEYCODES.HOME:
				event.preventDefault();
				_navigatePanels(AccordionSTRINGS.HOME);
				break;
			default:
				break;
		}
	};

	const _onTransitionEnd = (panel, event) => {
		startTransitionState(() => {
			if (event.target === panel.bodyElement) {
				if (
					panel.state === AccordionSTATES.CLOSING ||
					panel.state === AccordionSTATES.OPEN
				) {
					setPanelState(panel, AccordionSTATES.CLOSED);
				} else {
					setPanelState(panel, AccordionSTATES.OPEN);
				}
			}
		});
	};

	const _expandAll = () => {
		accordionPanels.forEach((element: HTMLElement) => {
			const { panel } = queryElementForPanels(element);
			if (panel.state === AccordionSTATES.CLOSED) _expandPanel(panel);
		});
	};

	const _collapseAll = () => {
		accordionPanels.forEach((element: HTMLElement) => {
			const { panel } = queryElementForPanels(element);
			if (panel.state === AccordionSTATES.OPEN) _collapsePanel(panel);
		});
	};

	useEffect(() => {
		setAccordionElements(_accordionRef.current);
		return () => {};
	}, []);

	useEffect(() => {
		if (isDefined(accordionPanels)) {
			initializeAccordion();
		}
		return () => {};
	}, [accordionPanels]);

	useEffect(() => {
		if (typeof expandPanel === 'function') {
			expandPanel({
				callback: (element: HTMLElement) => {
					const { panel } = queryElementForPanels(element);
					_expandPanel(panel);
				},
			});
		}
		if (typeof collapsePanel === 'function') {
			collapsePanel({
				callback: (element: HTMLElement) => {
					const { panel } = queryElementForPanels(element);
					_collapsePanel(panel);
				},
			});
		}
		if (typeof expandAll === 'function') {
			expandAll({
				callback: () => {
					_expandAll();
				},
			});
		}
		if (typeof collapseAll === 'function') {
			collapseAll({
				callback: () => {
					_collapseAll();
				},
			});
		}
		if (typeof onLoadPanelEvent === 'function') {
			onLoadPanelEvent({
				callback: (
					element: HTMLElement,
					state?: 'closed' | 'opened'
				) => {
					const { panel } = queryElementForPanels(element);
					_onLoadPanelEvent(panel, state);
				},
			});
		}
		return () => {};
	}, []);

	return (
		<Tag
			as={as}
			aria-label={ariaLabel}
			ref={_accordionRef}
			className={ClassName}
			{...(light ? { background: 'light' } : {})}
			{...attributes}
		>
			{Children.map(children, (child: ChildrenType | ChildrenType[]) => {
				const componentCheck = (_child) =>
					_child?.type?.name === 'AccordionPanel';
				if (isValidElement(child) && child.type === Fragment) {
					const childChildren = child.props?.children;
					const isAccordionPanel =
						Children.count(childChildren) > 0 &&
						Children.toArray(childChildren).some((child) =>
							componentCheck(child)
						);
					// if (isAccordionPanel) {
					// 	return child;
					// } else {
					// 	console.error(errorMsg);
					// }
					return child;
				}
				if (
					isValidElement(child) &&
					typeof child.type === 'function' &&
					componentCheck(child)
				) {
					return child;
				} else {
					return child;
				}
			})}
		</Tag>
	);
};
