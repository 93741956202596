import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiResponse } from "types/types";
import { createBaseQuery } from "../overrides/createBaseQuery";

export const documentsApi = createApi({
	reducerPath: "documentsApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_DOCUMENT_SERVICE_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeWslId: true,
			includeDocServiceApiKey: true,
		},
	}),
	endpoints: (builder) => ({
		uploadDocument: builder.mutation<ApiResponse<any>, any>({
			query: (formData) => ({ url: "/Storage/UploadDocument", method: "POST", body: formData }),
		}),
	}),
});

export const { useUploadDocumentMutation } = documentsApi;
