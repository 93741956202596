import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetContactFormData, selectContactForm } from "reducers/contact-us.reducer";
import { AppDispatch, RootState } from "store";
import { useTranslation } from "react-i18next";
import { Col, Row, Tag, TopBanner } from "components/components";
import ContactForm from "components/components/Form/ContactForm";
import ContactFormMobile from "components/components/Form/ContactFormMobile";
import { usePostContactDataMutation } from "services/contact-us";
import { ContactFormData } from "services/contact-us.types";


const ContactInfo = () => {
    const { t } = useTranslation();
    return (
        <Tag tag={"div"} className={"fds-p--y-2 fds-p--x-2"}>
            <Col xs={12}>
                <h4 className={"fds-type--title1 fds-m--b-1"}>{t("pages.contact.hqTitle")}</h4>
                <a
                    className={"fds-decoration--underline"}
                    href={"mailto:" + t("common.contactemail")}
                    style={{
                        color: "var(--fds-color--secondary)",
                    }}
                >
                    {t("common.contactemail")}
                </a>
                <p>(888) 850-1002</p>
                <p>{t("pages.contact.hours")}</p>
            </Col>
        </Tag>
    );
};


const Contact: React.FC = () => {
	const contactForm = useSelector(selectContactForm);
    const windowWidth = useSelector((state: RootState) => state.windowSize.width);
    const dispatch: AppDispatch = useDispatch();
    const [postContactData, { isLoading, isSuccess }] = usePostContactDataMutation();
    const [showSuccessBanner, setShowSuccessBanner] = useState(false);
    const { t } = useTranslation();

    const handleSubmit = async (data: ContactFormData) => {
        try {
            await postContactData(data).unwrap();
        } catch (error) {
            // Error handling is managed globally
        }
    };

    useEffect(() => {
        if (isSuccess) {
            setShowSuccessBanner(true);
            window.scrollTo(0, 0);
        }

        return () => {
            dispatch(resetContactFormData()); // Reset the Redux state on component unmount
        };
    }, [isSuccess, dispatch]);

	return (
		<>
			<div className="fds-position--relative">
				<TopBanner hidden={!showSuccessBanner} status={"success"} persistent={false} className={"no-access-success-banner"} buttonClick={() => setShowSuccessBanner(false)}>
					{" "}
					{t("pages.contact.formInputs.thankyouMessage")}{" "}
				</TopBanner>
			</div>
			<Col xs={12} className="fds-m--t-3 fds-md:fds-m--x-4 fds-lg:fds-m--x-3 fds-xl:fds-m--x-4 fds-md:fds-m--b-3 fds-sm:fds-m--b-1">
				<Row className="fds-flex--center">
					<Col xs={11} lg={10} className="fds-p--l-0 fds-m--b-1">
						<Row className="fds-flex--center">
							<Tag tag="h1" className="fmc-type--heading3 fds-color--primary fds-p--y-2 fds-align--center">
								{t("pages.contact.heading")}
							</Tag>
						</Row>
						<Row>
							<Tag tag="p" className="fmc-type--body2 fds-font--color__gray fds-p--b-2 fds-p--r-1">
								{t("pages.contact.headingLinePt1")} <span className="fds-color--primary fds-weight--bold">{t("pages.contact.headingLinePt1Bold")}</span> {t("pages.contact.headingLinePt2")}
							</Tag>
						</Row>
					</Col>
				</Row>
			</Col>

			{(windowWidth as number) >= 1024 ? (
				<Row className={"fds-m--x-2 fds-p--b--2 fds-m--b-2"}>
					<Col xs={6}>
						<ContactForm formData={contactForm} isLoading={isLoading} onSubmit={handleSubmit} />
					</Col>
					<Col xs={6} className={"fmc-divider--left"}>
						<ContactInfo />
					</Col>
				</Row>
			) : (
				<>
					<Col xs={12}>
						<ContactInfo />
					</Col>
					<Col xs={12}>
						<ContactFormMobile formData={contactForm} isLoading={isLoading} onSubmit={handleSubmit} />
					</Col>
				</>
			)}
		</>
	);
};


export default Contact;
