import React from "react";

/**
 * Props for the ReportDownloadButton component.
 *
 * @param isDropdownFetching Indicates if the dropdown data is being fetched.
 * @param isReportLoading Indicates if the report generation is in progress.
 * @param onClick Optional function to execute when the button is clicked.
 */
interface ReportDownloadButtonProps {
	isDropdownFetching: boolean;
	isReportLoading: boolean;
	onClick?: (e: any) => void;
}

/**
 * A reusable and well-documented button component for initiating report downloads.
 * The button's appearance and enabled state dynamically respond to changes in
 * the loading states provided via props. It acts as a submit button if no onClick handler is provided.
 */
const ReportDownloadButton: React.FC<ReportDownloadButtonProps> = ({ isDropdownFetching = false, isReportLoading = false, onClick }) => {
	return (
		<button
			type={onClick ? "button" : "submit"} // Determine the type based on onClick presence
			disabled={isDropdownFetching || isReportLoading}
			className="fmc-button fds-p--y-2"
			aria-label="Download Report"
			onClick={onClick} // Will be undefined if not provided
		>
			{isReportLoading ? "Preparing Download..." : "Download Report"}
			<span className="fds-font--ford-icons-download om-fds-icon-top--16 fds-p--x-1 fds-p--t-2 fds-color--white buttonCustomTop"></span>
		</button>
	);
};

export default ReportDownloadButton;
