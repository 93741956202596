import Cookies from 'js-cookie';

const cookieConfig: Cookies.CookieAttributes = {
	// domain: 'localhost:3000',
	sameSite: 'strict',
	secure: true,
};

const CookieUtils = (() => {
	const set = (
		key: string,
		value: string,
		options: Cookies.CookieAttributes = {}
	) => {
		Cookies.set(key, value, { ...cookieConfig, ...options });
	};

	const get = (key: string): string | undefined => {
		return Cookies.get(key);
	};

	const remove = (key: string): void => {
		Cookies.remove(key);
	};

	return {
		set,
		get,
		remove,
	};
})();

export default CookieUtils;
