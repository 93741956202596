import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setPreferredLanguage, setCountryCode } from './locale-preferences.slice';

interface UserState {
  userType: string | null;
  preferredLanguage: string;
  countryCode: string;
}

const initialState: UserState = {
  userType: null,
  preferredLanguage: 'en', // default value, can also be dynamically set
  countryCode: 'USA', // default value, can also be dynamically set
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserType(state, action: PayloadAction<string>) {
      state.userType = action.payload;
    },
  },
  // Respond to external actions
  extraReducers: (builder) => {
    builder
      .addCase(setPreferredLanguage, (state, action) => {
        state.preferredLanguage = action.payload;
      })
      .addCase(setCountryCode, (state, action) => {
        state.countryCode = action.payload;
      });
  },
});

export const { setUserType } = userSlice.actions;
export default userSlice.reducer;