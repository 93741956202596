import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import useInitializeLocale from "../../../hooks/useInitializeLocale";
import { useAuth } from "providers/AuthProvider";
import { setCountryCode, setIsLoading, setPreferredLanguage } from "reducers/locale-preferences.slice";
import Tag from "../Tag/Tag";
import { useUpdatePreferencesMutation } from "services/admin-dashboard";
import { mapToLocale } from "utils/LocaleUtils";
import i18n from "i18n";
import { useTranslation } from "react-i18next";

/**
 * LanguageSelector component for selecting language preferences.
 * @component
 */
interface LanguageSelectorProps {
	hideMails?: boolean;
}
const LanguageSelector: React.FC<LanguageSelectorProps> = ({ hideMails }: LanguageSelectorProps) => {
	const dispatch = useDispatch<AppDispatch>();
	const { tokenProps, isAdmin, isEmployee } = useAuth();
	const { preferredLanguage, countryCode } = useSelector((state: RootState) => state.localePreferences);
	const [updatePreferencesOnServer] = useUpdatePreferencesMutation();
	const { t } = useTranslation();
	hideMails = hideMails || false;
	useInitializeLocale(tokenProps);
	/**
	 * Determines if the user is qualified to see a particular language option based on
	 * admin status, employee status, country code, and entitlement roles.
	 *
	 * @param {string} targetCountryCode - The country code of the flag to be checked (e.g., 'USA' or 'CAN').
	 * @returns {boolean} - A boolean indicating whether the user is qualified.
	 */
	const isQualifiedToSeeLanguageOption = (targetCountryCode: string): boolean => {
		if (isAdmin) return true; // Admins always see the flag

		const entitlementRole = tokenProps?.authDetails?.entitlementRole;

		// Check for no entitlement roles
		if (!entitlementRole || entitlementRole.length === 0) {
			return countryCode === targetCountryCode;
		}

		if (isEmployee) {
			if (entitlementRole.includes(`FORD-INT-ACCESS-${targetCountryCode}`)) return true;
			if (countryCode === targetCountryCode) return true;
			return false;
		}

		return countryCode === targetCountryCode;
	};

	/**
	 * Determines if the language/flag selection button should be disabled based on user role and entitlements.
	 *
	 * @param {string} targetCountryCode - The country code of the button being checked (e.g., 'USA' or 'CAN').
	 * @returns {boolean} - A boolean indicating whether the button should be disabled.
	 */
	const isButtonDisabled = (targetCountryCode: string): boolean => {
		if (isAdmin) return false; // Admins can always change settings

		const entitlementRole = tokenProps?.authDetails?.entitlementRole;

		// Check for no entitlement roles
		if (!entitlementRole || entitlementRole.length === 0) {
			return !(isEmployee && countryCode === targetCountryCode);
		}

		if (isEmployee) {
			if (entitlementRole.includes(`FORD-INT-ACCESS-${targetCountryCode}`)) return false;
			if (countryCode === targetCountryCode) return true;
			return false;
		}

		return true; // Non-employees can't select their respective flags, so button is always disabled
	};

	/**
	 * Handles locale changes.
	 * @param {string} newCountry - The new country code.
	 * @param {string} newLang - The new language code.
	 */
	const handleLocaleChange = async (newCountry: string, newLang: string) => {
		if (!tokenProps?.authDetails?.wslId) {
			throw new Error("WSL ID cannot be null.");
		}
		if (tokenProps?.authDetails?.isAuthorized) {
			await updatePreferencesOnServer({
				wslId: tokenProps.authDetails.wslId,
				countryCode: newCountry,
				preferredLanguage: newLang,
			}).then((res: any) => {
				try {
					dispatch(setIsLoading(true)); // Set loading before async operation

					// Update countryCode in localePreferences slice and localStorage
					dispatch(setCountryCode(newCountry));

					// Update preferredLanguage in localePreferences slice and localStorage
					dispatch(setPreferredLanguage(newLang));

					// Update i18n locale
					i18n.changeLanguage(mapToLocale(newCountry, newLang));
				} catch (error) {
					console.error("An error occurred while updating user locale preferences:", error);
				} finally {
					dispatch(setIsLoading(false)); // Set loading to false after async operation
				}
			});
		} else {
			dispatch(setCountryCode(newCountry));

			// Update preferredLanguage in localePreferences slice and localStorage
			dispatch(setPreferredLanguage(newLang));
			i18n.changeLanguage(mapToLocale(newCountry, newLang));
		}
	};

	const isUSVisible = isQualifiedToSeeLanguageOption("USA");
	const isCANVisible = isQualifiedToSeeLanguageOption("CAN");

	const isUSActive: boolean = countryCode === "USA";
	const isCANActive: boolean = false;

	return (
		<>
			<Tag tag="div" className="language-swap-container">
				{false && (
					<button className="language-swap-btn fds-m--r-1" onClick={() => handleLocaleChange("USA", "en")} disabled={isButtonDisabled("USA")}>
						<img src={isUSActive ? "/assets/images/languageicons/icon-home-us-flag-color.svg" : "/assets/images/languageicons/icon-home-us-flag-gray.svg"} />
					</button>
				)}
			</Tag>

			{isCANActive && (
				<Tag tag="div" className="language-option-container">
					<div className="fds-color--primary fds-p--l-1">
						<Tag tag="div" className={`fmc-text-button fds-display--inlineflex ${preferredLanguage === "en" ? "fmc-button--active" : ""}`} onClick={() => handleLocaleChange("CAN", "en")}>
							EN
						</Tag>
						&nbsp;
						<Tag tag="div" className="fds-display--inlineflex">
							|
						</Tag>
						&nbsp;
						<Tag tag="div" className={`fmc-text-button fds-display--inlineflex ${preferredLanguage === "fr" ? "fmc-button--active" : ""}`} onClick={() => handleLocaleChange("CAN", "fr")}>
							FR
						</Tag>
					</div>
				</Tag>
			)}

			{!hideMails && (
				<div className="fds-color--primary fds-md:fds-p--x-3" style={{ fontSize: 13 }}>
					{t("common.programHQ")}: (888) 850-1002
					<span className="fds-p--x-1 fds-hide--xs fds-hide--sm">•</span>
					<a
						className="fmc-text-button"
						//   href="/undefined"
						href={`mailto:${t("pages.noAccess.emailaddress")}`}
						target="_blank"
						style={{
							fontSize: 13,
							color: "var(--fds-color--secondary)",
						}}
					>
						<span className="fds-p--x-1 normalFontStretch fds-xs:fds-p--x-0 fds-sm:fds-p--x-0">
							{/* ModelePHQ@fordprogramhq.com */}
							{t("pages.noAccess.emailaddress")}
						</span>
					</a>
				</div>
			)}
		</>
	);
};

export default LanguageSelector;
