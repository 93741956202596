import { useAuth } from "providers/AuthProvider";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

const usePaCode = () => {
	const tokenInfo: any = useAuth();
	const { isDealer, tokenProps } = tokenInfo || {};
	const { authDetails } = tokenProps || {};
	const { paCode: paCodeFromAuth } = authDetails || {};
	const { pacode, marketArea, region, salesCode } = useSelector((state: RootState) => state.pacode);
	return isDealer ? paCodeFromAuth : pacode;
};

export default usePaCode;
