// src/ErrorTestPage.tsx
import React, { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetMockErrorResponseQuery } from '../services/mock';
import { Col, Row, Tag } from 'components/components';
import { setErrorStatus } from 'reducers/service-error.slice';
import { useDispatch } from 'react-redux';
import { ErrorStates } from 'types/types';

const statusCodes = [200, 404, 500]; // Define your status codes

const ErrorTestPage: React.FC = () => {

    const dispatch = useDispatch();
    const [selectedCode, setSelectedCode] = useState<number | null>(null);

    // Adjusted to pass an object matching MockErrorQuery interface or skipToken
    const queryArg = selectedCode !== null ? { code: selectedCode } : skipToken;
    const { data, error, isLoading } = useGetMockErrorResponseQuery(queryArg);

    const handleButtonClick = (code: number) => {
        setSelectedCode(code);
    };

    const handleAdminOnlyClick = () => {
        dispatch(setErrorStatus(ErrorStates.AdminOnly));
    }

    return (
        <div>
            <Col xs={12} className="fds-m--t-3 fds-md:fds-m--x-4 fds-lg:fds-m--x-3 fds-xl:fds-m--x-4 fds-md:fds-m--b-3 fds-sm:fds-m--b-1">
                <Row className="fds-flex--center">
                    <Col xs={11} lg={10} className="fds-p--l-0 fds-m--b-1">
                        <Row className="fds-flex--center">
                            <Tag
                                tag="h1"
                                className="fmc-type--heading3 fds-color--primary fds-p--y-2 fds-align--center"
                            >
                                Error Test Page
                            </Tag>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Row className={'fds-m--x-2 fds-p--b--2 fds-m--b-2'}>

                    {isLoading && <p>Loading...</p>}
                    {error && <p>Error: {error.toString()}</p>}
                    {data && (
                        <div>
                            <h3>Response for Code: {selectedCode}</h3>
                            <p>{JSON.stringify(data)}</p>
                        </div>
                    )}

            </Row>
            <Row className={'fds-m--x-2 fds-p--b--2 fds-m--b-2'}>
                {statusCodes.map((code) => (
                    <Col xs={3}>
                        <div className="fds-p--y-3 fds-align--center">
                            <button className="fmc-button" key={code} onClick={() => handleButtonClick(code)}>
                                Test {code} Response
                            </button>
                        </div>
                    </Col>
                ))}
                                    <Col xs={3}>
                        <div className="fds-p--y-3 fds-align--center">
                            <button className="fmc-button" onClick={() => handleAdminOnlyClick()}>
                                Test Admin Only Dispatch
                            </button>
                        </div>
                    </Col>
            </Row>
        </div>
    );
};

export default ErrorTestPage;



