// @ts-nocheck
import {
	FC,
	Fragment,
	ReactNode,
	useRef,
	useEffect,
	Children,
	cloneElement,
} from 'react';
import clsx from 'clsx';
import { FMCTooltip } from '../../fds-system/src/components/_components';
import { Tag, Row, Col } from '../';
import { isDefined } from '../../utils/Helpers';

interface CTA {
	href: string;
	label: string | JSX.Element | ReactNode;
	menuItemWrapperProps?: object;
	menuItemProps?: object;
	aria: string;
	className?: string;
	leftIcon?: string;
	leftIconComponent?: any;
	style?: object;
}

interface OverflowMenuProps {
	className?: string;
	title?: string;
	dataPosition?: string;
	cta: CTA[];
	children?: any;
	namespace?: any;
}

const OverflowMenu: FC<OverflowMenuProps> = (props) => {
	const {
		className,
		dataPosition = 'bottom',
		cta,
		title,
		children,
		namespace,
		...attributes
	} = props;

	const toolTipRef = useRef();

	const classes = ['js-fmc-tooltip', 'fmc-tooltip', 'fmc-tooltip__nav'];

	const ClassName = clsx(classes, className);

	useEffect(() => {
		//namespace (2nd argument) may need to be navBar or passed in, we shall see
		new FMCTooltip(toolTipRef.current, namespace);
	}, [namespace]);

	return (
		<Tag
			className={ClassName}
			ref={toolTipRef}
			data-slug={'overflow-menu'}
			data-position={dataPosition}
			data-overflow-menu
			{...attributes}
		>
			{children &&
				Children.map(children, (child) => {
					return (
						<Fragment key={Math.random()}>
							{cloneElement(
								child,
								Object.assign({}, child.props, {
									className: clsx(
										'fmc-tooltip__trigger',
										child.props.className
									),
								})
							)}
						</Fragment>
					);
				})}
			<Tag className={'fmc-tooltip__wrapper'}>
				<Tag className={'fmc-tooltip__content'}>
					<Tag className={'fmc-overflow-menu'}>
						{title && (
							<Fragment>
								<Row padding={{ dir: 'y', amt: 1 }}>
									<Col
										xs={12}
										className={
											'fds-weight--bold fds-type--content1 fds-color--primary'
										}
									>
										{title}
									</Col>
								</Row>
							</Fragment>
						)}
						{cta.map(
							({
								href,
								label,
								aria,
								leftIcon,
								className = '',
								style = {},
								menuItemWrapperProps = {},
								menuItemProps = {},
							}) => {
								return (
									<Fragment key={Math.random()}>
										<Row {...menuItemWrapperProps}>
											<Col
												xs={12}
												className={clsx(
													'fmc-overflow-menu__item',
													className
												)}
												{...menuItemProps}
											>
												<Tag
													tag={'a'}
													tabIndex={0}
													href={href}
													aria-label={aria}
													padding={{
														dir: 'x',
														amt:
															typeof label ===
															'string'
																? 1
																: 0,
													}}
													{...{ style }}
												>
													{isDefined(leftIcon) && (
														<Tag
															tag={'span'}
															className={clsx(
																'fds-icon--offset-left',
																leftIcon
															)}
														/>
													)}
													<Tag
														tag={
															typeof label ===
															'string'
																? 'span'
																: 'div'
														}
														className={clsx(
															'fmc-overflow-menu__label',
															className
														)}
													>
														{' '}
														{label}{' '}
													</Tag>
												</Tag>
											</Col>
										</Row>
									</Fragment>
								);
							}
						)}
					</Tag>
				</Tag>
			</Tag>
		</Tag>
	);
};

export default OverflowMenu;
