import React, { useState, useEffect } from "react";
import { FieldError, UseFormRegister, UseFormSetValue, UseFormSetError, UseFormClearErrors } from "react-hook-form";
import Row from "./Columns/Row";
import Tag from "./Tag/Tag";
import Col from "./Columns/Col";
import { uploadDocument } from "utils/CommonUtils";
import { useUploadDocumentMutation } from "services/document";
import AttestationModal from "pages/attestation/components/AttestationModal";

interface AttestationFile {
	file?: File;
	docId: string;
	url: string;
	name?: string;
}

interface FileUploadProps {
	id?: string;
	initialFiles?: AttestationFile[]; // URLs of already uploaded files
	register: UseFormRegister<any>; // react-hook-form's register function
	setValue: UseFormSetValue<any>; // react-hook-form's setValue function
	setError: UseFormSetError<any>; // react-hook-form's setError function
	clearErrors: UseFormClearErrors<any>; // react-hook-form's clearErrors function
	name: string; // Field name in the form
	errors?: any; // Error object from react-hook-form
	allowedTypes?: Array<string>;
	maxFileSize?: number;
	sample?: string;
}

export const extractFileNameFromUrl = (url: string): string => {
	// Split the URL by "?" to ignore the query parameters
	const [baseUrl] = url.split("?");
	// Split by "/" and get the last part
	const parts = baseUrl.split("/");
	const fileName = parts[parts.length - 1];

	return fileName;
};

const SampleImage = ({ image }: any) => {
	return (
		<div style={{ position: "relative" }}>
			<p className={"fds-color--gray3"} style={{ fontSize: "1.1rem" }}>
				Example of required photo
			</p>
			<div className={"exPhotoContainer"}>
				<img className={"fds-img"} src={`/assets/images/attestation/${image}`} />
			</div>
			<p className={"fds-color--gray3"} style={{ fontSize: "1.1rem" }}>
				image_name.jpg
			</p>
		</div>
	);
};

const SIZE_10_MB = 10 * 1024 * 1024;
const FileUpload: React.FC<FileUploadProps> = ({ id = "fileUpload", sample = "", initialFiles = [], register, setValue, setError, clearErrors, name = "files", errors, allowedTypes = ["image/jpeg", "image/png"], maxFileSize = SIZE_10_MB }) => {
	// State to store selected files and previews
	const [uploadDocument, { isError, isSuccess }] = useUploadDocumentMutation();
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
	const [filePreviews, setFilePreviews] = useState<AttestationFile[]>([]);
	const [tempError, setTempError] = useState("");

	// Load initial files (URLs) for edit mode
	useEffect(() => {
		if (initialFiles?.length > 0) {
			setSelectedFileIds(initialFiles?.map((f: any) => f?.documentId));
			setFilePreviews(initialFiles?.map((file: any) => ({ url: file?.documentURL, docId: file?.documentId, name: file?.documentName || extractFileNameFromUrl(file?.documentURL) })));
		}
	}, [initialFiles]);

	// Handle file input change for multiple files
	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		clearErrors(name); // Clear any existing errors

		const files = Array.from(e.target.files || []);
		const newFiles: File[] = [];
		const newFileIds: string[] = [];
		const newPreviews: AttestationFile[] = [];

		const filteredFiles = files?.filter((file) => {
			if (!allowedTypes.includes(file.type)) {
				// setError(name, { type: "manual", message: "Only JPG or PNG files are allowed." });
				setTempError("Only JPG or PNG files are allowed.");
				setTimeout(() => {
					setTempError("");
				}, 4000);
				return false;
			}

			if (file.size > maxFileSize) {
				// setError(name, { type: "manual", message: "Each file must be under 10MB." });
				setTempError("Each file must be under 10MB.");
				setTimeout(() => {
					setTempError("");
				}, 4000);
				return false;
			}
			return true;
		});

		// Todo: File Validation

		const docPromises = filteredFiles?.map((file) => {
			const previewUrl = URL.createObjectURL(file);
			const formData = new FormData();
			formData.append("File", file);
			formData.append("ProjectId", "90009");
			newPreviews?.push({ file, docId: "", url: previewUrl });
			return uploadDocument(formData);
		});
		const results = await Promise.all(docPromises);
		const docIds = results?.map((d: any) => d.data);
		const updatedPreviews: any = newPreviews?.map((preview, order) => ({ ...preview, docId: docIds?.[order] }));
		setFilePreviews((prev) => {
			const newPreviews = [...prev, ...updatedPreviews];
			setSelectedFileIds(docIds);
			setValue(name, [...newPreviews?.map((prev) => prev?.docId)]);
			return newPreviews;
		});
	};

	// Remove selected file
	const removeFile = (index: number) => {
		setSelectedFileIds((prev) => prev.filter((_, i) => i !== index));
		setFilePreviews((prev) => {
			const updatedPreviews = prev.filter((_, i) => i !== index);
			setValue(
				name,
				updatedPreviews?.map((p) => p?.docId)
			);
			return updatedPreviews;
		});
		// Update the form value after removal
	};

	const [deleteModal, setDeleteModal] = useState<{ index: number; name: string } | null>(null);

	const onCofirmDelete = (index: number) => {
		setDeleteModal(null);
		removeFile(index);
	};

	// console.log("SelectedIds", selectedFileIds, initialFiles, filePreviews);

	return (
		<>
			<AttestationModal deleteModal={deleteModal} onDismiss={() => setDeleteModal(null)} onConfirmDelete={(index: number) => onCofirmDelete(index)} />
			<div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
				<div style={{ flexGrow: 1, display: "flex" }}>
					<div className="fmc-file-upload">
						<Row>
							<div className="fmc-file-upload__label"> Upload Documents</div>
							<label htmlFor={name}>
								<input id={name} type="file" onChange={handleFileChange} accept={".jpg, .png"} multiple />
								<div
									className="fmc-file-upload__text fds-m--b-1"
									style={{
										display: "flex",
										justifyContent: "center",
										flexFlow: "column",
									}}
								>
									<div className={"fmc-file-upload__drag-drop fds-align--center fds-p--t-1"}>Drag and drop files here or</div>
									<div className="fmc-file-upload__select fds-p--b-1">Select Image</div>
								</div>
							</label>
							{/* File Input */}

							<Col>
								<Row className={"fmc-file-upload__caption"}>
									<span>Accepted document types:</span>
									<span> .jpg, .png (max file size: 10mb)</span>
								</Row>
							</Col>
						</Row>
					</div>

					{/* Preview section */}
					<Row className="fds-m--l-4">
						<div style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
							<SampleImage image={sample} />
							{filePreviews.map((file, index) => (
								<div
									className={"exPhotoContainer fds-flex fds-flex-direction--column"}
									key={index}
									style={{
										position: "relative",
										width: "210px",
										height: "130px",
										border: "1px solid #ccc",
										marginTop: 24,
									}}
								>
									<img className={"fds-img"} src={file.url} alt={`preview-${index}`} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
									<p className="fds-color--gray3" style={{ fontSize: "1.1rem", overflowWrap: "break-word", maxWidth: 210, wordBreak: "break-word" }}>
										{file?.name || file?.file?.name}
									</p>
									<Tag tag={"img"} src={"/assets/icons/close-remove-secondary.png"} style={{ position: "absolute", top: -10, right: -10 }} onClick={() => setDeleteModal({ index: index as any, name: file?.name || file?.file?.name || "Image1" })} />
								</div>
							))}
						</div>
					</Row>
				</div>

				{/* {errors?.[name] && (
					<Row>
						<span className="fds-color__text--error1" style={{ fontSize: "1.1rem" }}>
							{errors?.[name]?.message}
						</span>
					</Row>
				)} */}

				{!!tempError && (
					<Row>
						<span className="fds-color__text--error1" style={{ fontSize: "1.1rem" }}>
							{tempError}
						</span>
					</Row>
				)}
			</div>
		</>
	);
};

export default FileUpload;
