import { createApi } from "@reduxjs/toolkit/query/react";
import { MecpDashboardAPiResponse } from "./mecp-dashboard.types";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const mecpDealerDashboardApi = createApi({
	reducerPath: "mecpDealerDashboardApi",
	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
		},
	}),
	endpoints: (builder) => ({
		getMecpDealerDashboardData: builder.query<MecpDashboardAPiResponse, string>({
			query: (paCode) => `dealer/${paCode}/dashboard/type/2`,
		}),
	}),
});

export const { useGetMecpDealerDashboardDataQuery } = mecpDealerDashboardApi;
