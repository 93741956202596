import { FC, Fragment } from 'react';
import clsx from 'clsx';
import { Tag } from '../';

interface CTA {
	href: string;
	label: string;
	disabled?: boolean;
}

interface BreadcrumbsProps {
	cta: CTA[];
	className?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
	const { cta, className, ...attributes } = props;
	const currentPage = cta[cta.length - 1];
	const otherCTA = cta.slice(0, cta.length - 1);

	const classes = ['fmc-breadcrumbs'];

	const ClassName = clsx(classes, className);

	return (
		<Tag tag={'nav'} aria-label={'breadcrumbs'} {...attributes}>
			<Tag tag={'ul'} className={ClassName}>
				{otherCTA.map(({ href, label, disabled = false }) => {
					return (
						<Fragment key={Math.random()}>
							<Tag tag={'li'}>
								{!disabled ? (
									<Tag
										tag={'a'}
										href={href}
										className={
											'fmc-breadcrumbs__link fds-color__text--gray2 text-decoration--none'
										}
									>
										{label}
									</Tag>
								) : (
									<Tag
										tag={'span'}
										className={
											'fmc-breadcrumbs__link fds-color__text--gray2 text-decoration--none'
										}
									>
										{label}
									</Tag>
								)}
							</Tag>
							<Tag tag={'li'}>
								<Tag
									tag={'span'}
									className={
										'fds-icon fds-font--ford-icons__chevron-right fds-color__text--gray2'
									}
								></Tag>
							</Tag>
						</Fragment>
					);
				})}
				<Tag tag={'li'}>
					<Tag
						tag={'span'}
						className={
							'fmc-breadcrumbs__current fds-color__text--primary'
						}
						aria-current={'page'}
					>
						{currentPage.label}
					</Tag>
				</Tag>
			</Tag>
		</Tag>
	);
};

export default Breadcrumbs;
