// gtm.ts
export const injectGTM = () => {
  if (window.document.getElementById('gtm-js')) return; // Check if GTM is already added

  const script = window.document.createElement('script');
  script.id = 'gtm-js';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GTM_KEY}`;
  script.onload = () => {
    console.log('GTM script loaded');
  };

  const noscript = window.document.createElement('noscript');
  const iframe = window.document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_KEY}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);

  window.document.head.appendChild(script);
  window.document.body.insertBefore(noscript, window.document.body.firstChild);
};