import { ErrorStates } from "types/types";

/**
 * Defines the structure for error handling configurations, including status codes.
 * Each entry maps certain URL paths, HTTP methods, and status codes to a specific error state.
 */
interface ErrorConfigEntry {
    paths: string[]; // Paths are segments to match exactly in the actual service URL path, it falls-back to supporting the current react route path.
    methods: string[];
    statusCodes: number[];
    errorState: ErrorStates;
}

/**
 * Configuration for mapping URL segments, methods, and status codes to specific error states.
 * Paths in the configuration are segments that must match exactly.
 */
export const errorConfig: ErrorConfigEntry[] = [
    {
        paths: ['preferences'],
        methods: ['POST', 'PUT'],
        statusCodes: [400, 404, 500],
        errorState: ErrorStates.Maintenance,
    },
    {
        paths: ['loaandwaiver', 'loaWaiver', 'editloawaivers'],
        methods: ['POST', 'PUT'],
        statusCodes: [400, 404, 500],
        errorState: ErrorStates.Maintenance,
    },
    {
        paths: ['contact'],
        methods: ['GET', 'POST', 'PUT'],
        statusCodes: [400, 404, 500],
        errorState: ErrorStates.Maintenance,
    },
    {
        paths: ['resource'],
        methods: ['GET'],
        statusCodes: [400, 404, 500],
        errorState: ErrorStates.Maintenance,
    },
    {
        paths: ['reports'],
        methods: ['POST', 'PUT', 'GET'],
        statusCodes: [400, 404, 500],
        errorState: ErrorStates.Maintenance,
    },
];

/**
 * Determines the appropriate error state based on the URL path segments, method, and status code.
 * This function checks if any part of the path segments matches any of the specified keywords exactly.
 *
 * @param {string} path - The URL path from which to check for key segments.
 * @param {string} method - The HTTP method associated with the request.
 * @param {number} statusCode - The HTTP status code returned from the server.
 * @returns {ErrorStates} - The determined error state based on the path content, method, and status code.
 *
 * @example
 * // Example for a GET request path containing 'reports' keyword
 * getErrorStateFromConfig('/path/to/endpoint/or/page, 'GET', 404);
 * // returns ErrorStates.ReportsPostError
 */
export function getErrorStateFromConfig(path: string, method: string, statusCode: number): ErrorStates {
    const pathSegments = path.split('/').filter(Boolean);  // Split path into segments and remove any empty segments

    // Check if any part of the path segments matches exactly any of the config paths
    const configEntry = errorConfig.find(entry =>
        entry.paths.some(keyword => 
            pathSegments.some(segment => segment === keyword)
        ) &&
        entry.methods.includes(method.toUpperCase()) &&
        entry.statusCodes.includes(statusCode)
    );

    return configEntry ? configEntry.errorState : ErrorStates.Maintenance;
}