import React from "react";
import { Col, Tag } from "..";

interface DashboardLegendProps {
	countryCode: string;
	hasLOA: boolean;
	hasWaiver: boolean;
	t: any;
}

const DashboardLegend = ({ countryCode, hasWaiver, hasLOA, t }: DashboardLegendProps) => {
	return (
		<Col xs={12} className={"fds-m--x-2 fds-p--b--2"}>
			<Tag tag={"div"} className={"dash-icon-legend "}>
				<div className={"dash-legend-icon fds-p--x-2"}>
					<img src="/assets/icons/meDDicon/icon-complete.svg" className={"dash-icon-legend-img"} />
					<p>{t("pages.dashboard.status.complete")}</p>
				</div>

				<div className={"dash-legend-icon fds-p--x-2"}>
					<img src="/assets/icons/meDDicon/icon-not-complete.svg" className={"dash-icon-legend-img"} />
					<p>{t("pages.dashboard.status.incomplete")}</p>
				</div>

				{/* <div className={"dash-legend-icon fds-p--x-2"}>
					<img src="/assets/icons/meDDicon/icon-deferred.svg" className={"dash-icon-legend-img"} />
					<p>{t("pages.dashboard.dataComingSoon")}</p>
				</div> */}

				{hasWaiver && (
					<div className={"dash-legend-icon fds-p--x-2"}>
						<img src="/assets/icons/meDDicon/icon-on-waiver.svg" className={"dash-icon-legend-img"} />
						<p>{t("pages.dashboard.status.waiver")}</p>
					</div>
				)}

				{hasLOA && (
					<div className={"dash-legend-icon fds-p--x-2"}>
						<img src="/assets/icons/meDDicon/icon-LOA.svg" className={"dash-icon-legend-img"} />
						<p>{t("pages.dashboard.status.loa")}</p>
					</div>
				)}
			</Tag>
		</Col>
	);
};

export default DashboardLegend;
