import { Tag } from "components/components";
import DashLabelInfoRenderer from "components/components/Dashboard/DashLabelInfoRenderer";
import { getMecpStatusIcon } from "components/utils/DashUtils";
import React, { Fragment, useMemo } from "react";
import { Criteria, MecpSectionType, MecpStatusType } from "services/mecp-dashboard.types";

interface MecpCriteriaTableProps {
	criterias: Criteria[];
	type: MecpSectionType;
}

const accordionColumns = [
	{ label: "Status", key: "status" },
	{ label: "Criteria", key: "name" },
	{ label: "Complete", key: "complete", className: "fds-color__text--primary" },
	{ label: "90 Day Grace Expiration", key: "graceExpiry90Day", className: "fds-color__text--primary", measureThreshold: false },
];
const splitAccordionColumns = [
	{ label: "Status", key: "status" },
	{ label: "Standard", key: "name" },
	{ label: "Target", key: "target", className: "fds-weight--bold dash-panel-font-gray" },
	{ label: "MTD", key: "mtd", className: "fds-weight--bold", measureThreshold: true },
];
const overviewColumns = [
	{ label: "Metric", key: "name", className: "transMemo-text", colWidth: "50%" },
	{ label: "MTD", key: "mtd", className: "fds-align--center fmc-type--heading5 fds-weight--bold", measureThreshold: false },
];

const rBallUni = "\u00AE";

const rballReplacedHTML = (str: string) => {
	return str?.split(rBallUni)?.map((s, idx) => {
		return (
			<Fragment key={idx}>
				{s}
				<sup key={"rball"}>®</sup>
			</Fragment>
		);
	});
};

const MecpCriteriaTable = ({ criterias, type }: MecpCriteriaTableProps) => {
	const columns = useMemo(() => {
		switch (type) {
			case "accordion":
				return accordionColumns;
			case "split-accordion":
				return splitAccordionColumns;
			case "overview":
				return overviewColumns;
			default:
				return [];
		}
	}, [type]);

	const STATIC_COLS = type === "overview" ? [] : ["name", "status"];

	const tableName = type === "split-accordion" ? "incentive-table" : "landing-table";
	const className = type === "split-accordion" ? "incentive-table-text" : "dash-table-text";
	return (
		<>
			{criterias?.map((criteria, idx) => (
				<Tag tag={"div"} key={idx} className={"dash-table-container fds-m--t-1 fds-m--b-2"}>
					<table className={tableName}>
						<tr className={"dash-table-head-row"}>
							{columns?.map((column) => (
								<th style={{ width: (column as any)?.colWidth }} key={column.key}>
									{column.label}
								</th>
							))}
						</tr>
						{type !== "split-accordion" && <tr style={{ height: "10px" }}></tr>}
						{criteria?.criteriaList?.map((criteriaList, indx) => {
							const { icon, color } = getMecpStatusIcon(criteriaList?.status);
							const { name, labelInfo } = criteriaList || {};
							return (
								<tr key={indx}>
									{type !== "overview" && (
										<>
											<td>
												<img className={"table-status-icon"} src={icon} />
											</td>
											<td>
												<p className={`${className} letter-spacing-0 dash-panel-font-${color}`}>
													{name?.includes(rBallUni) ? rballReplacedHTML(name) : name}
													<DashLabelInfoRenderer isLast={false} labelInfo={labelInfo} id={indx + ""} />
												</p>
											</td>
										</>
									)}
									{columns
										?.filter((col) => !STATIC_COLS?.includes(col.key))
										?.map((col, idx) => {
											const { key, label, className, measureThreshold } = col || {};
											const { mtdThreshold } = criteriaList || {};
											const mValue = parseInt((criteriaList as any)?.[key]);
											let tdColor = "";
											if (measureThreshold) {
												if (color === "green") tdColor = "dash-panel-font-green";
												else tdColor = mValue >= Number(mtdThreshold) ? "dash-panel-font-green" : "dash-panel-font-orange";
											}
											return (
												<td key={idx + key}>
													<p className={`${className} ${tdColor}`}>{(criteriaList as any)?.[key]}</p>
												</td>
											);
										})}
								</tr>
							);
						})}
					</table>
				</Tag>
			))}
		</>
	);
};

export default MecpCriteriaTable;
