import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getDealerCountryCode, getDealerLangCode } from "../providers/AuthProvider";
import { DealerEmailPreferencePojo, SuccessResponse, UserTrackingLogResponse } from "./email-notification-preferences.types";
import { createBaseQuery } from "./overrides/createBaseQuery";

interface PreferenceUpdateArgs {
	userWslId: string;
	countryCode: string;
	preferredLanguage: string;
}

// Define a base service using createApi
export const emailNotificationPreferencesApi = createApi({
	reducerPath: "emailNotificationPreferencesApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_DASHBOARD_API_HOST}/dealer/email/preference`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
		},
	}),
	endpoints: (builder) => ({
		preferenceUpdate: builder.mutation<UserTrackingLogResponse, PreferenceUpdateArgs>({
			query: (args) => ({
				url: "/preferences/update",
				method: "PUT",
				params: args,
			}),
		}),

		addEmailPreference: builder.mutation<SuccessResponse<DealerEmailPreferencePojo>, DealerEmailPreferencePojo>({
			query: (newData) => ({
				url: "",
				method: "POST",
				body: newData,
			}),
		}),

		getEmailPreferencesByWslId: builder.query<SuccessResponse<DealerEmailPreferencePojo>, string>({
			query: (wslId) => `${wslId}`,
		}),

		getAllEmailPreferencesByPaCode: builder.query<SuccessResponse<DealerEmailPreferencePojo>, string>({
			query: (paCode) => `all/${paCode}`,
		}),
	}),
});

// Export hooks for each query and mutation
export const { usePreferenceUpdateMutation, useAddEmailPreferenceMutation, useGetEmailPreferencesByWslIdQuery, useGetAllEmailPreferencesByPaCodeQuery } = emailNotificationPreferencesApi;
