import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Tag } from '../';

interface BannerProps {
	children: ReactNode | JSX.Element | string;
	className?: string;
	hidden?: boolean;
	nonDismissable?: boolean;
	persistent?: boolean;
	status?: 'success' | 'warning' | 'error' | 'default' | undefined;
	buttonClick?: any; //function to set visibility state of banner
}

const TopBanner: FC<BannerProps> = (props) => {
	const {
		children,
		className = '',
		nonDismissable = false,
		persistent = false,
		hidden = false,
		status,
		buttonClick,
		...attributes
	} = props;

	const BannerClasses = [
		'fmc-top-banner',
		{ 'fmc-top-banner--persistent': persistent },
		{ 'fmc-top-banner--default': status === 'default' },
		{ 'fmc-top-banner--success': status === 'success' },
		{ 'fmc-top-banner--warning': status === 'warning' },
		{ 'fmc-top-banner--error': status === 'error' },
	];

	const ClassName = clsx(BannerClasses, className);

	return (
		<Tag className={ClassName} hidden={hidden} {...attributes}>
			<Tag className={'fmc-top-banner__inner'}>
				<Tag tag={'span'} className={'fmc-top-banner__content'}>
					{' '}
					{children}{' '}
				</Tag>
				{!nonDismissable && (
					<Tag
						tag={'button'}
						className={'fmc-top-banner__dismiss'}
						aria-label={'close'}
						onClick={buttonClick}
					/>
				)}
			</Tag>
		</Tag>
	);
};

export default TopBanner;
