import { PayloadAction, createSlice } from '@reduxjs/toolkit'

// In your window-size.slice.ts
export interface WindowSizeState {
  width: number;
}


export const windowSizeSlice = createSlice({
  name: 'windowSize',
  initialState: {
    width: window.innerWidth,
  },
  reducers: {
    setWindowWidth: (state: WindowSizeState, action: PayloadAction<number>) => {
      state.width = action.payload;
    },
  },
});

export const { setWindowWidth } = windowSizeSlice.actions;

export default windowSizeSlice.reducer;
