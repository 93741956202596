import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import Tag from '../Tag/Tag';
import { Button, Col, TopBanner } from '..';
import { Link } from 'react-router-dom';
import { checkFilesExist } from 'reducers/document.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

interface LOAStep1Props {
	handleChange: any;
	formData: any;
	disableOptions?: string[];
	editDisableOptions?: any;
	page: any;
	identifyPage: string;
	isLoaSelected?: boolean;
	isWaiverSelected?: boolean;
}

const LOAStep1 = (props: LOAStep1Props) => {
	const {
		handleChange,
		formData,
		disableOptions,
		editDisableOptions,
		page,
		identifyPage,
		isLoaSelected,
		isWaiverSelected,
	} = props;
	const [successBanner, setSuccessBanner] = useState(false);
	const radioChange = (e: any) => {
		handleChange(e);
	};
	const dispatch = useDispatch();
	const { loa, waiver } = useSelector((state: RootState) => state.docId);
	const [selectedFile, setSelectedFile] = useState<'loa' | 'waiver'>('loa');

	useEffect(() => {
		// Assume hasDocsProps is obtained from somewhere, like component props, or Redux state, etc.
		const hasDocsProps: any = { loa: true, waiver: false };
		dispatch(checkFilesExist(hasDocsProps) as any);
	}, [dispatch]);

	return (
		<Fragment>
			{props.identifyPage === 'edit' &&
				editDisableOptions.length <= 1 &&
				(props.isWaiverSelected || props.isLoaSelected) && (
					<TopBanner
						status={'warning'}
						persistent={true}
						nonDismissable={true}
						buttonClick={() => setSuccessBanner(true)}
						className={'no-access-success-banner'}
					>
						{' '}
						A document does not currently exist for the selected
						Dealer, please use the Upload Document functionality to
						upload a document or select a new Dealer to edit a
						document for.
					</TopBanner>
				)}
			{disableOptions?.includes(formData.DocType) && (
				<TopBanner
					status={'warning'}
					persistent={true}
					nonDismissable={true}
					buttonClick={() => setSuccessBanner(true)}
					className={'no-access-success-banner'}
				>
					{' '}
					A document already exists for the selected Dealer, please
					use the Edit LOA/Waiver functionality to replace the
					document or select a new Dealer to upload a document for.
				</TopBanner>
			)}
			<Tag tag={'div'} className={'loa-single-step'}>
				<fieldset className={'fmc-check'} style={{ maxWidth: 'unset' }}>
					<legend className={'fds-color--primary'}>
						Select Document Type
					</legend>

					<label htmlFor={'docType-LOA'}>
						{/* <input
							type={'radio'}
							id={'docType-LOA'}
							name={'DocType'}
							value={'loa'}
							checked={selectedFile === 'loa'}
							disabled={!loa}
							onChange={() => setSelectedFile('loa')}
						/> */}
						<input
							type={'radio'}
							id={'docType-LOA'}
							name={'DocType'}
							value={'LOA'}
							checked={formData.DocType === 'LOA'}
							onChange={radioChange}
						/>
						<span className={'fds-color--primary'}>
							LOA (can only be used for Charging Infrastructure)
						</span>
					</label>
					<label htmlFor={'docType-Waiver'}>
						{/* <input
							type={'radio'}
							id={'docType-Waiver'}
							name={'DocType'}
							value={'waiver'}
							checked={selectedFile === 'waiver'}
							disabled={!waiver}
							onChange={() => setSelectedFile('waiver')}
						/> */}
						<input
							type={'radio'}
							id={'docType-Waiver'}
							name={'DocType'}
							value={'Waiver'}
							checked={formData.DocType === 'Waiver'}
							onChange={radioChange}
						/>
						<span className={'fds-color--primary'}>Waiver</span>
					</label>
				</fieldset>

				{/* <div className={'fds-m--y-2'}>
                    <Button ariaLabel={'Step 2'} rightIcon onClick={nextStep}>
                        Continue to Upload Documents
                    </Button>
                </div> */}
			</Tag>
		</Fragment>
	);
};

export default LOAStep1;
