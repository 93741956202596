import React from 'react';
import { useField } from 'formik';

interface FormikRadioGroupProps {
    name: string;
    options: Array<{ value: string; label: string }>;
}

/**
 * A Formik-aware radio group component that uses Formik's `useField` hook
 * for integrating with the form state. This component does not require Formik
 * props to be passed directly, enhancing its reusability and maintainability.
 *
 * @param {FormikRadioGroupProps} props - The properties for the radio group component.
 * @returns {React.ReactElement} The rendered radio buttons group.
 */
export const FormikRadioGroup: React.FC<FormikRadioGroupProps> = ({ name, options }) => {
    const [field, , helpers] = useField(name);

    return (
        <div className="fmc-check om-fmc-radio-check fds-color__text--primary fds-p--y-2 fds-xs:fds-flex__col-12">
            {options.map(option => (
                <label key={option.value}>
                    <input
                        type="radio"
                        {...field}
                        value={option.value}
                        checked={field.value === option.value}
                        onChange={() => helpers.setValue(option.value)}
                    />
                    <span>{option.label}</span>
                </label>
            ))}
        </div>
    );
};
