import { Fragment, useEffect, useMemo, useState } from "react";
import { Tag, Col, Button, Row } from "../";
import { useAuth } from "providers/AuthProvider";
import { useGetAdminDashboardDealerListDataQuery } from "services/admin-dashboard";
import NotificationBubble from "../Notifications/NotificationBubble";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setPacode } from "reducers/pacode.slice";
import { useTranslation } from "react-i18next";

const DealerInfo = () => {
	const dealerUserIcon = "/assets/icons/meDDicon/icon-user-avatar.svg";
	const tokenInfo: any = useAuth();
	const { isDealer } = tokenInfo || {};
	var dealersList: any = useGetAdminDashboardDealerListDataQuery();
	const location = useLocation();
	const reduxPacode = useSelector((state: RootState) => state.pacode.pacode);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const dispatch = useDispatch();
	//get pacode from redux

	const [showSelectedDealer, setShowSelectedDealer]: any = useState(null);
	const [userPanel, setUserPanel] = useState(false);

	const togglePanel = () => {
		setUserPanel(!userPanel);
	};

	useEffect(() => {
		if (location?.pathname && !reduxPacode) {
			dispatch(
				setPacode({
					pacode: location.pathname.split("/dashboard/")[1],
				})
			);
			//setpacode to redux
		} else if (reduxPacode && (location.pathname.includes("/dashboard") || location.pathname === "/")) {
			dispatch(
				setPacode({
					pacode: "",
					region: "",
					salesCode: "",
					marketArea: "",
				})
			);
		}
	}, [location]);

	useEffect(() => {
		if (reduxPacode && dealersList?.data) {
			setShowSelectedDealer(dealersList?.data?.data?.filter((item: any) => item.paCode === reduxPacode)[0]);
		} else {
			setShowSelectedDealer(null);
		}
	}, [reduxPacode, dealersList]);

	const [userData, setuserData]: any = useState({});
	useEffect(() => {
		setuserData(tokenInfo?.tokenProps?.authDetails);
	}, []);

	const handleNavigate = (e: any, href: string) => {
		e.preventDefault();
		setUserPanel(false);
		navigate(href);
	};

	const getTranslatedTierName = (tierName: string) => {
		if (tierName === "Certified") return t("common.certified");
		else if (tierName === "Certified Elite") return t("common.certifiedElite");
		else if (tierName === "Small Volume Exception") return t("common.smallVolumeException");
		else return tierName;
	};

	return (
		<Fragment>
			<Col
				md={4}
				lg={3}
				xl={3}
				padding={[
					{ dir: "l", amt: 0 },
					{ dir: "r", amt: 0 },
				]}
				className={"dealerAlign"}
			>
				<Tag tag={"div"} className={"dealer-info"}>
					<Tag tag={"div"} onClick={togglePanel}>
						<Row>
							<Tag tag={"div"} className="fds-p--x-0">
								<Col className={"fds-flex--center fds-flex-direction--column fds-color--primary navDealer-info"} padding={{ dir: "x", amt: 0 }}>
									<Tag tag={"div"} className={"fds-weight--light"}>
										{userData && (userData?.lastName || userData?.firstName) ? `${userData.firstName} ${userData.lastName}` : "Static name"}
									</Tag>
									{userData && userData?.userType === "Dealer" ? (
										<Tag tag={"div"} className={"fds-weight--bold"}>
											{userData?.businessName}
										</Tag>
									) : null}
									{userData && userData?.userType === "Dealer" ? (
										<Tag tag={"div"} className={"fds-weight--light"}>
											{userData?.indicator}
										</Tag>
									) : null}
									{userData && (userData?.userType === "Admin" || userData?.userType === "Employee") && showSelectedDealer && showSelectedDealer?.dealerName ? (
										<Tag tag={"div"} className={"fds-weight--light"}>
											{showSelectedDealer?.dealerName}
										</Tag>
									) : (
										<div style={{ minHeight: "22px" }}></div>
									)}
									{userData && (userData?.userType === "Admin" || userData?.userType === "Employee") && showSelectedDealer && showSelectedDealer?.indicator ? (
										<Tag tag={"div"} className={"fds-weight--light"}>
											{showSelectedDealer?.indicator}
										</Tag>
									) : null}
								</Col>
							</Tag>

							<Tag tag={"div"} className={"navDealer-container"}>
								<Tag tag={"div"} className={"fds-icon navDealer-avatar fds-p--l-1"}>
									<Tag tag={"div"} className={"dealerNotification"}>
										<Tag tag={"img"} src={dealerUserIcon} />
										<NotificationBubble />
									</Tag>
								</Tag>
							</Tag>
							{isDealer && false && <span className={`fds-font--ford-icons__chevron-down  fds-icon--offset-right-sm fds-p--t-2 ${userPanel ? "rotate-icon" : ""}`}></span>}
						</Row>
					</Tag>
					{userPanel && isDealer && false && (
						<Fragment>
							<Tag tag={"div"} className={"backdrop--invisible"} onClick={togglePanel}></Tag>
							<div className={" userDropDown"}>
								<div className={"popup arrow-top"}>
									<div className={"popup-wrapper"}>
										<div className={"fds-flex fds-flex-direction--column fds-align--left"}>
											<Tag tag={"span"} onClick={(e) => handleNavigate(e, "/email-preferences")} className={"fds-p--y-1"} style={{ cursor: "pointer" }}>
												<span>
													<img src={"assets/icons/meDDicon/Gear-Settings.png"} className={"fds-image"} />
												</span>{" "}
												<span>
													{t("components.dealerInfo.preferences")}
													<NotificationBubble type="preferences" />
												</span>
											</Tag>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</Tag>
			</Col>
		</Fragment>
	);
};

export default DealerInfo;
