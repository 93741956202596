// hooks/useInitializeLocale.ts
import i18n from 'i18n';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	setCountryCode,
	setPreferredLanguage,
} from 'reducers/locale-preferences.slice';
import { AppDispatch } from 'store';
import { mapToLocale } from 'utils/LocaleUtils';

/**
 * A helper function that dispatches an action if a value exists.
 * @param actionCreator - The Redux action creator function.
 * @param dispatch - The Redux dispatch function.
 * @param value - The value to be dispatched.
 */
const dispatchIfValueExists = (
	actionCreator: (value: string) => any,
	dispatch: AppDispatch,
	value?: string
) => {
	if (value) {
		dispatch(actionCreator(value));
	}
};

/**
 * Custom hook to initialize user locale preferences.
 * @param tokenProps - Token properties containing locale information.
 */
const useInitializeLocale = (tokenProps: any) => {
	const dispatch: AppDispatch = useDispatch();

	useEffect(() => {
		i18n.changeLanguage(
			mapToLocale(
				tokenProps?.authDetails?.countryCode,
				tokenProps?.authDetails?.preferredLanguage
			)
		);
		dispatchIfValueExists(
			setCountryCode,
			dispatch,
			tokenProps?.authDetails?.countryCode
		);
		dispatchIfValueExists(
			setPreferredLanguage,
			dispatch,
			tokenProps?.authDetails?.preferredLanguage
		);
	}, [dispatch]);
};

export default useInitializeLocale;
