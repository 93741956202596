import React from "react";

export interface DynamicDescriptionContent {
	type: "text";
	format: "bold" | "normal";
	color: string;
	content: string;
}

export interface DynamicDescriptionProps {
	type: "dynamic" | "string" | undefined;
	content?: string;
	contents?: DynamicDescriptionContent[];
}

const DynamicDescriptionRenderer = ({ type, content = "", contents = [] }: DynamicDescriptionProps) => {
	if (!type || (type === "string" && content === "") || (type === "dynamic" && contents?.length === 0)) return <></>;
	return (
		<p className={"fds-p--y-2 commitProgSummary"}>
			{type === "string" && content}
			{type === "dynamic" && (
				<>
					{contents?.map((content) => {
						const space = " ";
						switch (content?.type) {
							case "text":
								if (content?.format === "bold") return <span style={{ color: content?.color }}>{content.content}</span>;
								return content.content;
							default:
								return <></>;
						}
					})}
				</>
			)}
		</p>
	);
};

export default DynamicDescriptionRenderer;
