import { useGetMasterStatusReportDropdownOptionsQuery } from "../services/reports";

/**
 * A hook to manage and return filtered dropdown options for the Master Status Report form.
 * This hook abstracts the fetching logic for dropdown options and provides a simple API for getting
 * the distinct list of market areas, regions based on the selected market area, and zones based on
 * the selected region. It also exposes loading and error states to the consuming components.
 *
 * @param countryCode - The country code used to fetch relevant dropdown options.
 * @returns An object containing arrays of market areas, regions, and zones, along with loading and error states.
 *
 * @example
 * const {
 *   certifiedTypesOptions,
 *   marketAreaOptions,
 *   getRegionOptions,
 *   getZoneOptions,
 *   isError,
 *   isLoading,
 * } = useMasterStatusReportDropdownOptions('USA');
 */
export const useMasterStatusReportDropdownOptions = (countryCode: string) => {
	const { data: response, isError, isLoading } = useGetMasterStatusReportDropdownOptionsQuery(countryCode);

	// Utility to prepend 'All' if not present and ensure no duplication
	const prependAllOption = (options: string[]): string[] => {
		const allOption = "All";
		if (!options.includes(allOption)) {
			return [allOption, ...new Set(options)];
		}
		return options;
	};

	const certifiedStatus = response?.data?.certifiedStatus || [];
	const { monthAndYear = [] } = response?.data || {};
	const dropdownData = response?.data?.dropDownData || [];

	const certifiedTypesOptions = prependAllOption(certifiedStatus);
	const marketAreaOptions = prependAllOption(dropdownData.map((item: { marketAreaName: string }) => item.marketAreaName));

	// Function to dynamically determine regions based on selected market area or all if 'All' is passed
	const getRegionOptions = (marketArea: string) => {
		if (marketArea === "All") {
			return prependAllOption(dropdownData.map((item: { regionName: string }) => item.regionName));
		} else {
			return prependAllOption(dropdownData.filter((item: { marketAreaName: string }) => item.marketAreaName === marketArea).map((item: { regionName: string }) => item.regionName));
		}
	};

	// Function to determine zones based on the selected region
	const getZoneOptions = (region: string) => prependAllOption(dropdownData.filter((item: { regionName: string }) => item.regionName === region || region === "All").map((item: { zone: string }) => item.zone));

	return {
		certifiedTypesOptions,
		monthAndYearOptions: monthAndYear,
		marketAreaOptions,
		getRegionOptions,
		getZoneOptions,
		isError,
		isLoading,
	};
};
