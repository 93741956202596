import React, { Fragment, useState } from 'react';
import Tag from '../Tag/Tag';
import { Button, Col, Input, Row } from '..';
import formData from '../../../types/LOAFormTypes';
import error from '../../../types/LOAFormTypes';
import { forEachChild } from 'typescript';
interface LOAStep2Props {
	formData: formData;
	handleChange: any;
	fileError: string;
	fileName?: string;
}

const LOAStep2 = (props: LOAStep2Props) => {
	const { handleChange, formData, fileError, fileName } = props;
	const [error, setError] = useState('');

	return (
		<Fragment>
			<Col xs={12}>
				<div className={'loa-steps-text-container'}>
					<small className={'loa-steps-top-text fds-p--b-2'}>
						{' '}
						Please choose the file you wish to upload associated to
						the selected Dealer.
					</small>
				</div>
			</Col>
			<Tag tag={'div'} className={'loa-single-step fds-p--b-2'}>
				<Row>
					<Col xs={3}>
						<Col
							xs={12}
							className={'fmc-file-upload'}
							padding={{ dir: 't', amt: 2 }}
						>
							<Row>
								<Col
									xs={12}
									className={
										'fmc-file-upload__label fds-m--l-1'
									}
								>
									Upload Documents
								</Col>
								<Row>
									<Col xs={12} lg={12} md={12} tag={'label'}>
										<input
											id={'fileUpload'}
											type="file"
											accept={'application/pdf'}
											name={'uploadedFiles'}
											multiple
											onChange={(e: any) => {
												const file =
													e?.target?.files[0];

												if (file) {
													const maxsize =
														10 * 1024 * 1024;
													if (
														file.size > maxsize ||
														file.type !==
															'application/pdf'
													) {
														setError(
															'Accepted document type: .pdf (max 10MB)'
														);
														return;
													}
												}
												handleChange(e);
												setError('');
											}}
										/>
										<Tag
											className={'fmc-file-upload__text'}
											style={{
												display: 'flex',
												justifyContent: 'center',
												flexFlow: 'column',
											}}
										>
											<Tag
												tag={'div'}
												className={
													'fmc-file-upload__drag-drop fds-align--center'
												}
											>
												Drag and drop files here or
											</Tag>
											<Tag
												tag={'div'}
												className={
													'fmc-file-upload__select'
												}
											>
												Select Files
											</Tag>
										</Tag>
									</Col>
								</Row>
								<Col xs={12}>
									<Row
										className={
											'fmc-file-upload__caption fds-p--x-0'
										}
										style={{ paddingLeft: '0' }}
									>
										<Tag tag={'span'}>
											{' '}
											Accepted document type:{' '}
										</Tag>
										<Tag tag={'span'}>.pdf (max 10MB)</Tag>
										<Tag
											tag={'div'}
											className={'fmc-file-upload__files'}
										>
											{fileName && (
												<>
													<span className="fds-icon fds-icon--offset-left-xs fds-font--ford-icons__success fds-color__text--success1"></span>{' '}
													{fileName}
												</>
											)}
										</Tag>
									</Row>
								</Col>
							</Row>
						</Col>
					</Col>
					<Col xs={7}>
						<div className={'fds-m--t-3 fds-p--t-3'}>
							<Col xs={12} lg={6} md={8}>
								{error && (
									<small
										style={{
											color: 'var(--fds-color--error1)',
										}}
									>
										{error}
									</small>
								)}
							</Col>
							<Col xs={12} lg={6} md={8}>
								{fileError && (
									<small
										style={{
											color: 'var(--fds-color--error1)',
										}}
									>
										{fileError}
									</small>
								)}
							</Col>
						</div>
					</Col>
				</Row>
			</Tag>
		</Fragment>
	);
};

export default LOAStep2;
