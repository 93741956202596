import { MecpStatusType } from "services/mecp-dashboard.types";
import { ErrorStates } from "types/types";

export const getDealerSectionId = (id: number) => {
	switch (id) {
		case 1:
			return "meq";
		case 2:
			return "foundation";
		case 3:
			return "mecp";
		default:
			return "mecp";
	}
};

export const calcTaskProgress = (a: number, b: number) => {
	return Math.round((a / b) * 100);
};

export const throttle = (func: Function, delay: number) => {
	let throttling: boolean = false;

	return (...args: any[]) => {
		if (!throttling) {
			throttling = true;

			setTimeout(() => {
				throttling = false;
			}, delay);

			func(...args);
		}
	};
};

export const getEarningTitle = (earningName: string) => {
	switch (earningName) {
		case "E-Transit":
			return <>E-Transit™</>;
		case "F-150 Lighting":
			return (
				<>
					F-150<sup>®</sup> Lightning
					<sup>®</sup>
				</>
			);
		case "Mustang Mach-E":
			return (
				<>
					Mustang<sup>®</sup> Mach-E
					<sup>®</sup>
				</>
			);
		default:
			return <></>;
	}
};

export const getRandomId = () => {};

// This function is used to convert a string error state value to an ErrorStates enum value
export const stringToErrorState = (inputString: string | null): ErrorStates => {
	if (inputString === null) return ErrorStates.NoError;

	// Assuming 'adminOnly' is a known error state value that needs to be mapped to a specific ErrorState
	// Add any specific mappings needed here
	const mapping: { [key: string]: ErrorStates } = {
		maintenance: ErrorStates.Maintenance,
		adminOnly: ErrorStates.AdminOnly,
		noAccess: ErrorStates.NoAccess,
		noError: ErrorStates.NoError,
		noAccessPage: ErrorStates.NoAccessPage,
	};

	const mappedState = mapping[inputString];

	if (mappedState) {
		return mappedState;
	}

	// Attempt to directly match the inputString to ErrorStates
	const directMatch = Object.values(ErrorStates).find((value) => value === inputString);

	if (directMatch) {
		return directMatch as ErrorStates;
	}

	return ErrorStates.NoError;
};

export const uploadDocument = (file: any) => {
	const formData = new FormData();
	formData.append("ProjectId", "90009");
	formData.append("File", file);
	return fetch(`https://dev-apis.onemagnify.com/docupload/api/storage`, { method: "POST", headers: { "x-api-key": "AIzaSyDo4eNU_XV1MkuUMvrVKrH2mPS3WlaICmo" || "" }, body: formData })
		.then((d) => console.log(d))
		.catch((e) => console.log(e));
};

export const deleteDocument = () => {};

export const isPresent = (d: any) => {
	if (!d) return false;
	return !!d?.length;
};
