// @ts-nocheck
export enum AccordionCLASSES {
	COMPONENT = 'js-fmc-accordion',
	PANEL = 'fmc-accordion__panel',
	BUTTON = 'fmc-accordion__button',
	BUTTON_TEXT = 'fmc-accordion__button-text',
	BODY = 'fmc-accordion__body',
	CONTENT = 'fmc-accordion__content',
	ACTIVE = 'fmc-accordion--active',
	EXPANDED = 'fmc-accordion--expanded',
}

export enum AccordionATTRIBUTES {
	ID = 'id',
	ARIA_LABELLEDBY = 'aria-labelledby',
	ARIA_HIDDEN = 'aria-hidden',
	ARIA_EXPANDED = 'aria-expanded',
	ARIA_CONTROLS = 'aria-controls',
}

export enum AccordionSTRINGS {
	HOME = 'home',
	END = 'end',
	PREV = 'prev',
	NEXT = 'next',
}

export enum AccordionSTATES {
	CLOSING = 'closing',
	OPENING = 'opening',
	OPEN = 'open',
	CLOSED = 'closed',
}
