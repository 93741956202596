export const CHARGER_MAKES: any = {
	"ford-pro": "Ford Pro",
	abb: "ABB",
	autel: "Autel",
	blink: "Blink",
	chargepoint: "ChargePoint",
	"charge-pro": "Charge Pro",
	"enel-x": "Enel X",
	"evo-charge": "Evo Charge",
	incharge: "InCharge",
	phihong: "Phihong",
	powercharge: "PowerCharge",
	"red-e": "Red E",
	"sema-connect": "Sema Connect",
	"tellus-power": "Tellus Power",
	xova: "XOVA",
	zerova: "Zerova",
	other: "Other",
};

export const CHARGER_MAKES_2 = {
	"ford-pro": "Ford Pro",
	abb: "ABB",
	autel: "Autel",
	blink: "Blink",
	chargepoint: "ChargePoint",
	"charge-pro": "Charge Pro",
	"enel-x": "Enel X",
	"evo-charge": "Evo Charge",
	incharge: "InCharge",
	phihong: "Phihong",
	powercharge: "PowerCharge",
	"red-e": "Red E",
	"sema-connect": "Sema Connect",
	"tellus-power": "Tellus Power",
	xova: "XOVA",
	zerova: "Zerova",
	other: "Other",
};
