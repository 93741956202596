import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
// import { ContactFormData, ContactFormActionTypes } from '../services/contact-us.types'
import {
	DeleteAssociation,
	DeleteAssociationData,
	DeleteAssociationTypes,
} from '../services/delete-association.types';

import { deleteAssociationFormData } from 'services/delete-association'
// import { postContactData } from '../services/contact-us'

/**
 * Action to update the contact form state.
 * @param {DeleteAssociationData} data - The updated contact form data.
 * @returns {PayloadAction<DeleteAssociation>} - The action with payload containing the updated contact form data.
 */
export const deleteData = (
	data: DeleteAssociation
): PayloadAction<DeleteAssociation> => ({
	type: DeleteAssociationTypes.DELETE_ASSOCIATION_ACTION_TYPE,
	payload: data,
});

/**
 * Asynchronous action that handles the POST request for submitting contact data.
 * @async
 * @param {DeleteAssociationData} data - The data to be sent in the POST request.
 * @param {Object} thunkAPI - Toolkit's Thunk API.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating success or failure, or rejects with an error message.
 */
export const DeleteAssociationDataThunk = createAsyncThunk(
	DeleteAssociationTypes.DELETE_ASSOCIATION_ACTION_TYPE,
	async (data: DeleteAssociation, { rejectWithValue }) => {
		try {
			const success = await deleteAssociationFormData(data);
			// window.location.href = `/loawaiverdelete?type=${data.docType}`;
			return success; // Assuming server returns true for success and false for failure
		} catch (error) {
			if (error instanceof Error) {
				return rejectWithValue(error.message); // If it's an error instance, pass its message
			} else {
				return rejectWithValue('An unknown error occurred'); // Otherwise, send a generic error message
			}
		}
	}
);
