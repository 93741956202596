import React, { Fragment, useEffect } from 'react';
import Tag from '../Tag/Tag';
import { Button, Col } from '..';
import LOAS3Metrics from './LOAS3Metrics';
import formData from '../../../types/LOAFormTypes';

interface LOAStep3Props {
	formData: formData;
	handleChange: any;
	dataVerbiage: string[];
	associationError: string;
}

const LOAStep3 = (props: LOAStep3Props) => {
	const { formData, handleChange, dataVerbiage, associationError } = props;
	return (
		<Fragment>
			<Tag tag={'div'} className={'loa-single-step'}>
				<Col xs={9}>
					<p className={'fds-weight--bold fds-color--primary'}>
						Select Qualifiers Section association
					</p>
					<p className={' fds-m--y-1 fds-font--color__gray'}>
						Selection section will display a link for the Dealer to
						download the uploaded document.
					</p>
					<div className={'fds-m--y-2'}>
						<LOAS3Metrics
							formData={formData}
							handleChange={handleChange}
							dataVerbiage={dataVerbiage}
							associationError={associationError}
						/>
					</div>
				</Col>
			</Tag>
		</Fragment>
	);
};

export default LOAStep3;
