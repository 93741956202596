// @ts-nocheck
import { Accordion, AccordionPanel } from "../Accordion";
import DashPanelCriteria from "./DashPanelCriteria";

import Tag from "../Tag/Tag";
import { Fragment } from "react";
import { DealerDashboard, Section, SubSection } from "services/dashboard.types";
import { DOCUMENT_STATUS_TYPES, getDocumentURL } from "./MeQAccordion";
import { getLinkText, getSecondaryHighlightText, getSecondaryStatusText, getStatusText } from "components/utils/DashUtils";
import Button from "../Buttons/Button";

/**
 * @typedef MobileAccordionsProps
 * @property {DealerDashboard} dealerInfo - Contains information about the dealer.
 * @property {Section} sectionData - Contains information about the section.
 * @property {React.Dispatch<React.SetStateAction<{ callback: (element: HTMLElement) => void }>>} expandPanel - Function to handle the expand panel action.
 * @property {React.Dispatch<React.SetStateAction<{ callback: (element: HTMLElement) => void }>>} collapsePanel - Function to handle the collapse panel action.
 * @property {React.Dispatch<React.SetStateAction<{callback: (element: HTMLElement,state?: 'closed' | 'opened') => void;}>} onLoadPanelEvent - Function to handle the panel load event.
 */
interface MobileAccordionsProps {
	dealerInfo: DealerDashboard;
	sectionData: Section;
	countryCode: string;
	documents: any;
	id: string;
	t: (content: string) => string;
	expandPanel: React.Dispatch<React.SetStateAction<{ callback: (element: HTMLElement) => void }>>;
	collapsePanel: React.Dispatch<React.SetStateAction<{ callback: (element: HTMLElement) => void }>>;
	onLoadPanelEvent: React.Dispatch<
		React.SetStateAction<{
			callback: (element: HTMLElement, state?: "closed" | "opened") => void;
		}>
	>;
}

/**
 * Mobile accordion component that displays dealer sections with corresponding statuses.
 * @param {MobileAccordionsProps} props - The properties for the component.
 * @returns {JSX.Element} The mobile accordion component.
 */
const MobileAccordion = ({ id, documents, countryCode, t, dealerInfo, sectionData, expandPanel, collapsePanel, onLoadPanelEvent }: MobileAccordionsProps): JSX.Element => {
	const accordionIndex = Math.floor(Math.random() + sectionData.id);

	const isAllDeferred = sectionData.subSections.every((subSection: { status: string }) => subSection.status.toLowerCase() === "deferred");

	const isAllOnWaiver = sectionData.subSections.every((subSection: { status: string }) => subSection.status.toLowerCase() === "onwaiver");

	/**
	 * Determines the dealer status based on the given sub-section.
	 * @param {SubSection} subSection - The sub-section to check.
	 * @returns {string | undefined} The dealer status if found, undefined otherwise.
	 */
	const determineDealerStatus = (subSection: SubSection): "Complete" | "InComplete" | "Deferred" | undefined => {
		if (subSection.status.toLowerCase() === "active") {
			return subSection?.subStatus?.toLowerCase() === "complete" ? "Complete" : "InComplete";
		} else if (subSection.status.toLowerCase() === "inactive") {
			return "Deferred";
		} else {
			// Doesn't match the known values
			return undefined;
		}
	};

	const sortedSubSections = [...sectionData.subSections].sort((a: { status: string; subStatus?: string }, b: { status: string; subStatus?: string }) => {
		// Determine the order for "a" and "b" based on their status
		const orderA = a.status === "Active" ? (a.subStatus === "Complete" ? 0 : 1) : 2;
		const orderB = b.status === "Active" ? (b.subStatus === "Complete" ? 0 : 1) : 2;

		// Compare the orders to sort the subsections
		return orderA - orderB;
	});

	return (
		<Fragment>
			{/* Include dealer-specific information if required */}
			<div className={"section-container"}>
				{sectionData.completedSteps >= sectionData.totalSteps ? (
					<h2 style={{ color: "#008200" }} className={"fds-p--b-1"}>
						<Tag tag={"img"} padding={{ dir: "r", amt: 1 }} src={sectionData.title.includes("Model e") ? "/assets/icons/icon-battery-levels/icon-battery-completed-green.svg" : "/assets/icons/meDDicon/icon-complete.svg"} style={{ height: 24 }} />
						{sectionData.title}
					</h2>
				) : (
					<h2 className={"fds-p--b-1"}>
						{isAllDeferred && <Tag tag={"img"} padding={{ dir: "r", amt: 1 }} src={"/assets/icons/meDDicon/icon-deferred.svg"} className={"dash-status-icon"} style={{ height: 24 }} />}
						{isAllOnWaiver && <Tag tag={"img"} padding={{ dir: "r", amt: 1 }} src={"/assets/icons/meDDicon/icon-on-waiver.svg"} className={"dash-status-icon"} style={{ height: 24 }} />}
						{sectionData.title}
					</h2>
				)}
				{/* <p>
					{sectionData.completedSteps} of {sectionData.totalSteps} complete - {((sectionData.completedSteps / sectionData.totalSteps) * 100).toFixed(0)}%
				</p> */}

				<div className={"dash-mobile-divider"}>
					<Accordion light aria-label={`${sectionData.title} Accordion`} id={`${id}-accordion`} className={"dash-mobile-accordion"} style={{ borderTop: "1px solid black" }}>
						{sectionData?.subSections
							?.filter((s) => s.id !== 4)
							?.map((subSection, index: number) => {
								const documentObject: any = subSection?.criterias?.map((crt) => crt?.criteriaList?.findLast((crtList) => crtList?.complianceGroupId))?.filter((e) => e);
								const documentList: any = subSection?.criterias?.reduce((acc, cur) => {
									const ob = cur?.criteriaList
										?.filter((subCrt: any) => DOCUMENT_STATUS_TYPES?.includes(subCrt?.status) && documents?.[subCrt?.complianceGroupId])
										?.reduce((acc2, cur2: any) => {
											const docId = documents?.[cur2?.complianceGroupId];
											return {
												...acc2,
												[cur2?.status]: getDocumentURL(docId),
											};
										}, {});
									return {
										...acc,
										...ob,
									};
								}, {});
								const panelDocuments = Object.keys(documentList)?.map((document) => {
									return {
										linkText: getLinkText(document as any, t, countryCode),
										linkPath: documentList?.[document],
									};
								});
								const docId = documents?.[documentObject?.[0]?.complianceGroupId];
								const subSectionStatus = subSection.status;
								const { attestationStatus, attestationId } = subSection || {};
								return (
									<AccordionPanel
										countryCode={countryCode}
										id={subSection.name}
										label={subSection.name}
										dealerStatus={subSectionStatus}
										key={accordionIndex + index}
										statusText={getStatusText(subSectionStatus, t)}
										secondaryStatusText={getSecondaryStatusText(subSectionStatus, t, countryCode)}
										secondaryHighlightText={getSecondaryHighlightText(subSectionStatus, t, attestationId, attestationStatus)}
										panelLinks={panelDocuments}
										mobile
									>
										{subSection?.reportUrl && (
											<div className={"w-100 fds-flex fds-flex--center fds-m--y-2"}>
												<Button tag="a" target="_blank" href={subSection?.reportUrl} isOutlined noShadow buttonClassName="dlalign" rightIcon={`fds-icon--48 fds-m--l-1 fds-font--ford-icons-open`}>
													View Verification Report{" "}
												</Button>
											</div>
										)}
										{subSection?.criterias?.map((criteria, idx) => (
											<DashPanelCriteria countryCode={countryCode} mobile key={accordionIndex + index} criteria={criteria} isLast={idx + 1 === subSection?.criterias?.length} subSectionName={subSection.name} sectionId={subSection.id} />
										))}
									</AccordionPanel>
								);
							})}
					</Accordion>
				</div>
			</div>
		</Fragment>
	);
};

export default MobileAccordion;
