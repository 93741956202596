import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import Breadcrumbs from "components/components/Navigation/Breadcrumbs";
import { Col, Row } from "components/components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useTranslation } from "react-i18next";
import { FormikSelectField } from "components/components/Form/FormikSelectField";
import { useGetPhqReportDropdownOptionsQuery, useGetPhqReportQuery } from "services/reports";
import { usePhqReportDropdownOptions } from "hooks/usePhqReportDropdownOptions";
import { DynamicSuggestionInput } from "components/components/Form/DynamicSuggestionInput";
import ReportDownloadButton from "components/components/Form/ReportDownloadButton";
import useDownload from "hooks/useDownload";

// Define the types for the dropdown options
interface DropdownOption {
	label: string;
	value: string;
}

// Constants for the report types
const REPORT_TYPES: DropdownOption[] = [
	{ label: "STARS Certification", value: "CERTIFICATION" },
	// { label: "STARS Training Course", value: "TRAINING" },
];

export const ChargingEquipmentReport: React.FC = () => {
	const { t } = useTranslation();

	const { countryCode } = useSelector((state: RootState) => state.localePreferences);
	const { download, isDownloading } = useDownload();

	// Fetch dropdown options from the server
	const { data: dropdownData, isFetching: isDropdownFetching } = useGetPhqReportDropdownOptionsQuery(countryCode);

	// Initial form state
	const formik = useFormik({
		initialValues: {
			reportType: REPORT_TYPES[0].value,
			marketAreaName: "All",
			regionName: "All",
			zone: "All",
			dealerCode: "All",
			paCode: "All",
		},
		onSubmit: (values) => {
			setFetchReportTrigger(true); // Triggers the report fetch
		},
	});

	// Get filtered dropdown options based on selections
	const { filteredRegions, filteredZones, filteredMarketAreas, filteredDealerCodesOptions, filteredPaCodesOptions, handleMarketAreaChange, handleRegionChange, handleZoneChange } = usePhqReportDropdownOptions(dropdownData?.data?.dropDownData || []);

	// State to manage when the report is being fetched
	const [fetchReportTrigger, setFetchReportTrigger] = useState(false);

	const { reportType, marketAreaName: marketArea, regionName: region, zone, dealerCode, paCode } = formik.values || {};
	const phqReportUrl = useMemo(() => {
		return `${process.env.REACT_APP_REPORTS_API_HOST}/reports/level3/${marketArea}/${region}/${zone}/${dealerCode}/${paCode}/${countryCode}`;
	}, [region, zone, dealerCode, paCode, reportType, marketArea, countryCode]);

	if (isDropdownFetching) {
		return <div>Loading...</div>;
	}

	const dealerCodeLabel = countryCode === "CAN" ? t("common.dealerCode") : t("common.salesCode");

	return (
		<>
			<Col xs={12} className="fds-p--y-1">
				<Breadcrumbs
					className={"fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3"}
					cta={[
						{ href: "/reports", label: "Reports" },
						{ href: "/reports/charging-equipment", label: "Level 3 Charging Equipment Program Report" },
					]}
				/>

				<Row className={"fds-flex--center"}>
					<Col xs={12} md={8} className={"fds-flex--center fds-flex-direction--column fds-p--b-3"}>
						<h1 className="fmc-type--heading3 fds-color--primary fds-align--left fds-md:fds-align--center fds-p--b-1">Level 3 Charging Equipment Program Report</h1>
						<p className="fmc-type--body2 fds-font--color__gray fds-flex--center fds-align--left fds-md:fds-align--center fds-p--b-2 fds-p--r-1">
							Summary of L3CEP Request Form submissions from dealers excluding payment details. Report can be pre-filtered before generating by Market Area, Region, Zone, Dealer Sales Code and/or Dealer P&A Code.
						</p>
					</Col>
				</Row>

				<form onSubmit={formik.handleSubmit}>
					<Row className="fds-m--x-3 fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-m--b-3" style={{ gap: 16 }}>
						<Col className="masterSelections fds-xs:fds-flex__col-12 fds-md:fds-flex__col-2 fds-xs:fds-p--t-2 fds-md:fds-p--t-0 fds-md:fds-m--y-1">
							<FormikSelectField
								name="marketAreaName"
								label={"Market Area"}
								options={[...filteredMarketAreas.map((m) => ({ label: m, value: m }))]}
								formik={formik}
								onChange={(e) => {
									formik.setFieldValue("marketAreaName", e.target.value);
									handleMarketAreaChange(e.target.value);
								}}
							/>
						</Col>
						<Col className="masterSelections fds-xs:fds-flex__col-12 fds-md:fds-flex__col-2 fds-xs:fds-p--t-2 fds-md:fds-p--t-0 fds-md:fds-m--y-1">
							<FormikSelectField
								name="regionName"
								label={"Region"}
								options={[...filteredRegions.map((r) => ({ label: r, value: r }))]}
								formik={formik}
								onChange={(e) => {
									formik.setFieldValue("regionName", e.target.value);
									handleRegionChange(e.target.value);
								}}
							/>
						</Col>
						<Col className="masterSelections fds-xs:fds-flex__col-12 fds-md:fds-flex__col-2 fds-xs:fds-p--t-2 fds-md:fds-p--t-0 fds-md:fds-m--y-1">
							<FormikSelectField
								name="zone"
								label={"Zone"}
								options={[...filteredZones.map((z) => ({ label: z, value: z }))]}
								formik={formik}
								disabled={formik.values.regionName === "All"}
								onChange={(e) => {
									formik.setFieldValue("zone", e.target.value);
									handleZoneChange(e.target.value);
								}}
							/>
						</Col>
						<Col className="masterSelections fds-xs:fds-flex__col-12 fds-md:fds-flex__col-2 fds-xs:fds-p--t-2 fds-md:fds-p--t-0 fds-md:fds-m--y-1">
							<label htmlFor="dealerCode" className="fds-flex fds-p--b-1 fds-p--l-0 fmc-type--content2 fds-color__text--gray2 fds-xs:fds-flex__col-12">
								{dealerCodeLabel}
							</label>
							<DynamicSuggestionInput
								id="dealerCode"
								name="dealerCode"
								isDisabled={formik.values.paCode !== "All"}
								options={filteredDealerCodesOptions}
								placeholder="Dealer Code"
								value={filteredDealerCodesOptions.find((option) => option.value === formik.values.dealerCode) || "All"}
								onChange={(option) => formik.setFieldValue("dealerCode", option ? option.value : "All")}
							/>
						</Col>
						<Col className="masterSelections fds-xs:fds-flex__col-12 fds-md:fds-flex__col-2 fds-xs:fds-p--t-2 fds-md:fds-p--t-0 fds-md:fds-m--y-1">
							<label htmlFor="paCode" className="fds-flex fds-p--b-1 fds-p--l-0 fmc-type--content2 fds-color__text--gray2 fds-xs:fds-flex__col-12">
								PA Code
							</label>
							<DynamicSuggestionInput
								id="paCode"
								name="paCode"
								options={filteredPaCodesOptions}
								isDisabled={formik.values.dealerCode !== "All"}
								placeholder="PA Code"
								value={filteredPaCodesOptions.find((option) => option.value === formik.values.paCode) || "All"}
								onChange={(option) => formik.setFieldValue("paCode", option ? option.value : "All")}
							/>
						</Col>
					</Row>
					<Row className="fds-m--y-4 fds-md:fds-m--x-3 fds-lg:fds-m--x-3 fds-xl:fds-m--x-3 fds-xs:fds-align--center fds-md:fds-align--left">
						{/* <button type="reset" onClick={formik.handleReset} className="fmc-button fds-p--y-2 fds-m--r-4 fmc-button--outlined" aria-label="Clear Filters">
							Clear Filters
						</button> */}
						<ReportDownloadButton onClick={(e) => download(e, phqReportUrl, `Level3_Charging_Equipment_Program_Report_${Date.now()}.xlsx`)} isDropdownFetching={isDropdownFetching} isReportLoading={isDownloading} />
					</Row>
				</form>
			</Col>
		</>
	);
};

export default ChargingEquipmentReport;
