import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ProtectedLayout from "layouts/ProtectedLayout";
import ModelEDashboard from "pages/ModelEDashboard";
import Resources from "pages/Resources";
import EmailNotificationPreferences from "pages/EmailNotificationPreferences";
import { useAuth } from "providers/AuthProvider";
import EnrolledEmployees from "pages/EnrolledEmployees";
import Contact from "pages/Contact";
import AdminLOAWaiver from "pages/AdminLOAWaiver";
import EditLOAWaiver from "pages/EditLOAWaiver";
import ErrorPage from "pages/Error";
import ErrorTest from "pages/ErrorTest";
import NoAccess from "pages/NoAccess";
import { ErrorStates } from "types/types";
import Analytics from "pages/admin/analytics";
import Sunset from "pages/Sunset";
import Attestation from "pages/attestation";

const ALLOWED_COUNTRY_CODES = ["USA", "CAN"];

/**
 * A functional component to route dealer-related views.
 *
 * @returns {JSX.Element} - A JSX element containing the Routes for the app
 */
const DealerRouter: React.FC = () => {
	const { tokenProps } = useAuth();
	const { authDetails } = tokenProps || {};
	const { countryCode } = authDetails || {};

	const hasFullDealerAccessCAN = tokenProps?.authDetails?.entitlementRole?.includes("DEALER-FULL-ACCESS-CAN");
	if (!ALLOWED_COUNTRY_CODES?.includes(countryCode) || (countryCode === "CAN" && !hasFullDealerAccessCAN)) {
		return <Navigate to="no-access" />;
	}

	return (
		<Routes>
			<Route path="/" element={<ProtectedLayout />}>
				<Route index element={<ModelEDashboard />} />
				<Route path="resources" element={<Resources />} />
				<Route path="loaWaiver" element={<AdminLOAWaiver />} />
				<Route path="editloawaivers" element={<EditLOAWaiver />} />
				<Route path="contact" element={<Contact />} />
				<Route path="error" element={<ErrorPage />} />

				<Route path=":paCode/attestations/:attestationType?" element={<Attestation mode="view" />} />
				<Route path=":paCode/add-attestation/:attestationType?" element={<Attestation mode="add" />} />
				<Route path=":paCode/edit-attestation/:attestationType?" element={<Attestation mode="edit" />} />

				{/* Conditional route based on the user's role */}
				{(countryCode === "USA" || hasFullDealerAccessCAN) && <Route path="enrolled-employees/:paCode?" element={<EnrolledEmployees />} />}

				{/* Email Preferences route, conditional on the user being having entitlement role */}
				{(countryCode === "USA" || tokenProps?.authDetails?.entitlementRole?.length > 0) && <Route path="email-preferences" element={<EmailNotificationPreferences />} />}

				<Route path="error-test" element={<ErrorTest />} />

				{/* Catch-all route */}
				<Route path="*" element={<Navigate replace to="/error" state={{ errorState: ErrorStates.AdminOnly }} />} />
			</Route>

			<Route path="no-access" element={<NoAccess />} />
			<Route path="sunset" element={<Sunset />} />
		</Routes>
	);
};

export default DealerRouter;
