/* eslint-disable @typescript-eslint/ban-types */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionPanel, Col, Input } from '..';
import formData from '../../../types/LOAFormTypes';
import { useGetAssociationDataQuery } from 'services/loa-association';
interface LOAMetricsProps {
	formData: formData;
	handleChange: any;
	dataVerbiage: string[];
	associationError?: string;
}
const LOAS3Metrics = (props: LOAMetricsProps) => {
	const { formData, handleChange, dataVerbiage, associationError } = props;
	const { data = {} }: any = useGetAssociationDataQuery();
	// Splits input label into multiple lines
	const textVerbiage =
		'Signing of eCommerce Terms & Conditions \n(Ford Model e addendum)';
	const signingText = textVerbiage.split('\n').map((i) => {
		return <p key={i + 1}>{i}</p>;
	});

	const [disabledInputs, setDisabledInputs] = useState(false);
	const [alreadyChecked, setAlreadyChecked] = useState([{}]);

	const getGroupData = useMemo(() => {
		if (data?.data) {
			return data?.data.map((item: any, index: any) => {
				// const isChargingInfrastructure=item.groupName==='Charging Infrastructure'
				return (
					<div className={'fds-m--b-2'} key={index}>
						<label
							htmlFor={`Category-${item.groupName.replace(
								' ',
								''
							)}`}
						>
							<input
								id={`Category-${item.groupName.replace(
									' ',
									''
								)}`}
								aria-label={`Checkbox for ${item.groupName} Category`}
								name={`ChkBox-${item.groupName.replace(
									' ',
									''
								)}`}
								onChange={(e) => {
									handleChange(e, item.groupId);
								}}
								type={'checkbox'}
								value={item.groupName}
								className={
									'fds-m--r-1 fds-m--l-0 checkbox-margin--left loaFormInput'
								}
								style={{
									alignSelf: 'flex-start',
									marginTop: '0.5rem',
								}}
								disabled={
									formData.DocType === 'LOA' &&
									item.groupName !== 'Charging Infrastructure'
								}
								checked={formData.complianceGroupId.includes(
									item.groupId
								)}
							/>
							<span
								className={`fds-p--b-1 ${
									formData.DocType === 'LOA' &&
									item.groupName !== 'Charging Infrastructure'
										? 'fds-color__text--disabled2'
										: ''
								}`}
							>
								{item.groupName}
							</span>
						</label>
					</div>
				);
			});
		}
		return null;
	}, [data, handleChange, formData]);

	const checkform = () => {
		const inputs = document.getElementsByClassName('loaFormInput');
		Array.prototype.forEach.call(inputs, (input) => {
			if (dataVerbiage.includes(input.value)) {
				input.checked = true;
			} else {
				input.checked = false;
			}
		});
	};

	const checkBoxChange = (e: { target: { value: any } }) => {
		handleChange(e);
	};

	useEffect(() => {
		checkform();
		if (formData.DocType === 'LOA') {
			setDisabledInputs(true);
		} else {
			setDisabledInputs(false);
		}
	}, []);

	return (
		<Fragment>
			{getGroupData}
			<Col xs={12} lg={6} md={4}>
				{associationError && (
					<small
						style={{
							color: 'var(--fds-color--error1)',
						}}
					>
						{associationError}
					</small>
				)}
			</Col>
		</Fragment>
	);
};

export default LOAS3Metrics;
