import React from "react";
import { Row, Col } from "components/components";

interface attestFormInfo {
	dealerInformation: any;
	register?: any;
	errors?: any;
}

const AttestationDealerCard = (props: attestFormInfo) => {
	const { dealerInformation, register, errors } = props;

	return (
		<div className={"fds-p--y-1 fds-p--x-1"}>
			<h2 className={"fmc-type--heading6 fds-weight--bold letter-spacing-0 fds-p--b-2"}>Dealer Information</h2>
			<Row>
				<Col lg={4} className="fds-p--r-2">
					<p>
						<span className={"fds-weight--bold"}>Dealer Name:</span> {dealerInformation.dealerName}
					</p>
					<p>
						<span className={"fds-weight--bold"}>PA Code:</span> {dealerInformation.paCode}
					</p>
					<p>
						<span className={"fds-weight--bold"}>Sales Code:</span> {dealerInformation.salesCode}
					</p>
					<p>
						<span className={"fds-weight--bold"}>Dealer Street Address:</span> {dealerInformation.dealerStreetAddress}
					</p>
					<p>
						<span className={"fds-weight--bold"}>Dealer City:</span> {dealerInformation.dealerCity}
					</p>
					<p>
						<span className={"fds-weight--bold"}>Dealer State/Province/Region:</span> {dealerInformation.province}
					</p>
					<p>
						<span className={"fds-weight--bold"}>Dealer Postal/ZIP:</span> {dealerInformation.dealerZIP}
					</p>
					<p>
						<span className={"fds-weight--bold"}>Dealer Country:</span> {dealerInformation.dealerCountry}
					</p>
				</Col>
				<Col lg={6}>
					<p className="fmc-input--wrapper">
						<label htmlFor={"pointOfContact"} className={"fds-color__text--primary"}>
							Dealer Point of Contact
						</label>
						<input
							type={"text"}
							className={"fmc-input fds-m--t-1"}
							id={"pointOfContact"}
							{...register("dealerPointOfContact", {
								required: true,
								maxLength: {
									value: 100,
									message: "Text cannot exceed 100 characters", // Error message if validation fails
								},
							})}
						/>
						{errors?.dealerPointOfContact && <span className="fds-color__text--error1">{errors?.dealerPointOfContact?.message}</span>}
					</p>

					<p className="fmc-input--wrapper fds-m--y-2">
						<label htmlFor={"contactEmail"} className={"fds-color__text--primary"}>
							Dealer Point of Contact Email
						</label>
						<input
							type={"text"}
							className={"fmc-input fds-m--t-1"}
							id={"contactEmail"}
							{...register("dealerPointOfContactEmail", {
								required: true,
								pattern: {
									value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
									message: "Please enter a valid email address",
								},
							})}
						/>
						{errors?.dealerPointOfContactEmail && <span className="fds-color__text--error1">{errors?.dealerPointOfContactEmail?.message}</span>}
					</p>

					{/* <p><span className={'fds-weight--bold'}>Dealer Email:</span> {dealerInformation.dealerEmail}</p> */}
				</Col>
			</Row>
		</div>
	);
};

export default AttestationDealerCard;
