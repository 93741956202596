import React, { Fragment, useMemo } from "react";

import { LabelInfo } from "./../../../services/dashboard.types";
import { Tooltip } from "@onemagnify/cxc-components-web";

interface DashLabelInfoRendererProps {
	id: string;
	mobile?: boolean;
	isLast: boolean;
	isAlone?: boolean;
	labelInfo?: LabelInfo;
}

const DashLabelInfoRenderer = ({ id, isLast, isAlone = false, mobile = false, labelInfo }: DashLabelInfoRendererProps) => {
	const { category } = labelInfo || {};

	const BodyData = () => (
		<>
			{labelInfo?.data
				?.filter((lbl) => lbl?.type === "heading")
				?.map((lbl) => (
					<p>{lbl?.msg}</p>
				))}
			{labelInfo?.data
				?.filter((label) => label.type !== "heading")
				?.map((label, idx) => (
					<Fragment key={Math.floor(Math.random() + idx)}>
						{label?.type === "link" ? (
							<a target="_blank" href={label?.href}>
								{label?.msg}
							</a>
						) : (
							<li>{label?.msg}</li>
						)}
					</Fragment>
				))}
		</>
	);

	const isToolTip = category === "info" || mobile;
	const tooltipConfig = useMemo(() => {
		if (isToolTip) {
			let pos = "bottom";
			if (isLast) pos = "top";
			if (isAlone) pos = "right";
			return {
				cName: "row-info-tooltip",
				isPopup: true,
				position: pos,
			};
		}
		return {
			cName: "dash-table-tooltip",
			isPopup: false,
			position: "right",
		};
	}, [isLast, isAlone, isToolTip]);

	if (!labelInfo) return <></>;

	return (
		<>
			{/* @ts-ignore */}
			<Tooltip
				className={tooltipConfig?.cName}
				isPopupTip={tooltipConfig?.isPopup}
				position={tooltipConfig?.position as any}
				uniqueIdentifier={`${id} tooltip`}
				popupTipBody={isToolTip ? <BodyData /> : ""}
				// @ts-ignore
				tooltipContent={
					category === "list" ? (
						<ul className={"fds-p--l-1 fds-color__text--primary fds-stretch--condensed "} style={{ fontSize: "14px" }}>
							<BodyData />
						</ul>
					) : undefined
				}
			>
				<img className={"dash-info-icon"} src="/assets/icons/meDDicon/info-48-dk-gray.svg" />
			</Tooltip>
		</>
	);
};

export default DashLabelInfoRenderer;
