
//This code maps a list of strings to their lower-case versions
export const mapToLocale = (countryCode: string, preferredLanguage: string): string => {

    const mappings: { [key: string]: string } = {
        "USAen": "en-US",
        "CANen": "en-CA",
        "CANfr": "fr-CA",
    };

    return mappings[countryCode + preferredLanguage] || "en-US";
};