// @ts-nocheck
import React, { FC, Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import { Tag, Cell, Button } from '..';
import { isDefined } from '../../utils/Helpers';
interface CardHorizontal {
	active: boolean;
	size?: number;
	reverse?: boolean;
}

interface CellObject {
	size: string | number;
	order?: string | number;
}

interface CTAProps {
	label: string;
}

interface CardProps {
	tag?: string;
	className?: string;
	title?: string | ReactNode | JSX.Element;
	subtitle?: string;
	copy?: string;
	cta?: CTAProps;
	quiet?: boolean;
	media?: boolean;
	content?: boolean;
	mediaAreaProps?: object;
	imgProps?: object;
	dark?: boolean;
	size?: boolean | number | string | CellObject;
	xs?: boolean | number | string | CellObject;
	sm?: boolean | number | string | CellObject;
	md?: boolean | number | string | CellObject;
	lg?: boolean | number | string | CellObject;
	xl?: boolean | number | string | CellObject;
	horizontal?: CardHorizontal;
	contentAttributes?: object;
	children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const Card: FC<CardProps> = (props) => {
	const {
		tag = 'div',
		quiet = false,
		title,
		subtitle,
		imgProps = {},
		copy,
		cta,
		dark = false,
		media = false,
		content = false,
		horizontal = { active: false },
		className,
		children,
		mediaAreaProps = {},
		contentAttributes = {},
		...attributes
	} = props;

	const classes = [
		'fmc-cards__card',
		{ 'fmc-cards__card--dark': dark },
		{ 'fmc-cards__card--quiet': quiet },
		{ 'fmc-cards__card--horizontal': horizontal.active },
		{
			'fmc-cards__card--horizontal-25':
				horizontal.active && horizontal.size === 25,
		},
		{
			'fmc-cards__card--horizontal-75':
				horizontal.active && horizontal.size === 75,
		},
		{ 'fmc-cards__card--horizontal-reverse': horizontal.reverse },
	];

	const ClassName = clsx(classes, className);

	return (
		<Cell tag={tag} className={ClassName} {...attributes}>
			{media && (
				<Tag className={'fmc-cards__media-area'} {...mediaAreaProps}>
					<Tag
						tag={'img'}
						className={'fmc-cards__image'}
						src={'https://via.placeholder.com/1280x720'}
						alt={'a placeholder'}
						{...imgProps}
					/>
				</Tag>
			)}
			<Tag className={'fmc-cards__content'} {...contentAttributes}>
				{content && (
					<Fragment>
						{isDefined(title) && (
							<Tag
								tag={typeof title === 'string' ? 'h2' : 'div'}
								className={`${
									typeof title === 'string'
										? 'fmc-cards__title fds-transform--uppercase'
										: ''
								}`}
							>
								{title}
							</Tag>
						)}
						{isDefined(subtitle) && (
							<Tag
								tag={'h3'}
								className={
									'fmc-cards__subtitle fds-transform--uppercase'
								}
							>
								{subtitle}
							</Tag>
						)}
						{isDefined(copy) && (
							<Tag tag={'p'} className={'fmc-cards__body'}>
								{copy}
							</Tag>
						)}
						{isDefined(cta) && (
							<Tag className="fmc-cards__footer">
								<Button
									tag={'a'}
									noShadow
									href={'#'}
									target={'_blank'}
									rightIcon
								>
									{isDefined(cta) &&
									cta.hasOwnProperty('label')
										? cta.label
										: null}
								</Button>
							</Tag>
						)}
					</Fragment>
				)}

				{children}
			</Tag>
		</Cell>
	);
};

export default Card;
