import React from 'react';
import { Col } from '..';
interface LOACompletionProps {
	showLoader: boolean;
}
const LOACompletion = (props: LOACompletionProps) => {
	const { showLoader } = props;

	return (
		<>
			{showLoader ? (
				<p>Loading</p>
			) : (
				<div className={'fds-m--t-2 fds-m--b-2'}>
					<Col>
						<p className={'fds-p--y-1 fds-weight--bold'}>
							Document upload complete.
						</p>
						<p className={'fds-color__text--gray3'}>
							The documentation upload is complete and associated
							to the selected Dealer and visible on their
							Dashboard. If changes need to be made you can go
							through the process to edit, delete or change
							associations.
						</p>
					</Col>
				</div>
			)}
		</>
	);
};

export default LOACompletion;
