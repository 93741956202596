import React from "react";
import MecpSection from "./mecp/MecpSection";
import { MecpSection as TMecpSection, MecpSectionType, MecpDashboard } from "services/mecp-dashboard.types";
import MecpSummary from "./mecp/MecpSummary";
import { Col } from "components/components";

interface MecpDashboardProps {
	t: any;
	data: MecpDashboard | undefined;
	salesCode: string;
}

const MeCPDashboard = ({ t, data, salesCode }: MecpDashboardProps) => {
	const { mecpQualifier = false, sections, ...props } = data || {};
	const mecpSummary = sections?.find((section) => section?.type === "summary");
	return (
		<>
			{false && mecpSummary && <MecpSummary description={mecpSummary?.description} />}
			<Col xs={12} className={"fds-p--x-0 fds-xs:fds-m--x-0 fds-md:fds-m--x-2 fds-md:fds-m--y-4"}>
				{sections?.map((mecpSection) => {
					if (mecpSection?.type !== "summary") {
						return <MecpSection salesCode={salesCode} complete={mecpQualifier} key={mecpSection.id} t={t} type={mecpSection.type as MecpSectionType} section={mecpSection as any} />;
					}
				})}
			</Col>
		</>
	);
};

export default MeCPDashboard;
