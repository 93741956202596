import React, {
	Fragment,
	useContext,
	useState,
	useEffect,
	useMemo,
	FunctionComponent,
} from 'react';
import {
	Row,
	Col,
	Tag,
	Button,
	TopBanner,
	Container,
} from '../components/components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useAuth } from 'providers/AuthProvider';
import { useGetNoAccessDataMutation } from 'services/no-access';
import { useWindowResize } from 'hooks/useWindowResize';

import { useTranslation } from 'react-i18next';
import LanguageSelector from 'components/components/Navigation/LanguageSelector';

const NoAccess: FunctionComponent = () => {
	const tokenInfo: any = useAuth();
	const [NoAcessData, setNoAcessData]: any = useState({});
	const [isButtonDisabled, setButtonDisabled] = useState(false);

	const [updateFunc, { isError, isLoading, isSuccess }]: any =
		useGetNoAccessDataMutation();
	// const  windowWidth = useSelector((state: RootState) => state.windowSizeReducer.width);

	useWindowResize();

	const windowWidth = useSelector(
		(state: RootState) => state.windowSize.width
	);
	const [validEmail, setValidEmail] = useState('');
	const [Accesserror, setAccessError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [successBanner, setSuccessBanner] = useState(false);
	const [inpVal, setInpVal] = useState('');
	const [hideMailLinks, setHideMailLinks] = useState<boolean>(
		(windowWidth as number) <= 1024
	);

	const { t } = useTranslation();

	useEffect(() => {
		setNoAcessData(tokenInfo?.tokenProps?.authDetails);
	}, []);
	useEffect(() => {
		setHideMailLinks((windowWidth as number) <= 1024);
	}, [windowWidth]);
	const isValidEmail = (email: string) => {
		return /\S+@\S+\.\S+/.test(email);
	};

	const handleEmailChange = (event: { target: { value: string } }) => {
		setInpVal(event.target.value);
		if (isValidEmail(event.target.value)) {
			setValidEmail(event.target.value);
			setAccessError(false);
			setErrorMessage(false);
		} else {
			setAccessError(true);
		}
	};

	const handleBlur = () => {
		if (Accesserror) {
			setErrorMessage(true);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		console.log('scrolled');
	};

	const submitRequest = async (e: { preventDefault: () => void }) => {
		e.preventDefault();

		if (!inpVal || Accesserror) {
			setErrorMessage(true);
			return false;
		}

		setButtonDisabled(true);

		await updateFunc({
			firstName: NoAcessData.firstName,
			lastName: NoAcessData.lastName,
			email: validEmail,
			dealerName: NoAcessData.businessName,
			salesCode: NoAcessData.salesCode,
		});

		scrollToTop();

		setSuccessBanner(true);
	};

	const bannerMsg = useMemo(() => {
		if (!isLoading && isError) return t('pages.noAccess.failure');
		return t('pages.noAccess.success');
	}, [isLoading, isError]);
	
	const { countryCode } = useSelector((state: RootState) => state.localePreferences);
	const isUSActive: boolean = countryCode === 'USA';
	const isCANActive: boolean = countryCode === 'CAN';

	return (
		<Fragment>
			{(windowWidth as number) >= 1024 ? (
				<Fragment>
					{successBanner && (
						<TopBanner
							status={!isLoading && isError ? 'error' : 'success'}
							persistent={true}
							buttonClick={() => setSuccessBanner(false)}
							className={'no-access-success-banner'}
						>
							{' '}
							{bannerMsg}
						</TopBanner>
					)}
					<Row justify="end">
						<LanguageSelector key={'language-selector'} />
					</Row>

					<div
						className={
							'fds-position--relative fds-overflow--hidden'
						}
					>
						<img
							alt={'model e banner'}
							className={'fds-img'}
							src={
								'/assets/images/no-access-hero-banner_2680x680.jpg'
							}
						/>
						<div className={'no-access-banner-text-container'}>
							<p className={'no-access-banner-hero'}>
								{/* Sorry, you don't have access to the{' '} */}
								{t('pages.noAccess.heading')}
								<span className={'fds-wrap--nowrap'}>
									{/* Ford Model e<sup>™</sup> */}

									{t('pages.noAccess.headingP2')}
								</span>{' '}
								<span className={'fds-wrap--nowrap'}>
									{t('pages.noAccess.headingP3')}
								</span>
								{/* Dealer Dashboard */}
							</p>

							<p
								className={
									'fds-p--y-2 no-access-banner-secondary-text'
								}
							>
								{t('pages.noAccess.headText')}
							</p>
							{NoAcessData &&
							NoAcessData?.userType === 'Employee' ? (
								<p
									className={
										'no-access-banner-secondary-text'
									}
								>
									{t('pages.noAccess.request')}
									<a
										href={
											'/assets/pdfs/Model e Dealer Dashboard Ford Internal Role Access Guide.pdf'
										}
										target="_blank"
									>
										{t('pages.noAccess.linkText')}
									</a>
									.
								</p>
							) : null}
						</div>
					</div>
					<Row className={'fds-m--x-3 fds-m--y-2'}>
						<Col xs={6}>
							<p className={'no-access-title'}>
								{t('pages.noAccess.secondTitle')}
							</p>
							<Row className={'fds-p--b-3'}>
								<div
									className={`fds-flex fds-flex-direction--column fds-p--r-4`}
								>
									<small
										className={'fds-color__text--primary'}
									>
										{t('pages.noAccess.fName')}
									</small>
									<small>
										{NoAcessData && NoAcessData.firstName
											? NoAcessData.firstName
											: ''}
									</small>
								</div>
								<div
									className={`fds-flex fds-flex-direction--column fds-p--l-2`}
								>
									<small
										className={'fds-color__text--primary'}
									>
										{/* Last Name */}
										{t('pages.noAccess.lName')}
									</small>
									<small>
										{NoAcessData && NoAcessData.lastName
											? NoAcessData.lastName
											: ''}
									</small>
								</div>
							</Row>

							<Row>
								<div
									className={`fds-flex fds-flex-direction--column fds-p--r-4`}
								>
									<small
										className={'fds-color__text--primary'}
									>
										{/* Dealership */}
										{t('pages.noAccess.dealer')}
									</small>
									<small>
										{NoAcessData && NoAcessData.businessName
											? NoAcessData.businessName
											: ''}
									</small>
								</div>
								<div
									className={`fds-flex fds-flex-direction--column fds-p--l-3`}
								>
									<small
										className={'fds-color__text--primary'}
									>
										{/* Sales Code */}
										{t('pages.noAccess.sCode')}
									</small>
									{isUSActive ? (
										<small>
											{NoAcessData &&
											NoAcessData.salesCode
												? NoAcessData.salesCode
												: ''}
										</small>
									) : (
										''
									)}
									{isCANActive ? (
										<small>
											{NoAcessData && NoAcessData.paCode
												? NoAcessData.paCode
												: ''}
										</small>
									) : (
										''
									)}
								</div>
							</Row>

							<div
								className={`fmc-floating-label fds-m--y-3 fds-flex__col-7 fds-p--l-0 ${
									errorMessage
										? 'fmc-floating-label--error'
										: ''
								}`}
							>
								<input
									className={`fmc-input fds-color__bg--white ${
										errorMessage && !validEmail
											? 'fmc-input--error'
											: ''
									}`}
									id="email"
									onChange={handleEmailChange}
									onBlur={handleBlur}
									aria-label="emailInput"
									type="text"
									placeholder="Email"
								/>
								<label>
									<span className="fmc-floating-label__text">
										{/* Email */}
										{t('pages.noAccess.email')}
									</span>
								</label>
								{/* {error && (
									<small
										style={{
											color: 'var(--fds-color--error1)',
										}}
									>
										Please enter email.
									</small>
								)} */}

								{errorMessage && !validEmail && (
									<small
										style={{
											color: 'var(--fds-color--error1)',
										}}
									>
										{!inpVal
											? t('pages.noAccess.noemail')
											: t('pages.noAccess.emailError')}
									</small>
								)}
							</div>

							<Button
								ariaLabel={'Submit Support Request Button'}
								buttonClassName={'fds-m--y-2'}
								disabled={isButtonDisabled}
								onClick={(event: any) => {
									submitRequest(event);
								}}
							>
								{/* Request Support */}
								{t('pages.noAccess.button')}
							</Button>
						</Col>
						<Col xs={6} className={''}>
							<Tag
								tag={'div'}
								className={
									'fds-p--y-2 fds-p--x-2 fmc-divider--left'
								}
							>
								<Col xs={12}>
									<h4
										className={
											'fds-type--title1 fds-m--b-1'
										}
									>
										{t('pages.noAccess.programLabel')}
									</h4>
									<a
										className={'fds-decoration--underline'}
										href={`mailto:${t(
											'pages.noAccess.emailaddress'
										)}`}
										// href={
										// 	'mailto:ModelePHQ@fordprogramhq.com'
										// }
										style={{
											color: 'var(--fds-color--secondary)',
										}}
									>
										{t('pages.noAccess.emailaddress')}
										{/* ModelePHQ@fordprogramhq.com */}
									</a>
									<p>(888) 850-1002</p>
									{/* <p>Mon. - Fri. 9am - 7pm ET</p> */}
									<p>{t('pages.noAccess.hours')}</p>
								</Col>
							</Tag>
						</Col>
					</Row>
				</Fragment>
			) : (
				<Fragment>
					{successBanner && (
						<TopBanner
							status={!isLoading && isError ? 'error' : 'success'}
							persistent={true}
							buttonClick={() => setSuccessBanner(false)}
							className={'no-access-success-banner'}
						>
							{' '}
							{bannerMsg}
						</TopBanner>
					)}
					
					<Container>
						<Row justify={'start'}>
							<LanguageSelector
								key={'mobile-language-selector'}
							/>
						</Row>
					</Container>

					<Tag
						tag={'div'}
						aria-label={'mobile hero'}
						className={'no-access-mobile-hero'}
					>
						<img
							className={'fds-img'}
							src={
								'/assets/images/no-access-hero-banner_mobile_780x460.jpg'
							}
						/>

						<Tag
							tag={'div'}
							className={
								'no-access-mobile-hero-area fds-sm:fds-overflow--hidden fds-xs:fds-overflow--hidden'
							}
						>
							<p className={'no-access-mobile-title'}>
								{t('pages.noAccess.heading')}
								<span className={'fds-wrap--nowrap'}>
									{t('pages.noAccess.headingP2')}
								</span>
								<wbr />

								{t('pages.noAccess.headingP3')}
							</p>
							<p className={'no-access-mobile-text fds-p--y-2'}>
								{t('pages.noAccess.headText')}
							</p>
						</Tag>
					</Tag>
					<Tag
						tag={'div'}
						aria-label={'mobile content'}
						className={'mobile-no-access-content'}
					>
						<p className={'no-access-title fds-p--t-2'}>
							{t('pages.noAccess.secondTitle')}
						</p>

						<div
							className={`fds-flex fds-flex-direction--column fds-p--b-3`}
						>
							<small className={'fds-color__text--primary'}>
								{t('pages.noAccess.fName')}
							</small>
							<small>
								{NoAcessData && NoAcessData.firstName
									? NoAcessData.firstName
									: ''}
							</small>
						</div>
						<div
							className={`fds-flex fds-flex-direction--column fds-p--b-3`}
						>
							<small className={'fds-color__text--primary'}>
								{t('pages.noAccess.lName')}
							</small>
							<small>
								{NoAcessData && NoAcessData.lastName
									? NoAcessData.lastName
									: ''}
							</small>
						</div>

						<div
							className={`fds-flex fds-flex-direction--column fds-p--b-3`}
						>
							<small className={'fds-color__text--primary'}>
								{t('pages.noAccess.dealer')}
							</small>
							<small>
								{NoAcessData && NoAcessData.businessName
									? NoAcessData.businessName
									: ''}
							</small>
						</div>

						<div
							className={`fds-flex fds-flex-direction--column fds-p--b-3`}
						>
							<small className={'fds-color__text--primary'}>
								{/* Sales Code */}
								{t('pages.noAccess.sCode')}
							</small>
							{isUSActive ? (
								<small>
									{NoAcessData && NoAcessData.salesCode
										? NoAcessData.salesCode
										: ''}
								</small>
							) : (
								''
							)}
							{isCANActive ? (
								<small>
									{NoAcessData && NoAcessData.paCode
										? NoAcessData.paCode
										: ''}
								</small>
							) : (
								''
							)}
						</div>

						<div
							className={`fmc-floating-label fds-m--b-3 fds-flex__col-10 fds-p--l-0 ${
								errorMessage ? 'fmc-floating-label--error' : ''
							}`}
						>
							<input
								className={`fmc-input fds-color__bg--white ${
									errorMessage && !validEmail
										? 'fmc-input--error'
										: ''
								}`}
								id="email"
								onChange={handleEmailChange}
								onBlur={handleBlur}
								aria-label="emailInput"
								type="text"
								placeholder="Email"
							/>
							<label>
								<span className="fmc-floating-label__text">
									{t('pages.noAccess.email')}
								</span>
							</label>
							{errorMessage && !validEmail && (
								<small
									style={{
										color: 'var(--fds-color--error1)',
									}}
								>
									{!inpVal
										? t('pages.noAccess.noemail')
										: t('pages.noAccess.emailError')}
								</small>
							)}
						</div>
						<div
							className={
								'fds-flex fds-flex__justify--center fds-m--b-3'
							}
						>
							<Button
								ariaLabel={'Submit Support Request Button'}
								buttonClassName={'fds-m--y-2'}
								onClick={submitRequest}
								disabled={isButtonDisabled}
							>
								{t('pages.noAccess.button')}
							</Button>
						</div>

						<Col xs={12} className={'no-access-mobile-contact'}>
							<Tag
								tag={'div'}
								className={'fds-p--y-2 fds-p--x-2 '}
							>
								<Col xs={12}>
									<h4
										className={
											'fds-type--title1 fds-m--b-1'
										}
									>
										{t('pages.noAccess.programLabel')}
									</h4>
									<a
										className={'fds-decoration--underline'}
										href={`mailto:${t(
											'pages.noAccess.emailaddress'
										)}`}
										// href={
										// 	'mailto:ModelePHQ@fordprogramhq.com'
										// }
										style={{
											color: 'var(--fds-color--secondary)',
										}}
									>
										{/* ModelePHQ@fordprogramhq.com */}
										{t('pages.noAccess.emailaddress')}
									</a>
									<p>(888) 850-1002</p>
									{/* <p>Mon. - Fri. 9am - 7pm ET</p> */}
									<p>{t('pages.noAccess.hours')}</p>
								</Col>
							</Tag>
						</Col>
					</Tag>
				</Fragment>
			)}
		</Fragment>
	);
};

export default NoAccess;
