import Tag from '../components/components/Tag/Tag';
import { Fragment, useEffect } from 'react';
import Col from '../components/components/Columns/Col';
import { SearchBar } from '@onemagnify/cxc-components-web';
import Row from '../components/components/Columns/Row';
import DashSearch from '../components/components/Dashboard/DashSearch';
import { useAuth } from 'providers/AuthProvider';
import { useGetAdminDashboardDealerListDataQuery } from 'services/admin-dashboard';
import { AdminDashboardApiResponse } from 'services/admin-dashboard.types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSearchValue } from 'reducers/dealer-search.slice';
import { useTranslation } from 'react-i18next';

function ModelEAdminDashboard() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { tokenProps } = useAuth();
	const { authDetails } = tokenProps || {};
	const dealerUsername = `${authDetails?.firstName} ${authDetails?.lastName}`;
	const { searchValue } = useSelector(
		(state: RootState) => state.dealerSearch
	);
	const { countryCode, preferredLanguage } = useSelector(
		(state: RootState) => state.localePreferences
	);
	const { data, refetch } = useGetAdminDashboardDealerListDataQuery();

	// useEffect to refetch data whenever countryCode or preferredLanguage changes
	useEffect(() => {
		refetch();
	}, [countryCode, preferredLanguage, refetch]);

	return (
		<Fragment>
			<Col
				xs={12}
				className={
					'fds-m--t-3 fds-md:fds-m--x-4 fds-lg:fds-m--x-4 fds-xl:fds-m--x-4 fds-m--b-3'
				}
			>
				<Row className={'fds-flex--center'}>
					<Col xs={11} lg={10} className={'fds-p--l-0 fds-m--b-1 '}>
						<Row className={'fds-align--left'}>
							<Tag
								tag={'h1'}
								className={
									'fmc-type--heading3 fds-color--primary fds-p--y-2 '
								}
							>
								{t('common.welcome')}, {dealerUsername}
							</Tag>
						</Row>
						<Row className="fds-p--b-2">
							<Tag
								tag={'p'}
								className={
									'fmc-type--body2 fds-font--color__gray fds-p--r-1 fds-p--b-2'
								}
							>
								{t('pages.dealerDashboard.selectDealerMessage')}
							</Tag>

							<Tag
								tag={'p'}
								className={
									'fmc-type--body2 fds-font--color__gray'
								}
							>
								{t('pages.dealerDashboard.searchDealerMessage')}
							</Tag>
						</Row>
					</Col>
				</Row>
				<Row
					className={'fds-flex--center '}
					// style={{ justifyContent: 'center' }}
				>
					<Col xs={11} lg={10} className={'fds-p--l-0 fds-m--b-1 '}>
						<Tag
							tag={'label'}
							className={
								'fmc-type--body2 fds-font--color__gray fds-p--b-0 fds-weight--bold'
							}
						>
							{t('pages.dealerDashboard.searchDealerInputLabel')}

							<Col xs={12} className={'fds-p--x-0'}>
								<SearchBar
									placeholder={t('common.search')}
									value={searchValue}
									onChange={(term) =>
										dispatch(setSearchValue(term))
									}
									className={`fds-align--center dealer-dash-search fds-p--t-1`}
								/>

								<Tag tag={'div'} className={'dashSearch'}>
									<DashSearch
										searchTerm={searchValue}
										dealersArray={
											data?.data as
												| AdminDashboardApiResponse
												| undefined
										}
									/>
								</Tag>
							</Col>
						</Tag>
					</Col>
				</Row>
			</Col>
		</Fragment>
	);
}

export default ModelEAdminDashboard;

// fds-layout-grid__cell--span-12
