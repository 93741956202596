import { FormikProps, useFormikContext } from 'formik';
import React, { useRef } from 'react';

interface FormikInputFieldProps<T> {
    name: keyof T;
    placeholder: string;
    label: string;
    formik?: FormikProps<T>;
    required?: boolean;
    disabled?: boolean;
    whiteBg?: boolean; // Property for background color
}

export const FormikInputField = <T extends Record<string, any>>({
    name,
    placeholder,
    label,
    formik: formikProp,
    required = true,
    disabled = false,
    whiteBg = true, // Default value for whiteBg
}: FormikInputFieldProps<T>): React.ReactElement => {
    const formik = formikProp || useFormikContext<T>(); // Use provided formik prop or fallback to context
    const isTouched = formik.getFieldMeta(name as string).touched;
    const isError = formik.getFieldMeta(name as string).error;
    const fieldProps = formik.getFieldProps(name as string);
    const inputRef = useRef<HTMLInputElement>(null);

    const focusInput = (): void => {
        inputRef.current?.focus();
    };

    const inputClass = `fmc-input ${whiteBg ? 'fds-color__bg--white' : ''} ${isTouched && isError ? 'fmc-input--error' : ''}`;

    return (
        <>
            <div className={`fmc-floating-label fds-m--b-1 fds-xs:fds-flex__col-12 fds-p--l-0 ${isTouched && isError ? 'fmc-floating-label--error' : ''}`}>
                <input
                    {...fieldProps}
                    ref={inputRef}
                    type="text"
                    placeholder={placeholder}
                    className={inputClass}
                    disabled={disabled}
                />
                <label onClick={focusInput}>
                    <span className="fmc-floating-label__text">{label}{required && '*'}</span>
                </label>
            </div>
            {isTouched && isError && <small style={{ color: 'var(--fds-color--error1)' }}>{isError}</small>}
        </>
    );
};
