import { useState, useMemo } from 'react';
import { DropdownOptionsResponseDataItem } from "services/reports.types";




/**
 * Hook that returns filtered dropdown options for UserPreferences reports and handlers for selection changes.
 * 
 * @returns Object containing filtered options arrays and change handlers.
 */
interface UserPreferencesReportDropdownOptionsHook {
  filteredRegions: string[];
  filteredZones: string[];
  filteredMarketAreas: string[];
  filteredDealerCodesOptions: { label: string, value: string }[];
  filteredPaCodesOptions: { label: string, value: string }[];
  handleMarketAreaChange: (marketArea: string) => void;
  handleRegionChange: (region: string) => void;
  handleZoneChange: (zone: string) => void;
}


/**
 * Utility function to prepend an 'All' option to a list of dropdown options if it does not already exist.
 * 
 * @param {Object[]} options - The list of current options for the dropdown.
 * @returns A new array of options with 'All' prepended if necessary.
 */
const prependAllOption = (options: { label: string, value: string }[]): { label: string, value: string }[] => {
  const allOption = { label: 'All', value: 'All' };
  if (!options.some(option => option.value === 'All')) {
    return [allOption, ...options];
  }
  return options;
};


/**
 * Custom hook for managing UserPreferences report dropdown options with 'All' option included.
 * 
 * @param {UserPreferencesReportDropdownOptionsProps} props - The props object containing the initial dropdown data.
 * @returns {UserPreferencesReportDropdownOptionsHook} The hook object with filtered options and change handlers.
 */
export const useUserPreferencesReportDropdownOptions = ( dropDownData: DropdownOptionsResponseDataItem[]): UserPreferencesReportDropdownOptionsHook => {
  const [selectedMarketArea, setSelectedMarketArea] = useState<string>('All');
  const [selectedRegion, setSelectedRegion] = useState<string>('All');
  const [selectedZone, setSelectedZone] = useState<string>('All');

  // useMemo hooks for filtering options based on selections

  const filteredMarketAreas = useMemo(() => ['All', ...getUniqueValues(dropDownData, 'marketAreaName')], [dropDownData]);

  const filteredRegions = useMemo(() => {
    const regions = dropDownData.filter(({ marketAreaName }) => selectedMarketArea === 'All' || marketAreaName === selectedMarketArea).map(({ regionName }) => regionName);
    return ['All', ...new Set(regions)];
  }, [dropDownData, selectedMarketArea]);


  const filteredZones = useMemo(() => {
    if (selectedRegion === 'All') return ['All'];
    const zones = dropDownData.filter(({ regionName }) => regionName === selectedRegion).map(({ zone }) => zone);
    return ['All', ...new Set(zones)];
  }, [dropDownData, selectedRegion]);


  const filteredDealerCodesOptions = useMemo(() => prependAllOption(dropDownData
    .filter(({ regionName, zone }) => (selectedRegion === 'All' || regionName === selectedRegion) && (selectedZone === 'All' || zone === selectedZone))
    .map(({ dealerCode }) => ({ label: dealerCode, value: dealerCode }))), [dropDownData, selectedRegion, selectedZone]);


  const filteredPaCodesOptions = useMemo(() => prependAllOption(dropDownData
    .filter(({ regionName, zone }) => (selectedRegion === 'All' || regionName === selectedRegion) && (selectedZone === 'All' || zone === selectedZone))
    .map(({ paCode }) => ({ label: paCode, value: paCode }))), [dropDownData, selectedRegion, selectedZone]);


 // Handlers for selection changes

  const handleMarketAreaChange = (marketArea: string) => {
    setSelectedMarketArea(marketArea);
    setSelectedRegion('All');
    setSelectedZone('All');
  };

  const handleRegionChange = (region: string) => {
    setSelectedRegion(region);
    setSelectedZone('All');
  };

  const handleZoneChange = (zone: string) => {
    setSelectedZone(zone);
  };

  return {
    filteredRegions,
    filteredZones,
    filteredMarketAreas,
    filteredDealerCodesOptions,
    filteredPaCodesOptions,
    handleMarketAreaChange,
    handleRegionChange,
    handleZoneChange,
  };
};

/**
 * Returns an array of unique values for a given key in an array of objects.
 * @param data - The array of objects.
 * @param key - The key to extract unique values from.
 * @returns An array of unique values.
 */
const getUniqueValues = (data: any[], key: string): string[] => Array.from(new Set(data.map(item => item[key])));
