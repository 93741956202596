import React, { Fragment } from "react";
import { TopBanner } from "..";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import QualificationPeriodReminderBanner from "./QualificationPeriodReminderBanner";
import { DashboardMessageCenter, MessageCenters } from "services/dashboard.types";

const QualificationPeriodBanner = ({ countryCode, messageCenters }: { countryCode: string; messageCenters?: MessageCenters[] }) => {
	const { t } = useTranslation();
	const edcLink = `https://www.fmcdealer.dealerconnection.com/content/dam/fmcdealer/documents/edc/2024/EFC15038.pdf?authcheckretry`;
	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	const getMessageCenterContent = (object: any) => {
		if (!object?.text) return <></>;
		switch (object?.type) {
			case "text":
				const classNames = object?.bold ? "fds-weight--bold" : "";
				return <span className={classNames}>{object?.text}</span>;
			case "title":
				return <span className={"fds-color--secondary fds-weight--bold"}>{object?.text}: </span>;
			case "hyperlink":
				return (
					<a href={object?.hyperlink} target="_blank" style={{ color: "#1700f4" }}>
						{object?.text}
					</a>
				);
			default:
				return <></>;
		}
	};

	if (!messageCenters) return <></>;

	// const canShowBanner = useShowQualificationBanner();
	// if (!canShowBanner) return <></>;
	return (
		<Fragment>
			{(windowWidth as number) >= 1024 ? (
				<TopBanner className={"dash-qualification-banner"} status={undefined} nonDismissable persistent>
					<div className={"dash-temp-banner"}>
						<img style={{ paddingBottom: "30px", height: "60px" }} src={"/assets/icons/meDDicon/vehicle-info-icon-48.svg"} />
						<div className="flex fds-p--x-1">
							{messageCenters?.map((messageCenter, idx1) => (
								<div className={idx1 !== messageCenters?.length - 1 ? "fds-p--b-1" : ""} key={idx1}>
									{messageCenter?.map((messageObj, idx) => (
										<React.Fragment key={idx}>{getMessageCenterContent(messageObj)}</React.Fragment>
									))}
									.
								</div>
							))}
						</div>
					</div>
				</TopBanner>
			) : (
				<TopBanner className={"dash-qualification-banner"} status={undefined} nonDismissable persistent>
					<div className={"dash-temp-banner flex fds-flex-direction--column"} style={{ flexDirection: "column" }}>
						{messageCenters?.map((messageCenter, idx1) => (
							<div key={idx1} className={idx1 !== messageCenters?.length - 1 ? "fds-p--b-1" : ""}>
								{messageCenter?.map((messageObj, idx) => (
									<React.Fragment key={idx}>{getMessageCenterContent(messageObj)}</React.Fragment>
								))}
								.
							</div>
						))}
					</div>
				</TopBanner>
			)}
		</Fragment>
	);
};

export default QualificationPeriodBanner;
