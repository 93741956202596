import { FC, Fragment, useContext, useEffect, useMemo, useState } from "react";
import { AdminPageContext } from "../contexts/AdminContext";
import MobileDash from "../components/components/Dashboard/MobileDash";
import { useGetDealerDashboardDataQuery, useGetDealerDocumentInfoQuery } from "services/dashboard";

import { useAuth } from "providers/AuthProvider";
import { useParams } from "react-router-dom";
import { DealerDashboard, DealerDocument, DashboardSection, Section } from "services/dashboard.types";
import { calcTaskProgress, getDealerSectionId } from "utils/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setPacode } from "reducers/pacode.slice";
import "../i18n"; // This imports the i18n configuration you've set up
import { useTranslation } from "react-i18next";
import USADashboard from "./Dashboard/USADashboard";
import CANDashboard from "./Dashboard/CANDashboard";
import MecpDashboardApiData from "./../mocks/mecp-dashboard.json";
import { MecpDashboard } from "services/mecp-dashboard.types";
import { useGetMecpDealerDashboardDataQuery } from "services/mecp-dashboard";

export interface BaseDashboarProps {
	t: any;
	canShowQualificationBanner: boolean;
	countryCode: string;
	dealerCode: string;
	dashboardData: DealerDashboard | undefined;
	mecpDashboardData?: MecpDashboard | undefined;
	firstSection: DashboardSection | undefined;
	dealerSectionsInfo: any;
	chargingInfraStatus: any;
	loaAndWaiverInfo: any;
	sectionsWithId: DashboardSection[] | undefined;
	isQualified: boolean;
	dealerDocumentMapping: any;
	setExpandCallback: any;
	setCollapseCallback: any;
	setOnLoadCallback: any;
	isWeb: boolean;
}

const ModelEDashboard: FC = () => {
	const adminPageContext = useContext(AdminPageContext);

	const { t } = useTranslation();

	const { countryCode, preferredLanguage } = useSelector((state: RootState) => state.localePreferences);

	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	const { paCode: paCodeFromUrl } = useParams();
	const { tokenProps, isAdmin, isEmployee } = useAuth();
	const { authDetails, tokenDetails } = tokenProps || {};
	const { fordSiteCode: paCode } = tokenDetails || {};

	const { data: dealerDashboardData, isLoading, error, isError, isUninitialized, isSuccess, isFetching: isAuthDataFetching, refetch } = useGetDealerDashboardDataQuery(paCodeFromUrl || paCode || "");
	const { data } = dealerDashboardData || {};

	const { data: dealerDocdApiData } = useGetDealerDocumentInfoQuery(paCodeFromUrl || paCode || "");
	const { data: dealerDocumentData } = dealerDocdApiData || {};

	// const { data: mecpDashboardApiData } = MecpDashboardApiData || {};
	// const { data: mecpDashboardData } = MecpDashboardApiData || {};
	const { data: mecpDashboardApiData } = useGetMecpDealerDashboardDataQuery(paCodeFromUrl || paCode || "");
	const { data: mecpDashboardData } = mecpDashboardApiData || {};

	const dealerDocumentMapping = useMemo(() => {
		return dealerDocumentData?.reduce((acc, cur) => {
			return {
				...acc,
				[cur?.complianceGroupId]: cur?.documentId,
			};
		}, {});
	}, [dealerDocumentData]);

	const { dealerName, maapEarnings, dataAsOf, marketArea, region, salesCode, sections, paCode: paCodeFromApi, zone, messageCenter } = data || {};
	const dispatch = useDispatch();

	const sectionsWithId = sections?.map((section) => {
		return {
			...section,
			slugId: getDealerSectionId(section?.id),
		};
	});

	// When admin is implemented, set this with admin. I'm keeping it true to show buttons on pages they exist on
	useEffect(() => {
		adminPageContext?.setAdminPage(true);
	}, [adminPageContext]);

	useEffect(() => {
		refetch();
		if ((isError || error) && (isAdmin || isEmployee)) {
			window.location.href = "/";
		}
	}, [preferredLanguage, countryCode, refetch, isError]);

	useEffect(() => {
		dispatch(setPacode({ marketArea, region, salesCode, dealerName, zone }));
	}, [marketArea, region, salesCode, zone]);

	const dealerSectionsInfo: any = useMemo(() => {
		return sections?.reduce((acc, cur: Section, idx: number) => {
			const mId = getDealerSectionId(cur?.id);
			const completionPercent = calcTaskProgress(cur?.completedSteps, cur?.totalSteps);
			return {
				...acc,
				[mId]: cur,
				[`${mId}CompletionPercent`]: completionPercent,
			};
		}, {});
	}, [dealerName, countryCode, preferredLanguage]);

	// const dealerInformation = completeDealerInfo;
	const [expandCallback, setExpandCallback] = useState({
		callback: (element: HTMLElement) => {},
	});
	const [collapseCallback, setCollapseCallback] = useState({
		callback: (element: HTMLElement) => {},
	});

	const [onLoadCallback, setOnLoadCallback] = useState({
		callback: (element: HTMLElement, state?: "closed" | "opened") => {},
	});

	const chargingInfraStatus = useMemo(() => {
		return data?.sections?.[0]?.subSections?.find((subSection) => subSection?.name === "Charging Infrastructure")?.status || "InComplete";
	}, [data]);

	const loaAndWaiverInfo: any = useMemo(() => {
		return data?.sections?.reduce((secAcc, section) => {
			const subSectionData = section?.subSections?.reduce((acc, subSection) => {
				const criteriasInfo = subSection?.criterias?.reduce((criteriaAcc, criteria) => {
					const criteriaListInfo = criteria?.criteriaList?.reduce((criteriaListAcc, criteriaListItem) => {
						if (criteriaListItem.status === "LOA" || criteriaListItem.status === "OnWaiver") {
							return {
								...criteriaListAcc,
								[criteriaListItem.status]: true,
							};
						}
						return criteriaListAcc;
					}, {});

					if (criteria.status === "LOA" || criteria.status === "OnWaiver") {
						return {
							...criteriaAcc,
							[criteria.status]: true,
							...criteriaListInfo,
						};
					}

					return {
						...criteriaAcc,
						...criteriaListInfo,
					};
				}, {});

				if (subSection.status === "LOA" || subSection.status === "OnWaiver") {
					return {
						...acc,
						[subSection.status]: true,
					};
				}

				return {
					...acc,
					...criteriasInfo,
				};
			}, {});

			return {
				...secAcc,
				...subSectionData,
			};
		}, {});
	}, [data]);

	// "In-Progress" dealer information used as dealer on this page. Completed dealer information will be used in ModelEDashComplete.tsx
	if (isLoading || isAuthDataFetching) {
		return <h1>Loading...</h1>;
	}

	const welcomeMsg = `${t("common.welcome")}, ${dealerName}`;

	const isQualified = dealerSectionsInfo?.meqCompletionPercent === 100;
	const firstSection = sectionsWithId?.[0];
	const dealerCode = countryCode === "CAN" ? t("common.dealerCode") : t("common.paCode");

	const canShowQualificationBanner = (() => {
		if (countryCode === "CAN") return true;
		const bannerDate = new Date("2024-01-15T00:00:00").getTime();
		const now = Date.now();
		return now > bannerDate;
	})();
	const isWeb = (windowWidth as number) >= 1024;
	return (
		<Fragment key={Math.random().toFixed(4)}>
			{(windowWidth as number) >= 1024 ? (
				<>
					{countryCode === "USA" && (
						<USADashboard
							canShowQualificationBanner={canShowQualificationBanner}
							chargingInfraStatus={chargingInfraStatus}
							countryCode={countryCode}
							dashboardData={data}
							dealerCode={dealerCode}
							dealerDocumentMapping={dealerDocumentMapping}
							dealerSectionsInfo={dealerSectionsInfo}
							firstSection={firstSection}
							isQualified={isQualified}
							loaAndWaiverInfo={loaAndWaiverInfo}
							sectionsWithId={sectionsWithId}
							setCollapseCallback={setCollapseCallback}
							setExpandCallback={setExpandCallback}
							setOnLoadCallback={setOnLoadCallback}
							t={t}
							key={Math.random().toFixed(4)}
							isWeb={isWeb}
							mecpDashboardData={mecpDashboardData as any}
						/>
					)}
					{countryCode === "CAN" && (
						<CANDashboard
							canShowQualificationBanner={false}
							chargingInfraStatus={chargingInfraStatus}
							countryCode={countryCode}
							dashboardData={data}
							dealerCode={dealerCode}
							dealerDocumentMapping={dealerDocumentMapping}
							dealerSectionsInfo={dealerSectionsInfo}
							firstSection={firstSection}
							isQualified={isQualified}
							loaAndWaiverInfo={loaAndWaiverInfo}
							sectionsWithId={sectionsWithId}
							setCollapseCallback={setCollapseCallback}
							setExpandCallback={setExpandCallback}
							setOnLoadCallback={setOnLoadCallback}
							t={t}
							key={Math.random().toFixed(4)}
							isWeb={isWeb}
						/>
					)}
				</>
			) : (
				data && (
					<MobileDash
						messageCenters={messageCenter}
						chargingInfraStatus={chargingInfraStatus}
						meqCompletionPercent={dealerSectionsInfo?.meqCompletionPercent}
						loaAndWaiverInfo={loaAndWaiverInfo}
						countryCode={countryCode}
						dealerInformation={data}
						sections={sectionsWithId}
						documents={dealerDocumentMapping}
					/>
				)
			)}
		</Fragment>
	);
};

export default ModelEDashboard;
