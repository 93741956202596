import { createApi } from "@reduxjs/toolkit/query/react";
import { AdminDashboardAnalyticsResponse, AdminDashboardApiResponse } from "./admin-dashboard.types";
import { ApiResponse } from "types/types";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const adminDashboardApi = createApi({
	reducerPath: "adminDashboardApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_ADMIN_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
			includeWslId: true,
		},
	}),
	endpoints: (builder) => ({
		getAdminDashboardDealerListData: builder.query<AdminDashboardApiResponse, void>({
			query: () => `admin/dashboard/Landing`,
		}),
		updatePreferences: builder.mutation<void, { wslId: string; countryCode: string; preferredLanguage: string }>({
			query: ({ wslId, countryCode, preferredLanguage }) => ({
				url: `/admin/preferences/update?wslId=${wslId}&countryCode=${countryCode}&preferredLanguage=${preferredLanguage}`,
				method: "PUT",
			}),
		}),
		getAnalytics: builder.query<ApiResponse<AdminDashboardAnalyticsResponse>, void>({
			query: () => "/admin/dashboard/analytics/",
		}),
	}),
});

export const { useGetAdminDashboardDealerListDataQuery, useUpdatePreferencesMutation, useGetAnalyticsQuery } = adminDashboardApi;
