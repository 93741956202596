import React, { Fragment, useContext } from "react";
import DashBattery from "./DashBattery";
import { Col, Tag } from "..";
import { Tooltip } from "@onemagnify/cxc-components-web";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { QualifierStatusType } from "services/dashboard.types";

interface BatterySectionProps {
	fmeQPercentage: number;
	isLandingButton?: boolean;
	status: QualifierStatusType;
}

const BatterySection = (props: BatterySectionProps) => {
	const { status, fmeQPercentage } = props || {};
	const chargingQualified = status === "Complete" || status === "LOA";
	const fullyQualified = fmeQPercentage == 100;

	const isLandingButton = props.isLandingButton;
	const completeIcon = "/assets/icons/meDDicon/icon-complete.svg";
	const incompleteIcon = "/assets/icons/meDDicon/icon-not-complete.svg";

	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	return (
		<Fragment>
			{(windowWidth as number) >= 1024 ? (
				<Fragment>
					{isLandingButton ? (
						<Col lg={12}>
							<Tag tag={"div"} className={"w-100 fds-flex fds-flex--center fds-flex__items--center fds-flex-direction--column "}>
								<Tag tag={"div"} className={"fds-flex fds-flex--center fds-flex-direction--row"}>
									<Tag tag={"div"} className={"fds-flex fds-flex-direction--row fds-flex__items--center"}>
										<DashBattery dealerProgress={fmeQPercentage} isLandingButton />
										<Tag tag={"div"} className={"fds-flex fds-flex-direction--column fds-align--left fds-m--r-3"}>
											<p className={`fds-p--b-1 qual-battery-text letter-spacing-0 ${fullyQualified ? "hidden-qualified" : ""}`}>
												Ford Electric Vehicle Competency {/* @ts-ignore */}
												<Tooltip
													className={"fmeQ-tooltip"}
													position={"top"}
													uniqueIdentifier={`fmeQ tooltip`}
													tooltipContent={"This represents completion of the three Electric Vehicle Competency elements - Charging Infrastructure, Tools & Equipment and Electric Vehicle Training."}
												>
													<img className={"dash-info-icon"} src="/assets/icons/meDDicon/info-48-dk-gray.svg" />
												</Tooltip>
											</p>

											<p className={`battery-percentage-text letter-spacing-0 ${fullyQualified ? "hidden-qualified" : ""}`}>
												{fmeQPercentage}
												<sup>%</sup> Competency Met
											</p>
										</Tag>
									</Tag>
								</Tag>
							</Tag>
						</Col>
					) : (
						<Col lg={12}>
							<Tag tag={"div"} className={"w-100 fds-flex fds-flex--center fds-flex__items--center fds-flex-direction--column "}>
								<Tag tag={"div"} className={"fds-flex fds-flex--center fds-flex-direction--row"}>
									<Tag tag={"div"} className={"fds-flex fds-flex-direction--row fds-flex__items--center"}>
										<DashBattery dealerProgress={fmeQPercentage} />
										<Tag tag={"div"} className={"fds-flex fds-flex-direction--column fds-align--left fds-m--r-3"}>
											<p className={`fds-p--b-1 qual-battery-text letter-spacing-0 ${fullyQualified ? "hidden-qualified" : ""}`}>
												Ford Electric Vehicle Competency {/* @ts-ignore */}
												<Tooltip
													className={"fmeQ-tooltip"}
													position={"top"}
													uniqueIdentifier={`fmeQ tooltip`}
													tooltipContent={"This represents completion of the three Electric Vehicle Competency elements - Charging Infrastructure, Tools & Equipment and Electric Vehicle Training."}
												>
													<img className={"dash-info-icon"} src="/assets/icons/meDDicon/info-48-dk-gray.svg" />
												</Tooltip>
											</p>

											<p className={`battery-percentage-text letter-spacing-0 ${fullyQualified ? "hidden-qualified" : ""}`}>
												{fmeQPercentage}
												<sup>%</sup> Competency Met
											</p>
										</Tag>
									</Tag>
								</Tag>
							</Tag>
						</Col>
					)}
				</Fragment>
			) : (
				<Col xs={10} sm={9} lg={12} className={"fds-flex__self--center"}>
					<Tag tag={"div"} className={"w-100 fds-flex fds-flex--center fds-flex__items--center fds-flex-direction--column"}>
						<Tag tag={"div"} className={"fds-flex fds-flex--center fds-flex-direction--column "}>
							<Tag tag={"div"} className={"fds-flex fds-flex-direction--row fds-flex__items--center fds-flex--center"}>
								<>
									<div className={"fds-flex fds-flex__items--center fds-flex-direction--column"}>
										<div className={"fds-flex fds-flex__items--center fds-flex-direction--row"}>
											<DashBattery dealerProgress={fmeQPercentage} />
											<p className={` letter-spacing-0 fds-align--left fds-p--b-1 qual-battery-text-mobile ${fullyQualified ? "fds-color__text--success1" : ""}`}>
												Ford Electric Vehicle Competency {/* @ts-ignore */}
												<Tooltip
													className={"fmeQ-tooltip-mobile"}
													isPopupTip
													position={"bottom"}
													popupTipHeader=""
													uniqueIdentifier={`fmeQ tooltip`}
													popupTipBody={"This represents completion of the three Electric Vehicle Competency elements - Charging Infrastructure, Tools & Equipment and Electric Vehicle Training."}
												>
													<img className={"dash-info-icon"} src="/assets/icons/meDDicon/info-48-dk-gray.svg" />
												</Tooltip>
											</p>
										</div>

										<div>
											<Tag tag={"div"} className={`fds-flex fds-flex__items--center fds-flex-direction--column `}>
												<p className={`battery-percentage-text-mobile fds-align--center letter-spacing-0 ${fullyQualified ? "hidden-qualified" : ""}`}>
													{fmeQPercentage}
													<sup>%</sup>{" "}
												</p>
												<p className={`battery-percentage-text-mobile fds-align--center letter-spacing-0 ${fullyQualified ? "hidden-qualified" : ""}`}>
													<span className={"fds-wrap--nowrap fds-weight--medium "}>Competency Met </span>
												</p>
											</Tag>
										</div>
									</div>
								</>
							</Tag>
						</Tag>
					</Tag>
				</Col>
			)}
		</Fragment>
	);
};

export default BatterySection;
