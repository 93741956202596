import { Middleware, MiddlewareAPI } from 'redux';
import { setUserType } from '../reducers/user.slice';
import { setCountryCode, setPreferredLanguage } from '../reducers/locale-preferences.slice';
import GTMService from '../services/gtm';

/**
 * Middleware for integrating Redux actions with Google Tag Manager's data layer.
 * It listens for specific actions and updates the data layer accordingly.
 * 
 * @param {MiddlewareAPI} store - The Redux store API.
 * @returns A middleware function that processes actions.
 */
const gtmMiddleware: Middleware = (store: MiddlewareAPI) => next => action => {
  let updates: DataLayerObject = {};

  // Update data layer based on action type
  if (action.type === setUserType.type) {
    updates['user_type'] = action.payload;
  } else if (action.type === setCountryCode.type) {
    updates['country_code'] = action.payload;
  } else if (action.type === setPreferredLanguage.type) {
    updates['preferred_language'] = action.payload;
  }

  // Push updates to data layer if any
  if (Object.keys(updates).length > 0) {
    GTMService.pushToOrUpdateDataLayer(updates);
  }

  return next(action);
};

export default gtmMiddleware;
