import React from 'react';
import Select, { components, StylesConfig, DropdownIndicatorProps } from 'react-select';

interface OptionType {
    label: string;
    value: string;
}

interface DynamicSuggestionInputProps {
    id: string;
    name: string;
    options: OptionType[];
    isDisabled?: boolean;
    placeholder: string;
    value: OptionType | 'All';
    onChange: (option: OptionType | null) => void;
}

const autoCompleteStyles: StylesConfig<OptionType, false> = {
    control: (base, state) => ({
        ...base,
        width: '100%',
        border: '0.1rem solid #6e6e6e !important',
        borderRadius: '0.3rem',
        color: '#00095b',
        height: '4.8rem',
        paddingLeft: 0,
        paddingTop: 0,
        outlineOffset: '0.8rem',

        '&[aria-disabled="true"]': {
            backgroundColor: '#6e6e6e',
            backgroundImage: 'none',
            color: '#fff',
        },
        outline: state.isFocused ? '1px solid #00095b' : 'none',
        borderColor: state.isFocused ? '#004085' : base.borderColor, // Change border color when focused
        boxShadow: state.isFocused ? '0 1.1rem 1.1rem 0 rgba(0, 0, 0, 0.1), 0 2.1rem 2.1rem 0 rgba(0, 0, 0, 0.1), 0 3.2rem 3.2rem 0 rgba(0, 0, 0, 0.15);' : 'none',
    }),
    singleValue: (base, state) => ({
        ...base,
        color: '#00095b !important',
        fontFamily: '"FordAntenna", Arial, Helvetica, sans-serif !important',
    }),
    container: (base, props) => ({
        ...base,
        width: '100%',
        zIndex: '99',
        padding: 0,
    }),
    menu: (base) => ({
        // zIndex: '100',

        boxShadow: '0px 7px 10px 0px rgba(0, 0, 0, 0.4)',
    }),
    option: (base, state) => ({
        ...base,
        color: '#00095b',
        zIndex: '95',
        backgroundColor: state.isFocused ? '#f4f4f4' : '#fff',
        fontFamily: '"FordAntenna", Arial, Helvetica, sans-serif',
        fontSize: '1.6rem',
        padding: '1.6rem 2.4rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f4f4f4',
        },
    })
};

const CustomDropdownIndicator = (props: DropdownIndicatorProps<OptionType, false>) => (
    <components.DropdownIndicator {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M4.13585149,11.157374 C3.9466235,11.3584893 3.9562601,11.6749251 4.15737544,11.8641531 C4.35849078,12.0533811 4.67492657,12.0437445 4.86415456,11.8426292 L11.8641546,4.40288753 C12.0442273,4.21150253 12.0454221,3.91338437 11.8668891,3.72056218 L4.86688915,-3.83969617 C4.67927925,-4.04232178 4.36293087,-4.05449442 4.16030526,-3.86688453 C3.95767965,-3.67927463 3.94550701,-3.36292625 4.1331169,-3.16030064 L10.8160547,4.05751884 L4.13585149,11.157374 Z" fill="#102B4E" fillRule="nonzero" transform="translate(8.000002, 4.000001) scale(1, -1) rotate(-90.000000) translate(0, 0) "></path>
        </svg>
    </components.DropdownIndicator>
);


/**
 * DynamicSuggestionInput is a customizable select input component built on top of react-select.
 * It allows for dynamic option selection with enhanced accessibility and custom styling.
 * 
 * @param {string} id - The unique identifier for the select input, used for form labels.
 * @param {string} name - The name attribute for the select input, determining the field's key in form submissions.
 * @param {OptionType[]} options - An array of options that the user can select from. Each option is an object with a label and a value.
 * @param {boolean} [isDisabled=false] - Optional. Determines if the select input should be disabled.
 * @param {string} placeholder - A short hint that describes the expected value of the select input.
 * @param {OptionType | 'Api'} value - The currently selected option. Null if no option is selected.
 * @param {(option: OptionType | null) => void} onChange - The callback function that is called when the selected option changes.
 * 
 * @example
 * <DynamicSuggestionInput
 *   id="status-select"
 *   name="status"
 *   options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
 *   placeholder="Select Status"
 *   value={selectedOption}
 *   onChange={handleSelectChange}
 * />
 */
export const DynamicSuggestionInput: React.FC<DynamicSuggestionInputProps> = ({
    id,
    name,
    options,
    isDisabled = false,
    placeholder,
    value,
    onChange,
}) => {
    // Convert 'All' string to OptionType object if necessary
    const adjustedValue = value === 'All' ? { label: 'All', value: 'All' } : value;

    return (
        <Select
            inputId={id} // Ensure the input can be focused by clicking its label
            name={name}
            styles={autoCompleteStyles}
            className="fds-xs:fds-flex__col-12"
            isClearable={true}
            closeMenuOnSelect={true}
            placeholder={placeholder}
            options={options}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            onChange={(selected) => onChange(selected ? { label: selected.label, value: selected.value } : null)}
            value={adjustedValue} // Use the adjusted value here
            isDisabled={isDisabled}
        />
    );
};
