import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV === 'development',
		lng: 'en-US', // Set default language to en-US
		fallbackLng: 'en-US', // Use en-US as the fallback language
		backend: {
			loadPath: '/i18n/locales/{{lng}}/{{ns}}.json',
		},
		ns: ['translations'],
		defaultNS: 'translations',
		react: {
			useSuspense: true,
		},
		partialBundledLanguages: true, // Enable partial loading of languages
	});

export default i18n;
