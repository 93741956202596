import React, { Fragment, useContext } from 'react'
import EmployeeListTableRow from './EmployeeListTableRow'
import EmployeeListMobile from './EmployeeListMobile'
import {RootState} from '../../../store'
import { useSelector } from 'react-redux'
import { DealerEmailPreferencePojo } from 'services/email-notification-preferences.types'
import { useTranslation } from 'react-i18next';


interface EnrolledEmployeesListProps {
	employees: DealerEmailPreferencePojo[]
}

const EnrolledEmployeesList = (props: EnrolledEmployeesListProps) => {

    const { t } = useTranslation();

    const windowWidth = useSelector(
		(state: RootState) => state.windowSize.width
	);

    let employees = props.employees || [];

	return (
        <Fragment>
            {(windowWidth as number) >= 1024 ? (
                <>
                    <table className={'employeePref-table'}>
                        <thead>
                            <tr className={'employeePref-table-head-row'}>
                                <th>{t('pages.enrolledEmployees.tableNotif')}</th>
                                <th>{t('pages.enrolledEmployees.tableWSLID')}</th>
                                <th>{t('pages.enrolledEmployees.tableName')}</th>
                                <th>{t('pages.enrolledEmployees.tableEmail')}</th>
                                <th>{t('pages.enrolledEmployees.tablePhone')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee, index)=>{
                                return <EmployeeListTableRow employee={employee} index={`${index}`} key={Math.floor(Math.random()) + index}/>
                            })}
                        </tbody>
                    </table>
                </>
            ) : (
                <EmployeeListMobile employees={employees}/>
            )}
        </Fragment>
    );
}

export default EnrolledEmployeesList