import { CriteriaList, QualifierStatusType } from "services/dashboard.types";
import Tag from "../Tag/Tag";
import { getLinkExpiryText } from "components/utils/DashUtils";
import DashLabelInfoRenderer from "./DashLabelInfoRenderer";
import { useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";

interface MeQTableProps {
	delaerCriterias?: CriteriaList[];
	mobile?: boolean;
	countryCode: string;
	sectionName: string;
	sectionId: string | number;
	chargingInfra?: boolean;
}

const completeIcon = "/assets/icons/meDDicon/icon-complete.svg";
const incompleteIcon = "/assets/icons/meDDicon/icon-not-complete.svg";
const DCSIcon = "/assets/icons/meDDicon/icon-deferred.svg";
const waiverIcon = "/assets/icons/meDDicon/icon-on-waiver.svg";
const loaIcon = "/assets/icons/meDDicon/icon-LOA.svg";
const infoIcon = "/assets/icons/meDDicon/info-48-dk-gray.svg";

const getStatusObj = (status: QualifierStatusType, countryCode: string) => {
	switch (status) {
		case "Complete":
			return {
				icon: completeIcon,
				color: "green",
			};
		case "InComplete":
			return {
				icon: incompleteIcon,
				color: "orange",
			};
		case "Deferred":
			return {
				icon: DCSIcon,
				color: "gray",
			};
		case "LOA":
			return {
				icon: loaIcon,
				color: "gray",
			};
		case "OnWaiver":
			return {
				icon: countryCode === "CAN" ? loaIcon : waiverIcon,
				color: "gray",
			};
		default:
			return {
				icon: "",
				color: "",
			};
	}
};

const rBallUni = "\u00AE";

const rballReplacedHTML = (str: string) => {
	return str?.split(rBallUni)?.map((s, idx) => {
		return (
			<Fragment key={idx}>
				{s}
				<sup key={"rball"}>®</sup>
			</Fragment>
		);
	});
};

const MeQTable = (props: MeQTableProps) => {
	const { delaerCriterias, mobile = false, countryCode, sectionName, sectionId, chargingInfra = false } = props;
	const { t } = useTranslation();
	// const m = delaerCriterias?.map(dc => ({ ...dc, complete: 'Y' }));

	const standardColummns = [t("pages.dashboard.criteriaTableHeaders.status"), t("pages.dashboard.criteriaTableHeaders.criteria")];

	const includeComplete = useMemo(() => {
		return sectionName !== "Training" && sectionName !== "Tools & Equipment" && !!delaerCriterias?.find((dc) => !!dc?.complete?.toString());
	}, [sectionName, delaerCriterias]);

	const columns: any = useMemo(() => {
		if (sectionName === "Training" || sectionName === "Tools & Equipment") {
			return [...standardColummns, "Electric Vehicle Competency", t("pages.dashboard.criteriaTableHeaders.actual")];
		}

		if (sectionId?.toString() === "4") {
			return [t("pages.dashboard.criteriaTableHeaders.criteria"), t("pages.dashboard.criteriaTableHeaders.actual")];
		}

		if (includeComplete) {
			return [...standardColummns, t("pages.dashboard.criteriaTableHeaders.complete")];
		}

		return [...standardColummns, "Electric Vehicle Competency", t("pages.dashboard.criteriaTableHeaders.actual")];
	}, [includeComplete, standardColummns, delaerCriterias, sectionName, sectionId]);

	const standardTitle = "Electric Vehicle Competency";

	return (
		<>
			{mobile ? (
				<>
					{delaerCriterias?.map((criteria, idx) => {
						const { name: itemName } = criteria || {};
						const { color, icon } = getStatusObj(criteria?.status, countryCode);
						const complete = criteria?.complete?.trim();
						const mColor = sectionId?.toString() === "4" ? "blue" : color;
						return (
							<div className={"fds-m--x-2"}>
								<div className={"fds-p--b-1 fds-flex fds-flex-direction--column "}>
									<div className={"fds-flex fds-flex-direction--row fds-flex__items--start fds-flex--start fds-align--left"}>
										<img className={"table-status-icon fds-p--r-1"} src={icon} />
										<p className={`dash-table-text-mobile dash-panel-font-${mColor}`}>
											{itemName?.includes(rBallUni) ? rballReplacedHTML(itemName) : itemName}
											<DashLabelInfoRenderer mobile={mobile} id={criteria?.name} labelInfo={criteria?.labelInfo} isLast={delaerCriterias?.length - 1 === idx} isAlone={delaerCriterias?.length === 1} />
										</p>
									</div>
									<div className={"fds-m--x-3"}>
										{criteria.expiryDate ? <p className={"dash-table-text-ex-test dash-panel-font-gray"}>- {getLinkExpiryText(criteria?.status, criteria?.expiryDate, t)}</p> : <span className={"dash-table-spacer"}></span>}
										{sectionId?.toString() === "4" && (
											<ul className={"fds-p--l-1"}>
												<>
													{criteria?.noRequired && (
														<li className={"dash-panel-font-gray"}>
															{criteria?.noRequired} {standardTitle}
														</li>
													)}
													{criteria?.noAvailable && (
														<li className={"dash-panel-font-gray"}>
															{criteria?.noAvailable} {t("pages.dashboard.criteriaTableHeaders.actual")}
														</li>
													)}
												</>
											</ul>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</>
			) : (
				<>
					<Tag tag={"div"} className={"dash-table-container fds-m--t-1"}>
						<table className={`dash-table ${sectionId?.toString() === "4" ? "charging-infra-table" : ""}`}>
							<thead>
								<tr className={"dash-table-head-row"}>
									{columns?.map((colName: string) => (
										<th style={colName === "Electric Vehicle Competency" ? { textAlign: "center" } : {}} key={colName}>
											{colName}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								<tr style={{ height: "10px" }}></tr>
								{delaerCriterias?.map((criteria, idx) => {
									const { name: itemName } = criteria || {};
									const { color, icon } = getStatusObj(criteria?.status, countryCode);
									const mColor = sectionId?.toString() === "4" ? "blue" : color;
									return (
										<tr key={Math.floor(Math.random() + idx)}>
											{sectionId?.toString() !== "4" && (
												<td>
													<img className={"table-status-icon"} src={icon} />
												</td>
											)}
											<td>
												<p className={`dash-table-text dash-panel-font-${mColor}`}>
													{itemName?.includes(rBallUni) ? rballReplacedHTML(itemName) : itemName}
													<DashLabelInfoRenderer mobile={chargingInfra || false} id={criteria?.name} labelInfo={criteria?.labelInfo} isLast={delaerCriterias?.length - 1 === idx} isAlone={delaerCriterias?.length === 1} />
												</p>
												{criteria.expiryDate ? <p className={"dash-table-text-ex-test dash-panel-font-gray"}>- {getLinkExpiryText(criteria?.status, criteria?.expiryDate, t)}</p> : <span className={"dash-table-spacer"}></span>}
											</td>
											<>
												{sectionId?.toString() !== "4" && <td>{criteria?.noRequired}</td>}
												<td>{criteria?.noAvailable}</td>
											</>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Tag>
				</>
			)}
		</>
	);
};

export default MeQTable;
