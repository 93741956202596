import React, { Fragment } from 'react';
import ResourceCard from './ResourceCard';
import ResourceVideoCard from './ResourceVideoCard';

interface resourceCards {
	title: string;
	copy: string;
	video: boolean;
	externalLink: boolean;
	href: string;
	imgSrc?: string;
	resourceLinkType: string;
}

interface ResourceContainerProps {
	cards: resourceCards[];
}

const ResourceContainer = (props: ResourceContainerProps) => {
	const { cards } = props;

	return (
		<div
			className={
				'fmc-cards fds-layout-grid__inner fds-xs:fds-p--x-0 fds-sm:fds-p--x-0 fds-md:fds-p--x-1 fds-p--y-2'
			}
		>
			{cards.map((card, index) => {
				return (
					<Fragment key={`${index} + ${Math.random()}`}>
						{card.video ? (
							<ResourceVideoCard
								title={card.title}
								text={card.copy}
								video={card.video}
								videoLink={card.href}
								imgSrc={card.imgSrc}
							/>
						) : (
							<ResourceCard
								title={card.title}
								text={card.copy}
								externalLink={card.externalLink}
								buttonLink={card.href}
								resourceLinkType={card.resourceLinkType}
							/>
						)}
					</Fragment>
				);
			})}
		</div>
	);
};

export default ResourceContainer;
