import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DealerSearchState {
  isDealerSearchOpen: boolean;
  searchValue: string;
}

const initialState: DealerSearchState = {
  isDealerSearchOpen: false,
  searchValue: '',
};

const dealerSearchSlice = createSlice({
  name: 'dealerSearch',
  initialState,
  reducers: {
    setIsDealerSearchOpen: (state, action: PayloadAction<boolean>) => {
      state.isDealerSearchOpen = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    resetDealerSearch: (state) => {
      state.isDealerSearchOpen = false;
      state.searchValue = '';
    },
  },
});

export const { setIsDealerSearchOpen, setSearchValue, resetDealerSearch } = dealerSearchSlice.actions;

export default dealerSearchSlice.reducer;