export interface UpdateDocumentResponse {
	paCode: string;
	salesCode: string;
	docType: string;
	file: string;
	countryCode: string;
	preferredLanguage: string;
	complianceGroupId: number[];
	documentName: string;
	documentDescription: string;
	wslId: string;
	documentId: number[];
}
export interface replaceDocumentResponse {
	paCode: string;
	salesCode: string;
	docType: string;
	file: string;
	countryCode: string;
	preferredLanguage: string;
	complianceGroupId: any[];
	documentName: string;
	documentDescription: string;
	wslId: string;
}

export type DeleteAssociationData = {
	isError: boolean;
	message: string;
	data: DeleteAssociation;
};
export type DeleteAssociation = {
	docType: string;
	paCode: string;
	salesCode: string;
	countryCode: string;
	preferredLanguage: string;
};
export enum DeleteAssociationTypes {
	DELETE_ASSOCIATION_ACTION_TYPE = 'delete/deleteData',
}
