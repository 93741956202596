import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const NotificationBubble = ({ type = "notification" }) => {
	const notificationCount = useSelector((state: RootState) => state.notifications.count);
	const className = type === "notification" ? "notificationBadge" : "preferencesBadge";
	return (
		<div style={{ display: "inline" }}>
			{notificationCount > 0 && (
				<>
					<span className={className}>{notificationCount}</span>
				</>
			)}
		</div>
	);
};

export default NotificationBubble;
