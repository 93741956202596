import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedLayout from "layouts/ProtectedLayout";
import ModelEAdminDashboard from "pages/ModelEAdminDashboard";
import Resources from "pages/Resources";
import { useAuth } from "providers/AuthProvider";
import EnrolledEmployees from "pages/EnrolledEmployees";
import EmailNotificationPreferences from "pages/EmailNotificationPreferences";
import Contact from "pages/Contact";
import ModelEDashboard from "pages/ModelEDashboard";
import AdminLOAWaiver from "pages/AdminLOAWaiver";
import EditLOAWaiver from "pages/EditLOAWaiver";
import LOAWaiverDeleteConf from "pages/LOAWaiverDeleteConf";
import { MasterStatusReport } from "pages/reports/MasterStatusReport";
import Reports from "pages/Reports";
import { PhqReport } from "pages/reports/PhqReport";
import { MecpReport } from "pages/reports/MecpReport";
import { UserPreferencesReport } from "pages/reports/UserPreferencesReport";
import ErrorPage from "pages/Error";
import ErrorTest from "pages/ErrorTest";
import NoAccess from "pages/NoAccess";
import { ErrorStates } from "types/types";
import Sunset from "pages/Sunset";
import Attestation from "pages/attestation";
import ChargingEquipmentReport from "pages/reports/ChargingEquipmentReport";
import NationalRatingsReport from "pages/reports/NationalRatingsReport";

/**
 * A functional component to route employee-related views.
 * @function EmployeeRouter
 * @param {boolean} props.showComingSoon - A flag to indicate if the coming soon logic should be shown
 * @returns {JSX.Element} Rendered EmployeeRouter component
 */
const EmployeeRouter = (): JSX.Element => {
	const { tokenProps } = useAuth();
	const { authDetails } = tokenProps || {};
	const { countryCode } = authDetails || {};

	const hasFullDealerAccessCAN = tokenProps?.authDetails?.entitlementRole?.includes("DEALER-FULL-ACCESS-CAN");

	if (countryCode === "CAN" && !hasFullDealerAccessCAN) {
		return <Navigate to="no-access" replace />;
	}

	return (
		<Routes>
			<Route path="/" element={<ProtectedLayout />}>
				<Route index element={<ModelEAdminDashboard />} />
				<Route path="dashboard/:paCode" element={<ModelEDashboard />} />
				<Route path="enrolled-employees/:paCode?" element={<EnrolledEmployees />} />
				<Route path="contact" element={<Contact />} />
				<Route path="resources" element={<Resources />} />
				<Route path="loaWaiver" element={<AdminLOAWaiver />} />
				<Route path="editloawaivers" element={<EditLOAWaiver />} />
				<Route path="loawaiverdelete" element={<LOAWaiverDeleteConf />} />
				<Route path="error" element={<ErrorPage />} />

				<Route path=":paCode/attestations/:attestationType?" element={<Attestation mode="view" />} />
				<Route path=":paCode/add-attestation/:attestationType?" element={<Attestation mode="add" />} />
				<Route path=":paCode/edit-attestation/:attestationType?" element={<Attestation mode="edit" />} />

				{/* Conditional route for email preferences */}
				{countryCode === "USA" || tokenProps?.authDetails?.entitlementRole?.includes("FORD-INT-ACCESS-CAN") ? <Route path="email-preferences" element={<EmailNotificationPreferences />} /> : null}
				{countryCode === "USA" || tokenProps?.authDetails?.entitlementRole?.includes("FORD-INT-ACCESS-CAN") ? <Route path="email-preferences" element={<EmailNotificationPreferences />} /> : null}

				<Route path="reports" element={<Reports />} />
				<Route path="reports/mecp" element={<MecpReport />} />
				<Route path="reports/masterstatus" element={<MasterStatusReport />} />
				<Route path="reports/charging-equipment" element={<ChargingEquipmentReport />} />
				<Route path="reports/phq" element={<PhqReport />} />
				<Route path="reports/userPreferences" element={<UserPreferencesReport />} />
				<Route path="reports/national-rankings" element={<NationalRatingsReport />} />

				<Route path="error-test" element={<ErrorTest />} />

				{/* Catch-all route */}
				<Route path="*" element={<Navigate replace to="/error" state={{ errorState: ErrorStates.NoAccess }} />} />
			</Route>

			<Route path="no-access" element={<NoAccess />} />
			<Route path="sunset" element={<Sunset />} />
		</Routes>
	);
};

export default EmployeeRouter;
