import React, { FunctionComponent } from 'react';
import { FormikProps } from 'formik';

/**
 * Props for the FormikSelectField component.
 * 
 * @template T - The type of the values in the Formik form state.
 * @param {keyof T} name - The name of the select field, corresponding to a key in the Formik form state `T`.
 * @param {string} label - The label text to be displayed with the select field.
 * @param {Array<{ label: string; value: string }>} options - The selectable options for the select field.
 * @param {FormikProps<T>} formik - The Formik hook props providing access to form state and functions.
 * @param {(event: React.ChangeEvent<HTMLSelectElement>) => void} [onChange] - An optional custom onChange handler to be invoked alongside the Formik field's default onChange.
 * @param {boolean} [disabled=false] - An optional boolean to disable the select field.
 * @returns {React.ReactElement} - The rendered select field component.
 */
interface FormikSelectFieldProps<T> {
    name: keyof T;
    label: string;
    options: Array<{ label: string; value: string }>;
    formik: FormikProps<T>;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    disabled?: boolean;
}

/**
 * A Formik-aware select field component that integrates seamlessly with Formik for form state management.
 * It displays a dropdown list of options and handles input changes and validations based on Formik's state.
 * 
 * @template T - The type of the values in the Formik form state.
 * @param {FormikSelectFieldProps<T>} props - The properties for configuring the FormikSelectField component.
 * @returns {React.ReactElement} - The fully functional and styled select field component within a Formik form.
 */
export const FormikSelectField: FunctionComponent<FormikSelectFieldProps<any>> = ({
    name,
    label,
    options,
    formik,
    onChange,
    disabled = false,
}) => {
    // Extract touched and error status of the field from Formik's state.
    const { touched, error } = formik.getFieldMeta(name as string);
    const fieldProps = formik.getFieldProps(name as string);

    /**
     * Handles changes to the select field, invoking Formik's built-in handleChange 
     * and any additional custom onChange handler provided via props.
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event when the select field value changes.
     */
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        // Formik's built-in handleChange to update the form state.
        fieldProps.onChange(event);
        // Custom onChange handler if provided.
        if (onChange) onChange(event);
    };

    // Determine additional CSS classes for the select wrapper based on validation errors.
    const wrapperClass = `fds-flex__row fmc-input--wrapper ${touched && error ? 'fmc-input--error' : ''}`;

    return (
        <>
            <div className={wrapperClass}>
                <label htmlFor={name as string} className="fds-p--b-1 fds-p--l-0 fmc-type--content2 fds-color__text--gray2 fds-xs:fds-flex__col-12">
                    {label}
                </label>
                <select
                    {...fieldProps}
                    id={name as string}
                    className={`fmc-select ${touched && error ? 'fmc-select--error' : ''}`}
                    onChange={handleChange}
                    disabled={disabled} // Apply the disabled state based on props
                    aria-invalid={touched && error ? true : undefined}
                    aria-describedby={`${name as string}-error`}
                >
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            {touched && error && (
                <small style={{ color: 'var(--fds-color--error1)', fontSize: '0.875em' }} id={`${name as string}-error`}>
                    {error}
                </small>
            )}
        </>
    );
};
