import React, { Fragment, useContext } from "react";
import { Cell, Col, Container, Grid, Inner, Row, Tag } from "../components/components";
import { RootState } from "store";
import { useSelector } from "react-redux";

const Sunset = () => {
	const contentClasses = `coming-soon-text `;
	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	return (
		<div className="">
			{(windowWidth as number) >= 600 ? (
				<Fragment>
					<Grid tag={"nav"} className={" fds-p--y-1 fds-color__bg--white"}>
						<Inner>
							<Cell size={12}>
								<Container className=" fds-p--x-0 ">
									<Row justify={"start"}>
										<Col
											className={"w-100"}
											xs={12}
											lg={12}
											xl={12}
											padding={[
												{ dir: "y", amt: 2 },
												{ dir: "x", amt: 0 },
											]}
										>
											<Row className={"w-100"}>
												<Col
													className="fds-lg:fds-p--r-4 fds-sm:fds-p--r-2"
													xs={5}
													sm={5}
													md={3}
													lg={3}
													xl={3}
													padding={[
														{ dir: "l", amt: 0 },
														{ dir: "r", amt: 0 },
													]}
												>
													<Tag tag={"div"} aria-label={"Ford Model e™"}>
														<Tag tag={"img"} className={"fds-img navImg"} src={"/assets/images/Ford_Model_e_Blue_v2.svg"} alt={"Logo"} />
													</Tag>
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
							</Cell>
						</Inner>
					</Grid>

					<Tag tag={"div"} className={"wholeScreen"}>
						<Tag tag={"div"} className={"coming-soon-overlay"}>
							<Grid tag={"div"} className={"fds-m--x-0 fds-p--y-1 "}>
								<Inner>
									<Cell size={12}>
										<Container className=" fds-p--x-0 ">
											<Row justify={"start"}>
												<Col
													className={"w-100"}
													xs={12}
													lg={12}
													xl={12}
													padding={[
														{ dir: "y", amt: 2 },
														{ dir: "x", amt: 0 },
													]}
												>
													<Row className={"w-100"}>
														<Col
															xs={12}
															sm={12}
															md={9}
															lg={9}
															xl={8}
															padding={[
																{ dir: "l", amt: 0 },
																{ dir: "r", amt: 0 },
															]}
														>
															<Tag tag={"p"} className={" fds-m--t-2 sunsetText"}>
																Per{" "}
																<a target="_blank" href={"https://www.fmcdealer.dealerconnection.com/content/dam/fmcdealer/documents/edc/2024/EFC15038.pdf"}>
																	eDC15308
																</a>
																, the team is busy transforming the original Ford Model e<sup>™</sup> Dealer Dashboard.
															</Tag>
															<Tag tag={"p"} className={" sunsetText fds-m--t-2"}>
																Please reference the{" "}
																<a target="_blank" href={"https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/ford-model-e.html"}>
																	FMCDealer Ford Model e landing page
																</a>{" "}
																for upcoming communications and continue to utilize the Ford Model e Program Headquarters team at (888) 850-1002 or{" "}
																<a target="_blank" href={"mailto:ElectricVehicles@fordprogramhq.com"}>
																	ElectricVehicles@fordprogramhq.com
																</a>
																.
															</Tag>
														</Col>
													</Row>
												</Col>
											</Row>
										</Container>
									</Cell>
								</Inner>
							</Grid>
						</Tag>
					</Tag>
				</Fragment>
			) : (
				<Fragment>
					<Grid tag={"nav"} className={"fds-m--b-1 fds-p--y-1 "}>
						<Inner>
							<Cell size={12}>
								<Container className=" fds-p--x-0 ">
									<Row justify={"start"}>
										<Col
											className={"w-100"}
											xs={12}
											lg={12}
											xl={12}
											padding={[
												{ dir: "y", amt: 2 },
												{ dir: "x", amt: 0 },
											]}
										>
											<Row className={"w-100"}>
												<Col
													className="fds-lg:fds-p--r-4 fds-sm:fds-p--r-2"
													xs={5}
													sm={3}
													md={3}
													lg={3}
													xl={3}
													padding={[
														{ dir: "l", amt: 0 },
														{ dir: "r", amt: 0 },
													]}
												>
													<Tag tag={"div"} aria-label={"Ford Model e™"}>
														<Tag tag={"img"} className={"fds-img navImg"} src={"/assets/images/Ford_Model_e_Blue_v2.svg"} alt={"Logo"} />
													</Tag>
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
							</Cell>
						</Inner>
					</Grid>
					<Tag tag={"div"} className={"mobile-soon-bg"}>
						<Tag tag={"p"} className={" fds-m--t-2 fds-m--x-2 sunsetText"}>
							Per{" "}
							<a target="_blank" href={"https://www.fmcdealer.dealerconnection.com/content/dam/fmcdealer/documents/edc/2024/EFC15038.pdf"}>
								eDC15308
							</a>
							, the team is busy transforming the original Ford Model e<sup>™</sup> Dealer Dashboard.
						</Tag>
						<Tag tag={"p"} className={"fds-m--x-2 sunsetText fds-m--y-2"}>
							Please reference the{" "}
							<a target="_blank" href={"https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/ford-model-e.html"}>
								FMCDealer Ford Model e landing page
							</a>{" "}
							for upcoming communications and continue to utilize the Ford Model e Program Headquarters team at (888) 850-1002 or{" "}
							<a target="_blank" href={"mailto:ElectricVehicles@fordprogramhq.com"}>
								ElectricVehicles@fordprogramhq.com
							</a>
							.
						</Tag>
						<Tag tag={"img"} className={"fds-m--t-3"} src={"/assets/images/hero-coming-soon/coming-soon_1194x734-mobile.jpg"} />
					</Tag>
				</Fragment>
			)}
		</div>
	);
};

export default Sunset;
