import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { NoAccessApiResponse } from "./no-access.types";
import { getDealerCountryCode, getDealerLangCode } from "providers/AuthProvider";
export const noAccessApi = createApi({
	reducerPath: "noAccessApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_CONTACT_API_HOST}/mail/noaccess`,
		prepareHeaders: (headers) => {
			headers.set("apiKey", `${process.env.REACT_APP_GATEWAY_API_KEY}`);
			headers.set("countryCode", getDealerCountryCode());
			headers.set("preferredLanguage", getDealerLangCode());
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getNoAccessData: builder.mutation<NoAccessApiResponse, void>({
			query: (body) => {
				return {
					url: `send`,
					method: "POST",
					body,
				};
			},
		}),
	}),
});

export const { useGetNoAccessDataMutation } = noAccessApi;
