import { useAuth } from "providers/AuthProvider";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setErrorStatus } from "reducers/service-error.slice";
import { ErrorStates } from "types/types";

/**
 * A custom React hook that handles user authorization and authentication status.
 * It checks the user's authorization status and updates the error status in the redux store.
 *
 * @example
 * How to use `useAuthorization` hook:
 * ```tsx
 * const { isAuthenticated } = useAuthorization();
 * ```
 *
 * This will manage the user's authentication and authorization automatically,
 * updating the global error status if the user is unauthorized.
 *
 * @returns {Object} - The `isAuthenticated` flag indicating if the user is authenticated.
 */
const useAuthorization = () => {
	const dispatch = useDispatch();

	const { isAuthenticated, loginWithRedirect, tokenProps, isDealer, isEmployee, isAdmin } = useAuth();

	const determineAuthErrorState = (): ErrorStates => {
		if (!tokenProps?.authDetails?.isAuthorized) {
			if (isAdmin) {
				return ErrorStates.AdminOnly;
			} else if (isEmployee || isDealer) {
				return ErrorStates.NoAccessPage;
			}
		}
		return ErrorStates.NoError;
	};

	const checkUpdateAuthorizedStatus = useCallback(() => {
		const errorState = determineAuthErrorState();
		if (errorState !== ErrorStates.NoError) {
			dispatch(setErrorStatus(errorState));
		}
	}, [dispatch, determineAuthErrorState]); // Ensure dependencies are correctly handled

	useEffect(() => {
		if (!isAuthenticated) {
			loginWithRedirect(); // Redirect to login if not authenticated
		} else {
			checkUpdateAuthorizedStatus(); // Once authenticated, check authorization
		}
	}, [isAuthenticated, loginWithRedirect, checkUpdateAuthorizedStatus]);

	return { isAuthenticated, isAdmin, isDealer, isEmployee };
};

export default useAuthorization;
