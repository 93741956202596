import { createApi } from "@reduxjs/toolkit/query/react";
import { getDealerCountryCode, getDealerLangCode } from "providers/AuthProvider";
import { DeleteAssociation, UpdateDocumentResponse, replaceDocumentResponse } from "./delete-association.types";
import { createBaseQuery } from "./overrides/createBaseQuery";
// delete api
export const DeleteAssociationApi = createApi({
	reducerPath: "DeleteAssociationApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_ADMIN_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
		},
	}),

	endpoints: (builder) => ({
		getDeleteAssociationData: builder.query<
			DeleteAssociation,
			{
				docType: string;
				paCode: string;
				salesCode: string;
				countryCode: string;
				preferredLanguage: string;
			}
		>({
			query: ({ docType, countryCode, preferredLanguage, paCode, salesCode }) => ({
				url: `admin/loaandwaiver/document`,
				method: "DELETE",
				params: {
					docType,
					countryCode,
					preferredLanguage,
					paCode,
					salesCode,
				},
			}),
		}),

		// put api
		replaceDocument: builder.mutation<
			replaceDocumentResponse,
			{
				paCode: string;
				salesCode: string;
				docType: string;
				file: string;
				countryCode: string;
				preferredLanguage: string;
				complianceGroupId: any[];
				documentName: string;
				documentDescription: string;
				wslId: string;
				documentId: string;
			}
		>({
			query: (data) => ({
				url: "admin/loaandwaiver/document",
				method: "PUT",
				body: data,
			}),
		}),
		// put api
		updateDocument: builder.mutation<
			UpdateDocumentResponse,
			{
				paCode: string;
				salesCode: string;
				docType: string;
				countryCode: string;
				preferredLanguage: string;
				complianceGroupId: number[];
				wslId: string;
				documentId: number;
			}
		>({
			query: (data) => ({
				url: "admin/loaandwaiver/document/association",
				method: "PUT",
				body: data,
			}),
		}),
	}),
});

export async function deleteAssociationFormData(data: any): Promise<any> {
	const apiUrl = `${process.env.REACT_APP_ADMIN_DASHBOARD_API_HOST}/admin/loaandwaiver/document?docType=${data.docType}&paCode=${data.paCode}&salesCode=${data.salesCode}&countryCode=${data.countryCode}&preferredLanguage=${data.preferredLanguage}`;

	let response: Response;

	try {
		response = await fetch(apiUrl, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				countryCode: getDealerCountryCode(),
				preferredLanguage: getDealerLangCode(),
				"apikey": `${process.env.REACT_APP_GATEWAY_API_KEY}`,
			},
			body: JSON.stringify(data),
		});
	} catch (error) {
		throw new Error(`Network error: ${error}`);
	}

	if (!response.ok) {
		const statusText = response.statusText || "Unknown error";
		throw new Error(`HTTP error: ${response.status} - ${statusText}`);
	}

	let result: any;
	try {
		result = await response.json();
	} catch (error) {
		throw new Error(`Error parsing JSON: ${error}`);
	}

	if (result?.isError === false && typeof result?.data === "boolean") {
		return result.data;
	} else {
		throw new Error(result?.message || "Unexpected server response");
	}
}

export const { useGetDeleteAssociationDataQuery, useReplaceDocumentMutation, useUpdateDocumentMutation } = DeleteAssociationApi;
