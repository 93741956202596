import React from "react";
import { Button, Col, Tag } from "../../../components/components";
import { Criteria, MecpSectionType, MecpSubSection } from "services/mecp-dashboard.types";
import DashLabelInfoRenderer from "components/components/Dashboard/DashLabelInfoRenderer";
import { getMecpStatusText, getMecpStatusIcon } from "components/utils/DashUtils";
import MecpCriteriaTable from "./MecpCriteriaTable";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import useDownload from "hooks/useDownload";

export interface MecpCriteriaTableProps {
	type: MecpSectionType;
	subSections?: MecpSubSection[];
	t: any;
	salesCode: string;
}

const RenderSubSection = ({ icon, subSection, statusText, color, type }: any) => (
	<>
		<Tag tag={"div"} className={"fds-flex fds-flex-direction--row fds-flex__items--baseline fds-p--x-1 fds-m--y-2"}>
			<img src={icon} className={"fds-p--r-1 row-status-icon"} />
			<p className={`fds-weight--bold dash-inner-panel-title letter-spacing-0`}>
				{subSection?.name}
				<DashLabelInfoRenderer id="1" isLast={false} labelInfo={subSection?.labelInfo} />
				{statusText && ": "}
			</p>
			{statusText && (
				<span className={`dash-panel-status dash-panel-font-${color}`}>
					<span className={`dash-panel-font-${color}`}>&nbsp;{statusText}</span>
				</span>
			)}
		</Tag>
		<MecpCriteriaTable criterias={subSection?.criterias} type={type} />
	</>
);

const MecpCriteriaSection = ({ type, subSections, salesCode, t }: MecpCriteriaTableProps) => {
	const { paCode: paCodeFromUrl } = useParams();
	const { countryCode, preferredLanguage } = useSelector((state: RootState) => state.localePreferences);
	const { download } = useDownload();

	return (
		<>
			{subSections?.map((subSection, idx) => {
				const { icon, color } = getMecpStatusIcon(subSection?.status);
				const statusText = getMecpStatusText(subSection?.status, t);

				const reportFile = (() => {
					if (subSection.name === "Purchase Experience")
						return {
							fileName: "Purchase_Experience_Report.xlsx",
							fileURL: `${process.env.REACT_APP_REPORTS_API_HOST}/reports/mecp/pur-exp/${paCodeFromUrl}/${salesCode}/${countryCode}`,
						};
					if (subSection.name === "Ownership Experience")
						return {
							fileName: "Ownership_Experience_Report.xlsx",
							fileURL: `${process.env.REACT_APP_REPORTS_API_HOST}/reports/mecp/own-exp/${paCodeFromUrl}/${salesCode}/${countryCode}`,
						};
					return null;
				})();
				return (
					<React.Fragment key={idx}>
						<Tag tag={"div"} className={"fds-flex fds-flex-direction--row fds-flex__items--baseline fds-p--x-1 fds-m--y-2"}>
							<img src={icon} className={"fds-p--r-1 row-status-icon"} />
							<p className={`fds-weight--bold dash-inner-panel-title letter-spacing-0`}>
								{subSection?.name}
								<DashLabelInfoRenderer id="1" isLast={false} labelInfo={subSection?.labelInfo} />
								{statusText && ": "}
							</p>
							{statusText && (
								<span className={`dash-panel-status dash-panel-font-${color}`}>
									<span className={`dash-panel-font-${color}`}>&nbsp;{statusText}</span>
								</span>
							)}
						</Tag>
						<MecpCriteriaTable criterias={subSection?.criterias} type={type} />
						{reportFile && (
							<div className="w-100 fds-flex fds-flex--center fds-m--y-2">
								<Button tag="a" onClick={(e: any) => download(e, reportFile.fileURL, reportFile.fileName)} className="fds-flex fds-flex--center" target="_blank" href={"#"} noShadow buttonClassName="dlalign" rightIcon={``}>
									Download Report <span className="fds-font--ford-icons-download om-fds-icon-top--16 fds-p--x-1 fds-p--t-2 fds-color--white buttonCustomTop "></span>
								</Button>
							</div>
						)}
					</React.Fragment>
				);
			})}
		</>
	);
};

export default MecpCriteriaSection;
