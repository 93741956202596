import React, { Fragment } from 'react';
import { Tag } from '../..';
import LOADeleteModal from './LOADeleteModal';

interface SelectEditTypeLOAProps {
	handleEdit: any;
	deleteModal: boolean;
	setDeleteModal: any;
	formData: any;
	editTypeError: string;
	setEditTypeError: any;
}

const SelectEditTypeLOA = (props: SelectEditTypeLOAProps) => {
	const {
		handleEdit,
		deleteModal,
		setDeleteModal,
		formData,
		editTypeError,
		setEditTypeError,
	} = props;

	const radioChange = (e: any) => {
		if (e.target.checked) {
			setEditTypeError('');
		}
		handleEdit(e);
	};

	return (
		<Fragment>
			<LOADeleteModal
				deleteModal={deleteModal}
				setDeleteModal={setDeleteModal}
				formData={formData}
			/>
			<Tag tag={'div'} className={'loa-single-step'}>
				<fieldset className={'fmc-check'} style={{ maxWidth: 'unset' }}>
					<legend className={'fds-color--primary fds-weight--bold'}>
						Please select the type of edit you would like to perform
					</legend>

					<label htmlFor={'editdocType-Delete'}>
						<input
							type={'radio'}
							id={'editdocType-Delete'}
							name={'DocType'}
							value={'Delete'}
							onChange={radioChange}
						/>
						<span className={'fds-color--primary'}>Delete</span>
					</label>
					<label htmlFor={'editdocType-Replace'}>
						<input
							type={'radio'}
							id={'editdocType-Replace'}
							name={'DocType'}
							value={'Replace'}
							onChange={radioChange}
						/>
						<span className={'fds-color--primary'}>Replace</span>
					</label>
					<label htmlFor={'editdocType-Change'}>
						<input
							type={'radio'}
							id={'editdocType-Change'}
							name={'DocType'}
							value={'Change'}
							onChange={radioChange}
						/>
						<span className={'fds-color--primary'}>
							Change Associations
						</span>
					</label>
				</fieldset>
				{editTypeError && (
					<small
						style={{
							color: 'var(--fds-color--error1)',
						}}
					>
						{editTypeError}
					</small>
				)}
			</Tag>
		</Fragment>
	);
};

export default SelectEditTypeLOA;
