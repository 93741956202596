import React from 'react';
import { Col } from '../..';

const LOACompletion = () => {
	return (
		<div className={'fds-m--t-2 fds-m--b-4'}>
			<Col>
				<p className={'fds-p--y-1 fds-weight--bold'}>
					Edits and/or associations to the uploaded document have been
					completed.
				</p>
				<p className={'fds-color__text--gray3'}>
					The documentation edits and/or associations are complete and
					associated to the selected Dealer. The changes are visible
					on their Dashboard. If changes need to be made you can go
					through the process to edit, delete or change associations.
				</p>
			</Col>
		</div>
	);
};

export default LOACompletion;
