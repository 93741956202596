import React from 'react';
import { FormikProps } from 'formik';

interface FormikToggleSwitchProps<T> {
  name: keyof T;
  formik: FormikProps<T>;
  offText: string;
  onText: string;
}

export const FormikToggleSwitch = <T extends Record<string, any>>({
  name,
  formik,
  offText,
  onText
}: FormikToggleSwitchProps<T>) => {
  const { setFieldValue, values } = formik;
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Ensure name is treated as a string
    setFieldValue(String(name), e.target.checked);
  };

  // Use String(name) to ensure name is treated as a string
  const isChecked = !!values[String(name)];

  return (
    <label className='fmc-toggle fmc-toggle--skeuomorphic'>
      <span className='fmc-toggle__unchecked-option' aria-hidden="true">
        {offText}
      </span>
      <input
        aria-label="toggle-switch"
        id={`${String(name)}-toggle`}
        name={String(name)}
        onChange={handleChange}
        checked={isChecked}
        type="checkbox"
      />
      <span className='fmc-toggle__track'></span>
      <span className='fmc-toggle__checked-option' aria-hidden="true">
        {onText}
      </span>
    </label>
  );
};