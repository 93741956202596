import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiResponse } from "types/types";
import { createBaseQuery } from "../overrides/createBaseQuery";
import { AttestationsApiData } from "./types";

export const attestationsApi = createApi({
	reducerPath: "attestationsApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_ADMIN_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
			includeWslId: true,
		},
	}),
	endpoints: (builder) => ({
		getAttestationDealerInfo: builder.query<ApiResponse<AttestationsApiData>, { countryCode: string; paCode: string }>({
			query: ({ paCode, countryCode }) => `/admin/programs/attestation/dealerInfo/country/${countryCode}?paCode=${paCode}`,
		}),
		getAttestations: builder.query<ApiResponse<AttestationsApiData>, { countryCode: string; paCode: string }>({
			query: ({ paCode, countryCode }) => `/admin/programs/attestation/int/ci/dealer/paCode/${paCode}/country/${countryCode}`,
		}),
		postChargingInfraAttestation: builder.mutation<ApiResponse<AttestationsApiData>, void>({
			query: (formData) => ({ url: "/admin/programs/attestation/ci/dealer", method: "POST", body: formData }),
		}),
		postToolsAndEquipmentAttestation: builder.mutation<ApiResponse<AttestationsApiData>, void>({
			query: (formData) => ({ url: "/admin/programs/attestation/te/dealer", method: "POST", body: formData }),
			async onQueryStarted(props, { dispatch, queryFulfilled }) {
				console.log(props);
				// attestationsApi.util.updateQueryData("getAttestations", { countryCode, paCode }, (draft) => {
				// 	console.log(draft);
				// });
			},
		}),
		putChargingInfraAttestation: builder.mutation<ApiResponse<AttestationsApiData>, void>({
			query: (formData) => ({ url: "/admin/programs/attestation/ci/dealer", method: "PUT", body: formData }),
		}),
		putToolsAndEquipmentAttestation: builder.mutation<ApiResponse<AttestationsApiData>, void>({
			query: (formData) => ({ url: "/admin/programs/attestation/te/dealer", method: "PUT", body: formData }),
		}),
	}),
});

export const { useGetAttestationsQuery, useGetAttestationDealerInfoQuery, usePostChargingInfraAttestationMutation, usePostToolsAndEquipmentAttestationMutation, usePutChargingInfraAttestationMutation, usePutToolsAndEquipmentAttestationMutation } = attestationsApi;
