import React, {
	FC,
	ReactNode,
	useRef,
	useEffect,
	Fragment,
	Children,
	cloneElement,
} from 'react';
import clsx from 'clsx';
import { FMCTabs } from '../../fds-system/src/components/_components';
import { Tag } from '../';

interface TabProp {
	label: string;
	id: string;
	aria: string;
	selected?: boolean | undefined;
}

interface TabsProps {
	tag?: string;
	className?: string;
	ariaLabel: string;
	tabs: TabProp[];
	children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const Tabs: FC<TabsProps> = (props) => {
	const {
		tag = 'div',
		tabs,
		ariaLabel,
		className,
		children,
		...attributes
	} = props;

	const tabsRef: any = useRef();
	const classes = ['om-fmc-tabs', 'fmc-tabs', 'js-fmc-tabs'];

	const ClassName = clsx(classes, className);

	useEffect(() => {
		new FMCTabs(tabsRef.current, 'FMCTabs');
	}, [tabs]);

	return (
		<Tag tag={tag} ref={tabsRef} className={ClassName} {...attributes}>
			<Tag className={'fmc-tabs__tablist-wrapper'}>
				<Tag>
					<Tag
						className={'fmc-tabs__tablist'}
						role={'tablist'}
						aria-label={ariaLabel}
					>
						{tabs &&
							tabs.map(({ label, id, aria, selected }, idx) => {
								return (
									<Fragment key={Math.random()}>
										<Tag
											tag={'button'}
											className={
												'om-tabs__tab fmc-tabs__tab fds-m--x-3'
											}
											role={'tab'}
											aria-selected={
												typeof selected === 'boolean'
													? selected
													: idx === 0
											}
											aria-controls={aria}
											id={id}
											tabIndex={'-1'}
										>
											{label}
										</Tag>
									</Fragment>
								);
							})}
						<Tag
							tag={'span'}
							className={
								'om-tabs__tab-border fmc-tabs__tab-border'
							}
						/>
					</Tag>
				</Tag>
				<Tag className={'om-tabs__tabpanels fmc-tabs__tabpanels'}>
					{children &&
						Children.map(children, (child: any, idx) => {
							return (
								<Fragment key={Math.random()}>
									<Tag
										id={tabs[idx].aria}
										className={
											'om-tabs__tabpanel fmc-tabs__tabpanel'
										}
										role={'tabpanel'}
										aria-labelledby={tabs[idx].id}
										tabIndex={'-1'}
										style={{ width: '100%' }}
									>
										{cloneElement(child)}
									</Tag>
								</Fragment>
							);
						})}
				</Tag>
			</Tag>
		</Tag>
	);
};

export default Tabs;
