// @ts-nocheck
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tag from '../Tag/Tag';
import { AdminDashboardApiResponse } from 'services/admin-dashboard.types';
import { Link } from 'react-router-dom';
import {
	setIsDealerSearchOpen,
	setSearchValue,
	resetDealerSearch,
} from 'reducers/dealer-search.slice';
import { useTranslation } from 'react-i18next';
interface DashSearchProps {
	searchTerm: string;
	routePrefix?: string;
	dealersArray?: AdminDashboardApiResponse;
	onClickDealer?: (dealer: any) => void;
}

const DashSearch = (props: DashSearchProps) => {
	const dispatch = useDispatch();
	const { searchTerm, dealersArray, onClickDealer } = props;

	const handleClick = (dealer: any) => {
		dispatch(resetDealerSearch());

		if (onClickDealer) {
			onClickDealer(dealer);
		}
	};

	// const filteredDealers = dealersArray?.filter((dealer) =>
	// 	Object.values(dealer).some((value) =>
	// 		String(value).toLowerCase().includes(searchTerm.toLowerCase())
	// 	)
	// );
	const filteredDealers = dealersArray?.filter((dealer) =>
		['dealerName', 'salesCode', 'paCode'].some((key) =>
			String(dealer[key]).toLowerCase().includes(searchTerm.toLowerCase())
		)
	);

	let routePrefix = props?.routePrefix || '/dashboard';
	const { preferredLanguage, countryCode } = useSelector(
		(state: RootState) => state.localePreferences
	);
	const { t } = useTranslation();
	const isUSActive: boolean = countryCode === 'USA';
	const isCANActive: boolean = countryCode === 'CAN';

	return (
		<Fragment>
			<Tag tag={'div'} className="results-list-wrap">
				<Tag tag={'div'} className={'dealer-dash-search-results'}>
					<Tag tag={'ul'} style={{ height: '600px' }}>
						{searchTerm ? (
							filteredDealers?.length > 0 ? (
								filteredDealers?.map((filtered, index) => (
									<Tag
										tag={'li'}
										key={index}
										className={'dealer-dash-single-result'}
									>
										<div className="result-wrapper">
											<Link
												onClick={() =>
													handleClick(filtered)
												}
												to={`${routePrefix}/${filtered.paCode}`}
												state={{
													dealerName:
														filtered.dealerName,
												}}
												className={
													'dealer-dash-single-result-link'
												}
											>
												<div className="dealer-details">
													<p className={'dealerName'}>
														{filtered.dealerName}
													</p>
													{isUSActive ? (
														<p
															className={
																'dealerInformation'
															}
														>
															{/* Sales Code:{' '} */}
															{t(
																'common.salesCode'
															)}
															:{''}
															{
																filtered.salesCode
															}{' '}
															{/* P&A Code:{' '} */}
															{t('common.paCode')}
															:{''}
															{filtered.paCode}
														</p>
													) : (
														<p
															className={
																'dealerInformation'
															}
														>
															{/* Sales Code:{' '} */}
															{t(
																'common.dealerCode'
															)}
															:{''}
															{
																filtered.salesCode
															}{' '}
														</p>
													)}
												</div>
											</Link>
										</div>
									</Tag>
								))
							) : (
								<Tag
									tag={'div'}
									className={
										'fds-flex--center fds-flex-direction--column fds-align--center fds-p--x-2'
									}
								>
									<Tag
										tag={'p'}
										className={
											'fds-weight--bold fds-p--y-2'
										}
									>
										{/* No results for "{searchTerm}" */}
										{t('common.errorLabel')}"{searchTerm}"
									</Tag>
									<Tag tag={'p'}>
										{/* Please try a different search method or
										contact Program HQ for additional
										assistance. */}
										{t('common.errorText')}
									</Tag>
									<Tag
										tag={'a'}
										target="_blank"
										// href="mailto:ModelePHQ@fordprogramhq.com"
										href={`mailto:${t(
											'common.contactemail'
										)}`}
										style={{ textDecoration: 'underline' }}
									>
										{/* ModelePHQ@fordprogramhq.com */}
										{t('common.contactemail')}
									</Tag>
								</Tag>
							)
						) : (
							dealersArray?.map((dealer, index) => (
								<Tag
									tag={'li'}
									key={index}
									className={'dealer-dash-single-result'}
								>
									<div className="result-wrapper">
										<Link
											onClick={() => handleClick(dealer)}
											to={`${routePrefix}/${dealer.paCode}`}
											state={{
												dealerName: dealer.dealerName,
											}}
											className={
												'dealer-dash-single-result-link'
											}
										>
											<div className="dealer-details">
												<p className={'dealerName'}>
													{dealer.dealerName}
												</p>
												{isUSActive ? (
													<p
														className={
															'dealerInformation'
														}
													>
														Sales Code:{' '}
														{dealer.salesCode} | P&A
														Code: {dealer.paCode}
													</p>
												) : (
													<p
														className={
															'dealerInformation'
														}
													>
														{/* Sales Code:{' '} */}
														{t('common.dealerCode')}
														:{''}
														{dealer.paCode}
													</p>
												)}
											</div>
										</Link>
									</div>
								</Tag>
							))
						)}
					</Tag>
				</Tag>
			</Tag>
		</Fragment>
	);
};

export default DashSearch;
