
/**
 * Service class for interacting with Google Tag Manager's data layer.
 * This class provides a static method to update the data layer in a SPA environment.
 */
class GTMService {

  /**
   * Pushes an object to the data layer. If keys already exist, their values are updated.
   * Otherwise, the object is added to the data layer.
   * 
   * @param {DataLayerObject} obj - The object containing data to be pushed to the data layer.
   */
  static pushToOrUpdateDataLayer(obj: DataLayerObject): void {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      Object.keys(obj).forEach((key) => {
        const existingIndex = window.dataLayer.findIndex(item => item.hasOwnProperty(key));
        if (existingIndex !== -1) {
          // Update existing key value
          window.dataLayer[existingIndex][key] = obj[key];
        } else {
          // Add new object if key doesn't exist
          window.dataLayer.push(obj);
        }
      });
    }
  }

  // Additional methods can be added here as needed
}

export default GTMService;