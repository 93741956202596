export interface MockErrorResponse {
	code: number; // Assuming this matches the expected response structure
}

export interface MockErrorQuery {
	code: number;
}

export interface ApiResponse<T> {
	isError: boolean;
	message: string;
	data: T;
}

export interface CardDetail {
	cardTitle: string;
	cardDate?: string;
	cardContent?: string;
	cardImage?: string;
}

export interface PanelContent {
	panelTitle: string;
	cards: CardDetail[];
}

export interface Contact {
	name: string;
	phoneNumber: string;
	href: string;
}

export interface Option {
	label: string;
	value: string;
}

export interface FilterProps {
	onFilterChange: any;
	options?: Option[];
	name?: string;
	id?: string;
	// selectedFilters: Array<string>;
	// closeFilters: () => void;
}

export interface SelectOption {
	value: string;
	label: string;
}

export enum MMedUserTypes {
	Admin = "Admin",
	Dealer = "Dealer",
	Employee = "Employee",
}

export enum ErrorStates {
	Maintenance = "maintenance",
	AdminOnly = "adminOnly",
	NoAccess = "noAccess",
	NoAccessPage = "noAccessPage",
	NoError = "noError",
	ReportsPostError = "reportsPostError",
	UserPreferencesPostError = "userPreferencesPostError",
	LoaWaiverPostError = "loaWaiverPostError",
	ContactPostError = "contactPostError",
	ResourcesGetError = "resourcesGetError",
}

export type ServiceErrorState = {
	status: ErrorStates | string; // You can use the enum here if appropriate
	messageKey?: string; // Optional message key for localization
};
