import React, { useEffect, useMemo } from "react";
import ViewAttestation from "./ViewAttestation";
import { attestationsApi, useGetAttestationDealerInfoQuery, useGetAttestationsQuery } from "services/attestations";
import ManageAttestation from "./ManageAttestation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetDealerDashboardDataQuery } from "services/dashboard";
import { useDispatch } from "react-redux";
import { setPacode } from "reducers/pacode.slice";
import { useSelector } from "react-redux";
import { RootState } from "store";

const Attestation = ({ mode = "view" }: any) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { paCode } = useParams();

	const { data: { data } = {}, isLoading, isSuccess, refetch } = useGetAttestationsQuery({ countryCode: "USA", paCode: paCode || "" });
	const { attestationCI = null, attestationTE = null } = data || {};

	const { data: { data: dealerInfo } = {}, refetch: refetchDealer } = useGetAttestationDealerInfoQuery({ countryCode: "USA", paCode: paCode || "" });

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setPacode({ pacode: paCode }));
	}, [paCode]);

	useEffect(() => {
		refetch();
		refetchDealer();
	}, [location, refetch]);

	const updateAttestationsCache = (_paCode: string, ciData: any = null, teData: any = null) => {
		dispatch(
			// @ts-ignore
			attestationsApi.util.updateQueryData("getAttestations", { countryCode: "USA", paCode: _paCode ?? "" }, (draft) => {
				const draftData = draft.data || {};
				if (!!ciData && !!teData) {
					// Object.assign(draft.data, { ...(draft.data || {}), attestationTE: { ...(draft?.data?.attestationTE || {}), ...teData }, attestationCI: { ...(draft?.data?.attestationCI || {}), ...ciData } });
				} else if (!!ciData) {
					Object.assign(draft, { ...draft, data: { ...draftData, attestationCI: ciData } });
				} else {
					Object.assign(draft, { ...draft, data: { ...draftData, attestationTE: teData } });
				}
			})
		);
	};

	// 0 - No Data Present, 1 - Some Data Present, 2 - Both Data Present
	const attestMode = useMemo(() => {
		let mode = 0;
		if (!attestationCI && !attestationTE) return 0;
		let chPresent = false;
		let tePresent = false;

		if (attestationCI) {
			chPresent = !!Object.values(attestationCI)?.filter((d) => d !== null);
		}

		if (attestationTE) {
			tePresent = !!Object.values(attestationTE)?.filter((d) => d !== null);
		}

		if (chPresent && tePresent) mode = 2;
		if (chPresent || tePresent) mode = 1;
		return mode;
	}, [attestationCI, attestationTE]);

	// console.log("attestMode", isLoading, attestMode, mode, paCode);

	if (isLoading) return <p>Loading...</p>;

	return (
		<>
			{mode === "view" && <ViewAttestation paCode={paCode} chargingInfraData={attestationCI} toolsEquipmentData={attestationTE} dealerInfo={dealerInfo} />}
			{mode !== "view" && <ManageAttestation mode={attestMode} paCode={paCode} chargingInfraData={attestationCI} toolsEquipmentData={attestationTE} dealerInfo={dealerInfo} updateAttestationsCache={updateAttestationsCache} />}
		</>
	);
};

export default Attestation;
