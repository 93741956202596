export const QualificationOptions = [
	{ label: "All", value: "All" },
	{ label: "Qualified", value: "Qualified" },
	{ label: "Non-Qualified", value: "Non-Qualified" },
];

export const MecpSpecialistOptions = [
	{ label: "All", value: "All" },
	{ label: "Specialist 1", value: "Specialist1" },
	{ label: "Specialist 2", value: "Specialist2" },
	{ label: "Specialist 3(FBS)", value: "Specialist3" },
];
