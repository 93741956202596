import { Fragment, ReactNode, useContext } from 'react'
import clsx from 'clsx'
import { Grid, Inner, Cell, Button, Row, Container, Col, Tag } from '../'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { useWindowResize } from 'hooks/useWindowResize'
import { useTranslation } from 'react-i18next';

interface NavItem {
	href?: string;
	label: string;
	key?: string;
	rightIcon?: string;
	childNavItem?: boolean;
}

interface FooterProps {
	tag?: string;
	className?: string;
	background?: 'light' | 'dark' | 'none';
	siteNavItems: NavItem[];
	children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

function Footer(props: FooterProps) {
	const {
		tag = 'footer',
		className,
		siteNavItems,
		background = 'none',
	} = props;

	const classes = [
		{ 'footer-bg-override': background === 'none' },
		{ 'fds-bg--dark': background === 'dark' },
		{ 'fds-bg--light': background === 'light' },
		'fds-m--t-3',
	];

	const ClassName = clsx(classes, className);

	const CopyClasses = [
		'fds-wrap--nowrap',
		'footer-link-text',
		{ 'fds-color--primary': background !== 'dark' },
		// { 'fds-color--white': background === 'dark' },
		'normalFontStretch',
	];

	const { t } = useTranslation();

	useWindowResize();

	const windowWidth = useSelector(
		(state: RootState) => state.windowSize.width
	);
	const currentYear = new Date().getFullYear();
	return (
		<Grid tag={tag} className={ClassName} padding={[{ dir: 'x', amt: 2 }]}>
			<div className={'fmc-divider--horizontal'} />
			<Inner
				padding={[
					{ dir: 't', amt: 2 },
					{ dir: 'b', amt: 1 },
				]}
			>
				<Cell size={12}>
					<Container className={'fds-p--x-0'}>
						<Row justify={'center'}>
							<Col
								xs={12}
								sm={12}
								md={8}
								lg={8}
								xl={8}
								className={'fds-p--x-0'}
							>
								<Row
									justify={'center'}
									padding={{ dir: 'y', amt: 1 }}
								>
									<Col
										xs={12}
										md={10}
										lg={10}
										xl={'auto'}
										className={'fds-p--x-0'}
									>
										{(windowWidth as number) >= 1024 ? (
											<Row
												className={'footer-row'}
												padding={{ dir: 'y', amt: 1 }}
											>
												{siteNavItems.map(({ key, href }, idx, { length }) => {

														const label = t(`components.footer.${key}`);
														const anchor = t(`components.footer.${href}`);
														return (
															<Fragment
																key={Math.random()}
															>
																<Col
																	xs={9}
																	md={'auto'}
																	lg={'auto'}
																	className={
																		'fds-xs:fds-p--b-3 fds-xs:fds-p--x-0 fds-md:fds-p--y-0 fds-sm:fds-p--x-0'
																	}
																>
																	<Row
																		className={
																			'h-100 fds-flex__items--center'
																		}
																	>
																		<Col
																			xs={
																				'auto'
																			}
																			className={
																				idx +
																					1 !==
																				length
																					? 'fmc-divider--vertical fmc-divider--right--hide-lg fds-flex fds-flex__justify--center '
																					: 'fds-flex fds-flex__justify--center '
																			}
																		>
																			<Tag
																				tag={'a'}

																				href={href === "" ? "#": t(`components.footer.${href}`)}

																				className={clsx(
																					CopyClasses
																				)}
																				style={{
																					textDecoration:
																						'none',
																				}}
																				target={
																					'_blank'
																				}
																			>
																				{
																					label
																				}
																			</Tag>
																		</Col>
																	</Row>
																</Col>
															</Fragment>
														);
													}
												)}
											</Row>
										) : (
											<Col xs={12}>
												{siteNavItems.map(({ key, href }, idx, { length }) => {

														return (
															<Fragment
																key={Math.random()}
															>
																<Col
																	xs={'auto'}
																	padding={{
																		dir: 'y',
																		amt: 1,
																	}}
																	className={
																		idx +
																			1 !==
																		length
																			? ' fmc-divider--right--hide-lg fds-flex fds-flex__justify--center '
																			: 'fds-flex fds-flex__justify--center '
																	}
																>
																	<Tag
																		tag={
																			'a'
																		}
																		href={href === "" ? "#": t(`components.footer.${href}`)}
																		className={clsx(CopyClasses)}
																		style={{textDecoration:'none',}}
																		target={'_blank'}

																	>
																		{t(`components.footer.${key}`)}
																	</Tag>
																</Col>
															</Fragment>
														);
													}
												)}
											</Col>
										)}

										<Row
											justify={'center'}
											padding={{ dir: 'y', amt: 3 }}
										>
											<Col
												xs={12}
												className={
													'fds-align--center fds-p--x-0'
												}
											>
												<Tag
													tag={'p'}
													className={clsx(
														CopyClasses
													)}
												>
													© {currentYear} Ford Motor
													Company
												</Tag>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</Cell>
			</Inner>
		</Grid>
	);
}

export default Footer;
