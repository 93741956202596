import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row } from '..'; // Update import as needed
import { useDispatch, useSelector } from 'react-redux';
import { setFormData } from 'reducers/email-notification-preferences.slice';
import { useAddEmailPreferenceMutation } from 'services/email-notification-preferences';
import { DealerEmailPreferencePojo } from 'services/email-notification-preferences.types';
import { RootState } from 'store';
import { useAuth } from 'providers/AuthProvider';
import { setUserPreferencesNotification } from 'reducers/notifications.slice';
import { useTranslation } from 'react-i18next';
import { FormikInputField } from './FormikInputField';
import { FormikPhoneInputField } from './FormikPhoneInputField';
import { FormikToggleSwitch } from './FormikToggleSwitch';
import * as Yup from 'yup';
import { FormikConfig, useFormik } from 'formik';

interface EmailNotificationFormProps {
	data?: DealerEmailPreferencePojo;
	setConfirmBanner: (status: boolean) => void;
}

const EmailNotificationForm: React.FC<EmailNotificationFormProps> = ({ data, setConfirmBanner }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { tokenProps } = useAuth();
	const [updateDealer, isLoading] = useAddEmailPreferenceMutation();
	const businessName = tokenProps?.authDetails?.businessName || '';

	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	const formikConfig: FormikConfig<DealerEmailPreferencePojo> = {
		initialValues: {
			contactFirstname: data?.contactFirstname || tokenProps?.authDetails?.firstName || tokenProps.tokenDetails.givenName || '',
			contactLastname: data?.contactLastname || tokenProps?.authDetails?.lastName || tokenProps.tokenDetails.sn || '',
			emailAddress: data?.emailAddress || tokenProps?.tokenDetails?.mail || '',
			contactPhone: data?.contactPhone || tokenProps?.tokenDetails?.telephoneNumber || '',
			countryCode: data?.countryCode || tokenProps?.authDetails?.countryCode || 'US',
			languagePreference: data?.languagePreference || tokenProps?.authDetails.preferredLanguage || tokenProps.tokenDetails.preferredLanguage || 'en',
			wslid: data?.wslid || tokenProps?.authDetails?.wslId || tokenProps?.tokenDetails?.uid,
			isEmailOptin: data?.isEmailOptin === true,
			paCode: data?.paCode || tokenProps?.authDetails?.paCode || tokenProps.tokenDetails.fordSiteCode || '',
			salesCode: data?.salesCode || tokenProps?.authDetails?.salesCode || '',
			userType: data?.userType || tokenProps?.authDetails?.userType || '',
			userJobRole: data?.userJobRole || tokenProps?.authDetails?.userJobRole || '',
		},
		validationSchema: Yup.object().shape({
			contactFirstname: Yup.string()
				.required(t('pages.emailNotificationPreferences.errorFName'))
				.min(3, t('pages.emailNotificationPreferences.firstNameLengthError'))
				.matches(/^[a-zA-Z][a-zA-Z0-9 .,'-]*$/, { message: t('pages.emailNotificationPreferences.errorFName') }),

			contactLastname: Yup.string()
				.required(t('pages.emailNotificationPreferences.errorLName'))
				.min(3, t('pages.emailNotificationPreferences.lastNameLengthError'))
				.matches(/^[a-zA-Z][a-zA-Z0-9 .,'-]*$/, { message: t('pages.emailNotificationPreferences.errorLName') }),
			emailAddress: Yup.string()
				.required(t('pages.emailNotificationPreferences.errorEmail'))
				.matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
					{
						message: t('pages.emailNotificationPreferences.errorEmail')
					}
				),			
			contactPhone: Yup.string()
				.required(t('pages.emailNotificationPreferences.errorPhone'))
				.matches(/^\(\d{3}\) \d{3}-\d{4}$/, { message: t('pages.emailNotificationPreferences.errorPhone') })
				.notOneOf(['(XXX) XXX-XXXX'], { message: t('pages.emailNotificationPreferences.errorPhone') }),
		}),
		onSubmit: async (values: DealerEmailPreferencePojo) => {
			try {
				const response = await updateDealer(values).unwrap();
				dispatch(setFormData(response.data));
				// Assuming 'false' means preferences are set
				dispatch(setUserPreferencesNotification(false));
				setConfirmBanner(true);
				// other logic
			} catch (error) {
				console.error('Error in form submission:', error);
				// Handle errors here
			}
		},
	};

	const formik = useFormik<DealerEmailPreferencePojo>(formikConfig);

	return (
		<Fragment>
			{windowWidth >= 1024 ? (
				// For Desktop
				<form onSubmit={formik.handleSubmit} className={isLoading ? 'w-100 loading' : 'w-100'}>
					<Col md={8} lg={8}>
						<div className={'fds-p--b-2'}>
							<small>{t('pages.emailNotificationPreferences.dealerLabel')}</small>
							<p>{businessName}</p>
						</div>
						<Row className={'fds-p--b-2'}>
							<Col
								md={5}
								lg={5}
								className={'fds-p--x-0 fds-m--r-2'}
							>
								<FormikInputField
									name="contactFirstname"
									label={t('pages.emailNotificationPreferences.fName')}
									placeholder={t('pages.emailNotificationPreferences.fName')}
									formik={formik}
								/>
							</Col>
							<Col md={6} lg={6}>
								<FormikInputField
									name="contactLastname"
									label={t('pages.emailNotificationPreferences.lName')}
									placeholder={t('pages.emailNotificationPreferences.lName')}
									formik={formik}
								/>
							</Col>
						</Row>
						<Row className={'fds-p--b-2'}>
							<Col
								md={5}
								lg={5}
								className={'fds-p--x-0 fds-m--r-2'}
							>
								<FormikInputField
									name="emailAddress"
									label={t('pages.emailNotificationPreferences.email')}
									placeholder={t('pages.emailNotificationPreferences.email')}
									formik={formik}
								/>
							</Col>
							<Col md={6} lg={6}>
								<FormikPhoneInputField
									name="contactPhone"
									label={t('pages.emailNotificationPreferences.phone')}
									placeholder={t('pages.emailNotificationPreferences.phone')}
									formik={formik}
								/>
							</Col>
						</Row>
						<div>
							<p className={'fds-p--b-2'}>{t('pages.emailNotificationPreferences.notif')}</p>
							<FormikToggleSwitch<typeof formik.initialValues>
								name="isEmailOptin"
								formik={formik}
								offText="Off"
								onText="On"
							/>
						</div>
						<div className={'fds-p--y-4'}>
							<button type="submit" className="fmc-button">
								{t('pages.emailNotificationPreferences.saveBtn')}
							</button>
						</div>
					</Col>
				</form>
			) : (
				// For Mobile
				<form onSubmit={formik.handleSubmit} className={isLoading ? 'w-100 loading' : 'w-100'}>
					<Col xs={12} sm={12}>
						<div className={'fds-p--b-2'}>
							<small>{t('pages.emailNotificationPreferences.dealerLabel')}</small>
							<p>{businessName}</p>
						</div>

						<Col
							xs={12}
							sm={12}
							className={'fds-p--x-0 fds-m--b-2'}
						>
							<FormikInputField
								name="contactFirstname"
								label={t('pages.emailNotificationPreferences.fName')}
								placeholder={t('pages.emailNotificationPreferences.fName')}
								formik={formik}
							/>
						</Col>
						<Col
							xs={12}
							sm={12}
							className={'fds-p--x-0 fds-m--b-2'}
						>
							<div className={`fmc-floating-label fds-m--b-1`}>
								<FormikInputField
									name="contactLastname"
									label={t('pages.emailNotificationPreferences.lName')}
									placeholder={t('pages.emailNotificationPreferences.lName')}
									formik={formik}
								/>
							</div>
						</Col>

						<Col
							xs={12}
							sm={12}
							className={'fds-p--x-0 fds-m--b-2'}
						>
							<FormikInputField
								name="emailAddress"
								label={t('pages.emailNotificationPreferences.email')}
								placeholder={t('pages.emailNotificationPreferences.email')}
								formik={formik}
							/>
						</Col>
						<Col
							xs={12}
							sm={12}
							className={'fds-p--x-0 fds-m--b-2'}
						>
							<FormikPhoneInputField
								name="contactPhone"
								label={t('pages.emailNotificationPreferences.phone')}
								placeholder={t('pages.emailNotificationPreferences.phone')}
								formik={formik}
							/>
						</Col>

						<div>
							<p className={'fds-p--b-2'}>
								{t('pages.emailNotificationPreferences.notif')}
							</p>
							<FormikToggleSwitch<typeof formik.initialValues>
								name="isEmailOptin"
								formik={formik}
								offText="Off"
								onText="On"
							/>
						</div>
						<div className={'fds-p--y-4'}>
							<button className="fmc-button" type="submit">
								{t('pages.emailNotificationPreferences.saveBtn')}
							</button>
						</div>
					</Col>
				</form>
			)}
		</Fragment>
	);
};

export default EmailNotificationForm;
