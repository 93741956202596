import { Col, Button } from "components/components";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import AttestationDealerCard from "./AttestationDealerCard";
import { usePostToolsAndEquipmentAttestationMutation, usePutToolsAndEquipmentAttestationMutation } from "services/attestations";
import useAuthInfo from "hooks/useAuthInfo";

interface attestToolsProps {
	showSuccessBanner: any;
	defaultValues: any;
	editMode: boolean;
	dealerInfo: any;
	paCode: string;
	updateAttestationsCache: any;
}

const DefaultValues = {
	forkliftAccess: "accessTo",
	evDollies: "Yes",
	cautionCones: "No",
	batteryLift: "Yes",
	rescueCharger: "Yes",
	smokeMachine: "No",
	batteryArray: "Yes",
	pneumaticGun: "Yes",
	pressureTest: "No",
	esst: "Yes",
	f150Esst: "Yes",
	hoist: "No",
};

const convertResponses = (responses: any) => {
	const result: any = {};
	responses?.forEach((response: any) => {
		const { criteriaCode, isCriteriaCompleted, forkliftSelectionId } = response;
		if (criteriaCode === "TP1") {
			// Use forkliftSelectionId for TP1
			result[criteriaCode] = forkliftSelectionId !== null ? forkliftSelectionId.toString() : "1";
		} else {
			// Use "Yes" or "No" for other criteria based on isCriteriaCompleted
			result[criteriaCode] = isCriteriaCompleted ? "Yes" : "No";
		}
	});
	return result;
};

const parseFormData = (formData: any) => {
	const data: any = {
		toolsAndEquipCriteriaDTO: [],
	};
	Object.keys(formData)?.forEach((key) => {
		if (key?.includes("TP")) {
			const keyName = key === "TP1" ? "forkliftSelectionId" : "isCriteriaCompleted";
			data["toolsAndEquipCriteriaDTO"] = [
				...data?.["toolsAndEquipCriteriaDTO"],
				{
					criteriaCode: key,
					[keyName]: key === "TP1" ? formData?.[key] : formData?.[key] === "Yes",
				},
			];
		} else {
			data[key] = formData?.[key];
		}
	});
	return data;
};

const AttestationsToolsAndEquimentForm = (props: attestToolsProps) => {
	const { showSuccessBanner, editMode, defaultValues, dealerInfo, paCode, updateAttestationsCache } = props;
	const [postTEAttestation] = usePostToolsAndEquipmentAttestationMutation();
	const [putTEAttestation] = usePutToolsAndEquipmentAttestationMutation();
	const { responses = [] } = defaultValues || {};
	const navigate = useNavigate();
	const { wslId, preferredLanguage, countryCode } = useAuthInfo();

	const formValues: any = Object.keys(defaultValues || {})?.reduce((acc, cur) => {
		const val = cur === "responses" ? convertResponses(defaultValues?.[cur]) : { [cur]: defaultValues?.[cur] };
		return {
			...acc,
			...val,
		};
	}, {});

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		getValues,
	} = useForm({
		defaultValues: formValues,
	});

	const submitAndShowBanner = () => {
		showSuccessBanner({ message: "" });
		scrollToTop();
	};

	const formFieldValues = watch();
	const isAllFilled = Object.values(formFieldValues).every((value) => value !== undefined && value !== "");

	const onSubmit = async (formValues: any, e: any) => {
		e.preventDefault();
		const mData = parseFormData(formValues);
		const data = { ...mData, paCode: dealerInfo?.paCode, salesCode: dealerInfo?.salesCode, wslId, prefferedLanguage: preferredLanguage, countryCode, optionName: "Tools and Equipment" };
		const putData = {
			...defaultValues,
			responses: defaultValues?.responses?.map((data: any) => {
				const keyName = data?.criteriaCode === "TP1" ? "forkliftSelectionId" : "isCriteriaCompleted";
				const keyVal = data?.criteriaCode === "TP1" ? formValues?.[data?.["criteriaCode"]] : formValues?.[data?.["criteriaCode"]] === "Yes";
				return {
					...data,
					[keyName]: keyVal,
				};
			}),
			dealerPointOfContact: formValues?.dealerPointOfContact,
			dealerPointOfContactEmail: formValues?.dealerPointOfContactEmail,
			salesCode: dealerInfo?.salesCode,
			isDisclaimerChecked: true,
			wslId,
			countryCode,
		};

		try {
			const method = editMode ? putTEAttestation : postTEAttestation;
			const mData = editMode ? putData : data;
			const { isError, message } = await method(mData).unwrap();
			scrollToTop();
			showSuccessBanner({ message: "Attestation successfully submitted", status: isError ? "error" : "success" });

			if (!isError) {
				if (!editMode) updateAttestationsCache(paCode, null, putData);
				setTimeout(() => {
					navigate(`/${paCode}/attestations`);
				}, 2000);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const [formVisible, setFormVisible] = useState(false);

	useEffect(() => {
		setFormVisible(editMode ? true : false);
	}, [editMode]);

	const beginForm = (e: any) => {
		const beginCheck = e.target.checked;
		beginCheck ? setFormVisible(true) : setFormVisible(false);
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};

	const disableSubmit = !getValues("isDisclaimerChecked") === true;
	const hasErrors = Object.keys(errors)?.length !== 0;
	// console.log({ hasErrors, isAllFilled, disableSubmit });

	return (
		<div>
			<Col>
				<form onSubmit={handleSubmit(onSubmit)} className={"fds-m--y-2"}>
					<div className={"dealerTab-Section"}>
						<div className={"fds-p--x-1 fds-p--y-1"}>
							<h2 className={"fmc-type--heading6 fds-weight--bold letter-spacing-0 fds-p--b-2"}>Electric Vehicle Tools & Equipment Attestation</h2>

							{!editMode && (
								<label htmlFor={"readyBegin"} className={"fds-color--primary"}>
									<input id={"readyBegin"} className={"fds-m--r-1"} type={"checkbox"} value={true as any} {...register("isEvEquipmentInstalled", { required: true })} onChange={beginForm}></input>
									<span>I have acquired* all necessary tools and equipment (*forklift access) and am ready to begin the attestation process. </span>
								</label>
							)}

							{formVisible ? (
								<Fragment>
									<div className={"fds-m--b-2"}>
										<div className={"fds-m--y-3"}>
											<p>
												Dealers should not accept or perform work on repairs that require these tools/equipment if the Dealer does not possess the tools/equipment to perform the work safely, effectively or to the specific repair procedures outlined by the
												Company.
											</p>

											{/* <p className={'fds-p--y-2'}>
                                                Safeguards will be in place to protect the integrity
                                                of and ensure compliance with Electric Vehicle
                                                Competency. Reasonable and appropriate consequences,
                                                determined by the Company, will result if Dealers
                                                are not in compliance with Electric Vehicle
                                                Competency. Dealers who are non-compliant may be
                                                limited from performing warranty service on electric
                                                vehicles. The Company reserves the right to provide
                                                a warning letter to Dealers who are non-compliant.
                                            </p> */}

											<p className={"fds-p--t-2"}>
												The Company will be actively reviewing and auditing warranty claim submissions and Attestations to ensure the Dealer's compliance with Electric Vehicle Competency and to confirm that the Dealer has the appropriate tools and
												equipment. Any Dealer that engages in conduct/behavior/activities to violate Electric Vehicle Competency, or who submit ineligible and false Attestations are subject to being charged back for any improperly submitted warranty claim.
											</p>
										</div>
									</div>
									<div
										className={"fds-p--b-3"}
										style={{
											borderBottom: "1px solid var(--fds-color--gray3)",
										}}
									>
										<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
											<legend className={" fds-color--primary fds-weight--bold"} style={{ fontSize: "1.8rem" }}>
												<Col>
													Forklift
													<p className={"fds-p--y-1"}>Please include your forklift status:</p>
												</Col>
											</legend>
											<label htmlFor={"forkliftOwn"}>
												<input type={"radio"} id={"forkliftOwn"} {...register("TP1", { required: true })} value={"1"} />
												<span className={"fds-color--primary"}>I own a forklift</span>
											</label>

											<label htmlFor={"forkliftAccess"}>
												<input type={"radio"} id={"forkliftAccess"} {...register("TP1", { required: true })} value={"2"} />
												<span className={"fds-color--primary"}>I have access to a forklift</span>
											</label>

											<label htmlFor={"forkliftNoAccess"}>
												<input type={"radio"} id={"forkliftNoAccess"} {...register("TP1", { required: true })} value={"3"} />
												<span className={"fds-color--primary"}>I do not own or have access to a forklift</span>
											</label>
										</fieldset>
									</div>

									<div className={"fds-p--x-1 fds-p--y-1"}>
										<Col className={"fds-m--y-3"}>
											<Col>
												<h3 className={"fds-p--b-2"}>Electric Vehicle Tools & Equipment</h3>
												<p className={"fds-p--b-2"}> Please indicate if your dealership has acquired the following:</p>
											</Col>

											<div>
												<p className={"fds-weight--bold fds-p--y-1"}>Electric Vehicle Battery Dollies</p>
												<p className={"fds-p--b-2"}>Acts as a mobile workstation for HV Battery repairs; frees up a battery lift table to be utilized in another bay; helps with ease of transport and the safe transport of HV batteries in the shop.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has electric vehicle battery dollies?</legend>
													<label htmlFor={"evDolliesYes"}>
														<input type={"radio"} id={"evDolliesYes"} value={"Yes"} {...register("TP2", { required: true })} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"evDolliesNo"}>
														<input type={"radio"} id={"evDolliesNo"} {...register("TP2", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>

											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>High Voltage Caution Cones</p>
												<p className={"fds-p--b-2"}>Safety equipment to indicate area of caution where high-voltage work is being done in the shop.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has high voltage caution cones?</legend>
													<label htmlFor={"cautionConesYes"}>
														<input type={"radio"} id={"cautionConesYes"} {...register("TP3", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"cautionConesNo"}>
														<input type={"radio"} id={"cautionConesNo"} {...register("TP3", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>

											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>High Voltage Battery Lift Table</p>
												<p className={"fds-p--b-2"}>Used to drop/lift HV batteries safely and effectively within shop to swap or service batteries.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has high voltage battery lift table?</legend>
													<label htmlFor={"batteryLiftYes"}>
														<input type={"radio"} id={"batteryLiftYes"} {...register("TP4", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"batteryLiftNo"}>
														<input type={"radio"} id={"batteryLiftNo"} {...register("TP4", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>

											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>Rescue Charger</p>
												<p className={"fds-p--b-2"}>High-voltage hybrid battery pack to regain vehicle starting capabilities if Electric Vehicle has a dead battery.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has a rescue charger?</legend>
													<label htmlFor={"rescueChargerYes"}>
														<input type={"radio"} id={"rescueChargerYes"} {...register("TP5", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"rescueChargerNo"}>
														<input type={"radio"} id={"rescueChargerNo"} {...register("TP5", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>
											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>Smoke Machine</p>
												<p className={"fds-p--b-2"}>Pressurized air to ensure proper sealing of the high voltage battery, detects leaks in certain closed systems on vehicles.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has a smoke machine?</legend>
													<label htmlFor={"smokeMachineYes"}>
														<input type={"radio"} id={"smokeMachineYes"} {...register("TP6", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"smokeMachineNo"}>
														<input type={"radio"} id={"smokeMachineNo"} {...register("TP6", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>
											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>High Voltage Battery Array Charger</p>
												<p className={"fds-p--b-2"}>Tool that charges or discharges battery arrays up to 96 volts, needed when performing HV battery array service.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has high voltage battery array charger?</legend>
													<label htmlFor={"batteryArrayYes"}>
														<input type={"radio"} id={"batteryArrayYes"} {...register("TP7", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"batteryArrayNo"}>
														<input type={"radio"} id={"batteryArrayNo"} {...register("TP7", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>
											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>Pneumatic Gun</p>
												<p className={"fds-p--b-2"}>Hand tool with pneumatic press, used to apply thermal interface material during HV battery array service.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has a pneumatic gun?</legend>
													<label htmlFor={"pneumaticGunYes"}>
														<input type={"radio"} id={"pneumaticGunYes"} {...register("TP8", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"pneumaticGunNo"}>
														<input type={"radio"} id={"pneumaticGunNo"} {...register("TP8", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>
											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>Pressure Test Set</p>
												<p className={"fds-p--b-2"}>Tool used to test the internal battery cooling system.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has a pressure test set?</legend>
													<label htmlFor={"pressureTestYes"}>
														<input type={"radio"} id={"pressureTestYes"} {...register("TP9", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"pressureTestNo"}>
														<input type={"radio"} id={"pressureTestNo"} {...register("TP9", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>
											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>Electric Vehicle ESST Tool Kit</p>
												<p className={"fds-p--b-2"}>Contains tools called out in the workshop manual for 2020-Current Ford and Lincoln Battery Electric Vehicle.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has electric vehicle ESST Tool kit?</legend>
													<label htmlFor={"esstYes"}>
														<input type={"radio"} id={"esstYes"} {...register("TP10", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"esstNo"}>
														<input type={"radio"} id={"esstNo"} {...register("TP10", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>
											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>
													F-150<sup>®</sup> Lightning<sup>®</sup> ESST Tool Kit
												</p>
												<p className={"fds-p--b-2"}>Contains the additional battery lifting and leak testing equipment for F-150 LIghtning.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has F-150 Lightning ESST tool kit?</legend>
													<label htmlFor={"f150EsstYes"}>
														<input type={"radio"} id={"f150EsstYes"} {...register("TP11", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"f150EsstNo"}>
														<input type={"radio"} id={"f150EsstNo"} {...register("TP11", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>
											<div className={"fds-m--y-2"}>
												<p className={"fds-weight--bold fds-p--y-1"}>Electric Vehicle Hoist Lift</p>
												<p className={"fds-p--b-2"}>A vehicle hoist/lift to help with ease of lifting and the safe lifting of an electric vehicle for service without damaging the HV battery.</p>
												<fieldset className={"fmc-check "} style={{ maxWidth: "unset" }}>
													<legend className={" fds-color--primary"}>Dealership has electric vehicle hoist lift?</legend>
													<label htmlFor={"hoistYes"}>
														<input type={"radio"} id={"hoistYes"} {...register("TP12", { required: true })} value={"Yes"} />
														<span className={"fds-color--primary"}>Yes</span>
													</label>

													<label htmlFor={"hoistNo"}>
														<input type={"radio"} id={"hoistNo"} {...register("TP12", { required: true })} value={"No"} />
														<span className={"fds-color--primary"}>No</span>
													</label>
												</fieldset>
											</div>
										</Col>
									</div>
								</Fragment>
							) : (
								""
							)}
						</div>
					</div>

					{formVisible ? (
						<Fragment>
							<div className={"dealerTab-Section fds-m--y-2"}>
								<AttestationDealerCard dealerInformation={{ ...dealerInfo, ...formValues }} register={register} errors={errors} />
							</div>

							<div className={"dealerTab-Section fds-m--y-2"}>
								<Col className={"fds-p--x-1 fds-p--y-1"}>
									<h2 className={"fmc-type--heading6 fds-weight--bold letter-spacing-0 fds-p--b-2"}>Attestation</h2>

									<label htmlFor={"authorityCheck"} className={"fds-color--primary"} style={{ display: "flex", alignItems: "baseline" }}>
										<input id={"authorityCheck"} className={"fds-m--r-1"} type={"checkbox"} value={true as any} {...register("isDisclaimerChecked", { required: true })} style={{ marginRight: "1.5rem" }} />
										<span>
											By checking the box and submitting this form, I attest that I am an f(ii) authority or authorized by a member of f(ii) authority and attest on behalf of the Dealership listed under the Dealership's Sales Code listed above and that the
											information provided is true and correct.
										</span>
									</label>
								</Col>
								<Button onClick={handleSubmit} buttonClassName={"fds-m--y-2"} rightIcon noShadow disabled={disableSubmit || hasErrors || !isAllFilled}>
									Submit Attestation
								</Button>
							</div>
						</Fragment>
					) : (
						""
					)}
				</form>
			</Col>
		</div>
	);
};

export default AttestationsToolsAndEquimentForm;
