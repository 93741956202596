// FormikPhoneInputField.tsx

import React, { useRef, useState, useEffect } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { adjustCursorPosition, formatPhoneNumber } from 'utils/PhoneUtils';


interface FormikPhoneInputFieldProps<T> {
    name: keyof T;
    placeholder: string;
    label: string;
    formik?: FormikProps<T>;
    required?: boolean;
    disabled?: boolean;
    whiteBg?: boolean;
}

/**
 * A Formik field component for phone number input with automatic formatting.
 */
export const FormikPhoneInputField = <T extends Record<string, any>>({
    name,
    placeholder,
    label,
    formik: formikProp,
    required = true,
    disabled = false,
    whiteBg = true,
}: FormikPhoneInputFieldProps<T>): React.ReactElement => {
    const formik = formikProp || useFormikContext<T>();
    const [inputValue, setInputValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);
    const [prevValue, setPrevValue] = useState<string>('');

    useEffect(() => {
        const formattedValue = formatPhoneNumber(formik.values[name as string]) ?? '';
        setInputValue(formattedValue);
        formik.setFieldValue(name as string, formattedValue);
    }, [formik.values, name]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        const formattedValue = formatPhoneNumber(currentValue);
        setInputValue(formattedValue);
        formik.setFieldValue(name as string, formattedValue, true);
        if (inputRef.current) {
            adjustCursorPosition(inputRef.current, prevValue, formattedValue);
        }
        setPrevValue(formattedValue);
    };

    const focusInput = (): void => {
        inputRef.current?.focus();
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        formik.setFieldTouched(name as string, true, true);
        formik.validateField(name as string);
    };

    const isTouched = formik.getFieldMeta(name as string).touched;
    const isError = formik.getFieldMeta(name as string).error;
    const inputClass = `fmc-input ${whiteBg ? 'fds-color__bg--white' : ''} ${isTouched && isError ? 'fmc-input--error' : ''}`;

    return (
        <>
            <div className={`fmc-floating-label fds-m--b-1 fds-xs:fds-flex__col-12 fds-p--l-0 ${isTouched && isError ? 'fmc-floating-label--error' : ''}`}>
                <input
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    ref={inputRef}
                    maxLength={14}
                    type="text"
                    placeholder={placeholder}
                    className={inputClass}
                    disabled={disabled}
                    name={name as string}
                />
                <label onClick={focusInput}>
                    <span className="fmc-floating-label__text">{label}{required && '*'}</span>
                </label>
            </div>
            {isTouched && isError && <small style={{ color: 'var(--fds-color--error1)' }}>{isError}</small>}
        </>
    );
};
