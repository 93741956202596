import React from "react";
import { Col, Row, Tag } from "..";
import { ENABLE_MECP } from "pages/Dashboard/USADashboard";

interface DealerGreetingProps {
	t: any;
	welcomeMsg: string;
	paCodeFromApi?: string;
	dealerCode: string;
	countryCode: string;
	marketArea?: string;
	region?: string;
	zone?: string;
	salesCode?: number;
	isQualified: boolean;
	dataAsOf?: string;
}

const DealerGreeting = ({ dealerCode, welcomeMsg, countryCode, t, paCodeFromApi, marketArea, salesCode, region, zone, isQualified, dataAsOf }: DealerGreetingProps) => {
	return (
		<div>
			<Col xs={12} className={"fds-m--x-2"}>
				<Row className={"dashboard-title-block"}>
					<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-p--y-2 "}>
						{welcomeMsg}
					</Tag>
				</Row>
				<Tag tag={"p"} className={"fmc-type--body2 fds-font--color__gray fds-p--b-1 fds-flex--center"}>
					{dealerCode}: <span className={"fds-weight--bold"}>{paCodeFromApi} </span>
					{countryCode !== "CAN" && (
						<>
							<span className={"fds-m--x-2"}>•</span>
							{t("common.salesCode")}: <span className={"fds-weight--bold"}>{salesCode} </span>
						</>
					)}
					<span className={"fds-m--x-2"}>•</span>
					{t("common.marketArea")}: <span className={"fds-weight--bold"}>{marketArea} </span>
					<span className={"fds-m--x-2"}>•</span>
					{t("common.region")}: <span className={"fds-weight--bold"}>{region} </span>
					<span className={"fds-m--x-2"}>•</span>
					{t("common.zone")}: <span className={"fds-weight--bold"}>{zone} </span>
				</Tag>

				<Row className={"dashboard-title-block"}>
					{ENABLE_MECP && (
						<i style={{ fontSize: "1.6rem" }}>
							<span className={"fds-color__text--gray3 fds-stretch--condensed"}>Program Status: </span>
							{isQualified ? <span className={"fds-weight--medium fds-font--color__success fds-stretch--condensed"}>Qualified/Active</span> : <span className={"fds-weight--medium fds-font--color__error fds-stretch--condensed"}>Not Qualified/Inactive</span>}
						</i>
					)}
					{dataAsOf && (
						<i style={{ fontSize: "1.6rem", paddingLeft: 12 }}>
							<span className={"fds-color__text--gray3 fds-stretch--condensed"}>Data as of: </span>
							<span className={"fds-weight--medium fds-font--color__info fds-stretch--condensed"} style={{ color: "#066fef" }}>
								{dataAsOf}
							</span>
						</i>
					)}
				</Row>
			</Col>
		</div>
	);
};

export default DealerGreeting;
