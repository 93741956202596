import { FC, Fragment, ReactNode, useState, useEffect } from "react";
import { Tag, Grid, Inner, Cell, Button, Row, Col, Container } from "../";
import { dealerInfo } from "../../../constants/ModelEDashConstants";
import { isDefined } from "../../utils/Helpers";
import MobileDealerInfo from "./MobileDealerInfo";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { useNavigate } from "react-router";
import { RootState } from "store";
import { useSelector } from "react-redux";

interface CTAProps {
	aria?: string;
	label?: string;
	href?: string;
}

interface NavItem {
	href?: string;
	label: string;
	aria?: string;
	rightIcon?: string;
	childNavItem?: boolean;
}
interface SubMenuItemProps {
	cta?: CTAProps[];
	menuOpened?: boolean;
}

interface MenuItemProps {
	aria?: string;
	label?: string;
	href?: string;
	dropdown?: boolean;
	last?: boolean;
	cta?: CTAProps[];
	menuOpened?: boolean;
	setMenuOpenCallBack: () => void;
	objectKey: string;
	hideLinks: boolean;
}

type HideType = "xs" | "sm" | "md" | "lg" | "xl";

interface NavBarProps {
	navItems: NavItem[];
	tag?: string;
	menuOpened: boolean;
	dealerInformation: typeof dealerInfo;
	setMenuOpenCallBack: (toggle: boolean) => void;
	hide?: HideType[];
	children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
	hideLinks: any;
}

const MobileSubNavMenuItem: FC<SubMenuItemProps> = (props) => {
	const { cta, menuOpened, ...attributes } = props;
	const { t } = useTranslation();
	return (
		<Fragment>
			{isDefined(cta) && (
				<Row className={`om-fmc__navbar--mobile__subnav-menu${menuOpened ? " opened" : ""}`}>
					{Array.isArray(cta) &&
						cta.map(({ href, aria, label }) => {
							return (
								<Fragment key={Math.random()}>
									<Col
										xs={12}
										padding={[
											{ dir: "y", amt: 1 },
											{ dir: "x", amt: 3 },
										]}
										className={"fmc-divider--bottom om-fmc-divider--primary fds-color__bg--gray1"}
									>
										<Button
											tag={"a"}
											href={href}
											aria-label={aria}
											buttonClassName={"fds-align--center"}
											padding={[
												{ dir: "y", amt: 2 },
												{ dir: "x", amt: 2 },
											]}
											textButton
											{...attributes}
										>
											{label}
										</Button>
									</Col>
								</Fragment>
							);
						})}
				</Row>
			)}
		</Fragment>
	);
};

const MobileNavBarMenuItem: FC<MenuItemProps> = (props) => {
	function onNavHandle(event: React.MouseEvent<HTMLElement>, href: string) {
		event.preventDefault();
		window.open(href, "_blank");
		setMenuOpenCallBack();
	}
	const { t } = useTranslation();
	const { label, href, dropdown, aria, cta, last = false, setMenuOpenCallBack, menuOpened, objectKey, hideLinks } = props;
	const _attributes = Object.assign({}, dropdown ? { rightIcon: "fds-font--ford-icons__chevron-down" } : {}, !isDefined(cta) ? { href, tag: "a" } : {});
	return (
		<Fragment>
			<Col xs={12} className={"fds-flex--center fds-flex-direction--column"} padding={0}>
				<Row className={last ? "fmc-divider--bottom" : ""} margin={{ dir: "x", amt: 2 }}>
					<Col xs={12} padding={[{ dir: "y", amt: 1 }]}>
						<Button
							aria-label={aria}
							buttonClassName={"fds-align--left om-fmc__navbar--mobile__nav-dropdown fds-p--r-3 fds-p--y-1"}
							textButton
							onClick={() => {
								setMenuOpenCallBack();
							}}
							{..._attributes}
						>
							{label}
							{/* {t(`components.navbar.${objectKey}`)} */}
						</Button>
					</Col>
				</Row>
				<MobileSubNavMenuItem menuOpened={menuOpened} cta={cta} />
			</Col>
		</Fragment>
	);
};

const MobileNavBar: FC<NavBarProps> = (props) => {
	const { preferredLanguage } = useSelector((state: RootState) => state.localePreferences);
	const { navItems, menuOpened, setMenuOpenCallBack, dealerInformation, hideLinks } = props;

	const { t } = useTranslation();

	const closeBtn = () => {
		setMenuOpenCallBack(false);
	};

	return (
		<Fragment>
			<Grid tag={"nav"} className={"om-fmc__navbar--mobile-grid fds-p--x-0"} padding={{ dir: "y", amt: 1 }}>
				<Inner>
					<Cell size={12}>
						<Container className={"om-fmc__navbar--mobile-container fds-p--l-0"} style={{ maxHeight: 80 }}>
							<Row justify={"center"}>
								<Col xs={12} lg={12} xl={8} className={"fds-p--x-0"}>
									<Row style={{ minHeight: 50 }}>
										<Col xs={12}>
											<Row>
												{preferredLanguage === "fr" ? (
													<Col xs={6} sm={5} md={4} tag={"a"} href={"/"} aria-label={"Link to Home Page"}>
														<Tag
															tag={"img"}
															className={"fds-img"}
															src={"/assets/images/FordModelEFR.svg"}
															alt={"Logo"}
															style={{
																maxHeight: 80,
															}}
														/>
													</Col>
												) : (
													<Col xs={6} sm={5} md={4} tag={"a"} href={"/"} aria-label={"Link to Home Page"}>
														<Tag
															tag={"img"}
															className={"fds-img"}
															src={"/assets/images/Ford_Model_e_Blue_v2.svg"}
															alt={"Logo"}
															style={{
																maxHeight: 80,
															}}
														/>
													</Col>
												)}

												<Col xs={"auto"} />
												<Col
													xs={2}
													className={"fds-flex--center fds-flex-direction--column"}
													padding={{
														dir: "x",
														amt: 0,
													}}
												>
													<Tag
														tag={"button"}
														onClick={() => {
															setMenuOpenCallBack(true);
														}}
														aria-label={"Close Mobile Navigation"}
														className={"onemag-fds__navbar--mobile__nav-button onemag-fds__navbar--mobile-nav-button--closed"}
													>
														<Tag
															style={{
																lineHeight: "1.2rem",
															}}
															aria-hidden={true}
															className={"fds-icon fds-icon--48 fds-align--center fds-font--ford-icons__minus"}
														/>
														<Tag
															style={{
																lineHeight: "1.2rem",
															}}
															aria-hidden={true}
															className={"fds-icon fds-icon--48 fds-align--center fds-font--ford-icons__minus"}
														/>
														<Tag
															style={{
																lineHeight: "1.2rem",
															}}
															aria-hidden={true}
															className={"fds-icon fds-icon--48 fds-align--center fds-font--ford-icons__minus"}
														/>
														<Tag aria-hidden={true} className={"fds-color__text--primary"}>
															{t("components.navbar.menu")}
														</Tag>
													</Tag>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<div
											className={"semi-modal"}
											onClick={() => {
												setMenuOpenCallBack(false);
											}}
										>
											{" "}
										</div>
										<Col
											xs={10}
											className={"om-fmc__navbar--mobile__nav-wrapper"}
											padding={[
												{ dir: "x", amt: 0 },
												{ dir: "b", amt: 4 },
											]}
										>
											<Tag
												tag={"button"}
												onClick={() => {
													closeBtn();
													// setMenuOpenCallBack(false);
												}}
												aria-label={"Open Mobile Navigation"}
												className={"onemag-fds__navbar--mobile__nav-button onemag-fds__navbar--mobile-nav-button--opened mobile-close-position"}
												style={{ padding: "20px" }}
											>
												<Tag aria-hidden={true} className={"fds-icon fds-icon--24 fds-align--right fds-font--ford-icons__clear"} />
											</Tag>
											<Row className={"fds-m--t-4"}>
												{!hideLinks &&
													navItems.map((menuItem: any, idx, { length }) => {
														return (
															<Fragment key={Math.random()}>
																<MobileNavBarMenuItem
																	menuOpened={menuOpened}
																	setMenuOpenCallBack={() => {
																		setMenuOpenCallBack(!menuOpened);
																	}}
																	last={idx + 1 === length}
																	{...menuItem}
																	objectKey={menuItem?.key || ""}
																	// hideLinks={
																	// 	hideLinks
																	// }
																/>
															</Fragment>
														);
													})}

												<Col xs={12} className={"fds-flex--center fds-flex-direction--column"} padding={0}>
													<Row
														className={"fmc-divider--bottom"}
														margin={{
															dir: "x",
															amt: 2,
														}}
													>
														<Col
															xs={12}
															padding={[
																{
																	dir: "y",
																	amt: 3,
																},
															]}
														>
															<MobileDealerInfo dealerInformation={props.dealerInformation} />
														</Col>
													</Row>
												</Col>
												<Col xs={12} className={"fds-flex--center fds-flex-direction--column"} padding={0}>
													<Row
														margin={{
															dir: "x",
															amt: 2,
														}}
													>
														<Col
															xs={12}
															padding={[
																{
																	dir: "y",
																	amt: 3,
																},
																{
																	dir: "x",
																	amt: 2,
																},
															]}
															className={"fds-flex fds-flex--start fds-flex-direction--column"}
														>
															<Button
																tag={"a"}
																textButton
																buttonClassName={"fds-flex__self--start fds-p--y-1 fds-wrap--nowrap"}
																target="_blank"
																href="mailto:ElectricVehicles@fordprogramhq.com"
																style={{
																	whiteSpace: "nowrap",
																}}
															>
																<span className={"fds-p--r-1"}>
																	<img className={"nav-icon-sizing"} src={"../../../assets/icons/meDDicon/icon-email.svg"} />
																</span>
																<span
																	style={{
																		fontSize: "15px",
																	}}
																>
																	ElectricVehicles@fordprogramhq.com
																	{/* {t(
																		'pages.noAccess.emailaddress'
																	)} */}
																</span>
															</Button>

															<Button textButton buttonClassName={"fds-flex__self--start"}>
																<span className={"fds-p--r-1"}>
																	<img className={"nav-icon-sizing"} src={"../../../assets/icons/meDDicon/icon-phone.svg"} />
																</span>
																(888) 850-1002
															</Button>
															{/* <LanguageSelector
																hideMails={true}
															/> */}
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</Cell>
				</Inner>
			</Grid>
		</Fragment>
	);
};

export default MobileNavBar;
