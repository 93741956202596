import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DealerEmailPreferencePojo } from '../services/email-notification-preferences.types';

interface EmailNotificationPreferencesState {
  formData: DealerEmailPreferencePojo;
}

const initialState: EmailNotificationPreferencesState = {
  formData: {} as DealerEmailPreferencePojo,
};

const emailNotificationPreferencesSlice = createSlice({
  name: 'emailNotificationPreferences',
  initialState,
  reducers: {
    setFormData: (state, action: PayloadAction<DealerEmailPreferencePojo>) => {
      state.formData = action.payload;
    },
  },
});

export const { setFormData } = emailNotificationPreferencesSlice.actions;
export default emailNotificationPreferencesSlice.reducer;