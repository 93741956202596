import { DealerInfo } from '../types/dashTypes';

const dealerInfo: DealerInfo = {
	dealerName: 'Metropolitan Ford',
	dealerUser: 'Lenny Dykstra',
	dealerType: 'Certified Elite Dealer',
	PACode: 24680,
	salesCode: 98765,
	marketArea: 'East',
	region: 'New York',
	meQualifier: {
		sectionTitle: 'Ford Model e™ Qualifier',
		totalTasks: 3,
		tasksComplete: 1,
		panelData: [
			{
				panelTitle: 'Charging Infrastructure',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'NEMA',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Level 2 Chargers',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
					{
						rowStatus: 'onWaiver',
						rowCriteria: 'Level 3 Chargers',
						required: 1,
						atDealer: 1,
						waiverDate: '06/22/2023',
					},
					{
						rowStatus: 'incomplete',
						rowCriteria: 'Level 3 Public on BOCN',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Training',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria: 'Specialized Team Identified in STARS',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Service Capacity',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'Completed Assessment',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Acquired Tools/Equipment',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Retail Environment',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'Acquire and Activate Tablet',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'eCommerce',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'EMP Set-Up, Training, Route One',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'GuestXP',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'Enrolled, Trained, Installed',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
		],
	},
	foundationalCompliance: {
		sectionTitle: 'Foundational Compliance',
		meQComplete: false,
		totalTasks: 3,
		tasksComplete: 1,
		panelData: [
			{
				panelTitle: 'Charging Availability',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria: 'Public Charging',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Training',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria: 'Maintain Specialized Team',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'EV University Level 1 & Level 2',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'FGE Immersion',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
					{
						rowStatus: 'onWaiver',
						rowCriteria: 'Ford Model e™ FGE Intensive',
						required: 1,
						atDealer: 1,
						waiverDate: '06/22/2023',
					},
					{
						rowStatus: 'onWaiver',
						rowCriteria: 'Tech 43/44',
						required: 1,
						atDealer: 1,
						waiverDate: '06/22/2023',
					},
				],
			},
			{
				panelTitle: 'Service Capacity',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'Support Reoccurring Consultations',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Retail Environment',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'Tablet Utilization',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'eCommerce',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'Transparent Pricing',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'deferred',
						rowCriteria:
							'Sales Initiated & Completed via Ford.com eCommerce Platform',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'deferred',
						rowCriteria: 'Digital Closing Statement',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'GuestXP',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria:
							'Offering Service Scheduling (Online & In-Person via GuestXP)',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'deferred',
						rowCriteria: 'Input 100% of Warranty Events',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'deferred',
						rowCriteria: 'Input 100% of EV Customer Pay Events',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
		],
	},
	mecpCompliance: {
		sectionTitle: 'MeCP Compliance',
		meQComplete: false,
		totalTasks: 4,
		tasksComplete: 1,
		panelData: [
			{
				panelTitle: 'Sales Process',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria: 'Tablet Utilization - Delivery Day Tool',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria:
							'Min 3 Demo Units in Stock (of Different Series)',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Guest Check-Ins',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria: '7-10 Day Check-Ins',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria:
							'Offer and Schedule 1:1 Discover Your Ford Guide Session',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Remote Experiences',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria:
							'Offer Pick Up & Delivery with Loaner Offer or Mobile Service for 100% of Ford Model e™ Service Events (Warranty & Customer Pay) up to 3 Years / 36,000 Miles of Ownership',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Service Experiences',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria:
							'Return at Minimum State of Charge 20% Greater (up to 80% max SOC)',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Cleaned (Exterior Wash)',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Current with Latest Software Updates',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
					{
						rowStatus: 'onWaiver',
						rowCriteria:
							'100% Loaner Offer for Pick Up & Delivery or Mobile Service',
						required: 1,
						atDealer: 1,
						waiverDate: '06/22/2023',
					},
				],
			},
		],
	},
};
const completeDealerInfo: DealerInfo = {
	dealerName: 'Metropolitan Ford',
	dealerUser: 'Lenny Dykstra',
	dealerType: 'Certified Elite Dealer',
	PACode: 24680,
	salesCode: 98765,
	marketArea: 'East',
	region: 'New York',
	meQualifier: {
		sectionTitle: 'Ford Model e™ Qualifier',
		totalTasks: 3,
		tasksComplete: 3,
		panelData: [
			{
				panelTitle: 'Charging Infrastructure',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'NEMA',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Level 2 Chargers',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Level 3 Chargers',
						required: 1,
						atDealer: 1,
						waiverDate: '06/22/2023',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Level 3 Public on BOCN',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Training',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'Specialized Team Identified in STARS',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Service Capacity',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'Completed Assessment',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Acquired Tools/Equipment',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Retail Environment',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'Acquire and Activate Tablet',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'eCommerce',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'EMP Set-Up, Training, Route One',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'GuestXP',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'Enrolled, Trained, Installed',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
		],
	},
	foundationalCompliance: {
		sectionTitle: 'Foundational Compliance',
		meQComplete: true,
		totalTasks: 3,
		tasksComplete: 3,
		panelData: [
			{
				panelTitle: 'Charging Availability',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'Public Charging',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Training',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'Maintain Specialized Team',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'EV University Level 1 & Level 2',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'FGE Immersion',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
					{
						rowStatus: 'onWaiver',
						rowCriteria: 'Ford Model e™ FGE Intensive',
						required: 1,
						atDealer: 1,
						waiverDate: '06/22/2023',
					},
					{
						rowStatus: 'onWaiver',
						rowCriteria: 'Tech 43/44',
						required: 1,
						atDealer: 1,
						waiverDate: '06/22/2023',
					},
				],
			},
			{
				panelTitle: 'Service Capacity',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: 'Support Reoccurring Consultations',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Retail Environment',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'Tablet Utilization',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'eCommerce',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria: 'Transparent Pricing',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'deferred',
						rowCriteria:
							'Sales Initiated & Completed via Ford.com eCommerce Platform',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'deferred',
						rowCriteria: 'Digital Closing Statement',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'GuestXP',
				panelStatus: 'deferred',
				rows: [
					{
						rowStatus: 'deferred',
						rowCriteria:
							'Offering Service Scheduling (Online & In-Person via GuestXP)',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'deferred',
						rowCriteria: 'Input 100% of Warranty Events',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'deferred',
						rowCriteria: 'Input 100% of EV Customer Pay Events',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
		],
	},
	mecpCompliance: {
		sectionTitle: 'MeCP Compliance',
		meQComplete: true,
		totalTasks: 4,
		tasksComplete: 1,
		panelData: [
			{
				panelTitle: 'Sales Process',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria: 'Tablet Utilization - Delivery Day Tool',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria:
							'Min 3 Demo Units in Stock (of Different Series)',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Guest Check-Ins',
				panelStatus: 'complete',
				rows: [
					{
						rowStatus: 'complete',
						rowCriteria: '7-10 Day Check-Ins',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria:
							'Offer and Schedule 1:1 Discover Your Ford Guide Session',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Remote Experiences',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria:
							'Offer Pick Up & Delivery with Loaner Offer or Mobile Service for 100% of Ford Model e™ Service Events (Warranty & Customer Pay) up to 3 Years / 36,000 Miles of Ownership',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
				],
			},
			{
				panelTitle: 'Service Experiences',
				panelStatus: 'incomplete',
				rows: [
					{
						rowStatus: 'incomplete',
						rowCriteria:
							'Return at Minimum State of Charge 20% Greater (up to 80% max SOC)',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Cleaned (Exterior Wash)',
						required: '-',
						atDealer: '-',
						waiverDate: '-',
					},
					{
						rowStatus: 'complete',
						rowCriteria: 'Current with Latest Software Updates',
						required: 5,
						atDealer: 5,
						waiverDate: '-',
					},
					{
						rowStatus: 'onWaiver',
						rowCriteria:
							'100% Loaner Offer for Pick Up & Delivery or Mobile Service',
						required: 1,
						atDealer: 1,
						waiverDate: '06/22/2023',
					},
				],
			},
		],
	},
};
export { dealerInfo, completeDealerInfo };
