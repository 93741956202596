// @ts-nocheck
import { Fragment } from "react";
import { Accordion, AccordionPanel } from "../Accordion";
import { DealerInfo } from "../../../types/dashTypes";
import { Section } from "services/dashboard.types";
import { Button, Tag } from "..";
import DashPanelCriteria from "./DashPanelCriteria";

import { getLinkText, getSecondaryHighlightText, getSecondaryStatusText, getStatusText } from "./../../utils/DashUtils";

interface MeQProps {
	title?: string;
	dealer: DealerInfo;
	section?: Section;
	isQualified: boolean;
	countryCode: string;
	documents: any;
	completionPercent: number;
	expandedOnRender?: boolean;
	dataAsOf?: string;
	t: (content: string) => string;
	tasksCompleted(a: number, b: number): any;
	id: string;
	expandPanel: React.Dispatch<React.SetStateAction<{ callback: (element: HTMLElement) => void }>>;
	collapsePanel: React.Dispatch<React.SetStateAction<{ callback: (element: HTMLElement) => void }>>;
	onLoadPanelEvent: React.Dispatch<
		React.SetStateAction<{
			callback: (element: HTMLElement, state?: "closed" | "opened") => void;
		}>
	>;
}

export const DOCUMENT_STATUS_TYPES = ["OnWaiver", "LOA"];

export const getDocumentURL = (docId) => {
	return `${process.env.REACT_APP_DASHBOARD_API_HOST}/dealer/program/document/${docId}`;
};

const MeQAccordion = (props: MeQProps) => {
	const { id, countryCode, title, documents, section, t } = props;

	const taskPercentage = Math.round((section?.completedSteps / section?.totalSteps) * 100);

	const containerClass = countryCode === "USA" ? "dealerTab-Section" : "FMEQ-section";

	return (
		<Fragment>
			<div className={`${containerClass} fds-m--t-2`}>
				<Tag tag={"div"} className={"fds-flex fds-flex-direction--row letter-spacing-0 fds-flex__items--center"}>
					<p className={"fmc-type--heading5 fds-weight--bold fds-m--x-1 fds-m--y-1"}>{title || "Ford Model e Qualifier"}</p>

					{countryCode === "CAN" && (
						<p className={"MeQ-percent fds-m--x-4 fds-m--t-2"}>
							{section.completedSteps} {t("common.of")} {section.totalSteps} {t("common.complete")} - {taskPercentage}%
						</p>
					)}
				</Tag>
				<Tag tag={"div"} className={"fds-p--x-3 fds-m--y-2"}>
					<Accordion aria-label={`${section.title} Accordion`} light id={`${id}-accordion`} className={"dash-top-accordion"} key={`${id}-accordion`} style={{ borderTop: "1px solid black" }}>
						{section?.subSections
							?.filter((s) => s?.id != 4)
							?.map((subSection) => {
								const documentList = subSection?.criterias?.reduce((acc, cur) => {
									const ob = cur?.criteriaList
										?.filter((subCrt) => DOCUMENT_STATUS_TYPES?.includes(subCrt?.status) && documents?.[subCrt?.complianceGroupId])
										?.reduce((acc2, cur2) => {
											const docId = documents?.[cur2?.complianceGroupId];
											return {
												...acc2,
												[cur2?.status]: getDocumentURL(docId),
											};
										}, {});
									return {
										...acc,
										...ob,
									};
								}, {});
								const panelDocuments = Object.keys(documentList)?.map((document) => {
									return {
										linkText: getLinkText(document, t, countryCode),
										linkPath: documentList?.[document],
									};
								});
								const subSectionStatus = subSection.status;
								const { attestationStatus, attestationId } = subSection || {};
								return (
									<AccordionPanel
										id={subSection.name}
										label={subSection.name}
										dealerStatus={subSectionStatus}
										attestationId={attestationId}
										key={Math.random().toString(36).slice(2)}
										statusText={getStatusText(subSectionStatus, t)}
										secondaryHighlightText={getSecondaryHighlightText(subSectionStatus, t, attestationId, attestationStatus)}
										secondaryStatusText={getSecondaryStatusText(subSectionStatus, t, countryCode)}
										innerText={t("common.details")}
										panelLinks={panelDocuments}
										countryCode={countryCode}
									>
										{subSection?.reportUrl && (
											<div className={"w-100 fds-flex fds-flex--center"}>
												<Button tag="a" target="_blank" href={subSection?.reportUrl} isOutlined noShadow buttonClassName="dlalign" rightIcon={`fds-icon--48 fds-m--l-1 fds-font--ford-icons-open`}>
													View Verification Report{" "}
												</Button>
											</div>
										)}
										{subSection?.criterias?.map((criteria, idx) => (
											<DashPanelCriteria countryCode={countryCode} key={Math.random().toString(36).slice(2)} criteria={criteria} isLast={idx + 1 === subSection?.criterias?.length} subSectionName={subSection.name} sectionId={subSection.id} />
										))}
									</AccordionPanel>
								);
							})}
					</Accordion>
				</Tag>
			</div>
		</Fragment>
	);
};

export default MeQAccordion;
