import React, { FC } from 'react'
import { Button, Col, Row, Tag } from '../components/components'
import EnrolledEmployeesList from '../components/components/ModelE/EnrolledEmployeesList'
import { DealerEmailPreferencePojo } from 'services/email-notification-preferences.types'
import { useGetAllEmailPreferencesByPaCodeQuery } from 'services/email-notification-preferences'
import { useAuth } from 'providers/AuthProvider'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

// Define Props Type (if needed)
interface EnrolledEmployeesProps {}

// Header Component
const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <Row className="fds-flex--center">
      <Tag tag="h1" className="fmc-type--heading3 fds-color--primary fds-p--y-2 fds-align--center">
        {t('pages.enrolledEmployees.header')}
      </Tag>
    </Row>
  );
};

// Main Content
const MainContent: FC<{ employees: DealerEmailPreferencePojo[]; businessName?: string, isDealer: boolean }> = ({ employees, businessName, isDealer }) => {
  const { t } = useTranslation();

  return (
    <>
      <small>{t('pages.enrolledEmployees.dealerLabel')}</small>
      <p>{businessName || ''}</p>
      <Tag tag="div" className="fds-p--y-3">
        <EnrolledEmployeesList key={Math.random().toString(36).substring(7)} employees={employees} />
      </Tag>

      {isDealer && (
        <Button tag="a" href="/email-preferences" buttonClassName="fds-m--y-3">
          {t('pages.enrolledEmployees.button')}
        </Button>
      )}
    </>
  );
};

// Enrolled Employees
const EnrolledEmployees: FC<EnrolledEmployeesProps> = () => {
  const { t } = useTranslation();
  const { paCode: paCodeFromUrl } = useParams();
  const { isDealer } = useAuth();
  const location = useLocation();
  const stateDataFromLink  = location.state || {};

  const tokenProps = useAuth();
  
  const { data: initialRequestData, isLoading, isError, currentData } = useGetAllEmailPreferencesByPaCodeQuery(paCodeFromUrl ?? tokenProps.tokenProps.authDetails.paCode ?? '');

  const dealerName = stateDataFromLink?.dealerName || tokenProps?.tokenProps?.authDetails?.businessName || '';

  return (
    <Col xs={12} className="fds-m--t-3 fds-md:fds-m--x-4 fds-lg:fds-m--x-3 fds-xl:fds-m--x-4 fds-md:fds-m--b-3 fds-sm:fds-m--b-1">
      <Row className="fds-flex--center">
        <Col xs={11} lg={10} className="fds-p--l-0 fds-m--b-1">
          <Header />
            <MainContent isDealer={isDealer} employees={initialRequestData?.data as unknown as DealerEmailPreferencePojo[]} businessName={dealerName} />
        </Col>
      </Row>
    </Col>
  );
};

export default EnrolledEmployees;
