import React from "react";
import { Accordion, AccordionPanel } from "../Accordion";
import { Tag } from "..";
import { DealerEmailPreferencePojo } from "services/email-notification-preferences.types";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface EmployeeListMobileProps {
	employees: DealerEmailPreferencePojo[];
}

const EmployeeListMobile: React.FC<EmployeeListMobileProps> = ({ employees }) => {
	const { countryCode } = useSelector((state: RootState) => state.localePreferences);
	return (
		<Accordion id={"employeeListAccordion"} light={true} key={Math.random().toString(36).substring(7)}>
			{" "}
			{/* Accordion implementation */}
			{employees.map((employee, index) => {
				const employeePreference = employee.isEmailOptin ? "On" : "Off";
				return (
					<AccordionPanel countryCode={countryCode} key={Math.random()} label={`${employee.contactFirstname} ${employee.contactLastname}`} className="fmc-accordion__panel employee-list-accordion" id={`${index}`}>
						<Tag tag={"div"} className={"employee-list-container"}>
							<p className={"employee-list-title"}>Email Notifications</p>
							<p className={"employee-list-item"}>{employeePreference}</p>

							<p className={"employee-list-title"}>WSL-ID</p>
							<p className={"employee-list-item"}>{employee.wslid}</p>

							<p className={"employee-list-title"}>Email</p>
							<p className={"employee-list-item"}>{employee.emailAddress}</p>

							<p className={"employee-list-title"}>Phone Number</p>
							<p className={"employee-list-item"}>{employee.contactPhone}</p>
						</Tag>
					</AccordionPanel>
				);
			})}
		</Accordion>
	);
};

export default EmployeeListMobile;
