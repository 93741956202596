import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ContactFormData } from './contact-us.types';
import { createBaseQuery } from './overrides/createBaseQuery';

export const contactApi = createApi({
  reducerPath: 'contactApi',

  baseQuery: createBaseQuery({
    baseUrl: `${process.env.REACT_APP_CONTACT_API_HOST}/mail/contact-us`,
    config: {
      includeCountryCode: true,
      includeLanguage: true,
      includeApiKey: true,
    },
  }),

  endpoints: (builder) => ({
    postContactData: builder.mutation<boolean, ContactFormData>({
      query: (data) => ({
        url: 'send',
        method: 'POST',
        body: data
      }),
    }),
  }),
});

export const { usePostContactDataMutation } = contactApi;
