import { Fragment, useContext } from "react";
import NavBar from "../Navigation/NavBar";
import Footer from "../Navigation/Footer";
import { Outlet } from "react-router-dom";

import "../../scss/styles.scss";
import ScrollToTop from "../Navigation/ScrollToTop";
import { useTranslation } from "react-i18next";

function Layout() {
	const { t } = useTranslation();
	const navItems = [
		{
			label: t("components.navbar.dashboard"),
			aria: t("components.navbar.dashboard"),
			href: "/",
			id: "sectionDashboard",
		},
		{
			label: t("components.navbar.resources"),
			aria: t("components.navbar.resources"),
			href: "resources",
			id: "sectionResources",
		},
		{
			label: t("components.navbar.loaWaivers"),
			aria: t("components.navbar.loaWaivers"),
			href: "loaWaiver",
			id: "loaWaivers",
		},

		// {
		//     label: 'Program Standards',
		//     aria: 'Link to: Program Standards',
		//     href: 'program-standards',
		//     id: 'sectionProgramStandards',
		// },
		{
			label: t("components.navbar.reports"),
			aria: t("components.navbar.reports"),
			href: "reports",
			id: "sectionReports",
		},

		// {
		//     label: 'Contact',
		//     aria: 'Link to: Contact',
		//     href: 'https://fordbbashelpdesk.com/index.php?/Tickets/Submit',
		//     id: 'sectionContact',
		// },
	];

	const siteNavItems = [
		{
			label: "Terms & Conditions",
			href: t("components.footer.accesibilityLink"),
		},
		{
			label: "Accessibility",
			href: "#",
		},
		{
			label: "Privacy",
			href: "#",
		},
		// {
		// 	label: 'Cookie Settings',
		// 	href: '#',
		// },
		{
			label: "Your CA Privacy Rights",
			href: "#",
		},
		{
			label: "Ford.com",
			href: "#",
		},
	];
	return (
		<Fragment>
			<ScrollToTop />

			<NavBar navItems={navItems} />
			<Outlet />
			{/* California Residents Section */}

			{/* BBAS section */}

			<Footer background={"none"} siteNavItems={siteNavItems} />
		</Fragment>
	);
}

export default Layout;

{
	/* California Residents Section */
}
{
	/* <Section className={'fds-color__bg--gray1 fds-m--t-3'}>
                <Row padding={{ dir: 'y', amt: 1 }} justify={'center'}>
                    <Col xs={10} className={'fds-align--center'}>
                        <Tag
                            tag={'h6'}
                            className={'fmc-type--heading6 fds-weight--medium fds-color--primary'}
                        >
                            California Residents
                        </Tag>
                    </Col>
                    <Col xs={10} className={'fds-align--center'} padding={{ dir: 'y', amt: 2 }}>
                        <Tag tag={'p'} className={'fmc-type--body1 fds-color--primary'}>
                            Exercise your rights under the California Consumer Privacy Act
                        </Tag>
                    </Col>
                    <Col xs={12} sm={10} className={'fds-align--center'}>
                        <Button tag={'a'} href={'#'} style={{ padding: '2rem' }}>
                            Do Not Sell My Personal Information
                        </Button>
                    </Col>
                </Row>
            </Section> */
}
{
	/* BBAS section */
}
{
	/* <Section>
                <Col xs={12} className={'fmc-divider--horizontal'}>
                    <Row padding={{ dir: 'y', amt: 2 }} justify={'center'}>
                        <Col
                            xs={10}
                            md={6}
                            className={'fds-align--center'}
                            padding={{ dir: 'y', amt: 2 }}
                        >
                            <Tag
                                tag={'img'}
                                className={'fds-img bbas-logo--size '}
                                src={'/assets/images/body-builders-logo.svg'}
                                alt={'Logo'}
                                // style={{ maxWidth: 240 }}
                            />
                        </Col>
                        <Col
                            xs={12}
                            md={3}
                            className={
                                (windowWidth as number) >= 1024
                                    ? ''
                                    : 'fds-align--center fds-m--t-2'
                            }
                        >
                            <Tag
                                tag={'h6'}
                                className={'fmc-type--heading6 fds-weight--medium fds-color--gray2'}
                            >
                                Ford BBAS
                            </Tag>
                            <Col xs={12} className={'fds-p--x-0 fds-p--y-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    Bulletins
                                </Button>
                            </Col>
                            <Col xs={12} className={'fds-p--x-0 fds-p--b-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    Publications
                                </Button>
                            </Col>
                            <Col xs={12} className={'fds-p--x-0 fds-p--b-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    QVM Program
                                </Button>
                            </Col>
                            <Col xs={12} className={'fds-p--x-0 fds-p--b-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    Contact
                                </Button>
                            </Col>
                            <Col xs={12} className={'fds-p--x-0 fds-p--b-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    BBAS Admin
                                </Button>
                            </Col>
                        </Col>
                        <Col
                            xs={12}
                            md={3}
                            className={
                                (windowWidth as number) >= 1024
                                    ? ''
                                    : 'fds-align--center fds-m--t-3 fds-m--b-4'
                            }
                        >
                            <Tag
                                tag={'h6'}
                                className={'fmc-type--heading6 fds-weight--medium fds-color--gray2'}
                            >
                                More Ford
                            </Tag>
                            <Col xs={12} className={'fds-p--x-0 fds-p--y-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    Ford.com
                                </Button>
                            </Col>
                            <Col xs={12} className={'fds-p--x-0 fds-p--b-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    Ford Commercial Vehicle Center
                                </Button>
                            </Col>
                            <Col xs={12} className={'fds-p--x-0 fds-p--b-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    Ford Fleet
                                </Button>
                            </Col>
                            <Col xs={12} className={'fds-p--x-0 fds-p--b-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    Ford Credit
                                </Button>
                            </Col>
                            <Col xs={12} className={'fds-p--x-0 fds-p--b-1'}>
                                <Button
                                    tag={'a'}
                                    href={'#'}
                                    textButton
                                    className={
                                        'fmc-type--body1 fds-weight--medium fds-color--gray2'
                                    }
                                >
                                    Ford Upfits
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Section> */
}
