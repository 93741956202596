import React, { FC, ReactNode, Fragment } from 'react';
import clsx from 'clsx';
import { Tag } from '../';

interface StepProp {
	label: string;
	active: boolean;
	current: boolean;
	completed: boolean;
	disabled?: boolean;
	stepNum?: number;
}

interface TrackerProps {
	tag?: string;
	fraction?: boolean;
	className?: string;
	simple?: boolean;
	trackers: StepProp[];
	functionClick?: any;
}

const Tracker: FC<TrackerProps> = (props) => {
	const {
		tag = 'ul',
		simple = false,
		fraction = false,
		trackers,
		functionClick,
		className,
		...attributes
	} = props;

	const classes = [
		'om-fmc-tracker',
		'fmc-tracker',
		{ 'fmc-tracker--simple': simple },
	];

	const ClassName = clsx(classes, className);

	return (
		<Tag tag={tag} className={ClassName} {...attributes}>
			{trackers &&
				trackers.map((props, idx, { length }) => {
					const {
						label,
						current = false,
						completed = false,
						active = false,
						disabled = false,
						stepNum,
					} = props;

					const TrackerClassName = clsx([
						'fmc-tracker__step',
						{ 'fmc-tracker__step--complete': completed },
					]);

					const TrackerButtonClassName = clsx([
						'fmc-tracker__dot',
						{ 'fmc-tracker__dot--success': completed && !active },
						{ 'fmc-tracker__dot--active': !completed && active },
					]);

					const TrackerLabelClassName = clsx([
						'fmc-tracker__title',
						{ 'fmc-tracker__title--future': !completed && !active },
					]);

					return (
						<Fragment key={Math.random()}>
							<Tag
								tag={tag === 'ul' ? 'li' : 'div'}
								className={TrackerClassName}
							>
								{functionClick ? (
									<Tag
										tag={'button'}
										className={TrackerButtonClassName}
										aria-label={label}
										{...(current
											? { 'aria-current': 'step' }
											: {})}
										{...(disabled
											? { disabled: true }
											: {})}
										onClick={() => functionClick(stepNum)}
									>
										{/* {!simple ? `${idx + 1}${fraction ? '/' + length : ''}` : ''} */}
										{simple ? (
											''
										) : completed ? (
											<span
												className={
													'fds-icon fds-font--ford-icons__tick'
												}
											/>
										) : (
											`${idx + 1}${
												fraction ? '/' + length : ''
											}`
										)}
									</Tag>
								) : (
									<Tag
										tag={'button'}
										className={TrackerButtonClassName}
										aria-label={label}
										{...(current
											? { 'aria-current': 'step' }
											: {})}
										{...(disabled
											? { disabled: true }
											: {})}
									>
										{/* {!simple ? `${idx + 1}${fraction ? '/' + length : ''}` : ''} */}
										{simple ? (
											''
										) : completed ? (
											<span
												className={
													'fds-icon fds-font--ford-icons__tick'
												}
											/>
										) : (
											`${idx + 1}${
												fraction ? '/' + length : ''
											}`
										)}
									</Tag>
								)}

								<Tag
									tag={'p'}
									className={TrackerLabelClassName}
								>
									{label}
								</Tag>
							</Tag>
						</Fragment>
					);
				})}
		</Tag>
	);
};

export default Tracker;
