import BaseRouter from "routes/BaseRouter";
import "./App.css";
import useAuthorization from "hooks/useAuthorization";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { RootState } from "store";
import { useSelector } from "react-redux";

function App() {
	const { isAuthenticated, isDealer, isEmployee } = useAuthorization();
	const { countryCode } = useSelector((state: RootState) => state.localePreferences);
	const navigate = useNavigate();

	if (!isAuthenticated) {
		return null; // Prevents render of the app if not authenticated
	}

	// useEffect(() => {
	// 	if (countryCode === "USA" && (isDealer || isEmployee)) navigate("/sunset");
	// }, [isDealer, isEmployee, countryCode]);

	return (
		<div className="total-max-body">
			<BaseRouter />
		</div>
	);
}

export default App;
