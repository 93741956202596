import React from "react";
import { Tag } from "..";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface DashBatteryProps {
	dealerProgress: number;
	isLandingButton?: boolean;
}

const DashBattery = (props: DashBatteryProps) => {
	const { dealerProgress, isLandingButton } = props;
	let batteryPower = 0;
	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	if (dealerProgress < 10) {
		batteryPower = 0;
	} else if (dealerProgress == 10 || dealerProgress < 20) {
		batteryPower = 1;
	} else if (dealerProgress == 20 || dealerProgress < 30) {
		batteryPower = 2;
	} else if (dealerProgress == 30 || dealerProgress < 40) {
		batteryPower = 3;
	} else if (dealerProgress == 40 || dealerProgress < 50) {
		batteryPower = 4;
	} else if (dealerProgress == 50 || dealerProgress < 60) {
		batteryPower = 5;
	} else if (dealerProgress == 60 || dealerProgress < 70) {
		batteryPower = 6;
	} else if (dealerProgress == 70 || dealerProgress < 80) {
		batteryPower = 7;
	} else if (dealerProgress == 80 || dealerProgress < 90) {
		batteryPower = 8;
	} else if (dealerProgress == 90 || dealerProgress < 100) {
		batteryPower = 9;
	} else if (dealerProgress == 100) {
		batteryPower = 10;
	} else {
		batteryPower = 0;
	}

	const mobile = (windowWidth as number) <= 1024;

	return (
		<Tag tag={"div"} className={`${isLandingButton ? "battery-container-landing " : "battery-container"} fds-p--x-2`}>
			{batteryPower >= 0 ? (
				batteryPower == 10 ? (
					<img src={`/assets/icons/icon-battery-levels/icon-battery-completed-green-1.svg`} className={`${isLandingButton ? "fds-img" : "completeBatteryImg"}`} />
				) : (
					<img src={`/assets/icons/icon-battery-levels/battery-charged-${batteryPower}.svg`} className={`${mobile ? "mobileBatteryImg" : "fds-img fds-p--t-1"}  ${isLandingButton ? "landingBatteryImg" : ""}`} />
				)
			) : (
				<>
					<img src={`/assets/icons/icon-battery-levels/battery-charged-${batteryPower}.svg`} className={`${mobile ? "mobileBatteryImg" : "fds-img fds-p--t-1"}  ${isLandingButton ? "landingBatteryImg" : ""}`} />
					<p>Error: No Progress Found</p>
				</>
			)}
		</Tag>
	);
};

export default DashBattery;
