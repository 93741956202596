import React from "react";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { ContactFormData } from "services/contact-us.types";
import { FormikCharacterCountTextArea } from "./FormikCharacterCountTextArea";
import { FormikInputField } from "./FormikInputField";
import { FormikRadioGroup } from "./FormikRadioGroup";
import { useAuth } from "providers/AuthProvider";
import { useTranslation } from "react-i18next";

interface ContactFormProps {
	formData: ContactFormData;
	isLoading: boolean;
	onSubmit: (data: ContactFormData) => Promise<void>;
}

const ContactForm: React.FC<ContactFormProps> = ({ formData, isLoading, onSubmit }) => {
	const { tokenProps } = useAuth();
	const { t } = useTranslation();

	// Merge tokenProps with formData for initial values
	const initialValues: ContactFormData = {
		...formData,
		requestType: formData.requestType || "General",
		firstName: tokenProps?.authDetails?.firstName || "",
		lastName: tokenProps?.authDetails?.lastName || "",
		email: tokenProps?.authDetails?.mail || "",
		dealerName: tokenProps?.authDetails?.businessName || "",
		salesCode: tokenProps?.authDetails?.salesCode || "",
	};

	const validationSchema = Yup.object({
		firstName: Yup.string()
			.required(t("pages.contact.formInputs.nameError1"))
			.min(3, t("pages.contact.formInputs.firstNameLengthError"))
			.matches(/^[a-zA-Z][a-zA-Z0-9 .,'-]*$/, t("pages.contact.formInputs.errorFName")),
		lastName: Yup.string()
			.required(t("pages.contact.formInputs.nameError2"))
			.min(3, t("pages.contact.formInputs.lastNameLengthError"))
			.matches(/^[a-zA-Z][a-zA-Z0-9 .,'-]*$/, t("pages.contact.formInputs.errorLName")),
		email: Yup.string()
			.matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, { message: t("pages.contact.formInputs.emailError") })
			.required(t("pages.contact.formInputs.emailError")),
		message: Yup.string().required(t("pages.contact.formInputs.textAreaError")).max(100, t("pages.contact.formInputs.textAreaErrorMaxLength")),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{(formik) => (
				<Form className={isLoading ? "loading" : ""}>
					<div className="fds-xs:fds-flex__col-12">
						<div className="w-100">
							<small className="fds-p--l-1" style={{ fontSize: "12px", color: "rgb(77, 77, 77)" }}>
								{t("pages.contact.formInputs.typeInputLabel")}
							</small>
							<FormikRadioGroup name="requestType" options={[{ label: t("pages.contact.formInputs.typeChoice1"), value: "General" }]} />

							<input type="hidden" name="requestType" value="General" />

							<div className="fds-flex__row  fds-m--b-1">
								<div className="fds-xs:fds-flex__col-6">
									<FormikInputField name="firstName" placeholder={t("pages.contact.formInputs.fNameInput")} label={t("pages.contact.formInputs.fNameInput")} />
								</div>

								<div className="fds-xs:fds-flex__col-6">
									<FormikInputField name="lastName" placeholder={t("pages.contact.formInputs.lNameInput")} label={t("pages.contact.formInputs.lNameInput")} />
								</div>
							</div>

							<div className="fds-flex__row">
								<div className="fds-xs:fds-flex__col-12">
									<FormikInputField name="email" placeholder={t("pages.contact.formInputs.emailInput")} label={t("pages.contact.formInputs.emailInput")} />

									{formik.values.dealerName && (
										<div className="fds-p--y-1">
											<small style={{ fontSize: "12px", color: "rgb(77, 77, 77)" }}>{t("pages.contact.formInputs.dealershipLabel")}</small>
											<p className="fds-p--y-1"> {formik.values.dealerName}</p>
										</div>
									)}

									{formik.values.salesCode && (
										<div className="fds-p--y-1">
											<small style={{ fontSize: "12px", color: "rgb(77, 77, 77)" }}>{t("pages.contact.formInputs.salesCodeLabel")}</small>
											<p className="fds-p--y-1"> {formik.values.salesCode}</p>
										</div>
									)}
								</div>
							</div>

							<div className="fds-xs:fds-flex__col-12">
								<FormikCharacterCountTextArea
									name="message"
									placeholder="pages.contact.formInputs.textAreaDefault" // Translation key for the placeholder
									maxLength={100}
									labelTranslationKey="pages.contact.formInputs.textAreaLabel" // Translation key for the label
									charCounterTranslationKey="pages.contact.formInputs.charCounter" // Translation key for the character counter
								/>
							</div>

							{/* Add other fields similarly */}
							<div className="fds-p--y-3 fds-xs:fds-flex__col-12">
								<button type="submit" className={"fmc-button"} disabled={!formik.isValid || formik.isSubmitting}>
									<span>{t("pages.contact.formInputs.sendBtn")}</span>
								</button>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ContactForm;
