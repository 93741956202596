import React from "react";

import { Tag, Button } from "..";

interface DashboardCardProps {
	title: string;
	caption?: string;
	fileUrl?: string;
	fileLinkText?: string;
	content?: any;
}

const DashboardCard = ({ title, caption, fileUrl, fileLinkText, content }: DashboardCardProps) => {
	return (
		<>
			<div className={"dealerTab-Section fds-m--t-2"}>
				<Tag tag={"div"} className={"fds-flex fds-flex-direction--column "}>
					<p className={"fmc-type--heading6 fds-weight--bold letter-spacing-0 fds-m--x-1 "}>{title}</p>
					{caption && (
						<p className={"letter-spacing-0 fds-m--x-1"} style={{ fontSize: "2rem", paddingTop: ".5rem" }}>
							{caption}
						</p>
					)}
				</Tag>
				{fileUrl && (
					<Tag tag={"div"} className={"fds-p--x-3 fds-m--y-3"}>
						<div className={"w-100 fds-flex fds-flex--center"}>
							<Button tag="a" target="_blank" href={fileUrl} isOutlined noShadow buttonClassName="dlalign" rightIcon={`fds-icon--48 fds-m--l-1 fds-font--ford-icons-open`}>
								{fileLinkText}{" "}
							</Button>
						</div>
					</Tag>
				)}
				<Tag tag={"div"} className={"fds-md:fds-p--x-3 fds-m--y-3"}>
					{content}
				</Tag>
			</div>
		</>
	);
};

export default DashboardCard;
