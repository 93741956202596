import React, { useMemo } from "react";
import { Tag } from "..";
import { Criteria } from "services/dashboard.types";
import DashTable from "./DashTable";
import DashLabelInfoRenderer from "./DashLabelInfoRenderer";
import { getSecondaryStatusText, getStatusText } from "components/utils/DashUtils";
import { useTranslation } from "react-i18next";

interface DashPanelCriteraProps {
	isLast: boolean;
	criteria: Criteria;
	mobile?: boolean;
	countryCode: string;
	subSectionName: string;
	sectionId: string | number;
	disablePadding?: boolean;
	chargingInfra?: boolean;
}

const completeIcon = "/assets/icons/meDDicon/icon-complete.svg";
const incompleteIcon = "/assets/icons/meDDicon/icon-not-complete.svg";
const DCSIcon = "/assets/icons/meDDicon/icon-deferred.svg";
const loaIcon = "/assets/icons/meDDicon/icon-LOA.svg";
const waiverIcon = "/assets/icons/meDDicon/icon-on-waiver.svg";

const DashPanelCriteria = ({ isLast, criteria, countryCode, subSectionName, sectionId, disablePadding = false, mobile = false, chargingInfra = false }: DashPanelCriteraProps) => {
	const { t } = useTranslation();
	const statusObj = useMemo(() => {
		switch (criteria.status) {
			case "Complete":
				return {
					statusIconDisplay: completeIcon,
					statusColorDisplay: "green",
				};
			case "InComplete":
				return {
					statusIconDisplay: incompleteIcon,
					statusColorDisplay: "orange",
				};
			case "Deferred":
				return {
					statusIconDisplay: DCSIcon,
					statusColorDisplay: "gray",
				};
			case "OnWaiver":
				return {
					statusIconDisplay: countryCode === "CAN" ? loaIcon : waiverIcon,
					statusColorDisplay: "green",
				};
			case "LOA":
				return {
					statusIconDisplay: loaIcon,
					statusColorDisplay: "green",
				};
			default:
				return {
					statusColorDisplay: "",
					statusIconDisplay: "",
				};
		}
	}, [criteria]);

	const isChargingInfra = sectionId?.toString() === "4";
	const statusColor = isChargingInfra ? "blue" : statusObj?.statusColorDisplay;

	return (
		<>
			{mobile ? (
				<>
					<Tag tag={"div"}>
						<div className={"fds-flex fds-flex-direction--row fds-p--y-1"}>
							{criteria?.name && (
								<>
									<p className={`mobile-dash-text dash-panel-font-${statusColor}`}>{criteria.name}</p>
									{!isChargingInfra && (
										<>
											<DashLabelInfoRenderer id={criteria?.name} key={criteria?.name} labelInfo={criteria?.labelInfo} isLast={isLast} />
											<span className={`dash-panel-status-text fds-p--l-1 dash-panel-font-${statusColor}`}>
												{getStatusText(criteria?.status, t)}
												{getSecondaryStatusText(criteria?.status, t, countryCode) && <span className={"dash-panel-font-gray"}>- {getSecondaryStatusText(criteria?.status, t, countryCode)}</span>}
											</span>{" "}
										</>
									)}
								</>
							)}
						</div>
						<DashTable countryCode={countryCode} mobile delaerCriterias={criteria?.criteriaList} sectionName={subSectionName} sectionId={sectionId} />
					</Tag>
				</>
			) : (
				<Tag tag={"div"} className={disablePadding ? "" : "panel-section"}>
					<Tag tag={"div"} className={"fds-flex fds-flex-direction--row"}>
						{!isChargingInfra && <img src={statusObj?.statusIconDisplay} className={"fds-p--r-1 row-status-icon"} />}
						{criteria?.name && (
							<p className={`dash-inner-panel-title dash-panel-font-${statusColor}`}>
								{criteria.name}
								{!isChargingInfra && (
									<>
										<DashLabelInfoRenderer id={criteria?.name} key={criteria?.name} labelInfo={criteria?.labelInfo} isLast={isLast} />:{" "}
										<span className={"dash-panel-status"}>
											{getStatusText(criteria?.status, t)} {getSecondaryStatusText(criteria?.status, t, countryCode) && <span className={"dash-panel-font-gray"}>- {getSecondaryStatusText(criteria?.status, t, countryCode)}</span>}
										</span>{" "}
									</>
								)}
							</p>
						)}
					</Tag>
					<DashTable countryCode={countryCode} chargingInfra={chargingInfra} delaerCriterias={criteria?.criteriaList} sectionName={subSectionName} sectionId={sectionId} />
				</Tag>
			)}
		</>
	);
};

export default DashPanelCriteria;
