import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectErrorStatus } from "../../../reducers/service-error.slice";
import { ErrorStates } from "types/types";

/**
 * This component is a listener for error states within the application.
 * It uses the `useEffect` hook to watch the `errorStatus` and redirects
 * the user to specific routes based on the value of `errorStatus`.
 *
 * @example
 * If `ErrorRedirectListener` is rendered in the application tree:
 * ```tsx
 * <ErrorRedirectListener />
 * ```
 * and if the errorStatus changes to `ErrorStates.NoAccess`, it will navigate
 * to `/no-access`. If it is any other error, it navigates to `/error` with state.
 *
 * @returns {null} This component does not render anything; it performs redirection side effects.
 */
const ErrorRedirectListener: React.FC = () => {
	const errorStatus = useSelector(selectErrorStatus);
	const navigate = useNavigate();

	useEffect(() => {
		if (errorStatus !== ErrorStates.NoError) {
			if (errorStatus === ErrorStates.NoAccessPage) {
				navigate("/no-access");
			} else {
				navigate("/error", { state: { errorState: errorStatus } });
			}
		}
	}, [errorStatus, navigate]);

	return null;
};

export default ErrorRedirectListener;
