import { Middleware, isRejectedWithValue } from "@reduxjs/toolkit";
import { getErrorStateFromConfig } from "config/error-handling.config";
import { setErrorStatus } from "reducers/service-error.slice";

/**
 * Determines if an error should be skipped based on its characteristics.
 * This function prevents the middleware from dispatching an error state update for:
 * - Static resource errors (e.g., image or CSS file loading errors).
 * - WebSocket errors.
 * - Canceled fetch requests.
 * - Errors with a specific payload type (likely not service related).
 *
 * @param {any} payload - The error payload from a rejected action.
 * @returns {boolean} - True if the error should be skipped, otherwise false.
 *
 * @example
 * // Skip an error from a static resource:
 * shouldSkipError({ url: "http://example.com/image.png" }) // returns true
 *
 * // Skip a WebSocket error:
 * shouldSkipError({ url: "ws://example.com/socket" }) // returns true
 *
 * // Skip a canceled fetch request:
 * shouldSkipError({ statusText: "canceled" }) // returns true
 */
const shouldSkipError = (payload: any): boolean => {
	if (payload?.url) {
		const ext = payload.url.split(".").pop()?.toLowerCase();
		if (["png", "jpg", "jpeg", "gif", "svg", "webp", "css", "js", "woff", "woff2", "ttf", "bmp", "ico", "tif", "tiff", "mp3", "wav", "ogg", "mp4", "webm", "ogv", "eot", "otf"].includes(ext)) {
			return true;
		}
	}

	if (payload?.url && payload.url.startsWith("ws://")) return true;
	if (payload?.statusText === "canceled") return true;
	if (payload?.type) return true;

	return false;
};

/**
 * Middleware to handle errors from rejected actions.
 * This middleware checks if an action is rejected and, if so, determines whether an error status should be dispatched.
 * It uses `shouldSkipError` to filter out non-service errors.
 *
 * @param {MiddlewareAPI} store - The Redux store API.
 * @returns {Middleware} - The middleware to handle service errors.
 **/
const serviceErrorMiddleware: Middleware = (store) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		const method = action.meta?.arg?.originalArgs?.method || action?.meta?.baseQueryMeta?.request?.method || "GET";

		let statusCode = typeof action.payload?.status === "string" ? (action.payload.status === "FETCH_ERROR" ? 500 : parseInt(action.payload.status) || 500) : action.payload?.status || 500;

		// Extract the path from the payload URL or use the endpoint name
		const path = new URL(action?.meta?.baseQueryMeta?.request?.url || action.payload?.url || "/", window.location.origin).pathname;

		if (!shouldSkipError(action.payload)) {
			const errorState = getErrorStateFromConfig(path, method, statusCode);

			store.dispatch(setErrorStatus(errorState));
		}
	}

	return next(action);
};

export default serviceErrorMiddleware;
