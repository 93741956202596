// @ts-nocheck
import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Tag, Row } from '../';

interface DialogProps {
	opened?: boolean;
	centered?: boolean;
	standardCTA?: boolean;
	hideCloseButton?: boolean;
	className?: string;
	aria?: string;
	id?: string;
	tag?: string;
	title?: string;
	onClick: (evt) => void;
	children?: ReactNode | JSX.Element | JSX.Element[] | string | string[];
}

const Dialog: FC<DialogProps> = (props) => {
	const {
		opened,
		centered,
		title,
		className,
		tag = 'div',
		standardCTA = true,
		hideCloseButton = false,
		aria,
		children,
		onClick,
		...attributes
	} = props;

	const classes = [
		'fmc-dialog',
		{ 'fmc-dialog--opened': opened },
		{ 'fds-align--center': centered },
	];

	const ClassName = clsx(classes, className);

	return (
		<Tag
			tag={tag}
			className={ClassName}
			aria-label={aria}
			aria-modal={true}
			role={'dialog'}
			{...attributes}
		>
			<Tag
				className={'fmc-dialog__content'}
				{...(title ? { style: { paddingTop: '3rem' } } : {})}
			>
				{!hideCloseButton && (
					<Tag
						tag={'button'}
						onClick={(evt) => {
							onClick(evt);
						}}
						className={
							'fmc-dialog__close fds-icon fds-font--ford-icons__clear'
						}
						aria-label={'A close button.'}
					/>
				)}
				{title && (
					<Row
						className={
							'fmc-dialog__content-title fmc-divider--bottom-full fds-color--primary fmc-type--body1'
						}
						padding={{ dir: 'b', amt: 1 }}
						margin={{ dir: 'b', amt: 1 }}
					>
						{title}
					</Row>
				)}
				{children}
			</Tag>
		</Tag>
	);
};

export default Dialog;
