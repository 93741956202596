import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// In your window-size.slice.ts
export interface PacodeSlice {
	pacode: string;
	region: string;
	salesCode: string;
	marketArea: string;
	wslId: string;
	dealerName: string;
}

export const pacodeSlice = createSlice({
	name: "pacodeSlice",
	initialState: {
		pacode: "",
		region: "",
		salesCode: "",
		marketArea: "",
		wslId: "",
		dealerName: "",
		zone: "",
	},

	reducers: {
		setPacode: (state: PacodeSlice, action: PayloadAction<any>) => {
			return { ...state, ...action.payload };
		},
	},
});

export const { setPacode } = pacodeSlice.actions;

export default pacodeSlice.reducer;
