import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserPreferencesState {
	preferredLanguage: string;
	countryCode: string;
	isLoading: boolean;
}

const initialState: UserPreferencesState = {
	preferredLanguage: localStorage.getItem("MMED_DEALER_LANG_CODE") || "en",
	countryCode: localStorage.getItem("MMED_DEALER_COUNTRY_CODE") || "",
	isLoading: false,
};

export const localePreferencesSlice = createSlice({
	name: "localePreferences",
	initialState,
	reducers: {
		setPreferredLanguage: (state, action: PayloadAction<string>) => {
			state.preferredLanguage = action.payload;
			localStorage.setItem("MMED_DEALER_LANG_CODE", action.payload);
		},
		setCountryCode: (state, action: PayloadAction<string>) => {
			state.countryCode = action.payload;
			localStorage.setItem("MMED_DEALER_COUNTRY_CODE", action.payload);
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
	},
});

// Export actions
export const { setPreferredLanguage, setCountryCode, setIsLoading } = localePreferencesSlice.actions;

// Export the reducer
export default localePreferencesSlice.reducer;
