import { Fragment, useState } from 'react'
import { Button, Col, Row, Tag, TopBanner } from '../components/components'

import EmailNotificationForm from '../components/components/Form/EmailNotificationForm'
import { useGetEmailPreferencesByWslIdQuery } from 'services/email-notification-preferences'
import { useAuth } from 'providers/AuthProvider'
import { useTranslation } from 'react-i18next';

const EmailNotificationPreferences = () => {

    const { tokenProps } = useAuth();

    const { t } = useTranslation();

    let { entitlementRole } = tokenProps.authDetails;

    const paCode = tokenProps?.authDetails.wslId ?? tokenProps?.tokenDetails?.uid;

    const { data: initialRequestData, isLoading, isError } = useGetEmailPreferencesByWslIdQuery(paCode || '');

    const [confirmBanner, setConfirmBanner] = useState(false);

    return (
        <Fragment>
            {confirmBanner && (
                <TopBanner
                    status={'success'}
                    persistent={true}
                    className={'no-access-success-banner'}
                    buttonClick={() => setConfirmBanner(false)}
                >
                    {' '}
                    {t('pages.emailNotificationPreferences.success')}
                </TopBanner>
            )}

            <Col
                xs={12}
                className={
                    ' fds-m--t-3 fds-md:fds-m--x-4 fds-lg:fds-m--x-3 fds-xl:fds-m--x-4 fds-md:fds-m--b-3 fds-sm:fds-m--b-1'
                }
            >
                <Row className={'fds-flex--center '}>
                    <Col xs={11} lg={10} className={'fds-p--l-0 fds-m--b-1 '}>
                        <Row className={'fds-flex--center '}>
                            <Tag
                                tag={'h1'}
                                className={
                                    'fmc-type--heading3 fds-color--primary fds-p--y-2 fds-align--center'
                                }
                            >
                                {t('pages.emailNotificationPreferences.header')}
                            </Tag>
                        </Row>
                        <Row className={'fds-p--t-2'}>
                            {!isLoading && (
                                <EmailNotificationForm data={initialRequestData?.data} setConfirmBanner={setConfirmBanner} />
                            )}
                        </Row>
                        {(entitlementRole.includes("DEALER-FULL-ACCESS-USA") || entitlementRole.includes("DEALER-FULL-ACCESS-CAN")) && (
                            <Button tag={'a'} standardButton noShadow isOutlined href={'/enrolled-employees'}>
                                {t('pages.emailNotificationPreferences.viewBtn')}
                            </Button>
                        )}

                    </Col>
                </Row>
            </Col>
        </Fragment>
    );
};

export default EmailNotificationPreferences;
