/**
 * Formats a given phone number to the format: (XXX) XXX-XXXX.
 * @param value The input string to format.
 * @returns The formatted phone number or the original input if it's already formatted.
 */
export function formatPhoneNumber(value: string): string {
    const formattedPattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (formattedPattern.test(value)) return value;

    const numericOnly = value.replace(/\D/g, '');
    if (numericOnly.length === 0) return '';
    if (numericOnly.length <= 3) return `(${numericOnly}`;
    if (numericOnly.length <= 6) return `(${numericOnly.slice(0, 3)}) ${numericOnly.slice(3)}`;
    return `(${numericOnly.slice(0, 3)}) ${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 10)}`;
}

/**
 * Adjusts the cursor position in an input element after formatting its value.
 * @param inputElement The HTML input element whose cursor position is to be adjusted.
 * @param prevValue The previous value of the input before formatting.
 * @param formattedValue The new formatted value of the input.
 */
export function adjustCursorPosition(inputElement: HTMLInputElement, prevValue: string, formattedValue: string): void {
    let cursorPosition: number = inputElement.selectionStart ?? formattedValue.length;
    const adjustment = formattedValue.length - prevValue.length;

    if (formattedValue.length === 1) {
        cursorPosition = formattedValue.length + adjustment;
    } else {
        const numericPrevLength = prevValue.replace(/\D/g, '').length;
        const numericFormattedLength = formattedValue.replace(/\D/g, '').length;
        const diff = numericFormattedLength - numericPrevLength;

        if (diff > 0) {
            cursorPosition += diff + (formattedValue[cursorPosition - 1] === ')' ? 2 : formattedValue[cursorPosition - 2] === '-' ? 1 : 0);
        }
    }

    requestAnimationFrame(() => {
        inputElement.selectionStart = inputElement.selectionEnd = cursorPosition;
    });
}
