import DashBattery from "./DashBattery";
import { Col, Tag } from "..";
import { useTranslation } from "react-i18next";

interface HiddenSectionProps {
	fmeQPercentage: number;
	title: string;
}

const HiddenSection = (props: HiddenSectionProps) => {
	const { t } = useTranslation();

	const fmeQPercentage = props.fmeQPercentage;

	const fullyQualified = fmeQPercentage === 100 ? true : false;

	return (
		<Col lg={12}>
			<Tag tag={"div"} className={"fds-flex fds-flex--center fds-flex__items--center fds-flex-direction--column"}>
				<Tag tag={"div"} className={"fds-flex fds-flex-direction--row fds-flex__items--center"}>
					<DashBattery dealerProgress={fmeQPercentage} />
					<Tag tag={"div"} className={"fds-flex fds-flex-direction--column"}>
						<p className={`fds-p--b-2 v1 hidden-battery-text ${fullyQualified ? "hidden-qualified" : ""}`}>{props?.title || "Ford Model e Qualifier"}</p>
						<p className={`v1 hidden-battery-percentage ${fullyQualified ? "hidden-qualified" : ""}`}>
							{fmeQPercentage}% {t("pages.dashboard.qualified")}
						</p>
					</Tag>
				</Tag>
				<Tag tag={"div"} className={"fds-m--t-2"}>
					<p className={"hidden-qualifier-text"}>{t("pages.dashboard.earningForFoundationalComplianceMessage")}</p>
				</Tag>
			</Tag>
		</Col>
	);
};

export default HiddenSection;
