import React, { useState, useEffect, useMemo } from "react";
import { Fragment } from "react";
import { Tag, Col, Button, Row } from "../";
import { useAuth } from "providers/AuthProvider";
import { useLocation } from "react-router";
import { useGetAdminDashboardDealerListDataQuery } from "services/admin-dashboard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setPacode } from "reducers/pacode.slice";
import { useTranslation } from "react-i18next";
import NotificationBubble from "../Notifications/NotificationBubble";
import { DealerInfo } from "types/dashTypes";

interface MobileDealerInfoProps {
	dealerInformation: DealerInfo;
}
//

const MobileDealerInfo = (props: MobileDealerInfoProps) => {
	const { t } = useTranslation();
	const dealerUserIcon = "/assets/icons/meDDicon/icon-user-avatar.svg";
	var dealersList: any = useGetAdminDashboardDealerListDataQuery();
	const tokenInfo: any = useAuth();
	const reduxDealerInfo = useSelector((state: RootState) => state.pacode);
	const reduxPacode = useSelector((state: RootState) => state.pacode.pacode);
	const { countryCode } = useSelector((state: RootState) => state.localePreferences);
	const dealerCode = countryCode === "CAN" ? t("common.dealerCode") : t("common.paCode");
	const [showMobileSelectedDealer, setShowMobileSelectedDealer]: any = useState(null);

	useEffect(() => {
		if (reduxPacode && dealersList?.data) {
			setShowMobileSelectedDealer(dealersList?.data?.data?.filter((item: any) => item.paCode === reduxPacode)[0]);
		} else {
			setShowMobileSelectedDealer(null);
		}
	}, [reduxPacode, dealersList]);

	const [mobileuserData, setMobileuserData]: any = useState({});

	useEffect(() => {
		setMobileuserData(tokenInfo?.tokenProps?.authDetails);
	}, []);

	const [open, setOpen] = React.useState(false);

	const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setOpen(!open);
	};

	return (
		<Fragment>
			<Col
				xs={12}
				sm={"auto"}
				md={5}
				lg={6}
				xl={6}
				padding={[
					{ dir: "l", amt: 0 },
					{ dir: "r", amt: 0 },
				]}
				className={""}
			>
				<Tag tag={"div"} className={"dealer-info"}>
					<Row className={"w-100"}>
						<Col xs={12} className={"fds-p--x-0 w-100"}>
							<button className={"w-100"} style={{ whiteSpace: "nowrap" }} onClick={handleOpen}>
								<Row className={"fds-flex w-100"}>
									<div className={"fds-flex w-100"}>
										<Tag tag={"div"} className={"navDealer-container fds-p--r-1"}>
											<Tag tag={"div"} className={"fds-icon navDealer-avatar "}>
												<Tag tag={"div"} className="dealerNotification">
													<Tag tag={"img"} src={dealerUserIcon} />
													<NotificationBubble />
												</Tag>
											</Tag>
										</Tag>
										<Tag tag={"div"}>
											<Col xs={12} className={"fds-flex--center fds-flex-direction--column fds-color--primary navDealer-info-mobile"} padding={{ dir: "x", amt: 0 }}>
												<Tag tag={"div"} className={"fds-weight--light"}>
													{mobileuserData && (mobileuserData?.lastName || mobileuserData?.firstName) ? `${mobileuserData.firstName} ${mobileuserData.lastName}` : "Static name"}
												</Tag>
												{mobileuserData && mobileuserData?.userType === "Dealer" ? (
													<Tag tag={"div"} className={"fds-weight--bold"}>
														{mobileuserData?.businessName}
													</Tag>
												) : null}
												{(mobileuserData?.userType === "Admin" || mobileuserData?.userType === "Employee") && showMobileSelectedDealer && showMobileSelectedDealer?.dealerName ? (
													<Tag
														tag={"div"}
														className={"fds-weight--bold"}
														style={{
															whiteSpace: "pre-wrap",
														}}
													>
														{showMobileSelectedDealer?.dealerName}
													</Tag>
												) : null}

												{mobileuserData && mobileuserData?.userType === "Dealer" ? (
													<Tag tag={"div"} className={"fds-weight--light"}>
														{mobileuserData?.indicator}
													</Tag>
												) : null}
												{(mobileuserData?.userType === "Admin" || mobileuserData?.userType === "Employee") && showMobileSelectedDealer && showMobileSelectedDealer?.dealerName ? (
													<Tag tag={"div"} className={"fds-weight--light"}>
														{showMobileSelectedDealer?.indicator}
													</Tag>
												) : null}
											</Col>
										</Tag>
										{mobileuserData && mobileuserData?.userType === "Dealer" ? (
											<span
												className={
													open
														? "fds-align--right fds-font--ford-icons__chevron-down fds-icon--offset-right-sm fds-p--t-2 open-icon-chev fds-m--x-0"
														: "fds-align--right fds-font--ford-icons__chevron-down closed-icon-chev  fds-icon--offset-right-sm fds-p--t-2 fds-m--x-0 "
												}
											></span>
										) : (
											""
										)}
										{(mobileuserData?.userType === "Admin" || mobileuserData?.userType === "Employee") && showMobileSelectedDealer && showMobileSelectedDealer?.dealerName ? (
											<span
												className={
													open
														? "fds-align--right fds-font--ford-icons__chevron-down fds-icon--offset-right-sm fds-p--t-2 open-icon-chev fds-m--x-0"
														: "fds-align--right fds-font--ford-icons__chevron-down closed-icon-chev  fds-icon--offset-right-sm fds-p--t-2 fds-m--x-0 "
												}
											></span>
										) : (
											""
										)}
									</div>
								</Row>
							</button>
							{mobileuserData && mobileuserData?.userType === "Dealer" ? (
								<div className={open ? "dealer-dropdown dealer-dropdown-shown" : "dealer-dropdown dealer-dropdown-hidden"}>
									<div className={"dealer-secondary-info"}>
										<ul>
											{mobileuserData.paCode && (
												<li>
													{dealerCode}: <span>{mobileuserData.paCode}</span>
												</li>
											)}
											{countryCode !== "CAN" && mobileuserData.salesCode && (
												<li>
													{t("common.salesCode")}: <span>{mobileuserData.salesCode}</span>
												</li>
											)}
											{reduxDealerInfo?.marketArea && (
												<li>
													{t("common.marketArea")}: <span>{reduxDealerInfo?.marketArea}</span>
												</li>
											)}
											{reduxDealerInfo?.region && (
												<li>
													{t("common.region")}: <span>{reduxDealerInfo?.region}</span>
												</li>
											)}
											{reduxDealerInfo?.zone && (
												<li>
													{t("common.zone")}: <span>{reduxDealerInfo?.zone}</span>
												</li>
											)}
										</ul>
									</div>
								</div>
							) : (
								""
							)}
							{(mobileuserData?.userType === "Admin" || mobileuserData?.userType === "Employee") && showMobileSelectedDealer && showMobileSelectedDealer?.dealerName ? (
								<div className={open ? "dealer-dropdown dealer-dropdown-shown" : "dealer-dropdown dealer-dropdown-hidden"}>
									<div className={"dealer-secondary-info"}>
										<ul>
											{reduxDealerInfo?.pacode && (
												<li>
													{dealerCode}: <span>{reduxDealerInfo?.pacode}</span>
												</li>
											)}
											{countryCode !== "CAN" && reduxDealerInfo?.salesCode && (
												<li>
													{t("common.salesCode")}: <span>{reduxDealerInfo?.salesCode}</span>
												</li>
											)}
											{reduxDealerInfo?.marketArea && (
												<li>
													{t("common.marketArea")}: <span>{reduxDealerInfo?.marketArea}</span>
												</li>
											)}
											{reduxDealerInfo?.region && (
												<li>
													{t("common.region")}: <span>{reduxDealerInfo?.region}</span>
												</li>
											)}
											{reduxDealerInfo?.zone && (
												<li>
													{t("common.zone")}: <span>{reduxDealerInfo?.zone}</span>
												</li>
											)}
										</ul>
									</div>
								</div>
							) : (
								""
							)}
						</Col>
					</Row>
				</Tag>
			</Col>
		</Fragment>
	);
};

export default MobileDealerInfo;
