import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ErrorStates, ServiceErrorState } from 'types/types';

const initialState: ServiceErrorState = {
    status: ErrorStates.NoError
};


/**
 * Redux slice to manage service error states.
 *
 * This slice contains reducers to set and reset the error status in the application's state.
 *
 * @example
 * How to use actions from this slice:
 * ```tsx
 * import { useDispatch } from 'react-redux';
 * import { setErrorStatus, resetErrorStatus } from './service-error.slice';
 *
 * const dispatch = useDispatch();
 *
 * // Set an error status
 * dispatch(setErrorStatus(ErrorStates.AdminOnly));
 *
 * // Reset the error status
 * dispatch(resetErrorStatus());
 * ```
 *
 * @property {Function} setErrorStatus - Action to set the error status.
 * @property {Function} resetErrorStatus - Action to reset the error status.
 */
export const serviceErrorSlice = createSlice({
    name: 'serviceError',
    initialState,
    reducers: {
        setErrorStatus(state, action: PayloadAction<ErrorStates>) {
            state.status = action.payload;
        },
        resetErrorStatus(state) {
            state.status = ErrorStates.NoError;
        },
    },
});

export const { setErrorStatus, resetErrorStatus } = serviceErrorSlice.actions;
export const selectErrorStatus = (state: RootState) => state.serviceError.status;

export default serviceErrorSlice.reducer;