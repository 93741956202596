import { useAuth } from "providers/AuthProvider";

const useAuthInfo = () => {
	const tokenInfo: any = useAuth();
	const { isDealer, tokenProps } = tokenInfo || {};
	const { authDetails } = tokenProps || {};
	return authDetails;
};

export default useAuthInfo;
