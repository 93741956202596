// src/reducers/contactSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ContactFormData } from 'services/contact-us.types';

interface ContactState {
  contactForm: ContactFormData;
}

const initialState: ContactState = {
  contactForm: {
	firstName: '',
	lastName: '',
	email: '',
	dealerName: '',
	salesCode: '',
	message: '',
	requestType: 'General',
  }
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setContactFormData: (state, action: PayloadAction<ContactFormData>) => {
      state.contactForm = action.payload;
    },
    resetContactFormData: (state) => {
      state.contactForm = initialState.contactForm;
    }
  }
});

export const { setContactFormData, resetContactFormData } = contactSlice.actions;

export const selectContactForm = (state: RootState) => state.contactUs.contactForm;

export default contactSlice.reducer;
