import { createApi } from "@reduxjs/toolkit/query/react";
import { getDealerCountryCode, getDealerLangCode } from "providers/AuthProvider";
import { LOADocDeatilsApiResponse } from "./loa-docs-details.types";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const loaDocDetailsApi = createApi({
	reducerPath: "loaDocDetailsApi",
	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_ADMIN_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
		},
	}),
	endpoints: (builder) => ({
		getDocmentStatusForDealer: builder.query<LOADocDeatilsApiResponse, { paCode: string; salesCode: string }>({
			query: ({ paCode, salesCode }) => ({
				url: `/admin/loaandwaiver/document/status`,
				method: "GET",
				params: {
					paCode,
					salesCode,
					countryCode: getDealerCountryCode(),
					preferredLanguage: getDealerLangCode(),
				},
			}),
		}),
	}),
});

export const { useGetDocmentStatusForDealerQuery } = loaDocDetailsApi;
