import React, { useState } from "react";
import { Col, Tag } from "..";
import { DashProgressBar } from "./DashProgressBar";
import MobileAccordion from "./MobileAccordion";
import { DashboardMessageCenter, DealerDashboard, MessageCenters, Section } from "services/dashboard.types";
import { useTranslation } from "react-i18next";
import QualificationPeriodBanner from "./QualificationPeriodBanner";
import BatterySection from "./BatterySection";
import ChargingInfraSection from "./ChargingInfraSections";

export interface MobileDashProps {
	messageCenters?: MessageCenters[];
	dealerInformation: DealerDashboard;
	sections: any;
	documents: any;
	countryCode: string;
	meqCompletionPercent: number;
	chargingInfraStatus: string;
	loaAndWaiverInfo: {
		LOA?: boolean;
		OnWaiver?: boolean;
	};
}

/**
 * @typedef MobileDashProps
 * @property {DealerDashboard} dealerInformation - Contains information about the dealer.
 */

/**
 * Mobile dashboard component that displays dealer information, progress, and earnings.
 * @param {MobileDashProps} props - The properties for the component.
 * @returns {JSX.Element} The mobile dashboard component.
 */
const MobileDash: React.FC<MobileDashProps> = ({ countryCode, chargingInfraStatus, meqCompletionPercent, loaAndWaiverInfo, documents, dealerInformation: dealerDashboardInformation, sections, messageCenters }: MobileDashProps): JSX.Element => {
	const { t } = useTranslation();

	const [, setExpandCallback] = useState({
		callback: (element: HTMLElement) => {},
	});
	const [, setCollapseCallback] = useState({
		callback: (element: HTMLElement) => {},
	});

	const [, setOnLoadCallback] = useState({
		callback: (element: HTMLElement, state?: "closed" | "opened") => {},
	});

	const { dealerName } = dealerDashboardInformation;

	/**
	 * Determines if a section is fully qualified.
	 * @param {Section} section - The section to check.
	 * @returns {boolean} True if the section is fully qualified, false otherwise.
	 */
	const isFullyQualified = (section: Section) => section.totalSteps === section.completedSteps;

	const canShowQualificationBanner = (() => {
		if (countryCode === "CAN") return true;
		const bannerDate = new Date("2024-01-15T00:00:00").getTime();
		const now = Date.now();
		return now > bannerDate;
	})();

	const loaWaiverInfo = {
		loa: Object.keys(loaAndWaiverInfo)?.includes("LOA"),
		waiver: Object.keys(loaAndWaiverInfo)?.includes("OnWaiver"),
		loaAndWaiver: Object.keys(loaAndWaiverInfo)?.includes("LOA") && Object.keys(loaAndWaiverInfo)?.includes("OnWaiver"),
		loaOnly: Object.keys(loaAndWaiverInfo)?.includes("LOA") && !Object.keys(loaAndWaiverInfo)?.includes("OnWaiver"),
		waiverOnly: !Object.keys(loaAndWaiverInfo)?.includes("LOA") && Object.keys(loaAndWaiverInfo)?.includes("OnWaiver"),
		none: !Object.keys(loaAndWaiverInfo)?.includes("LOA") && !Object.keys(loaAndWaiverInfo)?.includes("OnWaiver"),
	};

	const chargingInfraSection = sections?.[0].subSections?.find((s: any) => s.id?.toString() == "4");

	const waiverText = countryCode === "CAN" ? t("pages.dashboard.status.waiverOrException") : t("pages.dashboard.status.waiver");
	const waiverIcon = countryCode === "CAN" ? "/assets/icons/meDDicon/icon-LOA.svg" : "/assets/icons/meDDicon/icon-on-waiver.svg";

	return (
		<>
			{true && countryCode !== "CAN" && (
				<div className={"fds-m--b-1"}>
					<QualificationPeriodBanner countryCode={countryCode} messageCenters={messageCenters} />
				</div>
			)}
			<Col xs={12} className={"fds-p--t-3 fds-flex fds-flex__items--center fds-flex-direction--column"}>
				<Tag tag={"div"} className={"fds-align--center fds-m--b-2"}>
					<h1 className={"fmc-type--heading3 fds-p--x-1"}>
						{t("common.welcome")}, {dealerName}
					</h1>
				</Tag>
				<div className={"fds-flex fds-flex-direction--column"}>
					{/* <div className={"fds-p--t-1 fds-flex fds-flex--center"}>
						<p className={"fds-stretch--condensed fds-style--italic "} style={{ fontSize: "1.6rem" }}>
							<span className={"fds-color__text--gray3"}>Data as of: </span>

							<span className={"fds-weight--medium"} style={{ color: "#066FEF" }}></span>
						</p>
					</div> */}
					<div className={"fds-flex--center fds-m--y-2"}>
						<BatterySection status={chargingInfraStatus as any} fmeQPercentage={meqCompletionPercent} />
					</div>
				</div>
				<Tag tag={"div"} className={"w-100 fds-xs:fds-p--x-1 fds-sm:fds-p--x-2 fds-md:fds-p--x-3 fds-p--y-2"}>
					<Tag tag={"div"} className={"dash-icon-legend-mobile "}>
						<div className={"fds-flex fds-flex-direction--row"}>
							<div className={"mobile-icon-legend-column fds-m--t-2"}>
								<div className={"dash-legend-icon  fds-p--x-2"}>
									<img src="/assets/icons/meDDicon/icon-complete.svg" className={"dash-icon-legend-img"} />
									<p>{t("pages.dashboard.status.complete")}</p>
								</div>

								{loaWaiverInfo.waiver && (
									<div className={"dash-legend-icon fds-p--x-2"}>
										<img src={waiverIcon} className={"dash-icon-legend-img"} />
										<p>{waiverText}</p>
									</div>
								)}

								{loaWaiverInfo.loaOnly && (
									<div className={"dash-legend-icon fds-p--x-2"}>
										<img src="/assets/icons/meDDicon/icon-LOA.svg" className={"dash-icon-legend-img"} />
										<p>{t("pages.dashboard.status.loa")}</p>
									</div>
								)}
							</div>

							<div className={"mobile-icon-legend-column fds-m--t-2"}>
								<div className={"dash-legend-icon fds-p--x-2"}>
									<img src="/assets/icons/meDDicon/icon-not-complete.svg" className={"dash-icon-legend-img"} />
									<p>{t("pages.dashboard.status.incomplete")}</p>
								</div>

								{/* <div className={"dash-legend-icon fds-p--x-2"}>
									<img src="/assets/icons/meDDicon/icon-LOA.svg" className={"dash-icon-legend-img"} />
									<p>Letter of Approval (LOA)</p>
								</div> */}

								{loaWaiverInfo.loaAndWaiver && (
									<div className={"dash-legend-icon fds-p--x-2"}>
										<img src="/assets/icons/meDDicon/icon-LOA.svg" className={"dash-icon-legend-img"} />
										<p>{t("pages.dashboard.status.loa")}</p>
									</div>
								)}
							</div>
						</div>

						{/* <div className={"fds-flex__items--start fds-flex--start"}>
							{countryCode === "CAN" && (
								<div className={"dash-legend-icon fds-p--x-2"}>
									<img src="/assets/icons/meDDicon/icon-deferred.svg" className={"dash-icon-legend-img"} />
									<p>{t("pages.dashboard.dataComingSoon")}</p>
								</div>
							)}
							<div className={"dash-legend-icon fds-p--x-2"}></div>
						</div> */}
					</Tag>
					<Tag tag={"div"} className={"fds-p--y-3"}>
						<div className={"fmc-accordion js-fmc-accordion fmc-accordion--light"}>
							{sections.map((section: any, index: number) => (
								<React.Fragment key={index}>
									<MobileAccordion
										countryCode={countryCode}
										id={section?.slugId}
										t={t}
										documents={documents}
										sectionData={section}
										dealerInfo={dealerDashboardInformation}
										expandPanel={setExpandCallback}
										collapsePanel={setCollapseCallback}
										onLoadPanelEvent={setOnLoadCallback}
									/>
									{index < sections.length - 1 && (
										<React.Fragment>
											<div className="dash-mobile-spacer"></div>
											<div className={"dash-mobile-divider fds-m--x-2"}></div>
											<div className="dash-mobile-spacer"></div>
										</React.Fragment>
									)}
								</React.Fragment>
							))}
						</div>
					</Tag>

					{!!chargingInfraSection?.id && <ChargingInfraSection countryCode={countryCode} sectionId={chargingInfraSection?.id} subSection={chargingInfraSection} mobile={true} />}
				</Tag>
			</Col>
		</>
	);
};

export default MobileDash;
