import { createApi } from "@reduxjs/toolkit/query/react";
import { AuthResponseProps } from "providers/AuthProvider";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_AUTH_API_HOST}`,
		config: {
			includeApiKey: true,
			includeMmedAccessToken: true,
		},
	}),
	endpoints: (builder) => ({
		getAuthData: builder.query<AuthResponseProps, string>({
			query: () => ({ url: "/auth", method: "POST" }),
		}),
	}),
});

export const { useGetAuthDataQuery } = authApi;
