import React from 'react';
import formData from '../../../../types/LOAFormTypes';
import { Col } from '../..';

interface LOAStep3ConfProps {
	formData: formData;
	dataVerbiage: string[];
}

const LOAStep3Confirmation = (props: LOAStep3ConfProps) => {
	const { formData, dataVerbiage } = props;

	const sortVerbiage = (array: typeof dataVerbiage) => {
		const sortOrder: any = {
			'Charging Infrastructure': 0,
			Training: 1,
			'Service Capacity': 2,
			'Retail Environment': 3,
			eCommerce: 4,
			GuestXP: 5,
		};
		return [...array].sort((a, b) => {
			return sortOrder[a] - sortOrder[b];
		});
	};

	const sortedPicks = sortVerbiage(dataVerbiage);

	return (
		<div className={'fds-m--x-2 fds-m--y-2'}>
			<Col xs={12} className={'fds-p--x-0'}>
				<p className={'fds-weight--bold fds-color--primary'}>
					Selected Qualifiers
				</p>
				<p className={' fds-m--y-1 fds-font--color__gray'}>
					Please confirm Qualifiers section association
				</p>
			</Col>
			<Col>
				{sortedPicks.map((item) => {
					return (
						<p
							className={'fds-p--y-1 fds-weight--medium'}
							key={item.indexOf(item) + 1}
						>
							{item}
						</p>
					);
				})}
			</Col>
		</div>
	);
};

export default LOAStep3Confirmation;
