import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useGetCurrentAssociationDataQuery } from 'services/current-association'
import { useGetDocmentStatusForDealerQuery } from 'services/loa-docs-details'

interface CheckfileExistProps {
	docType: string;
	paCode: string;
	salesCode: string;
	countryCode: string;
	preferredLanguage: string;
	wslId: string;
}
async function checkFilesExistInDb(
	fileCheckProps: CheckfileExistProps
): Promise<{ loa: boolean; waiver: boolean }> {
	const response = useGetDocmentStatusForDealerQuery(fileCheckProps as any);
	return {
		loa: response.data?.data.loa || false,
		waiver: response.data?.data.waiver || false,
	};
}

export const checkFilesExist = createAsyncThunk(
	'files/checkExist',
	async (fileCheckProps: CheckfileExistProps) => {
		const response = await checkFilesExistInDb(fileCheckProps);
		return response;
	}
);

const filesSlice = createSlice({
	name: 'files',
	initialState: {
		loa: false,
		waiver: false,
		isLoading: false,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(checkFilesExist.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(checkFilesExist.fulfilled, (state, action) => {
				state.isLoading = false;
				state.loa = action.payload.loa;
				state.waiver = action.payload.waiver;
			});
	},
});

export default filesSlice.reducer;
