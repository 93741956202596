import React from 'react'
import { DealerEmailPreferencePojo } from 'services/email-notification-preferences.types'


interface EmployeeListTableRowProps {
	employee: DealerEmailPreferencePojo;
	index: string;
}

const EmployeeListTableRow = (props : EmployeeListTableRowProps) => {

	const {employee, index} = props;

	const notificationSetting = (employee.isEmailOptin) ? 'On' : 'Off';

	return (
        <tr key={Math.random().toString(36).substring(7)}>
            <td>{notificationSetting}</td>
            <td>{employee.wslid}</td>
            <td>{`${employee.contactFirstname} ${employee.contactLastname}`}</td>
            <td>{employee.emailAddress}</td>
            <td>{employee.contactPhone}</td>
        </tr>
    );
}

export default EmployeeListTableRow