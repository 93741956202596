import { camelizeKeys } from 'humps';

const useQueryParams = (): Record<string, string> => {
	const search =
		window.location.search?.split('?')?.[1] ||
		window.location.href?.split('#')?.[1];
	const urlParams = new URLSearchParams(search);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return camelizeKeys(Object.fromEntries(urlParams)) as Record<string, any>;
};

export default useQueryParams;
