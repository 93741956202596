import React, { Fragment, useEffect } from "react";
// import { Col, Row, Tag } from "../components/components";
import AttestationView from "./components/Attestations";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Tag } from "components/components";
import { attestationEntry } from "./components/Attestations";

const ViewAttestation = (props: any) => {
	const { paCode, chargingInfraData, toolsEquipmentData, dealerInfo } = props || {};
	// console.log({ chargingInfraData, toolsEquipmentData });

	const navigate = useNavigate();

	useEffect(() => {
		// if (!chargingInfraData && !toolsEquipmentData) {
		// 	navigate(`/${paCode}/add-attestation`);
		// }
	}, [chargingInfraData, toolsEquipmentData, paCode]);

	return (
		<Fragment>
			<Col xs={12} className={"fds-m--y-3"}>
				<Row className={"fds-flex--center fds-p--y-3 fds-m--x-3"}>
					<div className={"fds-flex--center fds-flex__items--center fds-flex-direction--column  fds-p--x-0"}>
						<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-align--center fds-p--y-2"}>
							View Attestation
						</Tag>
						<Col xs={12} className={"fds-align--center"}>
							<p className={"fds-wrap--nowrap"}>
								See current attestation responses below. To make changes please{" "}
								<Link className={"fds-decoration--underline"} to={`/${paCode}/edit-attestation`}>
									Edit Attestation.
								</Link>{" "}
							</p>
						</Col>
					</div>
				</Row>
				<Row className={"fds-flex--center fds-m--x-3"}>
					<Col xs={12}>
						<AttestationView paCode={paCode} attestationCI={chargingInfraData} attestationTE={toolsEquipmentData} />
					</Col>
				</Row>
			</Col>
		</Fragment>
	);
};

export default ViewAttestation;
