import React, { Fragment } from 'react';
import Tag from '../Tag/Tag';

interface ResourceVideoCardProps {
	title: string;
	text: string;
	video: boolean;
	videoLink: string;
	imgSrc?: string;
}

const ResourceVideoCard = (props: ResourceVideoCardProps) => {
	const { title, text, videoLink, imgSrc } = props;

	return (
		<Fragment>
			<div
				className={'fmc-cards__card card-size fds-p--x-0'}
				style={{ margin: '5px' }}
			>
				<Tag
					className={'fmc-cards__media-area fmc-cards__video'}
					style={{ contain: 'content' }}
				>
					<a
						href={videoLink}
						target={'_blank'}
						rel={'noreferrer noopener'}
					>
						{imgSrc ? (
							<img
								className="resource-vid-image"
								alt={`${title} video`}
								src={imgSrc}
							/>
						) : (
							<img
								className="resource-vid-image"
								alt={`${title} video`}
								src={'https://placehold.co/335x195'}
							/>
						)}
					</a>
				</Tag>
				<Tag tag={'div'} style={{ padding: '25px' }}>
					<h3 className={'fds-p--y-2 '}> {title}</h3>
					<div
						className={
							'fmc-cards__body fds-p--t-1 fds-color__text--primary fds-align--left fds-p--b-3'
						}
						style={{ fontSize: '14px' }}
					>
						{text}
					</div>
				</Tag>
			</div>
		</Fragment>
	);
};

export default ResourceVideoCard;
