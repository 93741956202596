/* eslint-disable sonarjs/prefer-immediate-return */
import formData from '../../../../types/LOAFormTypes';
import LOAStep1 from '../LOAStep1';
import LOAStep2 from '../LOAStep2';
import LOAStep3 from '../LOAStep3';
import LOAStep3Confirmation from './LOAStep3Confirmation';
import React, { useState } from 'react';
import EditChosenAssociations from './EditChosenAssociations';
import SelectEditTypeLOA from './SelectEditTypeLOA';
import LOAEditCompletion from './LOAEditCompletion';

interface LOAEditFormInputsProps {
	page: number;
	formData: formData;
	dataVerbiage: string[];
	fileError: string;
	associationError: string;
	handleChange: any;
	editType: string;
	handleEdit: any;
	deleteModal: boolean;
	setDeleteModal: any;
	editDisableOptions?: any;
	identifyPage: string;
	fileName: string;
	isWaiverSelected?: boolean;
	isLoaSelected?: boolean;
	editTypeError: string;
	setEditTypeError: any;
}

const LOAEditFormInputs = (props: LOAEditFormInputsProps) => {
	const {
		page,
		handleChange,
		formData,
		dataVerbiage,
		editType,
		handleEdit,
		deleteModal,
		setDeleteModal,
		editDisableOptions,
		identifyPage,
		fileName,
		fileError,
		associationError,
		isWaiverSelected,
		isLoaSelected,
		editTypeError,
		setEditTypeError,
	} = props;

	return (
		<div>
			{page === 0 ? (
				<LOAStep1
					handleChange={handleChange}
					formData={formData}
					editDisableOptions={editDisableOptions}
					page={page}
					identifyPage="edit"
					isWaiverSelected={isWaiverSelected}
					isLoaSelected={isLoaSelected}
				/>
			) : (
				<></>
			)}
			{page === 1 ? (
				<EditChosenAssociations
					formData={formData}
					dataVerbiage={dataVerbiage}
				/>
			) : (
				<></>
			)}
			{page === 2 ? (
				<SelectEditTypeLOA
					handleEdit={handleEdit}
					deleteModal={deleteModal}
					setDeleteModal={setDeleteModal}
					formData={formData}
					editTypeError={editTypeError}
					setEditTypeError={setEditTypeError}
				/>
			) : (
				<></>
			)}
			{page === 3 ? (
				<LOAStep2
					formData={formData}
					handleChange={handleChange}
					fileError={fileError}
					fileName={fileName}
				/>
			) : (
				<></>
			)}
			{/* 4 is checkboxes, 5 is confirmation 6 is finish- */}
			{page === 4 ? (
				<LOAStep3
					formData={formData}
					handleChange={handleChange}
					dataVerbiage={dataVerbiage}
					associationError={associationError}
				/>
			) : (
				<></>
			)}
			{page === 5 ? (
				<LOAStep3Confirmation
					formData={formData}
					dataVerbiage={dataVerbiage}
				/>
			) : (
				<></>
			)}
			{page === 6 ? <LOAEditCompletion /> : <></>}
		</div>
	);
};

export default LOAEditFormInputs;
