import QualificationPeriodBanner from "components/components/Dashboard/QualificationPeriodBanner";
import React, { Fragment } from "react";
import { Tag, Col, Row } from "../../components/components";
import HiddenSection from "../../components/components/Dashboard/HiddenSection";
import BatterySection from "../../components/components/Dashboard/BatterySection";
import MeQAccordion from "../../components/components/Dashboard/MeQAccordion";
import { calcTaskProgress } from "utils/CommonUtils";
import { BaseDashboarProps } from "pages/ModelEDashboard";

const CANDashboard = ({
	t,
	canShowQualificationBanner,
	countryCode,
	dealerCode,
	dashboardData,
	firstSection,
	dealerSectionsInfo,
	chargingInfraStatus,
	loaAndWaiverInfo,
	sectionsWithId,
	isQualified,
	dealerDocumentMapping,
	setExpandCallback,
	setCollapseCallback,
	setOnLoadCallback,
}: BaseDashboarProps) => {
	const { dealerName, maapEarnings, dataAsOf, marketArea, region, salesCode, sections, paCode: paCodeFromApi } = dashboardData || {};
	const welcomeMsg = `${t("common.welcome")}, ${dealerName}`;
	console.log({ loaAndWaiverInfo });
	return (
		<>
			<Fragment key={Math.random().toFixed(4)}>
				{/* Start Welcome Section */}
				{canShowQualificationBanner && <QualificationPeriodBanner countryCode={countryCode} />}
				<Col xs={12} className={"fds-m--t-3 fds-m--x-2 fds-m--b-3"}>
					<Row className={"dashboard-title-block"}>
						<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-p--y-2 "}>
							{welcomeMsg}
						</Tag>
					</Row>
					<Row className={"dashboard-title-block"}>
						<Tag tag={"p"} className={"fmc-type--body2 fds-font--color__gray fds-p--b-3"}>
							{dealerCode}: <span className={"fds-weight--bold"}>{paCodeFromApi} </span>
							{countryCode !== "CAN" && (
								<>
									<span className={"fds-m--x-2"}>•</span>
									{t("common.salesCode")}: <span className={"fds-weight--bold"}>{salesCode} </span>
								</>
							)}
							<span className={"fds-m--x-2"}>•</span>
							{t("common.marketArea")}: <span className={"fds-weight--bold"}>{marketArea} </span>
							<span className={"fds-m--x-2"}>•</span>
							{t("common.region")}: <span className={"fds-weight--bold"}>{region} </span>
						</Tag>
					</Row>
					{dataAsOf && (
						<Row className="dashboard-title-block fds-m--b-2">
							<i style={{ fontSize: "2rem" }}>
								<span className="fds-color__text--gray3">{t("pages.dashboard.dataAsOf")}: </span>
								<span className="fds-weight--bold" style={{ color: "rgb(6, 111, 239)" }}>
									{dataAsOf}
								</span>
							</i>
						</Row>
					)}
					{countryCode === "CAN" && <HiddenSection title={firstSection?.title || ""} fmeQPercentage={dealerSectionsInfo?.meqCompletionPercent} />}
					{countryCode === "USA" && <BatterySection status={chargingInfraStatus || "InComplete"} fmeQPercentage={dealerSectionsInfo?.meqCompletionPercent} />}
				</Col>

				<Col xs={12} className={"fds-m--x-2 fds-p--b--2"}>
					<Tag tag={"div"} className={"dash-icon-legend "}>
						<div className={"dash-legend-icon fds-p--x-2"}>
							<img src="/assets/icons/meDDicon/icon-complete.svg" className={"dash-icon-legend-img"} />
							<p>{t("common.complete")}</p>
						</div>

						<div className={"dash-legend-icon fds-p--x-2"}>
							<img src="/assets/icons/meDDicon/icon-not-complete.svg" className={"dash-icon-legend-img"} />
							<p>{t("common.incomplete")}</p>
						</div>

						{countryCode === "CAN" && (
							<div className={"dash-legend-icon fds-p--x-2"}>
								<img src="/assets/icons/meDDicon/icon-deferred.svg" className={"dash-icon-legend-img"} />
								<p>{t("pages.dashboard.dataComingSoon")}</p>
							</div>
						)}

						{Object.keys(loaAndWaiverInfo || {})?.includes("OnWaiver") && (
							<div className={"dash-legend-icon fds-p--x-2"}>
								<img src="/assets/icons/meDDicon/icon-LOA.svg" className={"dash-icon-legend-img"} />
								<p>{t("pages.dashboard.status.waiverOrException")}</p>
							</div>
						)}

						{Object.keys(loaAndWaiverInfo || {})?.includes("LOA") && (
							<div className={"dash-legend-icon fds-p--x-2"}>
								<img src="/assets/icons/meDDicon/icon-LOA.svg" className={"dash-icon-legend-img"} />
								<p>{t("pages.dashboard.status.loa")}</p>
							</div>
						)}
					</Tag>
				</Col>

				<Col xs={12} className={"fds-m--x-2 fds-p--b--2"}>
					{sectionsWithId?.map((section, key) => (
						<MeQAccordion
							id={section?.slugId as any}
							key={section?.slugId}
							title={section?.title}
							dealer={dealerSectionsInfo}
							isQualified={isQualified}
							documents={dealerDocumentMapping}
							completionPercent={dealerSectionsInfo?.[`${section?.slugId}CompletionPercent`]}
							section={section}
							tasksCompleted={calcTaskProgress}
							expandPanel={setExpandCallback}
							collapsePanel={setCollapseCallback}
							onLoadPanelEvent={setOnLoadCallback}
							countryCode={countryCode}
							t={t}
						/>
					))}
				</Col>
			</Fragment>
		</>
	);
};

export default CANDashboard;
