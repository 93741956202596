import React, { useMemo } from "react";
import { useAuth } from "providers/AuthProvider";
import AdminRouter from "./AdminRouter";
import DealerRouter from "./DealerRouter";
import EmployeeRouter from "./EmployeeRouter";
import { MMedUserTypes } from "types/types";
import { Route, Routes } from "react-router-dom";
import NoAccess from "pages/NoAccess";

/**
 * A functional component that serves as the base router,
 * delegating routes based on the authenticated user type.
 *
 * @returns {JSX.Element} The rendered router component
 */
const BaseRouter: React.FC = (): JSX.Element => {
	/**
	 * Retrieve the user type from the authentication context
	 * @type {MMedUserTypes}
	 */
	const { userType } = useAuth();

	const Router: JSX.Element = useMemo(() => {
		switch (userType) {
			case MMedUserTypes.Admin:
				return <AdminRouter />;
			case MMedUserTypes.Dealer:
				return <DealerRouter />;
			case MMedUserTypes.Employee:
				return <EmployeeRouter />;
			default:
				return <></>;
		}
	}, [userType]);

	return (
		<>
			<Routes>
				<Route path="no-access" element={<NoAccess />} />
				<Route path="*" element={Router} />
			</Routes>
		</>
	);
};

export default BaseRouter;
