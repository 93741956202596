import { createApi } from '@reduxjs/toolkit/query/react';
import { ResourceApiResponse } from './resource.types';
import { createBaseQuery } from './overrides/createBaseQuery';

export const resourceApi = createApi({
	reducerPath: 'resourceApi',

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_RESOURCES_API_HOST}/dealer`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
		},
		
	}),

	endpoints: (builder) => ({
		getResourceData: builder.query<ResourceApiResponse, void>({
			query: () => `resources`,
		}),
	}),
});

export const { useGetResourceDataQuery } = resourceApi;
