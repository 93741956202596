import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const contentTypeToExtension = {
	"text/plain": "txt",
	"text/html": "html",
	"text/css": "css",
	"text/csv": "csv",
	"application/json": "json",
	"application/javascript": "js",
	"application/pdf": "pdf",
	"application/xml": "xml",
	"application/zip": "zip",
	"application/vnd.ms-excel": "xls",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
	"application/msword": "doc",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
	"application/vnd.ms-powerpoint": "ppt",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
	"image/jpeg": "jpg",
	"image/png": "png",
	"image/gif": "gif",
	"image/svg+xml": "svg",
	"audio/mpeg": "mp3",
	"audio/wav": "wav",
	"video/mp4": "mp4",
	"video/x-msvideo": "avi",
	"application/octet-stream": "bin", // Default for binary files
};

const useDownload = () => {
	const navigate = useNavigate();
	const [isDownloading, setIsDownloading] = useState(false);

	const download = async (e: any, url: string, fileName?: string) => {
		setIsDownloading(true);
		if (e) {
			e?.preventDefault?.();
			e?.stopPropagation?.();
		}
		try {
			const headers: any = {
				apikey: process.env.REACT_APP_GATEWAY_API_KEY,
			};
			const response = await fetch(url, { headers });

			if (!response.ok) {
				throw new Error(`Error fetching file: ${response.statusText}`);
			}

			const blob = await response.blob();
			const contentDisposition = response.headers.get("Content-Disposition");
			const contentType = response.headers.get("Content-Type");

			let file = fileName ?? "downloaded-file";

			if (contentDisposition && contentDisposition.includes("filename=")) {
				const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
				if (fileNameMatch && fileNameMatch.length > 1) {
					file = fileNameMatch[1];
				}
			}
			setIsDownloading(false);

			const blobUrl = URL.createObjectURL(blob);
			// window.open(blobUrl, "_blank");

			const link = document.createElement("a");
			link.href = blobUrl;
			link.target = "_blank";
			link.download = file;
			link.type = contentType ?? "application/octet-stream";
			document.body.appendChild(link);
			link.click();

			// Cleanup
			setTimeout(() => {
				URL.revokeObjectURL(blobUrl);
				document.body.removeChild(link);
			}, 500);
		} catch (error) {
			navigate("/error");
		}
	};

	return {
		download,
		isDownloading,
	};
};

export default useDownload;
