import { createApi } from "@reduxjs/toolkit/query/react";
import { getDealerCountryCode, getDealerLangCode } from "providers/AuthProvider";
import { LOAWaiverAssociationApiResponse } from "./loa-association.types";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const loaAssociationApi = createApi({
	reducerPath: "loaAssociationApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_ADMIN_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
		},
	}),
	endpoints: (builder) => ({
		getAssociationData: builder.query<LOAWaiverAssociationApiResponse, void>({
			query: () => ({
				url: `/admin/compliance/qualifiers/association`,
				method: "GET",
				params: {
					countryCode: getDealerCountryCode(),
					preferredLanguage: getDealerLangCode(),
				},
			}),
		}),
	}),
});

export const { useGetAssociationDataQuery } = loaAssociationApi;
