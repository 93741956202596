import React, { Fragment, useContext } from 'react';
import { Col, Row, Tag } from '../components/components';
import "../i18n"; // Imports the i18n configuration
import { useTranslation } from "react-i18next"; // Imports the useTranslation hook
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { ErrorStates } from 'types/types';
import { useLocation } from 'react-router-dom';


const ErrorPage: React.FC = () => {
    const location = useLocation();
    const windowWidth = useSelector((state: RootState) => state.windowSize.width);
    const { t } = useTranslation();

    // Get the error state from the location state
    const navState = location.state as { errorState?: ErrorStates }; // Typecasting the state
    const errorState = navState?.errorState || 'generic'; // Default to 'generic' if no specific error state is provided

    // Determine the error message key based on the error state
    const errorMessageKey = `common.errors.${errorState}`;

    return (
        <Fragment>
            {(windowWidth as number) >= 1024 ? (
                <Row className='fds-m--x-2 fds-m--y-4'>
                    <Col xs={6}>
                        <Tag tag='div' className='fds-p--y-2 fds-p--x-3'>
                            <p className='fds-color__text--gray3 fds-p--b-2'>
                                <span className='fds-weight--bold'>
                                {t(errorMessageKey)}
                                </span>
                            </p>
                            <p className='fds-color__text--gray3'>
                                {t("common.contactMessage")}
                            </p>
                        </Tag>
                    </Col>
                    <Col xs={6} className='fmc-divider--left'>
                        <Tag tag='div' className='fds-p--y-2 fds-p--x-2'>
                            <Col xs={12}>
                                <h1 className='fds-type--title1 fds-m--b-3' style={{ fontSize: '2.5rem' }}>
                                    {t("common.assistanceContacts")}
                                </h1>

                                <div className='fds-m--y-2'>
                                    <h2 className='fds-type--title1 ' style={{ fontSize: '1.8rem' }}>
                                        {t("common.programHQ")}
                                    </h2>
                                    <a className='fds-decoration--underline' href={`mailto:${t("common.contactemail")}`} style={{ color: 'var(--fds-color--secondary)' }}>
                                        {t("common.contactemail")}
                                    </a>
                                    <p>{t("common.programHQPhone")}</p>
                                    <p>{t("common.programHQHours")}</p>
                                </div>
                            </Col>
                        </Tag>
                    </Col>
                </Row>
            ) : (
                <div className='fds-m--x-2 fds-m--y-4'>
                    <Col xs={12}>
                        <Tag tag='div' className='fds-p--y-3 fds-p--x-2'>
                            <p className='fds-color__text--gray3 fds-p--b-2'>
                                <span className='fds-weight--bold'>
                                {t(errorMessageKey)}
                                </span>
                            </p>
                            <p className='fds-color__text--gray3'>
                                {t("common.contactMessage")}
                            </p>
                        </Tag>
                    </Col>
                    <Col xs={12}>
                        <Tag tag='div' className='fds-p--y-2'>
                            <h1 className='fds-type--title1 fds-m--b-1' style={{ fontSize: '2rem' }}>
                                {t("common.assistanceContacts")}
                            </h1>

                            <div className='fds-m--y-2'>
                                <h2 className='fds-type--title1 ' style={{ fontSize: '1.5rem' }}>
                                    {t("common.programHQ")}
                                </h2>
                                <a className='fds-decoration--underline' href={`mailto:${t("common.contactemail")}`} style={{ color: 'var(--fds-color--secondary)' }}>
                                    {t("common.contactemail")}
                                </a>
                                <p>{t("common.programHQPhone")}</p>
                                <p>{t("common.programHQHours")}</p>
                            </div>
                        </Tag>
                    </Col>
                </div>
            )}
        </Fragment>
    );
};

export default ErrorPage;
