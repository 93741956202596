import React from "react";
import { MecpSection as TMecpSection, MecpSectionType } from "services/mecp-dashboard.types";
import MecpCriteriaSection from "./MecpCriteriaSection";
import { Col, Tag } from "components/components";
import DynamicDescriptionRenderer from "components/components/Utilities/DynamicDescriptionRenderer";

interface MecpSectionProps {
	type: MecpSectionType;
	section: TMecpSection;
	t: any;
	complete: boolean;
	salesCode: string;
}

const MecpLock = ({ complete }: any) => {
	return (
		<div className={"fds-flex fds-flex--center fds-flex-direction--column "}>
			<div className={"unlock-divider"}></div>
			<div>
				<div className={"fds-flex fds-flex-direction--row fds-flex--center fds-flex__items--center mecp-lock-container"}>
					<img src={`${complete ? "/assets/icons/icon-lock-unlock/icon-unlock-green.svg" : "/assets/icons/icon-lock-unlock/icon-lock-gray.svg"}`} alt="" style={{ height: "5rem", margin: "1rem" }} />
					<p className={"fds-color__text--gray3 fds-style--italic fds-p--l-1 letter-spacing-0 fds-p--t-1"} style={{ fontSize: "1.2rem" }}>
						Monthly training qualifier must be met to be eligible to earn incentives.
					</p>
				</div>
			</div>

			<div className={"unlock-divider"}></div>
		</div>
	);
};

const MecpSection = ({ type, section, complete, t, salesCode }: MecpSectionProps) => {
	if (type === "summary") {
		return <></>;
	}

	const RenderSubSections = () => {
		return section.sectionData?.map((subSections, idx) => (
			<React.Fragment key={idx}>
				{type === "split-accordion" ? (
					<Col xs={12} md={6} className={`fds-m--x-1 fds-m--y-1 fds-p--x-0 w-100 ${idx === 0 ? "mecpdivider-right" : ""}`}>
						<MecpCriteriaSection salesCode={salesCode} type={type} subSections={subSections?.subSections} t={t} />
					</Col>
				) : (
					<MecpCriteriaSection salesCode={salesCode} type={type} subSections={subSections?.subSections} t={t} />
				)}
			</React.Fragment>
		));
	};
	const sectionName = type === "overview" ? "transMemo-Section" : "dealerTab-Section";
	const colClassName = type === "overview" ? "fds-p--x-2  fds-m--y-3" : "";
	const headerWrapClass = type === "overview" ? "" : "fds-b--b-1";
	const overviewTextStyle = type === "overview" ? "landingButtonHead fds-md:fds-m--x-1 fds-m--y-1 fds-p--b-2 fds-b--b-1" : "landingButtonHead fds-md:fds-m--x-1 fds-m--y-1";

	return (
		<>
			<Col xs={12} sm={12} md={type === "overview" ? 6 : 12} className={colClassName}>
				{type === "split-accordion" && <MecpLock complete={complete} />}
				<div className={sectionName}>
					<Tag tag={"div"} className={headerWrapClass}>
						<span className={"fds-flex fds-xs:fds-flex-direction--column fds-md:fds-flex-direction--row fds-flex__items--baseline"}>
							<p className={overviewTextStyle}>{section.title}</p>
						</span>
					</Tag>

					{type === "overview" && (
						<p className="fds-color__text--gray3 fds-p--t-3 fds-p--b-2" style={{ textAlign: "center", fontSize: "2rem" }}>
							Data Coming Soon
						</p>
					)}
					{type !== "overview" && (
						<>
							<DynamicDescriptionRenderer type={section?.description?.type} content={section?.description?.content} contents={section?.description?.contents} />

							{type === "split-accordion" ? <div className={"fds-flex fds-xs:fds-flex-direction--column fds-md:fds-flex-direction--row fds-flex__items--start fds-flex--center "}>{RenderSubSections()}</div> : RenderSubSections()}

							{section?.additionalNote && (
								<p className={"fds-color__text--gray3 fds-style--italic fds-m--t-2 fds-p--t-1 letter-spacing-0"} style={{ fontSize: "1.2rem", lineHeight: "1.5rem" }}>
									Note: {section?.additionalNote}
								</p>
							)}
						</>
					)}
				</div>
			</Col>
		</>
	);
};

export default MecpSection;
