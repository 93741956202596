import React from 'react';
import Button from '../Buttons/Button';
import Tag from '../Tag/Tag';
import { useTranslation } from 'react-i18next';

interface ResourceCardProps {
	title: string;
	text: string;
	externalLink: boolean;
	buttonLink: string;
	resourceLinkType: string;
}

const ResourceCard = (props: ResourceCardProps) => {

	const { t } = useTranslation();

	const {
		title,
		text,
		externalLink,
		resourceLinkType,
		buttonLink = '/',
	} = props;

	return (
		<div
			className={' fmc-cards__card card-size'}
			style={{ padding: '25px', margin: '5px' }}
		>
			<h3 className={'fds-p--y-1 '}> {title}</h3>
			<div
				className={
					'fmc-cards__body fds-p--t-1 fds-color__text--primary fds-align--left fds-p--b-3'
				}
				style={{ fontSize: '14px' }}
			>
				{text}
			</div>
			<div className="fmc-cards__footer fds-p--y-3">
				{externalLink === true && resourceLinkType !== 'Pdf' ? (
					<Button>
						<Tag tag={'a'} href={buttonLink} target="_blank">
							<Tag
								tag={'span'}
								className={'fds-color__text--white'}
							>
								{t('pages.resources.goToSite')}
							</Tag>
							<Tag
								tag={'span'}
								className={
									'fds-p--x-1 fds-font--ford-icons-open-white om-fds-icon--16 '
								}
								style={{
									paddingBottom: '0.7rem',
								}}
							></Tag>
						</Tag>
					</Button>
				) : (
					<Button>
						<Tag
							tag={'a'}
							href={
								title ===
								'Ford Model e Dealer Dashboard User Navigation Guide'
									? '/assets/pdfs/FRD23-MDE-Model e Dashboard QuickStart Guide - Phase 1_Sep23_R8.pdf'
									: buttonLink
							}
							target="_blank"
						>
							<Tag
								tag={'span'}
								className={'fds-color__text--white'}
							>
								{t('pages.resources.download')}
							</Tag>
							<Tag
								tag={'span'}
								className={
									'fds-p--x-1 fds-font--ford-icons-download om-fds-icon--16 '
								}
								style={{
									paddingBottom: '0.5rem',
								}}
							></Tag>
						</Tag>
					</Button>
				)}
			</div>
		</div>
	);
};

export default ResourceCard;
