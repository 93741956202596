import { createApi } from "@reduxjs/toolkit/query/react";
import { LOAWaiverApiResponse } from "./loa-waiver.types";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const loaWaiverApi = createApi({
	reducerPath: "loaWaiverApi",
	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_ADMIN_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
		},
	}),

	endpoints: (builder) => ({
		getLOADataQuery: builder.mutation<LOAWaiverApiResponse, void>({
			query: (body) => {
				return {
					url: `admin/loaandwaiver/document`,
					method: "POST",
					body,
				};
			},
		}),
	}),
});

export const { useGetLOADataQueryMutation } = loaWaiverApi;
