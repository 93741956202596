import React, { useState } from 'react'
import { Button, Col, Dialog, Row, Tag } from '../..'
import { RootState, AppDispatch } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteAssociationDataThunk } from 'actions/association.action'
import { useNavigate } from 'react-router-dom'
interface LOADeleteModalProps {
	deleteModal: boolean;
	setDeleteModal: any;
	formData: any;
}
const LOADeleteModal = (props: LOADeleteModalProps) => {
	const { deleteModal, setDeleteModal, formData } = props;
	const dispatch: AppDispatch = useDispatch();

	const { pacode, marketArea, region, salesCode, dealerName } = useSelector(
		(state: RootState) => state.pacode
	);
	const { countryCode, preferredLanguage } = useSelector(
		(state: RootState) => state.localePreferences
	);
	const navigate = useNavigate();

	const [btnClick, setBtnClick] = useState(false);
	const DeleteAssociation = async () => {
		setBtnClick(true);
		const response = await dispatch(
			DeleteAssociationDataThunk({
				docType: formData.DocType.toUpperCase(),
				paCode: pacode,
				salesCode: salesCode,
				countryCode: countryCode,
				preferredLanguage: preferredLanguage,
			})
		);
		navigate(`/loawaiverdelete?type=${formData.DocType.toUpperCase()}`);
	};

	return (
		<div>
			<Dialog
				title={'Delete Waiver or LOA'}
				opened={deleteModal}
				onClick={() => setDeleteModal(false)}
			>
				<Col xs={12}>
					<Tag
						tag={'p'}
						className={
							'fds-align--center fds-m--t-3 fds-color__text--gray2 font-size--cta'
						}
					>
						You have chosen to delete {formData.DocType} documents
						for {dealerName}.
					</Tag>

					<Tag
						tag={'p'}
						className={
							'fds-align--center fds-color__text--gray2 font-size--cta fds-p--y-1'
						}
					>
						Are you sure? This action cannot be undone.
					</Tag>

					<Row className={'fds-flex__justify--center'}>
						<Col xs={6}>
							<Row className="fds-align--center fds-m--y-2">
								<Col xs={5}>
									<Button
										isOutlined
										onClick={() => setDeleteModal(false)}
										className={'font-size--cta'}
									>
										Cancel
									</Button>
								</Col>
								<Col xs={5}>
									<Button
										ariaLabel={'confirm delete'}
										onClick={() => DeleteAssociation()}
										disabled={btnClick}
										buttonClassName={'fds-m--l-2 '}
									>
										Yes, Delete
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Dialog>
		</div>
	);
};

export default LOADeleteModal;
