import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Interface defining the structure of the notifications state.
 */
interface NotificationsState {
  showUserPreferenceNotification: boolean; // Flag to show/hide user preference notification.
  count: number; // Total count of notifications.
}

/**
 * Initial state of the notifications slice.
 */
const initialState: NotificationsState = {
  showUserPreferenceNotification: false,
  count: 0,
};

/**
 * Slice for notifications management.
 * 
 * This slice is designed to handle various types of notifications within the application.
 * Currently, it manages the state for any notification.
 */
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,

  reducers: {
    /**
     * Action to set the visibility of user preference notifications.
     * 
     * @param state - The current state of the notifications.
     * @param action - Payload action with boolean value indicating the visibility state.
     */
    setUserPreferencesNotification: (state, action: PayloadAction<boolean>) => {
      state.showUserPreferenceNotification = action.payload;
      if (!action.payload) {
        // Reset count when preference notification is not shown.
        state.count = 0; 
      } else {
        // Increment count when preference notification is shown.
        state.count += 1;
      }
    },

    /**
     * Action to increment the notification count.
     * 
     * This can be used to increase the count of notifications in response to various events
     * in the application, beyond user preference notifications.
     */
    addNotification: (state) => {
      state.count += 1;
    },

    /**
     * Action to reset notifications to their initial state.
     * 
     * This clears all notifications and resets the count, making it useful for scenarios
     * where a complete reset of notification state is required.
     */
    resetNotifications: (state) => {
      state.showUserPreferenceNotification = false;
      state.count = 0;
    },
  },
});

// Exporting the actions from the notifications slice.
export const { setUserPreferencesNotification, addNotification, resetNotifications } = notificationsSlice.actions;

// Exporting the reducer of the notifications slice.
export default notificationsSlice.reducer;
