/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/prefer-immediate-return */
import React, { useEffect, useState } from 'react';
import LOAEditFormInputs from './LOAEditFormInputs';
import { Button, Row } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useGetDocmentStatusForDealerQuery } from 'services/loa-docs-details';
import { LOADocDeatilsApiResponse } from 'services/loa-docs-details.types';
import { useReplaceDocumentMutation } from 'services/delete-association';
import { useGetCurrentAssociationDataQuery } from 'services/current-association';
import { Form } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';
import { useUpdateDocumentMutation } from 'services/delete-association';
import { checkFilesExist } from 'reducers/document.slice';
import { Data } from '@react-google-maps/api';
interface EditFormLOAWaiverInterface {
	page: number;
	setPage: any;
}

const EditFormLOAWaiver = (props: EditFormLOAWaiverInterface) => {
	const { page, setPage } = props;
	const [editDisableOptions, setEditDisableOptions]: any = useState([]);
	const { pacode, marketArea, region, salesCode, wslId } = useSelector(
		(state: RootState) => state.pacode
	);

	const { countryCode, preferredLanguage } = useSelector(
		(state: RootState) => state.localePreferences
	);
	// check whether the doc exist or not
	const { data, isLoading } = useGetDocmentStatusForDealerQuery({
		paCode: pacode,
		salesCode: salesCode,
	}) as {
		data: LOADocDeatilsApiResponse | undefined;
		isLoading: boolean;
		isError: boolean;
	};

	const [updateFunc, { isError, isSuccess }]: any =
		useUpdateDocumentMutation();
	const [
		replaceFunc,
		{ isError: replaceError, isSuccess: ReplaceisSuccess },
	]: any = useReplaceDocumentMutation();

	const title = {
		0: 'Page 1',
		1: 'Page 2',
		3: 'Page 3',
		4: 'Finish',
	};

	const [formData, setFormData] = useState({
		DocType: 'LOA',
		uploadedFiles: '',
		fileName: '',
		complianceGroupId: [],
	});
	const [fileError, setFileError] = useState('');
	const [dataVerbiage, setDataVerbiage] = useState<string[]>([]);
	const [editType, setEditType] = useState<string>('');
	const [waiverUserData, setWaiverUserData]: any = useState({});
	const [showLoader, setShowLoader] = useState(false);
	const tokenInfo: any = useAuth();
	useEffect(() => {
		setWaiverUserData(tokenInfo?.tokenProps?.authDetails);
	});
	const assessCheckboxes = (e: any) => {
		const verbiageArray = [...dataVerbiage];
		const itemIndex = verbiageArray.indexOf(e.target.value);

		if (itemIndex > -1) {
			verbiageArray.splice(itemIndex, 1);
			setDataVerbiage(verbiageArray);
		} else {
			verbiageArray.push(e.target.value);
			setDataVerbiage(verbiageArray);
		}
	};

	const { data: associationData }: any = useGetCurrentAssociationDataQuery({
		docType: formData.DocType.toUpperCase(),
		paCode: pacode,
		salesCode: salesCode,
		countryCode: countryCode,
		preferredLanguage: preferredLanguage,
		wslId: wslId,
	});

	const [fileName, setFileName] = useState('');
	const [associationError, setAssociationError] = useState('');
	const [editTypeError, setEditTypeError]: any = useState('');
	const getFileName = (e: any) => {
		const uploadedFiles = e.target.files;
		const uploadedSingleFile = uploadedFiles[0].name;
		setFileName(uploadedSingleFile);
	};
	useEffect(() => {
		if (associationData && associationData.data) {
			setFormData((pre: any) => ({
				...pre,
				complianceGroupId: associationData.data.currentComplaince.map(
					(item: any) => item.groupId
				),
			}));
			setDataVerbiage(
				associationData.data.currentComplaince.map(
					(item: any) => item.groupName
				)
			);
		}
	}, [associationData]);

	const handleChange = (e: any, groupId: any) => {
		const type = e.target.type;

		const checkBox = type === 'checkbox' ? e.target : '';

		const name = e.target.name;
		const file = type === 'file' ? e?.target?.files[0] : '';
		const value = type === checkBox ? e.target.checked : e.target.value;

		if (checkBox) {
			assessCheckboxes(e);
			setFormData((prevData: any) => ({
				...prevData,
				complianceGroupId: e.target.checked
					? [...prevData.complianceGroupId, groupId]
					: prevData.complianceGroupId.filter(
							(item: any) => item !== groupId
					  ),
			}));
			setAssociationError('');
			return;
		} else if (type === 'file') {
			getFileName(e);
			setFormData((pre: any) => ({
				...pre,
				[name]: file,
				fileName: file.name,
			}));
			setFileError('');
			return;
		}

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const [deleteModal, setDeleteModal] = useState(false);
	const handleEdit = (e: any) => {
		const chosenRadio = e.target.value;
		setEditType(chosenRadio);
	};

	const { ...inputInformation } = formData;

	const canSubmit =
		[...Object.values(inputInformation)].every(Boolean) &&
		page === Object.keys(title).length - 1;

	const handlePrev = () => setPage((prev: number) => prev - 1);

	const handleNext = async (action: string) => {
		if (action === 'replace') {
			if (formData.uploadedFiles === '')
				setFileError('Please select at least one file');
			else {
				setFileError('');
				setPage((prev: number) => prev + 1);
			}
		} else if (action === 'step3') {
			if (formData.complianceGroupId.length) {
				setAssociationError('');
				setPage((prev: number) => prev + 1);
			} else {
				setAssociationError(
					'Please select at least one qualifers section association'
				);
			}
		} else if (action === 'step4') {
			if (editType === 'Change') {
				setShowLoader(true);
				const payload = {
					docType: formData.DocType.toUpperCase(),
					complianceGroupId: formData.complianceGroupId,
					salesCode,
					paCode: pacode,
					countryCode,
					preferredLanguage,
					wslId: wslId,
					documentId: associationData?.data?.docId,
				};
				await updateFunc(payload);
				setShowLoader(false);
			} else if (editType === 'Replace') {
				setShowLoader(true);
				const payload = new FormData();
				payload.append('docType', formData.DocType.toUpperCase());
				payload.append('file', formData.uploadedFiles);
				payload.append(
					'complianceGroupId',
					JSON.parse(JSON.stringify(formData.complianceGroupId))
				);
				payload.append('salesCode', salesCode);

				payload.append('paCode', pacode);
				payload.append('documentDescription', '');
				payload.append('documentName', '');

				payload.append('countryCode', countryCode);
				payload.append('preferredLanguage', preferredLanguage);

				payload.append('wslId', wslId);
				payload.append('documentId', associationData?.data?.docId);
				await replaceFunc(payload);
				setShowLoader(false);
			}
		} else {
			setPage((prev: number) => prev + 1);
		}
	};

	const radioEditDirect = () => {
		setEditTypeError('');
		if (!editType) {
			setEditTypeError('Please select at least one type of Edit');
			return;
		}

		if (editType == 'Delete') {
			setDeleteModal(true);
		} else if (editType == 'Replace') {
			// need to check
			handleNext('');
		} else {
			setPage(4);
		}
	};

	const handleSubmit = (e: { preventDefault: () => void }) => {
		e.preventDefault();
	};
	useEffect(() => {
		if (isSuccess) setPage((prev: number) => prev + 1);
	}, [isSuccess]);
	useEffect(() => {
		if (ReplaceisSuccess) setPage((prev: number) => prev + 1);
	}, [ReplaceisSuccess]);
	useEffect(() => {
		if (data) {
			const temp: any = [];
			if (data?.data?.loa) temp.push('LOA');
			if (data?.data?.waiver) temp.push('Waiver');
			setEditDisableOptions(temp);
		}
	}, [data]);
	const isWaiverSelected =
		formData.DocType === 'Waiver' && !data?.data?.waiver;
	const isLoaSelected = formData.DocType === 'LOA' && !data?.data?.loa;

	const formContent = (
		<form className={'fds-color--primary'} onSubmit={handleSubmit}>
			<LOAEditFormInputs
				page={page}
				formData={formData}
				handleChange={handleChange}
				dataVerbiage={dataVerbiage}
				editType={editType}
				handleEdit={handleEdit}
				deleteModal={deleteModal}
				setDeleteModal={setDeleteModal}
				editDisableOptions={editDisableOptions}
				identifyPage="edit"
				fileError={fileError}
				fileName={fileName}
				associationError={associationError}
				isWaiverSelected={isWaiverSelected}
				isLoaSelected={isLoaSelected}
				editTypeError={editTypeError}
				setEditTypeError={setEditTypeError}
			/>
			<div className="formButton-container">
				{page === 0 ? (
					<Button
						buttonClassName={'fds-m--t-2'}
						ariaLabel={'Continue to review associations'}
						onClick={() => handleNext('step1')}
						disabled={
							!editDisableOptions.includes(formData.DocType) ||
							isWaiverSelected ||
							isLoaSelected
						}
						// disabled={formData.DocType === ''}
						rightIcon
					>
						Continue to View Current Associations
					</Button>
				) : (
					<></>
				)}
				{page === 1 ? (
					<Button
						ariaLabel={'Continue to Edit'}
						onClick={() => handleNext('step2')}
						rightIcon
					>
						Continue to Edit Type
					</Button>
				) : (
					<></>
				)}
				{page === 2 ? (
					<Button
						ariaLabel={'Make Edits'}
						onClick={radioEditDirect}
						rightIcon
					>
						Make My Edits
					</Button>
				) : (
					<></>
				)}
				{page === 3 ? (
					<Button
						ariaLabel={'Make Associations'}
						onClick={() => handleNext('replace')}
						buttonClassName={'fds-m--r-2'}
						rightIcon
					>
						Continue to Make Associations
					</Button>
				) : (
					<></>
				)}
				{/* 4 is checkboxes, 5 is confirmation 6 is finish- */}
				{page === 4 ? (
					<Button
						ariaLabel={'Save Associations'}
						onClick={() => handleNext('step3')}
						rightIcon
					>
						Save Associations
					</Button>
				) : (
					<></>
				)}
				{page === 5 ? (
					<Row>
						<Button
							ariaLabel={'Edit Associations'}
							onClick={handlePrev}
							buttonClassName={'fds-m--r-1'}
							leftIcon
						>
							Edit Associations
						</Button>
						{showLoader ? (
							<p>Loading...</p>
						) : (
							<Button
								ariaLabel={'Save Associations'}
								onClick={() => handleNext('step4')}
								buttonClassName={'fds-m--l-1'}
								rightIcon
							>
								Save Associations
							</Button>
						)}
					</Row>
				) : (
					<></>
				)}
				{page === 6 ? (
					<Row className={'fds-m--t-4'}>
						<Button
							ariaLabel={'edit additional'}
							onClick={() => {
								window.location.href = '/';
							}}
							buttonClassName={'fds-m--r-2 '}
						>
							Edit Additional
						</Button>

						<Button
							ariaLabel={'dashboard'}
							onClick={() => {
								window.location.href = `/dashboard/${pacode}`;
							}}
							buttonClassName={'fds-m--l-2 LOA-button-outline'}
							dark
						>
							View Dashboard
						</Button>
					</Row>
				) : (
					<></>
				)}
			</div>
		</form>
	);

	return formContent;
};

export default EditFormLOAWaiver;
