// src/api.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MockErrorResponse, MockErrorQuery } from '../types/types';

export const mockApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_RESOURCES_API_HOST,
  }),
  endpoints: (builder) => ({
    getMockErrorResponse: builder.query<MockErrorResponse, MockErrorQuery>({
      query: ({ code }) => `/mock/response?code=${code}`,
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetMockErrorResponseQuery } = mockApi;