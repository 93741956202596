import { createApi } from "@reduxjs/toolkit/query/react";
import { CurrentAssociationApiResponse } from "./current-association.types";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const currentAssociationApi = createApi({
	reducerPath: "currentAssociationApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_ADMIN_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
			includeWslId: true,
		},
	}),

	endpoints: (builder) => ({
		getCurrentAssociationData: builder.query<
			CurrentAssociationApiResponse,
			{
				paCode: string;
				salesCode: string;
				countryCode: string;
				preferredLanguage: string;
				wslId: string;
				docType: string;
			}
		>({
			query: ({ countryCode, preferredLanguage, paCode, salesCode, wslId, docType }) => ({
				url: `admin/compliance/qualifiers/currentAssociation`,
				method: "GET",
				params: {
					docType,
					paCode,
					salesCode,
					countryCode,
					preferredLanguage,
					wslId,
				},
			}),
		}),
	}),
});

export const { useGetCurrentAssociationDataQuery } = currentAssociationApi;
