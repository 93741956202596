import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Row, Tag, Tabs, Accordion, AccordionPanel } from "../components/components";
import ResourceContainer from "../components/components/ModelE/ResourceContainer";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useWindowResize } from "hooks/useWindowResize";
import { useGetResourceDataQuery } from "services/resource";
import { UserResource } from "services/resource.types";
import { t } from "i18next";

const Resources = () => {
	const [resourceTabData, setResourceTabData]: any = useState([]);
	const { countryCode, preferredLanguage } = useSelector((state: RootState) => state.localePreferences);

	useWindowResize();

	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	const { data, isLoading, error, refetch } = useGetResourceDataQuery();

	useEffect(() => {
		// Refetch the data if the country or language switches
		refetch();
		getAllResourceData();
	}, [data, countryCode, preferredLanguage]);

	const getAllResourceData = () => {
		if (Array.isArray(data?.data)) {
			const arr: any = [];

			data?.data.map((val: any, ind: any) => {
				arr.push({
					label: `${val.typeName} (${val.userResources.length})`,
					id: `${val.typeName}`,
					aria: `Resources${ind + 1}`.toString(),
					selected: ind === 0 ? true : false,
					userResource: [],
				});

				const tempArr: any = [];
				val.userResources.map((item: UserResource) => {
					tempArr.push({
						title: item.resourceHeading,
						copy: item.resourceDescription,
						video: ind == 3 ? true : false,
						externalLink: true,
						href: item.resourceExternalLink,
						imgSrc: item.resourceImage,
						resourceLinkType: item.resourceLinkType,
					});
				});

				arr[ind].userResource = tempArr;
			});

			setResourceTabData(arr);
		}
	};

	const uniqueId = "id-" + Math.random().toString(36).substr(2, 16);
	return (
		<Fragment>
			{(windowWidth as number) >= 1024 ? (
				<Col xs={12} className={"fds-m--t-3 fds-md:fds-m--x-1 fds-lg:fds-m--x-0 fds-xl:fds-m--x-1 fds-md:fds-m--b-3 fds-sm:fds-m--b-1"}>
					<Row className={"fds-flex--center "}>
						<Col xs={12} lg={12} className={"fds-p--l-0 fds-m--b-1 "}>
							<Row className={"fds-flex--center "}>
								<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-p--y-2 fds-align--center"}>
									{t("pages.resources.header")}
								</Tag>
							</Row>
							<Col xs={12} className={"fds-flex--center fds-flex-direction--column fds-p--b-3"}>
								<Tag tag={"p"} className={"fmc-type--body2 fds-font--color__gray  fds-align--center fds-p--b-2 fds-p--r-1 "}>
									{t("pages.resources.subHead1")}
								</Tag>
								<Tag tag={"p"} className={" fmc-type--body2 fds-p--l-1 fds-align--center  fds-weight--bold fds-font--color__primary"}>
									{t("pages.resources.subHead2")}
								</Tag>
							</Col>
						</Col>
					</Row>

					<div className={"fds-p--x-0"}>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								{resourceTabData && resourceTabData.length > 0 && (
									<Tabs className={"modeleresourcetabs"} ariaLabel={"Model e Resources"} tabs={resourceTabData || []} key={uniqueId}>
										{resourceTabData &&
											resourceTabData.length > 0 &&
											resourceTabData.map((item: any, index: any) => (
												<>
													<Tag tag={"div"} tabIndex={-1} key={index}>
														<ResourceContainer cards={item.userResource} />
													</Tag>
													{item.id === "Videos" ? (
														<Tag tag={"div"} className={"fds-align--center fds-p--y-2 fds-m--t-3"}>
															<small className={"fds-color__text--gray2"}>
																For more videos about Ford EVs and customer experience, visit{" "}
																<a target="_blank" href={"http://fordtube.dealerconnection.com"}>
																	<span className={"fds-decoration--underline fds-color__text--secondary"}>Ford Tube</span>
																</a>
																.
															</small>
														</Tag>
													) : (
														""
													)}
												</>
											))}
									</Tabs>
								)}
							</Col>
						</Row>
					</div>
				</Col>
			) : (
				<Col xs={12} className={"fds-md:fds-m--x-1 fds-sm:fds-m--b-1"}>
					<Row className={"fds-flex--center "}>
						<Col xs={12} lg={12} className={"fds-p--l-0 fds-m--b-1 "}>
							<Row className={"fds-flex--start "}>
								<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-p--y-2 fds-align--left"}>
									{t("pages.resources.header")}
								</Tag>
							</Row>
							<Row className={"fds-flex--start fds-m--b-1"}>
								<Tag tag={"p"} className={"fmc-type--body2 fds-font--color__gray  fds-align--left fds-p--b-2  "}>
									{t("pages.resources.subHead1")}
								</Tag>
								<Tag tag={"p"} className={" fmc-type--body2 fds-align--left  fds-weight--bold fds-font--color__primary"}>
									{t("pages.resources.subHead2")}
								</Tag>
							</Row>
							<Accordion key={uniqueId} ariaLabel={"Model e Resources"} light id={"ModeleResourcesAccordion"}>
								{resourceTabData.length > 0 &&
									resourceTabData.map((item: any, index: any) => (
										<>
											<AccordionPanel countryCode={countryCode} key={Math.random()} id={"FMEResourcePanel"} className={`fmc-accordion__panel resources-accordion`} label={item.label}>
												<ResourceContainer cards={item.userResource} />
												{item.id === "Videos" ? (
													<Tag tag={"div"} className={"fds-align--center fds-p--y-2 fds-m--t-3"}>
														<small className={"fds-color__text--gray2"}>
															For more videos about Ford EVs and customer experience, visit{" "}
															<a target="_blank" href={"http://fordtube.dealerconnection.com"}>
																<span className={"fds-decoration--underline fds-color__text--secondary"}>Ford Tube</span>
															</a>
															.
														</small>
													</Tag>
												) : (
													<span />
												)}
											</AccordionPanel>
										</>
									))}
							</Accordion>
						</Col>
					</Row>
				</Col>
			)}
		</Fragment>
	);
};

export default Resources;
