import { createApi } from "@reduxjs/toolkit/query/react";
import { DashboardAPI, DashboardAPiResponse, DealerDocument } from "./dashboard.types";
import { createBaseQuery } from "./overrides/createBaseQuery";

export const dealerDashboardApi = createApi({
	reducerPath: "dealerDashboardApi",

	baseQuery: createBaseQuery({
		baseUrl: `${process.env.REACT_APP_DASHBOARD_API_HOST}`,
		config: {
			includeCountryCode: true,
			includeLanguage: true,
			includeApiKey: true,
		},
	}),
	endpoints: (builder) => ({
		getDealerDashboardData: builder.query<DashboardAPiResponse, string>({
			query: (paCode) => `dealer/${paCode}/dashboard`,
		}),
		getDealerDocumentInfo: builder.query<DashboardAPI<DealerDocument[]>, string>({
			query: (paCode) => `dealer/${paCode}/document`,
		}),
		updatePreferences: builder.mutation<void, { wslId: string; countryCode: string; preferredLanguage: string }>({
			query: ({ wslId, countryCode, preferredLanguage }) => ({
				url: `/dealer/preferences/update?wslId=${wslId}&countryCode=${countryCode}&preferredLanguage=${preferredLanguage}`,
				method: "PUT",
			}),
		}),
	}),
});

export const { useGetDealerDashboardDataQuery, useUpdatePreferencesMutation, useGetDealerDocumentInfoQuery } = dealerDashboardApi;
