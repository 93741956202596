import { Fragment } from 'react';
import Button from '../Buttons/Button';
import Col from '../Columns/Col';
import Row from '../Columns/Row';
import Input from '../Input/Input';
import Tag from '../Tag/Tag';

// interface LoginProps {
//     closeDialog: () => void;
// }

function Login() {
    return (
        <Fragment>
            <Col xs={12}>
                <Row className="fds-flex__justify--center fds-m--t-3">
                    <Tag
                        tag={'img'}
                        src={'/assets/images/body-builders-logo.svg'}
                        className={'fds-p--l-4 fds-m--l-2'}
                    />
                </Row>
                <Row className="fds-flex__justify--center fds-m--t-3">
                    <Col xs={6}>
                        <Input
                            id={'userNameLoginInput'}
                            aria={'User Name'}
                            placeholder={'User Name'}
                            tag={'input'}
                        />
                    </Col>
                </Row>
                <Row className="fds-flex__justify--center fds-m--t-2 fds-m--b-3">
                    <Col xs={6}>
                        <Input
                            id={'passwordLoginInput'}
                            aria={'Password'}
                            placeholder={'Password'}
                            tag={'input'}
                        />
                    </Col>
                </Row>
                <Row className="fds-align--center fds-m--b-3">
                    <Col xs={12}>
                        <Button className="font-size--cta">Log In</Button>
                    </Col>
                </Row>
            </Col>
        </Fragment>
    );
}

export default Login;
